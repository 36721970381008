import gql from 'graphql-tag';

export const statement = `
mutation updatePasswordAccount($payload:ParamUpdatePasswordAccount!) {
  updatePasswordAccount(v:$payload){
    status
  }
}
`;

export default gql`
  ${statement}
`;
