import gql from 'graphql-tag';

export const statement = `   
mutation updateProductBanner($payload:ParamProductBanner!){
    updateProductBanner(v:$payload){
        status
    }
}
`;

export default gql`
  ${statement}
`;
