import gql from 'graphql-tag';

export const statement = `
query me{
  me{
    name
    email
    phone
    account_roles {
      role
    }
  }
}
`;

export default gql`
  ${statement}
`;
