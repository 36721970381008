import gql from 'graphql-tag';

export const statement = `
query accountNavigation($payload:AccountNavigationInput){
  accountNavigation(v:$payload){
    id
    role_name
    name
    link    
    createdAt
    updatedAt               
  }
}
`;

export default gql`
  ${statement}
`;
