import gql from 'graphql-tag';

export const statement = `
query salesTotal($payload:ParamSales!) {
    salesTotal(v:$payload) {
        sub_total
        down_payment
    }
}`;

export default gql`
  ${statement}
`;
