import gql from 'graphql-tag';

export const statement = `
query invoice($spkNo:String!){
    invoice(spk_no:$spkNo) {
        product_id
        spk_no
        sub_total
        down_payment
    }
}
`;

export default gql`
  ${statement}
`;
