const String = {};
String.cut = (text, count) => {
  return text.slice(0, count) + (text.length > count ? '...' : '');
};

String.findStringInsideHaystack = (haystack, needle) => {
  const a = haystack;
  const term = needle;
  return a.filter(item => item.toUpperCase().indexOf(term) > -1);
};

String.thousandSeparator = x => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

String.generateRandom = length => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

String.capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default String;
