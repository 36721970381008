import gql from 'graphql-tag';

export const statement = `
mutation createOperator($payload:ParamOperator!){
   createOperator(v: $payload){
     email
     is_email_confirmed
     is_phone_confirmed
  }
}
`;

export default gql`
  ${statement}
`;
