import * as yup from 'yup';
import URL from 'utils/url';

const youtubeFormat = URL.youtubeFormat();

const RegexNumber = /^[0-9]*$/;
const errorMustBeNumberMesssage = 'Harus diisi dengan angka';
export default {
  product_name: yup.string().required('Nama produk harus diisi'),
  product_description: yup.string().required('Deskiripsi produk harus diisi'),
  category: yup.string().required('Kategori produk harus diisi'),
  weight_type: yup.string().required('Tipe berat produk harus diisi'),
  product_video: yup.string().when('show_product_video', show_product_video => {
    if (show_product_video) {
      return yup
        .string()
        .matches(youtubeFormat, 'Link video youtube tidak valid')
        .max(255, 'Maksimal 255 karakter');
    }
  }),
  weight: yup
    .string()
    .required('Berat produk harus diisi')
    .matches(RegexNumber, errorMustBeNumberMesssage)
    .max(3, 'Maksimal 3 karakter'),
  width: yup
    .string()
    .required('Panjang produk harus diisi')
    .matches(RegexNumber, errorMustBeNumberMesssage)
    .max(3, 'Maksimal 3 karakter'),
  wide: yup
    .string()
    .required('Lebar produk harus diisi')
    .matches(RegexNumber, errorMustBeNumberMesssage)
    .max(3, 'Maksimal 3 karakter'),
  height: yup
    .string()
    .required('Tinggi produk harus diisi')
    .matches(RegexNumber, errorMustBeNumberMesssage)
    .max(3, 'Maksimal 3 karakter'),
};
