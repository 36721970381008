import gql from 'graphql-tag';

export const statement = `
query withdrawRequest($payload:ParamWithdrawRequest) {
  withdrawRequest(v:$payload) {
    count
    rows{
      id
      amount
      status
      createdAt
      updatedAt
      image_done_proof
      media{
        secure_url
      }      
      account{        
        name
        email
        phone    
      }            
    }
  }
}
`;

export default gql`
  ${statement}
`;
