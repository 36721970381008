const Role = [
  {
    name: 'FINISH',
    value: 14,
  },
  {
    name: 'DESIGN',
    value: 5,
  },
  {
    name: 'PECAHWARNA',
    value: 9,
  },
  {
    name: 'JAHITAN',
    value: 12,
  },
  {
    name: 'PACKING',
    value: 13,
  },
  {
    name: 'POTONGAN',
    value: 6,
  },
  {
    name: 'PRESS',
    value: 11,
  },
  {
    name: 'SABLON',
    value: 10,
  },
  {
    name: 'PREPARE',
    value: 16,
  },
  {
    name: 'BORDIR',
    value: 15,
  },
];

export default Role;
