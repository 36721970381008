import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CREATE_PRODUCT_VARIANT_VALUE from 'graphql/product/variantValue/create';
import Error from 'component/error/index';
import FormVariantValue from './form';
import Validation from './validation';
import { SUCCESS_REDIRECT, FAILED_REDIRECT } from './constant';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentWrapper: {
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  media: {
    height: 350,
    backgroundSize: 'contain',
  },
  buttonChangePrice: {
    marginTop: theme.spacing(1),
  },
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  inputFile: {
    display: 'none',
  },
  imagePreview: {
    maxWidth: '100%',
  },
  header: {
    paddingTop: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function AddProductCategoryInternal() {
  const classes = useStyles();
  const AddProductVariantValue = yup.object().shape({
    ...Validation,
  });
  const { register, handleSubmit, errors, formState, setValue } = useForm({
    mode: 'onChange',
    validationSchema: AddProductVariantValue,
    submitFocusError: false,
    nativeValidation: false,
  });

  const [addProductVariantValue, { loading, error: errorProductVariantValue }] = useMutation(
    CREATE_PRODUCT_VARIANT_VALUE,
    {
      onCompleted: async () => {
        window.location.href = SUCCESS_REDIRECT;
      },
      onError: () => {
        window.location.href = FAILED_REDIRECT;
      },
    },
  );

  const onSave = async dataAddProduct => {
    const reassign = dataAddProduct;
    reassign.id_variant = parseInt(dataAddProduct.id_variant, 10);
    await addProductVariantValue({
      variables: {
        payload: reassign,
      },
    });
  };

  if (errorProductVariantValue) {
    return <Error />;
  }

  return (
    <FormVariantValue
      setValue={setValue}
      formState={formState}
      isEdit={false}
      classes={classes}
      errors={errors}
      register={register}
      loading={loading}
      handleSubmit={handleSubmit}
      onSubmit={onSave}
    />
  );
}
