import React, { useState } from 'react';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import DetailSizeIcon from '@material-ui/icons/EventNote';
import InvoiceIcon from '@material-ui/icons/Receipt';
import PrintIcon from '@material-ui/icons/Print';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import EyeIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import ChangeStatusIcon from '@material-ui/icons/DirectionsRun';
import UploadIcon from '@material-ui/icons/Backup';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Money from 'utils/money';
import CheckoutUtils from 'utils/checkout';
import ORDER_QUERY from 'graphql/order';
import INVOICE_QUERY from 'graphql/invoice/index';
import ORDER_PAYMENT_QUERY from 'graphql/order/payment';
import URL from 'utils/url';
import EmptyList from 'assets/svg/empty_list.svg';
import AddToPhotosTwoToneIcon from '@material-ui/icons/AddToPhotosTwoTone';
import RenderCarousel from '../../../../component/carousel';
import { ORDER_STATUS_MAPPING } from '../../../../constant/index';

const useStyles = makeStyles(() => ({
  cardContent: {
    minHeight: '200px',
  },
  image: {
    width: '300px',
    maxWidth: '100%',
  },
}));

function OrderStatusList({ orders }) {
  return (
    <div>
      {orders.map((order, index) => (
        <div key={index}>
          <Typography gutterBottom variant="body2" color="textSecondary" component="p">
            {order.OrderStatus.name}: {order.note}
          </Typography>
        </div>
      ))}
    </div>
  );
}
function RenderEditButton({
  index,
  getOrderAction,
  value,
  activeIndexForEdit,
  setActiveIndexForEdit,
  loadingGetOrderAction,
}) {
  if (loadingGetOrderAction && index === activeIndexForEdit) {
    return <CircularProgress size={30} />;
  }
  return (
    <Tooltip title="Ubah">
      <IconButton
        size="small"
        onClick={async () => {
          setActiveIndexForEdit(index);
          await getOrderAction({
            variables: {
              payload: {
                id: value.order_id,
              },
            },
          });
        }}
      >
        <EditIcon color="primary" fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

function RenderWorkOrder({
  setIdOrderStatus,
  setOpenAddDivisionNote,
  setAdditionalInvoiceData,
  setModalPayment,
  setPaymentDetail,
  setMediaProofSPKState,
  setImageMediaProofSPK,
  setModalUploadOnGoingImageSPK,
  setSeeUploadBankReceipt,
  setSizeDetail,
  setDetaillNote,
  isRoleBoss,
  isRoleDesign,
  data,
  classes,
  setShowInvoice,
  setShowAccConfirmation,
  setSelectedSpkNumber,
  changeSpkStatus,
  setIdOrderForUpdateStatus,
  setOrderNumberForUpdateStatus,
  setEmailCustomerForUpdateStatus,
  history,
  setInvoiceDetail,
  setModalSpkImageAfterEdit,
  setOpenImageAfterEdit,
  setImageAfterEdit,
  setSpkUploadPictureState,
  setImageSPKPublicId,
  OnEmpty,
}) {
  const classesStyle = useStyles();
  const [activeIndexForEdit, setActiveIndexForEdit] = useState('');
  const [activeIndexForInvoice, setActiveIndexForInvoice] = useState('');

  const [getInvoiceAction, { loading: loadingGetInvoice }] = useLazyQuery(INVOICE_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: dataInvoice => {
      setShowInvoice(true);
      setInvoiceDetail(dataInvoice);
    },
  });

  const [getOrderPayment, { loading: loadingOrderPayment }] = useLazyQuery(ORDER_PAYMENT_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: dataOrderPayment => {
      setModalPayment(true);
      setPaymentDetail(dataOrderPayment.order);
    },
  });

  const [getOrderAction, { loading: loadingGetOrderAction }] = useLazyQuery(ORDER_QUERY, {
    onCompleted: dataOrder => {
      const {
        S_Qty,
        PendekS_Qty,
        PanjangS_Qty,
        M_Qty,
        PendekM_Qty,
        PajangM_Qty,
        L_Qty,
        PendekL_Qty,
        PanjangL_Qty,
        XL_Qty,
        PendekXL_Qty,
        PanjangXL_Qty,
        PanjangXXL_Qty,
        XXL_Qty,
        PendekXXL_Qty,
        ThreeXL_Qty,
        PendekThreeXL_Qty,
        PanjangThreeXL_Qty,
        FourthXL_Qty,
        PendekFourthXL_Qty,
        PanjangFourthXL_Qty,
        FifthXL_Qty,
        PendekFifthXL_Qty,
        PanjangFifthXL_Qty,
        SixthXL_Qty,
        PendekSixthXL_Qty,
        PanjangSixthXL_Qty,
        id,
      } = dataOrder.order[0];

      const { down_payment, note, spk_no } = dataOrder.order[0].Spk[0];

      const queryString = URL.makeQueryString({
        id,
        S_Qty,
        PendekS_Qty,
        PanjangS_Qty,
        M_Qty,
        PendekM_Qty,
        PajangM_Qty,
        L_Qty,
        PendekL_Qty,
        PanjangL_Qty,
        XL_Qty,
        PanjangXL_Qty,
        ThreeXL_Qty,
        PendekThreeXL_Qty,
        PanjangThreeXL_Qty,
        PendekXL_Qty,
        PanjangXXL_Qty,
        XXL_Qty,
        PendekXXL_Qty,
        FourthXL_Qty,
        PendekFourthXL_Qty,
        PanjangFourthXL_Qty,
        FifthXL_Qty,
        PendekFifthXL_Qty,
        PanjangFifthXL_Qty,
        SixthXL_Qty,
        PendekSixthXL_Qty,
        PanjangSixthXL_Qty,
        down_payment,
        note,
        spk_no,
      });
      history.push(`/spk-edit${queryString}`);
    },
  });
  const spkLength = data.spk.length;
  if (spkLength === 0) {
    return (
      <Grid container item xs={12} direction="column" alignItems="center">
        <OnEmpty />
        <Typography variant="h6" align="center">
          Pekerjaan kosong
        </Typography>
        <img alt="error" className={classesStyle.image} src={EmptyList} />
      </Grid>
    );
  }
  return data.spk.map((value, index) => {
    const { Invoice, Order } = value;
    let valueSubTotal = value.sub_total;
    const {
      BankTransferReceipt,
      FifthXL_Qty,
      FourthXL_Qty,
      L_Qty,
      M_Qty,
      PajangM_Qty,
      PanjangFifthXL_Qty,
      PanjangFourthXL_Qty,
      PanjangL_Qty,
      PanjangS_Qty,
      PanjangSixthXL_Qty,
      PanjangThreeXL_Qty,
      PanjangXL_Qty,
      PanjangXXL_Qty,
      PendekFifthXL_Qty,
      PendekFourthXL_Qty,
      PendekL_Qty,
      PendekM_Qty,
      PendekS_Qty,
      PendekSixthXL_Qty,
      PendekThreeXL_Qty,
      PendekXL_Qty,
      PendekXXL_Qty,
      S_Qty,
      SixthXL_Qty,
      ThreeXL_Qty,
      XL_Qty,
      XXL_Qty,
      payment_method,
      Voucher,
      Referral,
      order_no,
      Product,
      OrderDelivery,
      OrderChildren,
    } = Order;

    const { media_after_edit: mediaAfterEdit } = value;
    const mediaAfterEditPublicID = mediaAfterEdit.public_id;
    const { media_proof_spk: mediaProofSPK } = value;
    const billedAmount = Money.setPriceWithCurrency(Invoice?.billed_amount);
    const payAmount = Money.setPriceWithCurrency(Invoice?.pay_amount);
    const returnAmount = Money.setPriceWithCurrency(Invoice?.return_amount);
    let downPayment = Money.setPriceWithCurrency(value.down_payment);
    const customerPhone = value.Customer[0].phone;
    const invoiceCreated = Invoice?.createdAt;
    const customerName = value.Customer[0].name;
    const invoiceDetailText = `Tagihan: ${billedAmount} - DP: ${downPayment} -  Bayar: ${payAmount} 
    - Kembalian : ${returnAmount}`;
    const parsedTime = moment(value.createdAt).format('DD MMMM YYYY hh:mm A');
    const idOrder = value.Order.id;
    const orderNumber = value.Order.order_no;
    let customerEmail = '';
    if (value.Order.Customer) {
      customerEmail = value?.Order.Customer[0]?.email;
    }
    const idOrderStatus = value.Order.id_order_status;
    const spkNo = value.spk_no;
    const idOrderStatusMapping =
      ORDER_STATUS_MAPPING.find(x => x.number === idOrderStatus)?.name || 'Tidak valid';
    const isConfirmed = value.is_confirmed;
    const mediaAfterEditLength = mediaAfterEdit.length;
    const mediaProofSPKLength = mediaProofSPK.length;
    const BankTransferReceiptLength = BankTransferReceipt.length;
    const showBankTransferReceiptIcon = BankTransferReceiptLength > 0 && payment_method === 'BANK';
    let voucherCode;
    let voucherPercentage;
    if (Voucher) {
      voucherCode = Voucher?.[0]?.code;
      voucherPercentage = Voucher?.[0]?.percentage;
      const getDiscount = CheckoutUtils.showDiscount(voucherPercentage, valueSubTotal);
      valueSubTotal = getDiscount.bare;
      downPayment = valueSubTotal / 2;
    }
    if (Referral) {
      voucherCode = Referral?.[0]?.code;
      voucherPercentage = Referral?.[0]?.percentage;
      const getDiscount = CheckoutUtils.showDiscount(voucherPercentage, valueSubTotal);
      valueSubTotal = getDiscount.bare;
      downPayment = valueSubTotal / 2;
    }
    downPayment = Money.setPriceWithCurrency(downPayment);
    const subTotalAsString = Money.setPriceWithCurrency(valueSubTotal);

    return (
      <Grid item xs={12} sm={12} md={4} key={index}>
        <Card className={classes.root}>
          <RenderCarousel data={value.media} classes={classes} />
          <CardActionArea
            onClick={() => {
              setDetaillNote({
                visibility: true,
                message: value.note,
              });
            }}
          >
            <CardContent className={classesStyle.cardContent}>
              <Typography gutterBottom variant="h6">
                {order_no}
              </Typography>
              <Typography gutterBottom variant="h6">
                {spkNo}
              </Typography>
              <Typography gutterBottom variant="body2" color="textSecondary">
                {Product.rows[0].name}
              </Typography>
              <Typography variant="subtitle1">{customerName}</Typography>
              <Typography gutterBottom variant="subtitle1">
                {customerEmail}
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                {parsedTime}
              </Typography>
              <Tooltip title={value.note}>
                <Typography variant="body2" color="textSecondary" component="p" noWrap>
                  {value.note}
                </Typography>
              </Tooltip>
              <Typography variant="body2" color="textSecondary" component="p">
                Sub Total : {subTotalAsString}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Down Payment : {downPayment}
              </Typography>
              {(Voucher || Referral) && (
                <>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Kode {Voucher ? 'Voucher' : 'Referral'} : {voucherCode}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Presentase : {voucherPercentage}%
                  </Typography>
                </>
              )}
              <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                Metode Pembayaran: {payment_method}
              </Typography>
              {OrderDelivery?.courier_cost && (
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  Ongkos Kirim: {OrderDelivery?.courier_cost}
                </Typography>
              )}
              {value.NoteWorkOrder && <OrderStatusList orders={value.NoteWorkOrder} />}
            </CardContent>
          </CardActionArea>
          <CardActions disableSpacing>
            <Chip color="primary" label={idOrderStatusMapping} />
          </CardActions>
          <CardActions style={{ flexWrap: 'wrap' }}>
            {isRoleBoss && isConfirmed === 'N' && (
              <>
                <Tooltip title="Acc">
                  <IconButton
                    size="small"
                    onClick={async () => {
                      setShowAccConfirmation(true);
                      setSelectedSpkNumber(spkNo);
                      setEmailCustomerForUpdateStatus(customerEmail);
                    }}
                  >
                    <CheckIcon color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>
                <RenderEditButton
                  index={index}
                  setActiveIndexForEdit={setActiveIndexForEdit}
                  activeIndexForEdit={activeIndexForEdit}
                  getOrderAction={getOrderAction}
                  value={value}
                  loadingGetOrderAction={loadingGetOrderAction}
                />
              </>
            )}
            {isRoleBoss && isConfirmed === 'Y' && (
              <>
                {loadingGetInvoice && activeIndexForInvoice === index && (
                  <CircularProgress size={30} />
                )}
                {Invoice === null ? (
                  <Tooltip title="Buat Invoice">
                    <IconButton
                      size="small"
                      onClick={async () => {
                        setActiveIndexForInvoice(index);
                        setAdditionalInvoiceData({
                          OrderDelivery,
                          Voucher,
                          Referral,
                        });
                        await getInvoiceAction({
                          variables: {
                            spkNo,
                          },
                        });
                      }}
                    >
                      <InvoiceIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={invoiceDetailText}>
                    <Chip
                      color="secondary"
                      label="Lunas"
                      onClick={() => {
                        const payload = {
                          created_at: invoiceCreated,
                          spk_no: spkNo,
                          down_payment: downPayment,
                          billed_amount: billedAmount,
                          pay_amount: payAmount,
                          return_amount: returnAmount,
                        };
                        const queryPrint = URL.makeQueryString(payload);
                        history.push(`/spk/print${queryPrint}`);
                      }}
                    />
                  </Tooltip>
                )}
                <Tooltip title="Print">
                  <IconButton
                    size="small"
                    onClick={async () => {
                      const payload = {
                        id: value.id,
                        media: value?.media[0].url,
                      };
                      if (mediaAfterEdit.length > 0) {
                        payload.media_after_edit = mediaAfterEdit[0].url;
                      }
                      const queryPrint = URL.makeQueryString(payload);
                      window.open(
                        `https://voodoo-print.herokuapp.com${queryPrint}`,
                        '_blank', // <- This is what makes it open in a new window.
                      );
                    }}
                  >
                    <PrintIcon color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>

                <RenderEditButton
                  index={index}
                  setActiveIndexForEdit={setActiveIndexForEdit}
                  activeIndexForEdit={activeIndexForEdit}
                  getOrderAction={getOrderAction}
                  value={value}
                  loadingGetOrderAction={loadingGetOrderAction}
                />
              </>
            )}
            {/* Role not boss only be able to see button change status */}
            {isConfirmed === 'Y' && (
              <>
                <Tooltip title="Ubah Status">
                  <IconButton
                    size="small"
                    onClick={async () => {
                      setSelectedSpkNumber(spkNo);
                      setIdOrderForUpdateStatus(idOrder);
                      setEmailCustomerForUpdateStatus(customerEmail);
                      setOrderNumberForUpdateStatus(orderNumber);
                      await changeSpkStatus();
                    }}
                  >
                    <ChangeStatusIcon color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Tambah Catatan Divisi">
                  <IconButton
                    size="small"
                    onClick={async () => {
                      setIdOrderStatus(idOrderStatus);
                      setOpenAddDivisionNote(true);
                      setOrderNumberForUpdateStatus(orderNumber);
                      setSelectedSpkNumber(spkNo);
                    }}
                  >
                    <AddToPhotosTwoToneIcon color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip
              title={mediaProofSPKLength > 0 ? 'Ganti Gambar Terkini' : 'Upload Gambar Terkini'}
            >
              <IconButton
                size="small"
                onClick={() => {
                  setModalUploadOnGoingImageSPK(true);
                  setSelectedSpkNumber(spkNo);

                  if (mediaProofSPKLength > 0) {
                    setMediaProofSPKState('EDIT');
                    setImageMediaProofSPK(mediaProofSPK?.[0]?.secure_url);
                  }
                }}
              >
                <UploadIcon color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
            {isRoleDesign && isConfirmed === 'Y' && (
              <>
                <Tooltip title={mediaAfterEditLength > 0 ? 'Ganti gambar' : 'Upload gambar'}>
                  <IconButton
                    size="small"
                    onClick={async () => {
                      setModalSpkImageAfterEdit(true);
                      setSelectedSpkNumber(spkNo);
                      if (mediaAfterEditLength > 0) {
                        setSpkUploadPictureState('EDIT');
                        setImageSPKPublicId(mediaAfterEditPublicID);
                      }
                    }}
                  >
                    <UploadIcon color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {isConfirmed === 'Y' && mediaAfterEditLength > 0 && (
              <Tooltip title="Lihat gambar">
                <IconButton
                  size="small"
                  onClick={async () => {
                    setOpenImageAfterEdit(true);
                    setImageAfterEdit(mediaAfterEdit);
                  }}
                >
                  <EyeIcon color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            )}

            {showBankTransferReceiptIcon && (
              <Tooltip title="Lihat Bukti Bayar">
                <IconButton
                  size="small"
                  onClick={async () => {
                    setSeeUploadBankReceipt({
                      visibility: true,
                      imageSource: BankTransferReceipt[0].media[0].url,
                    });
                  }}
                >
                  <EyeIcon color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Detail Ukuran">
              <IconButton
                size="small"
                onClick={async () => {
                  setSizeDetail({
                    OrderChildren,
                    FifthXL_Qty,
                    FourthXL_Qty,
                    L_Qty,
                    M_Qty,
                    PajangM_Qty,
                    PanjangFifthXL_Qty,
                    PanjangFourthXL_Qty,
                    PanjangL_Qty,
                    PanjangS_Qty,
                    PanjangSixthXL_Qty,
                    PanjangThreeXL_Qty,
                    PanjangXL_Qty,
                    PanjangXXL_Qty,
                    PendekFifthXL_Qty,
                    PendekFourthXL_Qty,
                    PendekL_Qty,
                    PendekM_Qty,
                    PendekS_Qty,
                    PendekSixthXL_Qty,
                    PendekThreeXL_Qty,
                    PendekXL_Qty,
                    PendekXXL_Qty,
                    S_Qty,
                    SixthXL_Qty,
                    ThreeXL_Qty,
                    XL_Qty,
                    XXL_Qty,
                  });
                }}
              >
                <DetailSizeIcon color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
            <IconButton
              size="small"
              onClick={() => {
                let customerPhoneFilter = customerPhone;
                if (customerPhone.charAt(0) === '0') {
                  customerPhoneFilter = `62${customerPhone.substr(1, customerPhone.length)}`;
                }
                let text = `No Order: ${orderNumber}, No SPK: ${spkNo}, Total : ${subTotalAsString}, Down Payment: ${downPayment}`;
                text += 'Lebih lengkap kunjungi https://voodoocloth.co.id';
                window.open(
                  `https://api.whatsapp.com/send?phone=${customerPhoneFilter}&text=${text}`,
                );
              }}
            >
              <WhatsAppIcon color="primary" fontSize="small" />
            </IconButton>
          </CardActions>
          {loadingOrderPayment && index === activeIndexForEdit ? (
            <CircularProgress size={30} />
          ) : (
            <Button
              style={{
                marginBottom: '24px',
                marginLeft: '16px',
              }}
              color="primary"
              variant="outlined"
              onClick={async () => {
                setActiveIndexForEdit(index);
                const idOrderAsInt = parseInt(idOrder, 10);
                await getOrderPayment({
                  variables: {
                    payload: {
                      id: idOrderAsInt,
                    },
                  },
                });
              }}
            >
              Detail
            </Button>
          )}
        </Card>
      </Grid>
    );
  });
}

export default React.memo(RenderWorkOrder);
