import * as yup from 'yup';

export default {
  amount: yup
    .string()
    .required('Jumlah harus diisi')
    .matches(/^[0-9]*$/, 'Jumlah harus berupa angka'),
  note: yup.string().required('Keperluan harus diisi'),
  installment_time: yup.string().required('Jangka waktu peminjaman harus dipilih'),
};
