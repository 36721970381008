import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useMutation } from '@apollo/react-hooks';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
  CONFIRM_PASSWORD_NOT_SAME,
  PASSWORD_MUST_BE_FILLED_IN,
  PASSWORD_MINIMUM_EIGHT,
  CONFIRM_PASSWORD_MUST_BE_FILLED_IN,
} from 'constant/errorMessage';
import RESET_PASSWORD_ACTION from 'graphql/account/resetPasswordAccount';
import URLUtils from 'utils/url';
import { useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';

const useStyles = makeStyles(theme => ({
  accountImage: {
    width: '300px',
  },
  formWrapper: {
    padding: theme.spacing(3),
  },
  orText: {
    marginTop: theme.spacing(1),
  },
  wrapper: {
    height: '100vh',
    paddingTop: theme.spacing(5),
  },
  left: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  formPassword: {
    marginBottom: theme.spacing(2),
  },
}));

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState({
    visibility: false,
    message: '',
  });
  const ResetPasswordSchema = yup.object().shape({
    new_password: yup
      .string()
      .required(PASSWORD_MUST_BE_FILLED_IN)
      .min(8, PASSWORD_MINIMUM_EIGHT),
    confirm_new_password: yup
      .string()
      .required(CONFIRM_PASSWORD_MUST_BE_FILLED_IN)
      .oneOf([yup.ref('new_password')], CONFIRM_PASSWORD_NOT_SAME),
  });

  const { register, setValue, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
    validationSchema: ResetPasswordSchema,
    submitFocusError: false,
    nativeValidation: false,
  });
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const { search } = location;
  const [resetPasswordAction, { loading: loadingResetPasswordAction }] = useMutation(
    RESET_PASSWORD_ACTION,
    {
      onCompleted: () => {
        setValue('new_password', '');
        setValue('confirm_new_password', '');
        setOpenSnackBar({
          visibility: true,
          message: 'Sandi berhasil di perbaharui silahkan login kembali',
        });
      },
      onError: error => {
        setOpenSnackBar({
          visibility: true,
          message: error.graphQLErrors[0].message,
        });
      },
    },
  );

  const token = URLUtils.queryString(search, 'token');

  const onSubmit = async data => {
    const { new_password } = data;
    await resetPasswordAction({
      variables: {
        payload: {
          token,
          password: new_password,
        },
      },
    });
  };

  return (
    <Grid container direction="row" justify="center" className={classes.wrapper}>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar.visibility}
        onClose={() => {
          setOpenSnackBar({
            visibility: false,
          });
        }}
        message={openSnackBar.message}
      />

      <Grid item xs={12} sm={4}>
        <Paper elevation={3} className={classes.formWrapper}>
          <Typography variant="h6" gutterBottom>
            Atur Ulang Sandi
          </Typography>

          <FormControl fullWidth variant="outlined" className={classes.formPassword}>
            <InputLabel style={{ top: '-7px' }} htmlFor="new_password">
              Kata Sandi Baru
            </InputLabel>
            <OutlinedInput
              fullWidth
              type={showPassword ? 'text' : 'password'}
              margin="dense"
              inputRef={register}
              name="new_password"
              id="new_password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel style={{ top: '-7px' }} htmlFor="confirm_new_password">
              Konfirmasi Kata Sandi Baru
            </InputLabel>
            <OutlinedInput
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              margin="dense"
              error={errors?.confirm_new_password && true}
              helperText={errors?.confirm_new_password?.message}
              inputRef={register}
              name="confirm_new_password"
              id="confirm_new_password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={100}
            />
          </FormControl>
          <Box mt={2} display="flex" justifyContent="center">
            {loadingResetPasswordAction ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                disabled={!formState.isValid}
                onClick={handleSubmit(onSubmit)}
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Atur Ulang
              </Button>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
