import React from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

function List({ label, value, classes }) {
  return (
    <Grid item xs={4} className={classes.wrapper}>
      <div>{label}</div>
      <div>{value}</div>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: theme.spacing(1),
  },
}));

function OrderDialogDetail({ data, open, handleClose }) {
  const classes = useStyles();
  const sizeChildren = [
    {
      name: 'PendekS_Qty',
      label: 'Pendek S Qty',
    },
    {
      name: 'S_Qty',
      label: '3/4 S Qty',
    },
    {
      name: 'PanjangS_Qty',
      label: 'Panjang S Qty',
    },
    {
      name: 'PendekM_Qty',
      label: 'Pendek M Qty',
    },
    {
      name: 'M_Qty',
      label: '3/4 M Qty',
    },
    {
      name: 'PanjangM_Qty',
      label: 'Panjang M Qty',
    },
    {
      name: 'PendekL_Qty',
      label: 'Pendek L Qty',
    },
    {
      name: 'L_Qty',
      label: '3/4 L Qty',
    },
    {
      name: 'PanjangL_Qty',
      label: 'Panjang L Qty',
    },
    {
      name: 'PendekXL_Qty',
      label: 'Pendek XL Qty',
    },
    {
      name: 'XL_Qty',
      label: '3/4 XL Qty',
    },
    {
      name: 'PanjangXL_Qty',
      label: 'Panjang XL Qty',
    },
  ];

  const {
    /* eslint-disable-next-line camelcase */
    PanjangXL_Qty,
    /* eslint-disable-next-line camelcase */
    S_Qty,
    /* eslint-disable-next-line camelcase */
    PendekS_Qty,
    /* eslint-disable-next-line camelcase */
    PanjangS_Qty,
    /* eslint-disable-next-line camelcase */
    M_Qty,
    /* eslint-disable-next-line camelcase */
    PendekM_Qty,
    /* eslint-disable-next-line camelcase */
    PajangM_Qty,
    /* eslint-disable-next-line camelcase */
    L_Qty,
    /* eslint-disable-next-line camelcase */
    PendekL_Qty,
    /* eslint-disable-next-line camelcase */
    PanjangL_Qty,
    /* eslint-disable-next-line camelcase */
    XL_Qty,
    /* eslint-disable-next-line camelcase */
    PendekXL_Qty,
    /* eslint-disable-next-line camelcase */
    PanjangXXL_Qty,
    /* eslint-disable-next-line camelcase */
    XXL_Qty,
    /* eslint-disable-next-line camelcase */
    PendekXXL_Qty,
    /* eslint-disable-next-line camelcase */
    FourthXL_Qty,
    /* eslint-disable-next-line camelcase */
    PendekFourthXL_Qty,
    /* eslint-disable-next-line camelcase */
    PanjangFourthXL_Qty,
    /* eslint-disable-next-line camelcase */
    FifthXL_Qty,
    /* eslint-disable-next-line camelcase */
    PendekFifthXL_Qty,
    /* eslint-disable-next-line camelcase */
    PanjangFifthXL_Qty,
    /* eslint-disable-next-line camelcase */
    SixthXL_Qty,
    /* eslint-disable-next-line camelcase */
    PendekSixthXL_Qty,
    /* eslint-disable-next-line camelcase */
    PanjangSixthXL_Qty,
    /* eslint-disable-next-line camelcase */
    PendekThreeXL_Qty,
    /* eslint-disable-next-line camelcase */
    ThreeXL_Qty,
    /* eslint-disable-next-line camelcase */
    PanjangThreeXL_Qty,
    OrderChildren,
  } = data;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Detail Ukuran</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            Ukuran Dewasa
          </Typography>
          <Grid container direction="row">
            {/* eslint-disable-next-line camelcase */}
            <List label="Pendek S" value={PendekS_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="3/4 S" value={S_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="Panjang S" value={PanjangS_Qty} classes={classes} />

            {/* eslint-disable-next-line camelcase */}
            <List label="Pendek M" value={PendekM_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="3/4 M" value={M_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="Panjang M" value={PajangM_Qty} classes={classes} />

            {/* eslint-disable-next-line camelcase */}
            <List label="Pendek L" value={PendekL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="3/4 L" value={L_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="Panjang L" value={PanjangL_Qty} classes={classes} />

            {/* eslint-disable-next-line camelcase */}
            <List label="Pendek XL" value={PendekXL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="3/4 XL" value={XL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="Panjang XL" value={PanjangXL_Qty} classes={classes} />

            {/* eslint-disable-next-line camelcase */}
            <List label="Pendek XXL" value={PendekXXL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="3/4 XXL" value={XXL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="Panjang XXL" value={PanjangXXL_Qty} classes={classes} />

            {/* eslint-disable-next-line camelcase */}
            <List label="Pendek 3XL" value={PendekThreeXL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="3/4 3XL" value={ThreeXL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="Panjang 3XL" value={PanjangThreeXL_Qty} classes={classes} />

            {/* eslint-disable-next-line camelcase */}
            <List label="Pendek 4XL" value={PendekFourthXL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="3/4 4XL" value={FourthXL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="Panjang 4XL" value={PanjangFourthXL_Qty} classes={classes} />

            {/* eslint-disable-next-line camelcase */}
            <List label="Pendek 5XL" value={PendekFifthXL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="3/4 5XL" value={FifthXL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="Panjang 5XL" value={PanjangFifthXL_Qty} classes={classes} />

            {/* eslint-disable-next-line camelcase */}
            <List label="Pendek 6XL" value={PendekSixthXL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="3/4 6XL" value={SixthXL_Qty} classes={classes} />
            {/* eslint-disable-next-line camelcase */}
            <List label="Panjang 6XL" value={PanjangSixthXL_Qty} classes={classes} />
          </Grid>
          <Typography variant="h6" gutterBottom>
            Ukuran Anak
          </Typography>
          <Grid container direction="row">
            {OrderChildren &&
              sizeChildren.map(value => {
                const { name, label } = value;
                return <List label={label} value={OrderChildren[name]} classes={classes} />;
              })}
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(OrderDialogDetail);
