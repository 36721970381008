import Money from './money';

const Checkout = {};

Checkout.showDiscount = (percentage, reduceFrom) => {
  const result = {};
  result.bare = '';
  result.formatted = '';
  const filterTotal = reduceFrom - (percentage * reduceFrom) / 100;
  return {
    bare: filterTotal,
    formatted: Money.formatIDR(filterTotal.toString()),
  };
};

Checkout.checkIfItsReferral = string => {
  const takeString = string.substring(0, 5);
  return takeString === 'VDCOM';
};

export default Checkout;
