import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import VARIANT_VALUE from 'graphql/product/variantValue/get';
import Loading from 'component/loading';
import Error from 'component/error';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import Alert from '@material-ui/lab/Alert/Alert';
import TableContainer from '@material-ui/core/TableContainer';
import { NetworkStatus } from 'apollo-client';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import URL from '../../../../utils/url';
import LoadMore from '../../../../component/LoadMore';
import empty from '../../../../assets/svg/empty.svg';
import Add from './add';
import Edit from './edit';

const RenderIndex = ({
  register,
  handleSubmit,
  onSubmit,
  classes,
  data,
  fetchMore,
  loading,
  refetch,
  history,
  statusPage,
}) => {
  const dataProductVariantValue = data.variantValue;
  const dataProductVariantValueLength = dataProductVariantValue.length;
  return dataProductVariantValueLength !== 0 ? (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Produk Variant Value</Typography>
        <Button
          onClick={() => {
            history.push('/variant-value/tambah');
          }}
          color="primary"
          variant="contained"
        >
          Tambah
        </Button>
      </div>

      <Grid container justify="center" className={classes.searchWrapper}>
        <TextField
          className={classes.searchField}
          size="small"
          variant="outlined"
          inputRef={register}
          name="variant_value"
          label="Cari Variant Value"
        />
        <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
          Cari
        </Button>
      </Grid>
      {statusPage === 'berhasil' && (
        <Alert
          onClose={() => {
            history.push('/variant-value');
          }}
          variant="filled"
          severity="success"
          className={classes.alertWrapper}
        >
          Aksi pada produk variant value berhasil
        </Alert>
      )}
      {statusPage === 'gagal' && (
        <Alert
          onClose={() => {
            history.push('/variant-value');
          }}
          variant="filled"
          severity="error"
          className={classes.alertWrapper}
        >
          Aksi pada produk variant value gagal
        </Alert>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Variant</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Meta Value</TableCell>
              <TableCell>Tanggal Tambah</TableCell>
              <TableCell>Tanggal Ubah</TableCell>
              <TableCell>Ubah</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProductVariantValue.map(row => {
              const { name, id, variant, createdAt, updatedAt, meta_value } = row;
              const idTable = `${id}`;
              return (
                <TableRow key={idTable}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{variant.name}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{meta_value}</TableCell>
                  <TableCell>{moment(createdAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell>{moment(updatedAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => {
                        const payload = {
                          id,
                          name,
                          variant_name: variant.name,
                          variant_id: variant.id,
                        };
                        const queryEdit = URL.makeQueryString(payload);
                        history.push(`variant-value/ubah${queryEdit}`);
                      }}
                    >
                      <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <LoadMore
          loading={loading}
          refetch={refetch}
          fetchMore={async () => {
            const payload = {
              offset: dataProductVariantValueLength,
            };
            await fetchMore({
              variables: {
                payload,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  ...prev,
                  variantValue: [...prev.variantValue, ...fetchMoreResult.variantValue],
                };
              },
            });
          }}
        />
      </TableContainer>
    </>
  ) : (
    <Grid direction="column" container alignItems="center">
      <img src={empty} alt="empty_img" className={classes.imageEmpty} />
      <Typography variant="h5" gutterBottom>
        Tidak ada data produk variant value
      </Typography>
      <Button
        onClick={() => {
          history.push('/variant-value/tambah');
        }}
        color="primary"
        variant="contained"
      >
        Tambah
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageEmpty: {
    width: '500px',
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loadMoreButtonWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  searchWrapper: {
    paddingBottom: theme.spacing(2),
  },
  searchField: {
    paddingRight: theme.spacing(1),
  },
  alertWrapper: {
    marginBottom: theme.spacing(1),
  },
}));

export default function VariantValue() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split('/');
  const { data, error, fetchMore, refetch, networkStatus } = useQuery(VARIANT_VALUE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      payload: {
        offset: 0,
        limit: 10,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const initialLoading = networkStatus === NetworkStatus.loading;
  const fetchMoreLoading = networkStatus === NetworkStatus.fetchMore;
  const refetchLoading = networkStatus === NetworkStatus.refetch;

  const { search } = location;
  const statusPage = URL.queryString(search, 'status');
  const { register, handleSubmit } = useForm({
    submitFocusError: false,
    nativeValidation: false,
  });

  if (initialLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  const onSubmit = async dataOnSubmit => {
    const { variant_value: variantValue } = dataOnSubmit;
    await refetch({
      payload: {
        name: variantValue,
      },
    });
  };

  if (path[2] === 'tambah') {
    // Uses from component is edit set to false
    return <Add />;
  }

  if (path[2] === 'ubah') {
    return <Edit refetch={refetch} />;
  }

  return (
    <RenderIndex
      statusPage={statusPage}
      history={history}
      loading={fetchMoreLoading || refetchLoading}
      classes={classes}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      refetch={refetch}
      fetchMore={fetchMore}
      data={data}
    />
  );
}
