import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}));

function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <CircularProgress />
    </div>
  );
}

export default Loading;
