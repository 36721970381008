import Carousel from 'react-material-ui-carousel';
import CardMedia from '@material-ui/core/CardMedia';
import React from 'react';
import CardActionArea from '@material-ui/core/CardActionArea';

function RenderCarousel({ data, classes }) {
  if (data.length) {
    return (
      <Carousel>
        {data.map((item, index) => {
          return (
            <CardActionArea
              key={item.url}
              onClick={() => {
                window.open(item.secure_url || item.url, '_blank');
              }}
            >
              <CardMedia
                key={index}
                className={classes.media}
                src={item.secure_url || item.url}
                image={item.secure_url || item.url}
              />
            </CardActionArea>
          );
        })}
      </Carousel>
    );
  }
  return <CardMedia className={classes.media} image="/image_empty.png" title="Paella dish" />;
}

export default React.memo(RenderCarousel);
