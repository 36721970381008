import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm } from 'react-hook-form';
import PRODUCT_CATEGORY_INTERNAL_QUERY from 'graphql/product/category/internal/index';
import PRODUCT_MATERIAL_QUERY from 'graphql/product/material/get';
import PRODUCT_COLOR_QUERY from 'graphql/product/color/get';
import Loading from 'component/loading';
import Error from 'component/error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { NetworkStatus } from 'apollo-client';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import ImageUtils from 'utils/Image';
import UploadComponent from 'component/upload/index';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Box from '@material-ui/core/Box';
import { PRODUCT_INTERNAL } from 'constant/upload_path';
import { Editor } from '@tinymce/tinymce-react';
import RenderFileUploadError from './component/FileUploadErrorText';
import RenderCarousel from '../../component/carousel/index';
import PublishedOption from './component/PublishedOption/index';
import RenderProductType from './component/ProductType/index';
import { PRODUCT_CONSTANT } from '../../constant/index';
import ArrowBackComponent from '../../component/back/index';
import SUCCESS_REDIRECT from './constant';

export default function Form({
  isEdit,
  media,
  classes,
  errors,
  register,
  loading,
  handleSubmit,
  onSubmitRegister,
  control,
  setValue,
}) {
  const [multipleImageUpload, setMultipleImageUpload] = useState([]);
  const [multipleImageEdit, setMultipleImageEdit] = useState([]);
  const [multipleAddImageForEdit, setMultipleAddImageForEdit] = useState([]);
  const [addImageCounter, setAddImageCounter] = useState(1);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    register('category');
    register('product_material');
    register('product_color');
  }, [register]);

  useEffect(() => {
    if (isEdit) {
      const materialURL = urlParams.get('material');
      const colorURL = urlParams.get('color');
      const materialAsArray = materialURL.split(',');
      const colorAsArray = colorURL.split(',');
      setValue('product_material', materialAsArray);
      setValue('product_color', colorAsArray);
    }
  }, []);

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingAddProduct, setLoadingAddProduct] = useState(false);

  const payloadGetCategoryInternal = {
    offset: 0,
    limit: 10,
  };

  const payloadGetProductMaterial = {
    offset: 0,
    limit: 10,
  };
  const payloadGetProductColor = {
    offset: 0,
    limit: 10,
  };
  if (isEdit) {
    payloadGetCategoryInternal.name = urlParams.get('category');
  }

  const {
    data: dataProductMaterial,
    error: errorProductMaterial,
    loading: loadingProductMaterial,
  } = useQuery(PRODUCT_MATERIAL_QUERY, {
    variables: {
      payload: payloadGetProductMaterial,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    data: dataProductColor,
    error: errorProductColor,
    loading: loadingProductColor,
  } = useQuery(PRODUCT_COLOR_QUERY, {
    variables: {
      payload: payloadGetProductColor,
    },
    fetchPolicy: 'no-cache',
  });

  const { data, refetch, error: errorProductCategoryInternal, networkStatus } = useQuery(
    PRODUCT_CATEGORY_INTERNAL_QUERY,
    {
      variables: {
        payload: payloadGetCategoryInternal,
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  const initialLoading = networkStatus === NetworkStatus.loading;
  let mediaAsObject = null;
  if (media) {
    mediaAsObject = JSON.parse(media);
  }
  const { handleSubmit: handleSubmitUpload } = useForm({
    submitFocusError: false,
    nativeValidation: false,
  });

  const history = useHistory();
  const onSubmitDeleteImage = async dataOnSubmit => {
    const { public_id } = dataOnSubmit;
    const deletePayload = [{ public_id }];
    setLoadingUpload(true);
    if (mediaAsObject.length === 1) {
      alert('Produk minimal harus memiliki satu gambar');
      setLoadingUpload(false);
      return false;
    }
    try {
      await ImageUtils.delete(deletePayload, async () => {
        // Upload without insert. just update
        await ImageUtils.deleteUploadInformation(
          public_id,
          () => {
            window.location.href = SUCCESS_REDIRECT;
          },
          () => {
            setLoadingUpload(false);
          },
        );
      });
    } catch (e) {
      setLoadingUpload(false);
    }
  };
  const onSubmitUploadImage = async (dataOnSubmit, index) => {
    const { public_id, request_id } = dataOnSubmit;
    const imageBaseIndex = multipleImageEdit[index];
    if (typeof imageBaseIndex === 'undefined') {
      alert('Harap upload product terlebih dahulu');
      return false;
    }
    setLoadingUpload(true);
    const deletePayload = [{ public_id }];
    try {
      await ImageUtils.delete(deletePayload, async () => {
        // Upload without insert. just update
        await ImageUtils.updateUpload(
          PRODUCT_INTERNAL,
          imageBaseIndex,
          () => {
            window.location.href = SUCCESS_REDIRECT;
          },
          () => {
            setLoadingUpload(false);
          },
          public_id,
          request_id,
        );
      });
    } catch (e) {
      setLoadingUpload(false);
    }
  };
  // eslint-disable-next-line camelcase
  const fileUploadErrorForAdd = errors?.file_upload;

  if (loadingProductColor || loadingProductMaterial) {
    return <Loading />;
  }

  if (errorProductColor || errorProductMaterial) {
    return <Error />;
  }

  const autoCompleteProductColor = {};
  if (isEdit) {
    const matchesColorURL = urlParams.get('color').split(',');
    const defaultValue = [];
    matchesColorURL.forEach(value => {
      return dataProductColor?.productColor.filter(x => {
        if (x.id === parseInt(value, 10)) {
          defaultValue.push({
            id: value,
            name: x.name,
          });
        }
      });
    });
    // eslint-disable-next-line prefer-destructuring
    autoCompleteProductColor.defaultValue = defaultValue;
  }
  autoCompleteProductColor.options = dataProductColor?.productColor;
  autoCompleteProductColor.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteProductColor.renderInput = params => (
    <TextField
      {...params}
      label="Warna"
      variant="outlined"
      fullWidth
      margin="dense"
      helperText={errors?.product_color?.message}
      error={errors?.product_color && true}
    />
  );
  autoCompleteProductColor.noOptionsText = 'Warna tidak di temukan';
  autoCompleteProductColor.size = 'small';
  autoCompleteProductColor.onChange = (value, newValue) => {
    if (newValue.length > 0) {
      setValue('product_color', newValue);
    } else {
      setValue('product_color', '');
    }
  };

  const autoCompleteProductMaterial = {};
  if (isEdit) {
    const matchesMaterialURL = urlParams.get('material').split(',');
    const defaultValue = [];
    matchesMaterialURL.forEach(value => {
      return dataProductMaterial?.productMaterial.filter(x => {
        if (x.id === parseInt(value, 10)) {
          defaultValue.push({
            id: value,
            name: x.name,
          });
        }
      });
    });
    // eslint-disable-next-line prefer-destructuring
    autoCompleteProductMaterial.defaultValue = defaultValue;
  }
  autoCompleteProductMaterial.options = dataProductMaterial?.productMaterial;
  autoCompleteProductMaterial.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteProductMaterial.renderInput = params => (
    <TextField
      {...params}
      label="Material"
      variant="outlined"
      margin="dense"
      fullWidth
      helperText={errors?.product_material?.message}
      error={errors?.product_material && true}
    />
  );
  autoCompleteProductMaterial.noOptionsText = 'Material tidak di temukan';
  autoCompleteProductMaterial.size = 'small';
  autoCompleteProductMaterial.onChange = (value, newValue) => {
    if (newValue.length > 0) {
      setValue('product_material', newValue);
    } else {
      setValue('product_material', []);
    }
  };

  const autoCompleteProperty = {};
  if (isEdit) {
    // eslint-disable-next-line prefer-destructuring
    autoCompleteProperty.defaultValue = data?.productCategoryInternal[0];
  }
  autoCompleteProperty.options = data?.productCategoryInternal;
  autoCompleteProperty.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteProperty.renderInput = params => (
    <TextField {...params} label="Kategori" variant="outlined" fullWidth />
  );
  autoCompleteProperty.noOptionsText = 'Kategori tidak di temukan';
  autoCompleteProperty.size = 'small';
  autoCompleteProperty.onChange = (value, newValue) => {
    if (newValue?.name) {
      setValue('category', newValue?.name);
    } else {
      setValue('category', '');
    }
  };
  autoCompleteProperty.onInputChange = event => {
    const categoryInternalName = event?.target?.value;
    if (categoryInternalName !== 0) {
      refetch({
        payload: {
          name: categoryInternalName,
        },
      });
    }
  };

  return (
    <Paper elevation={3} className={classes.wrapper}>
      <Grid container className={classes.header}>
        <ArrowBackComponent history={history} location="/produk" />
        <Typography variant="h6" gutterBottom align="center" className={classes.title}>
          Produk
        </Typography>
      </Grid>
      <Grid container direction="row" className={classes.contentWrapper} spacing={3}>
        <Grid
          container
          direction="row"
          item
          xs={12}
          sm={isEdit ? 6 : 12}
          spacing={1}
          style={{ alignContent: 'baseline' }}
        >
          <Grid item xs={12}>
            <Autocomplete multiple {...autoCompleteProductColor} />
            <Autocomplete multiple {...autoCompleteProductMaterial} />
            <TextField
              helperText={errors?.name?.message}
              error={errors?.name && true}
              variant="outlined"
              label="Nama"
              name="name"
              fullWidth
              margin="dense"
              inputRef={register}
            />
          </Grid>
          <Grid item xs={12}>
            <Editor
              apiKey="vw7i3j8c79sw8kkiu9hktyt1ylvrgfy3bcamrxkbib77utab"
              initialValue="<p>Initial content</p>"
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image',
                  'charmap print preview anchor help',
                  'searchreplace visualblocks code',
                  'insertdatetime media table paste wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic | \
                          alignleft aligncenter alignright | \
                          bullist numlist outdent indent | help',
              }}
              onChange={() => {}}
            />

            <TextField
              helperText={errors?.description?.message}
              error={errors?.description && true}
              variant="outlined"
              label="Deskripsi"
              name="description"
              fullWidth
              margin="dense"
              inputRef={register}
            />
          </Grid>
          {!initialLoading && (
            <Grid item xs={12}>
              <Autocomplete {...autoCompleteProperty} />
              {errorProductCategoryInternal && (
                <FormHelperText>
                  Kesalahan saat mengambil list product category ineternal
                </FormHelperText>
              )}
              {errors.category && (
                <FormControl error>
                  <FormHelperText>{errors.category.message}</FormHelperText>
                </FormControl>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h6">Harga per Ukuran Dewasa</Typography>
          </Grid>
          {PRODUCT_CONSTANT.field.map((value, index) => {
            const { label, name } = value;
            const helperText = errors[name]?.message;
            const isError = errors[name] && true;
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <TextField
                  helperText={helperText}
                  error={isError}
                  variant="outlined"
                  label={label}
                  name={name}
                  fullWidth
                  margin="dense"
                  inputRef={register}
                />
              </Grid>
            );
          })}

          <Grid item xs={12}>
            <Typography variant="h6">Harga per Ukuran Anak</Typography>
          </Grid>
          {PRODUCT_CONSTANT.sizeChildren.map((value, index) => {
            const { label, name } = value;
            const helperText = errors[name]?.message;
            const isError = errors[name] && true;
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <TextField
                  helperText={helperText}
                  error={isError}
                  variant="outlined"
                  label={label}
                  name={name}
                  fullWidth
                  margin="dense"
                  inputRef={register}
                />
              </Grid>
            );
          })}

          <Grid item xs={12}>
            <Typography variant="h6">Berat per Ukuran dalam gram Dewasa</Typography>
          </Grid>
          {PRODUCT_CONSTANT.weight.map((value, index) => {
            const { label, name } = value;
            const helperText = errors[name]?.message;
            const isError = errors[name] && true;
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <TextField
                  helperText={helperText}
                  error={isError}
                  variant="outlined"
                  label={label}
                  name={name}
                  fullWidth
                  margin="dense"
                  inputRef={register}
                />
              </Grid>
            );
          })}

          <Grid item xs={12}>
            <Typography variant="h6">Berat per Ukuran dalam gram Anak</Typography>
          </Grid>
          {PRODUCT_CONSTANT.weightChildren.map((value, index) => {
            const { label, name } = value;
            const helperText = errors[name]?.message;
            const isError = errors[name] && true;
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <TextField
                  helperText={helperText}
                  error={isError}
                  variant="outlined"
                  label={label}
                  name={name}
                  fullWidth
                  margin="dense"
                  inputRef={register}
                />
              </Grid>
            );
          })}

          <RenderProductType name="type" control={control} />
          {!isEdit && (
            <Grid container direction="column" alignItems="center">
              <RenderFileUploadError fileUploadError={fileUploadErrorForAdd} />
              <UploadComponent.RenderInputUpload
                multiple
                onChange={e => {
                  const multiImage = ImageUtils.storeMultiImage(e);
                  setMultipleImageUpload(multiImage);
                }}
                register={register}
                name="file_upload"
                classes={classes}
                id="icon-upload-product"
              />
              <Grid container direction="row" spacing={1}>
                {multipleImageUpload.map((value, index) => {
                  return (
                    <Grid item xs={12} sm={4} key={index}>
                      <img src={value} className={classes.imagePreview} alt={value} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
          {loading ? (
            <Grid container direction="row" justify="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Button
              fullWidth
              onClick={handleSubmit(onSubmitRegister)}
              color="primary"
              variant="contained"
              className={classes.buttonChangePrice}
            >
              {isEdit ? 'Ubah Harga' : 'Tambah Produk'}
            </Button>
          )}
        </Grid>
        {isEdit && (
          <Grid container item xs={12} sm={6} direction="column">
            {mediaAsObject && <RenderCarousel data={mediaAsObject} classes={classes} />}
            <PublishedOption control={control} name="published" />
            <Paper
              elevation={3}
              style={{
                paddingTop: '16px',
                paddingBottom: '16px',
                width: '100%',
                marginTop: '24px',
              }}
            >
              <Grid container direction="column">
                <Typography
                  variant="h6"
                  align="center"
                  className={classes.title}
                  style={{ marginBottom: '16px' }}
                >
                  Edit Gambar Produk
                </Typography>

                {mediaAsObject.map((value, index) => {
                  const { url } = value;
                  const multipleImageUploadByIndex = multipleImageUpload[index];
                  return (
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      style={{ marginBottom: '16px' }}
                      key={url}
                    >
                      <Grid item xs={12} sm={4} container justify="center">
                        <input
                          style={{ paddingLeft: '8px' }}
                          type="file"
                          onChange={e => {
                            const newArr = [...multipleImageEdit];
                            newArr[index] = e.target.files;
                            setMultipleImageEdit(newArr);
                            const multiImage = ImageUtils.storeMultiImage(e);
                            setMultipleImageUpload(values => [...values, multiImage]);
                            /* setMultipleImageEdit(values => [...values, e.target.files]); */
                          }}
                        />
                        {multipleImageUploadByIndex && (
                          <img
                            src={multipleImageUploadByIndex}
                            style={{ maxWidth: '100%' }}
                            alt="product_edit_preview"
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={4} container alignItems="center">
                        <img src={url} style={{ maxWidth: '100%' }} alt="product_edit" />
                      </Grid>
                      <Grid item xs={12} sm={4} container justify="center" alignItems="center">
                        {!loadingUpload ? (
                          <>
                            <Tooltip title="Upload perubahan gambar">
                              <IconButton
                                onClick={() => {
                                  handleSubmitUpload(onSubmitUploadImage(value, index));
                                }}
                                size="small"
                              >
                                <EditIcon color="primary" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Hapus gambar">
                              <IconButton
                                onClick={async () => {
                                  if (
                                    window.confirm('Apa anda yakin ingin menghapus gambar ini ?')
                                  ) {
                                    await onSubmitDeleteImage(value, index);
                                  }
                                }}
                                size="small"
                              >
                                <DeleteIcon color="primary" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        ) : (
                          <Grid container direction="row" justify="center">
                            <CircularProgress />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>
            <Paper
              elevation={3}
              style={{
                paddingTop: '16px',
                paddingBottom: '16px',
                width: '100%',
                marginTop: '24px',
              }}
            >
              <Typography
                variant="h6"
                align="center"
                className={classes.title}
                style={{ marginBottom: '16px' }}
              >
                Tambah Gambar Produk
              </Typography>

              <Box mb={2}>
                <Tooltip title="Tambah Gambar Produk">
                  <IconButton
                    onClick={() => {
                      if (addImageCounter < 5) {
                        setAddImageCounter(addImageCounter + 1);
                      }
                    }}
                    size="small"
                  >
                    <AddIcon color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Kurangi Gambar Produk">
                  <IconButton
                    onClick={() => {
                      if (addImageCounter !== 1) {
                        setAddImageCounter(addImageCounter - 1);
                      }
                    }}
                    size="small"
                  >
                    <RemoveIcon color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              {[...Array(addImageCounter)].map((value, index) => {
                return (
                  <input
                    onChange={e => {
                      const newArr = [...multipleAddImageForEdit];
                      newArr[index] = e.target.files;
                      setMultipleAddImageForEdit(newArr);
                    }}
                    type="file"
                    style={{ paddingLeft: '8px' }}
                  />
                );
              })}
              {loadingAddProduct ? (
                <CircularProgress />
              ) : (
                <Button
                  style={{ marginTop: '16px' }}
                  onClick={async () => {
                    const imageLength = multipleAddImageForEdit.length;
                    if (imageLength === 0) {
                      alert('Harap upload produk terlebih dahulu');
                      setLoadingAddProduct(false);
                      return false;
                    }
                    setLoadingAddProduct(true);
                    multipleAddImageForEdit.map(async value => {
                      await ImageUtils.uploadIgnoreRequestID(
                        PRODUCT_INTERNAL,
                        value,
                        () => {
                          window.location.href = '/produk';
                        },
                        () => {
                          setLoadingAddProduct(false);
                        },
                        mediaAsObject[0].request_id,
                      );
                    });
                  }}
                  color="primary"
                  variant="contained"
                >
                  Upload
                </Button>
              )}
            </Paper>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
