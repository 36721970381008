import React, { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import EDIT_PRODUCT_VOUCHER from 'graphql/product/voucher/update';
import moment from 'moment';
import TextField from '@material-ui/core/TextField/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackComponent from '../../../component/back';
import SUCCESS_REDIRECT from './constant';
import AuthContext from '../../../AuthContext';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentWrapper: {
    paddingBottom: theme.spacing(3),
  },
  header: {
    paddingTop: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  media: {
    height: 350,
    backgroundSize: 'contain',
  },
  buttonChangePrice: {
    marginTop: theme.spacing(1),
  },
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  inputFile: {
    display: 'none',
  },
  buttonSubmit: {
    marginTop: theme.spacing(2),
  },
}));

export default function ProductEdit() {
  const { setShowError, setErrorMessage } = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  const EditProductVoucher = yup.object().shape({
    title: yup.string().required('Judul harus diisi'),
    description: yup.string().required('Deskripsi harus diisi'),
    image: yup.string().required('Gambar harus diisi'),
  });
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const { register, handleSubmit, errors } = useForm({
    validationSchema: EditProductVoucher,
    submitFocusError: false,
    nativeValidation: false,
    defaultValues: {
      title: urlParams.get('title'),
      description: urlParams.get('description'),
      image: urlParams.get('image'),
      expiredAt: moment(urlParams.get('expiredAt')).format('YYYY-MM-DD'),
    },
  });
  const [editProductVoucher, { loading }] = useMutation(EDIT_PRODUCT_VOUCHER, {
    onCompleted: async () => {
      window.location.href = SUCCESS_REDIRECT;
    },
    onError: async error => {
      setShowError(true);
      setErrorMessage(error.message);
    },
  });

  const todayDate = moment().format('YYYY-MM-DD');

  const onSubmit = async dataEdit => {
    const { title, description, image, expiredAt } = dataEdit;
    await editProductVoucher({
      variables: {
        payload: {
          code: urlParams.get('code'),
          title,
          description,
          image,
          expiredAt,
        },
      },
    });
  };

  return (
    <div>
      <Grid container className={classes.header}>
        <ArrowBackComponent history={history} location="/voucher" />
        <Typography variant="h6" gutterBottom align="center" className={classes.title}>
          Produk Voucher
        </Typography>
      </Grid>
      <TextField
        helperText={errors?.title?.message}
        error={errors?.title && true}
        variant="outlined"
        label="Judul"
        name="title"
        fullWidth
        margin="dense"
        inputRef={register}
      />
      <TextField
        helperText={errors?.description?.message}
        error={errors?.description && true}
        variant="outlined"
        label="Deskripsi"
        name="description"
        fullWidth
        margin="dense"
        inputRef={register}
      />
      <TextField
        helperText={errors?.image?.message}
        error={errors?.image && true}
        variant="outlined"
        label="Gambar"
        name="image"
        fullWidth
        margin="dense"
        inputRef={register}
      />
      <TextField
        inputRef={register}
        helperText={errors?.expiredAt?.message}
        error={errors?.expiredAt && true}
        name="expiredAt"
        margin="dense"
        size="small"
        fullWidth
        label="Kadaluwarsa"
        type="date"
        defaultValue={todayDate}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {loading ? (
        <Box display="flex" justifyContent="center" pt={1}>
          <CircularProgress />
        </Box>
      ) : (
        <Button
          fullWidth
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="contained"
          className={classes.buttonSubmit}
        >
          Ubah Voucher
        </Button>
      )}
    </div>
  );
}
