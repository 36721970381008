import gql from 'graphql-tag';

export const statement = `
  mutation upsertNoteWorkOrder($input: UpsertNoteWorkOrderInput!) {
    upsertNoteWorkOrder(input: $input) {
      id
      note
      order_status_id
      order_no
      spk_no
     
    }
  }
`;

export default gql`
  ${statement}
`;
