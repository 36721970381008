import gql from 'graphql-tag';

export const statement = `
   
  query product($payload:ParamProductInput){
  product(v:$payload) {
    count
    rows {     
      id
      name
      type
      createdAt
      updatedAt          
      material{     
        product_material{
          id
          name
        }
      }  
      color {
        product_color {
          id
          name
        }
      }                             
      media {
        url
        public_id
        request_id
      }
      category
      published
      description
      image
      stok                              
      S_Price
      PendekS_Price
      PanjangS_Price     
      M_Price
      PendekM_Price
      PanjangM_Price     
      L_Price
      PendekL_Price
      PanjangL_Price     
      XL_Price
      PendekXL_Price
      PanjangXL_Price         
      XXL_Price
      PendekXXL_Price
      PanjangXXL_Price                    
      ThreeXL_Price
      PendekThreeXL_Price 
      PanjangThreeXL_Price
      FourthXL_Price
      PendekFourthXL_Price
      PanjangFourthXL_Price
      FifthXL_Price
      PendekFifthXL_Price
      PanjangFifthXL_Price    
      SixthXL_Price
      PendekSixthXL_Price
      PanjangSixthXL_Price
      Product_Child {                
        S_Children_Price
        PendekS_Children_Price
        PanjangS_Children_Price
        M_Children_Price
        PendekM_Children_Price
        PanjangM_Children_Price
        L_Children_Price
        PendekL_Children_Price
        PanjangL_Children_Price
        XL_Children_Price
        PendekXL_Children_Price
        PanjangXL_Children_Price                       
      }     
      weight {        
        id
        PendekS_weight
        S_weight
        PanjangS_weight
  
        M_weight
        PendekM_weight
        PanjangM_weight
  
        L_weight
        PendekL_weight
        PanjangL_weight
  
        XL_weight
        PendekXL_weight
        PanjangXL_weight
  
  
        XXL_weight
        PendekXXL_weight
        PanjangXXL_weight
  
        ThreeXL_weight
        PendekThreeXL_weight
        PanjangThreeXL_weight
  
        FourthXL_weight
        PendekFourthXL_weight
        PanjangFourthXL_weight
  
        FifthXL_weight
        PendekFifthXL_weight
        PanjangFifthXL_weight
  
        SixthXL_weight
        PendekSixthXL_weight
        PanjangSixthXL_weight 
                                                 
        PendekS_Children_weight
        S_Children_weight
        PanjangS_Children_weight
        PendekM_Children_weight
        M_Children_weight
        PanjangM_Children_weight

        PendekL_Children_weight
        L_Children_weight
        PanjangL_Children_weight

        PendekXL_Children_weight
        XL_Children_weight
        PanjangXL_Children_weight
                                                                                   
      }                                                    
    }
  }
}   
`;

export default gql`
  ${statement}
`;
