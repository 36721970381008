import gql from 'graphql-tag';

export const statement = `   
query productVoucher($payload:ProductVoucherInput) {
  productVoucher(v:$payload) {  
    code   
    title
    image
    description
    id_account
    percentage          
    Product {
      count
      rows {
        id
        name
      }
    }
    Customer{
      id
      name
    }                     
    expiredAt    
  }
}
`;

export default gql`
  ${statement}
`;
