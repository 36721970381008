import gql from 'graphql-tag';

export const statement = `   
mutation updateProductMaterial($payload:UpdateProductMaterial!) {
    updateProductMaterial(v:$payload) {
        id
        name
    }
}
`;

export default gql`
  ${statement}
`;
