import gql from 'graphql-tag';

export const statement = `  
query variant($payload:ParamVariant){
    variant(v:$payload){
        id
        name
        createdAt
        updatedAt
    }
}
`;

export default gql`
  ${statement}
`;
