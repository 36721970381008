import React, { useState } from 'react';
import axios from 'axios';
import { useMutation } from '@apollo/react-hooks';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import UploadValidation from 'utils/validation/upload';
import * as yup from 'yup';
import Upload from 'component/upload/index';
import ImageUtils from 'utils/Image';
import RenderFileUploadError from 'component/FileUploadErrorText';
import CONFIRM_WITHDRAW from 'graphql/wallet/WithdrawRequest/confirm';

export default function DialogWithdrawSuccess({ open, onClose, id, refetch }) {
  const [confirmWithdrawAction] = useMutation(CONFIRM_WITHDRAW, {
    onCompleted: () => {
      onClose();
      refetch();
    },
    onError: () => {},
  });
  const [multipleImageUpload, setMultipleImageUpload] = useState([]);
  const uploadValidation = UploadValidation('file_upload');
  const uploadImageScheme = yup.object().shape(uploadValidation);
  const { register, handleSubmit, getValues, errors } = useForm({
    validationSchema: uploadImageScheme,
    submitFocusError: false,
    nativeValidation: false,
  });
  const onYes = () => {
    const urlUpload = `${process.env.REACT_APP_SERVICE_MEDIA}/api/v1/uploadS3`;
    const uploadValue = getValues('file_upload');
    const uploadValueLength = uploadValue.length;
    const dataForm = new FormData();
    for (let i = 0; i < uploadValueLength; i += 1) {
      dataForm.append(`files[${i}]`, uploadValue[i]);
    }
    axios
      .post(urlUpload, dataForm)
      .then(async dataUpload => {
        await confirmWithdrawAction({
          variables: {
            payload: {
              id: parseInt(id, 10),
              image_done_proof: dataUpload.data.data,
            },
          },
        });
      })
      .catch(() => {});
  };

  const onNo = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Tandai sudah di bayar</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ketika anda upload bukti transfer, customer akan menerima notifikasi bahwa request
          withdraw berhasil.Saldo akan berkurang dari wallet customer dan sampai ke rekening pribadi
          customer
        </DialogContentText>
        <input
          onChange={e => {
            const multiImage = ImageUtils.storeMultiImage(e);
            setMultipleImageUpload(multiImage);
          }}
          style={{
            display: 'none',
          }}
          id="file_upload"
          name="file_upload"
          ref={register}
          type="file"
          accept="image/*"
        />
        <Upload.RenderImagePreview image={multipleImageUpload} />
        <Upload.RenderUploadIcon text="Upload bukti transfer" htmlFor="file_upload" />
        <RenderFileUploadError fileUploadError={errors?.file_upload} center />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onYes)} color="primary" variant="contained">
          Ya
        </Button>
        <Button onClick={onNo} color="primary" variant="outlined">
          Tidak
        </Button>
      </DialogActions>
    </Dialog>
  );
}
