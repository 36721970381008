import * as yup from 'yup';

const UploadValidation = (fieldUpload, maxSize = 1024) => {
  return {
    file_upload: yup
      .mixed()
      .test(fieldUpload, 'Gambar harus di upload', value => {
        return !!value?.[0];
      })
      .test(fieldUpload, 'Tipe file yang di dukung adalah JPEG dan PNG', valueType => {
        const imageValue = valueType?.[0];
        if (imageValue) {
          const { type } = imageValue;
          return type === 'image/jpeg' || type === 'image/png';
        }
        return false;
      })
      .test(fieldUpload, 'Ukuran gambar maksimal 2 MB', valueSize => {
        const imageValue = valueSize?.[0];
        if (imageValue) {
          const { size } = imageValue;
          const sizeInMB = (size / (maxSize * maxSize)).toFixed(2);
          return sizeInMB < 2;
        }
        return false;
      }),
  };
};

export default UploadValidation;
