const PRODUCT_INTERNAL = 'voodoo/product_internal/';
const PRODUCT_CATEGORY_INTERNAL = 'voodoo/product_category_internal/';
const PRODUCT_CATEGORY_WEBSITE = 'voodoo/product_category_website/';
const PRODUCT_WEBSITE = 'voodoo/product_website/';
const PRODUCT_SPK = 'voodoo/spk/';
const PRODUCT_BANNER = 'voodoo/product_banner/';
export {
  PRODUCT_INTERNAL,
  PRODUCT_CATEGORY_INTERNAL,
  PRODUCT_SPK,
  PRODUCT_BANNER,
  PRODUCT_CATEGORY_WEBSITE,
  PRODUCT_WEBSITE,
};
