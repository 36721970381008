import gql from 'graphql-tag';

export const statement = `   
mutation createProductCategoryWebsite($payload:ParamCreateProductCategoryWebsite!) {
    createProductCategoryWebsite(v:$payload) {
        name
    }
}
`;

export default gql`
  ${statement}
`;
