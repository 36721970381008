import gql from 'graphql-tag';

export const statement = `
mutation updateProduct($payload:ProductInput!){
  updateProduct(v:$payload) {
    id
    name
    description
    category    
  }
}
`;

export default gql`
  ${statement}
`;
