import gql from 'graphql-tag';

export const statement = `   
query productColor($payload: ParamProductColor){
  productColor(v: $payload){
    id
    name    
    createdAt
    updatedAt
  }
}
`;

export default gql`
  ${statement}
`;
