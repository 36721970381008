import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

/**
 * @return {null}
 */
function RenderFileUploadError({ fileUploadError, center }) {
  const style = {};
  if (center) {
    style.textAlign = 'center';
  }
  if (fileUploadError) {
    return (
      <FormControl error fullWidth>
        <FormHelperText style={style}>{fileUploadError.message}</FormHelperText>
      </FormControl>
    );
  }
  return null;
}

export default React.memo(RenderFileUploadError);
