import gql from 'graphql-tag';

export const statement = `
mutation createInvoice($payload:ParamCreateInvoice!){
    createInvoice(v: $payload) {
       status
    }
}
`;

export default gql`
  ${statement}
`;
