import React, { useEffect, useState } from 'react';
import Verify from '@material-ui/icons/ConfirmationNumberSharp';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import { NetworkStatus } from 'apollo-client';
import Loading from 'component/loading';
import Error from 'component/error';
import Typography from '@material-ui/core/Typography';
import LoadMore from 'component/LoadMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PARTNER_APPLICATION_QUERY from '../../graphql/account/partner-application/get';
import VERIFY_ACCOUNT_PARTNER_APPLICATION from '../../graphql/account/partner-application/verify';
import CUSTOMER_QUERY from '../../graphql/account/customer';
import empty from '../../assets/svg/empty.svg';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  imageEmpty: {
    maxWidth: '100%',
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  emptyWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export default function PartnerApplication() {
  const classes = useStyles();
  const payload = {
    offset: 0,
    limit: 10,
  };

  const { register, setValue } = useForm({
    submitFocusError: false,
    nativeValidation: false,
  });

  const [openSnackBar, setOpenSnackBar] = useState({
    visibility: false,
    message: '',
  });
  const payloadCustomer = {
    offset: 0,
    limit: 10,
  };
  const { data: dataCustomer, loading: loadingCustomer, refetch: refetchCustomer } = useQuery(
    CUSTOMER_QUERY,
    {
      variables: {
        payload: payloadCustomer,
      },
      fetchPolicy: 'no-cache',
    },
  );

  const { data, refetch, fetchMore, networkStatus, error } = useQuery(PARTNER_APPLICATION_QUERY, {
    variables: {
      payload,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [verifyAccountPartnerApplication] = useMutation(VERIFY_ACCOUNT_PARTNER_APPLICATION, {
    onCompleted: () => {
      refetch();
    },
    onError: errorVerifyAccount => {
      setOpenSnackBar({
        visibility: true,
        message: errorVerifyAccount.graphQLErrors[0].message,
      });
    },
  });

  const dataCount = data?.accountPartnerApplication?.count;
  const dataRows = data?.accountPartnerApplication?.rows;

  const initialLoading = networkStatus === NetworkStatus.loading;
  const fetchMoreLoading = networkStatus === NetworkStatus.fetchMore;
  const refetchLoading = networkStatus === NetworkStatus.refetch;

  useEffect(() => {
    register('customer');
  }, [register]);

  if (initialLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  const autoCompleteCustomerProperty = {};
  autoCompleteCustomerProperty.fullWidth = true;
  autoCompleteCustomerProperty.options = dataCustomer?.customer;
  autoCompleteCustomerProperty.getOptionLabel = option => {
    return `${option.name}-${option.email}`;
  };
  autoCompleteCustomerProperty.renderInput = params => (
    <TextField {...params} label="Customer" variant="outlined" margin="dense" />
  );
  autoCompleteCustomerProperty.noOptionsText = 'Customer tidak di temukan';
  autoCompleteCustomerProperty.size = 'small';
  autoCompleteCustomerProperty.onChange = (value, newValue) => {
    if (newValue?.id) {
      const idValue = newValue?.id;
      refetch({
        payload: {
          id_account: idValue,
        },
      });
      setValue('customer', idValue);
    } else {
      setValue('customer', '');
    }
  };
  autoCompleteCustomerProperty.onInputChange = event => {
    const name = event?.target?.value;
    // close button is clicked
    if (name !== 0) {
      refetchCustomer({
        payload: {
          name,
        },
      });
      refetch({
        payload: {
          offset: 0,
          limit: 10,
        },
      });
    }
  };

  return (
    <>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar.visibility}
        onClose={() => {
          setOpenSnackBar({
            visibility: false,
          });
        }}
        message={openSnackBar.message}
      />
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Partner Application</Typography>
        <Grid container>
          {!loadingCustomer && <Autocomplete {...autoCompleteCustomerProperty} />}
        </Grid>
      </div>
      <TableContainer component={Paper}>
        {dataCount === 0 ? (
          <>
            <div className={classes.emptyWrapper}>
              <img src={empty} alt="empty_img" className={classes.imageEmpty} />
              <Typography variant="h6" align="center">
                Aplikasi Partner tidak di temukan
              </Typography>
            </div>
          </>
        ) : (
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nama</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>KTP</TableCell>
                <TableCell>NPWP</TableCell>
                <TableCell>Status Verifikasi</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRows.map(value => {
                const {
                  id,
                  ktp,
                  npwp,
                  is_verified,
                  createdAt,
                  updatedAt,
                  account: { name, phone, email },
                } = value;

                return (
                  <TableRow>
                    <TableCell>{id}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>{phone}</TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>{ktp}</TableCell>
                    <TableCell>{npwp}</TableCell>
                    <TableCell>{is_verified}</TableCell>
                    <TableCell>{createdAt}</TableCell>
                    <TableCell>{updatedAt}</TableCell>
                    <TableCell>
                      <Tooltip title={is_verified === 'Y' ? 'Batalkan Verifikasi' : 'Verifikasi'}>
                        <IconButton
                          size="small"
                          onClick={async () => {
                            await verifyAccountPartnerApplication({
                              variables: {
                                payload: {
                                  id,
                                  email,
                                  is_verified: is_verified === 'Y' ? 'N' : 'Y',
                                },
                              },
                            });
                          }}
                        >
                          <Verify color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {dataCount > 10 && (
        <>
          {fetchMoreLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Box pt={2}>
              <LoadMore
                loading={fetchMoreLoading || refetchLoading}
                refetch={refetch}
                fetchMore={async () => {
                  const dataRowsLength = dataRows.length;
                  const payloadFetchMore = {
                    offset: dataRowsLength,
                  };
                  await fetchMore({
                    variables: {
                      payload: payloadFetchMore,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      const newRows = fetchMoreResult.accountPartnerApplication.rows;
                      const newCount = fetchMoreResult.accountPartnerApplication.count;
                      return {
                        accountPartnerApplication: {
                          ...prev.accountPartnerApplication,
                          rows: [...prev.accountPartnerApplication.rows, ...newRows],
                          count: newCount,
                        },
                      };
                    },
                  });
                }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
}
