const URL = {};
const makeQueryString = param => {
  let query = '?';
  const paramLength = Object.keys(param).length;
  Object.keys(param).map((value, index) => {
    query += `${value}=${param[value]}`;
    if (index < paramLength - 1) {
      query += '&';
    }
    return true;
  });
  return query;
};

const queryString = (url, key) => {
  const query = new URLSearchParams(url);
  return query.get(key);
};
/* https://stackoverflow.com/questions/2964678/jquery-youtube-url-validation-with-regex */

const youtubeFormat = () => {
  // eslint-disable-next-line max-len
  return /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
};

URL.makeQueryString = makeQueryString;
URL.queryString = queryString;
URL.youtubeFormat = youtubeFormat;
export default URL;
