import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function Back({ history, location }) {
  return (
    <IconButton
      style={{ marginLeft: '8px', marginBottom: '8px' }}
      onClick={() => {
        history.replace(location);
      }}
      color="inherit"
    >
      <ArrowBackIcon />
    </IconButton>
  );
}
