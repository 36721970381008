import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

function Navigation({ item }) {
  const useStyles = makeStyles({
    bottomTabNavigation: {},
    list: {
      width: 250,
    },
  });
  const classes = useStyles();
  const history = useHistory();
  const handleNavigation = link => {
    history.push(link);
  };

  return (
    <div className={classes.list} role="presentation">
      <List>
        {item.map((value, indexNavigation) => {
          const { name, link } = value;
          const textLink = name.charAt(0).toUpperCase() + name.slice(1);
          return (
            <ListItem onClick={() => handleNavigation(link)} button key={indexNavigation}>
              <ListItemText primary={textLink} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

export default React.memo(Navigation); // Only re-render if props change
