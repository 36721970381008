import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import Snackbar from '@material-ui/core/Snackbar';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Routes from './routes';
import AuthContext from './AuthContext';
import client from './client';

Sentry.init({
  dsn: 'https://044f86aa42b947319ecda2f94ef98353@o468796.ingest.sentry.io/5498057',
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

let customTheme = createTheme({
  typography: {
    fontFamily: ['"Montserrat"', 'Open Sans'].join(','),
  },
  palette: {
    primary: {
      main: red[500],
    },
  },
});
customTheme = responsiveFontSizes(customTheme);

const App = () => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={customTheme}>
        <AuthContext.Provider
          value={{
            showError,
            errorMessage,
            setShowError,
            setErrorMessage,
          }}
        >
          <Sentry.ErrorBoundary fallback="An error has occured">
            <Snackbar
              autoHideDuration={1500}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={showError}
              onClose={() => {
                setShowError(false);
              }}
              message={errorMessage}
            />
            <Routes />
          </Sentry.ErrorBoundary>
        </AuthContext.Provider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
