import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageEmpty: {
    width: '500px',
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loadMoreButtonWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  searchWrapper: {
    paddingBottom: theme.spacing(2),
  },
  searchField: {
    paddingRight: theme.spacing(1),
  },
  alertWrapper: {
    marginBottom: theme.spacing(1),
  },
}));
