import gql from 'graphql-tag';

export const statement = `

query salesMinimum($payload:ParamSales!) {
    salesMinimum(v:$payload) {
        sub_total
        down_payment
    }
}
`;

export default gql`
  ${statement}
`;
