import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import EDIT_PRODUCT_COLOR from 'graphql/product/color/update';
import Error from 'component/error/index';
import FormProductColor from './form';
import Validation from './validation';
import SUCCESS_REDIRECT from './constant';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentWrapper: {
    paddingBottom: theme.spacing(3),
  },
  header: {
    paddingTop: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  media: {
    height: 350,
    backgroundSize: 'contain',
  },
  buttonChangePrice: {
    marginTop: theme.spacing(1),
  },
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  inputFile: {
    display: 'none',
  },
}));

export default function ProductEdit() {
  const classes = useStyles();
  const EditProductColor = yup.object().shape({
    ...Validation,
  });
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
    validationSchema: EditProductColor,
    submitFocusError: false,
    nativeValidation: false,
    defaultValues: {
      name: urlParams.get('name'),
    },
  });

  const [editProductAction, { loading, error: errorProductColor }] = useMutation(
    EDIT_PRODUCT_COLOR,
    {
      onCompleted: async () => {
        window.location.href = SUCCESS_REDIRECT;
      },
    },
  );

  const onUpdate = async dataEditProductColor => {
    const reassign = dataEditProductColor;
    reassign.id = parseInt(urlParams.get('id'), 10);
    await editProductAction({
      variables: {
        payload: reassign,
      },
    });
  };

  if (errorProductColor) {
    return <Error />;
  }

  return (
    <FormProductColor
      isEdit
      formState={formState}
      classes={classes}
      errors={errors}
      register={register}
      loading={loading}
      handleSubmit={handleSubmit}
      onSubmit={onUpdate}
    />
  );
}
