import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import empty from 'assets/svg/empty.svg';

const useStyles = makeStyles(theme => ({
  item: {
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  imageEmpty: {
    maxWidth: '100%',
  },
  wrapperEmpty: {
    padding: theme.spacing(4),
  },
}));

function DetailAddress({ open, data, handleClose, getAccountAddress }) {
  const classes = useStyles();

  const accountAddressData = data.accountAddress;
  const accountAddressDataLength = accountAddressData.length;
  const accountAddressExist = accountAddressDataLength > 0;

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogTitle>Detail Alamat</DialogTitle>
      <DialogContent>
        {accountAddressExist &&
          accountAddressData.map(value => {
            const { address_name, city, full_address, subdistrict, province } = value;
            return (
              <Paper elevation={3} className={classes.item}>
                <Typography variant="h6">{address_name}</Typography>
                <Typography variant="h6">{province}</Typography>
                <Typography variant="subtitle1">{city}</Typography>
                <Typography variant="body1">{subdistrict}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {full_address}
                </Typography>
              </Paper>
            );
          })}
        {!accountAddressExist && (
          <div className={classes.wrapperEmpty}>
            <img src={empty} alt="empty_img" className={classes.imageEmpty} />
            <Typography variant="h6" align="center">
              Detail alamat tidak di temukan
            </Typography>
          </div>
        )}
      </DialogContent>
      {accountAddressExist && (
        <DialogActions style={{ paddingBottom: '16px' }}>
          <Button fullWidth color="primary" variant="contained" onClick={getAccountAddress}>
            Muat lebih
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default React.memo(DetailAddress);
