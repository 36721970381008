import React, { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import ACTIVATE_OR_DEACTIVATE from 'graphql/account/activateOrDeactivate';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm } from 'react-hook-form';

function ActivateOrDeactivate({
  setActivateDeactivateVisibility,
  customerEmail,
  visiblity = false,
  refetch,
  customerActive,
  setCustomerActive,
}) {
  const { register, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    submitFocusError: false,
    nativeValidation: false,
  });

  const [activateOrDeactivateAction, { loading: loadingActivateOrDeactivate }] = useMutation(
    ACTIVATE_OR_DEACTIVATE,
    {
      onCompleted: () => {
        setActivateDeactivateVisibility(false);
        refetch();
      },
      onError: () => {
        setActivateDeactivateVisibility(false);
      },
    },
  );

  const Option = [
    {
      value: 'Y',
      label: 'Y',
    },
    {
      value: 'N',
      label: 'N',
    },
  ];

  const onSubmit = async data => {
    const payload = {
      is_active: data.is_active,
      email: customerEmail,
    };
    await activateOrDeactivateAction({
      variables: {
        payload,
      },
    });
  };

  useEffect(() => {
    register('is_active');
    setValue('is_active', 'Y');
  }, []);
  return (
    <Dialog
      open={visiblity}
      fullWidth
      onClose={() => {
        setActivateDeactivateVisibility(false);
      }}
    >
      <DialogTitle>Aktifkan Customer</DialogTitle>
      <DialogContent>
        <RadioGroup
          row
          value={customerActive}
          onChange={event => {
            setCustomerActive(event.target.value);
          }}
        >
          {Option.map(valueOption => {
            const { value: valueOptionContent, label } = valueOption;
            return (
              <FormControlLabel
                key={valueOptionContent}
                value={valueOptionContent}
                control={
                  <Radio
                    color="primary"
                    onChange={event => {
                      const radioValue = event.target.value;
                      setValue('is_active', radioValue);
                    }}
                  />
                }
                label={label}
              />
            );
          })}
        </RadioGroup>
      </DialogContent>
      <DialogActions style={{ paddingBottom: '16px' }}>
        {loadingActivateOrDeactivate ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button onClick={handleSubmit(onSubmit)} fullWidth color="primary" variant="contained">
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(ActivateOrDeactivate);
