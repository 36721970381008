import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import React from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import MUTATION_UPDATE_ORDER from 'graphql/order/update';
import Loading from '../../../component/loading/index';
import { PRODUCT_CONSTANT } from '../../../constant/index';
import Validation from '../validation';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  yesButton: {
    marginRight: theme.spacing(1),
  },
  footerWrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default function SpkEdit() {
  const classes = useStyles();
  const history = useHistory();
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [updateOrderAction, { loading }] = useMutation(MUTATION_UPDATE_ORDER, {
    onCompleted: () => {
      setOpenSnackBar(true);
      setTimeout(() => {
        window.location.href = '/spk';
      }, 1000);
    },
  });
  const urlParams = new URLSearchParams(window.location.search);

  const spkEditSchemaObject = yup.object().shape(Validation);
  const { register, handleSubmit } = useForm({
    validationSchema: spkEditSchemaObject,
    submitFocusError: false,
    nativeValidation: false,
    defaultValues: {
      id: urlParams.get('id'),
      S_Qty: urlParams.get('S_Qty'),
      PendekS_Qty: urlParams.get('PendekS_Qty'),
      PanjangS_Qty: urlParams.get('PanjangS_Qty'),
      M_Qty: urlParams.get('M_Qty'),
      PendekM_Qty: urlParams.get('PendekM_Qty'),
      PajangM_Qty: urlParams.get('PajangM_Qty'),
      L_Qty: urlParams.get('L_Qty'),
      PendekL_Qty: urlParams.get('PendekL_Qty'),
      PanjangL_Qty: urlParams.get('PanjangL_Qty'),
      XL_Qty: urlParams.get('XL_Qty'),
      PendekXL_Qty: urlParams.get('PendekXL_Qty'),
      PanjangXL_Qty: urlParams.get('PanjangXL_Qty'),
      XXL_Qty: urlParams.get('XXL_Qty'),
      PendekXXL_Qty: urlParams.get('PendekXXL_Qty'),
      PanjangXXL_Qty: urlParams.get('PanjangXXL_Qty'),
      ThreeXL_Qty: urlParams.get('ThreeXL_Qty'),
      PendekThreeXL_Qty: urlParams.get('PendekThreeXL_Qty'),
      PanjangThreeXL_Qty: urlParams.get('PanjangThreeXL_Qty'),
      FourthXL_Qty: urlParams.get('FourthXL_Qty'),
      PendekFourthXL_Qty: urlParams.get('PendekFourthXL_Qty'),
      PanjangFourthXL_Qty: urlParams.get('PanjangFourthXL_Qty'),
      FifthXL_Qty: urlParams.get('FifthXL_Qty'),
      PendekFifthXL_Qty: urlParams.get('PendekFifthXL_Qty'),
      PanjangFifthXL_Qty: urlParams.get('PanjangFifthXL_Qty'),
      SixthXL_Qty: urlParams.get('SixthXL_Qty'),
      PendekSixthXL_Qty: urlParams.get('PendekSixthXL_Qty'),
      PanjangSixthXL_Qty: urlParams.get('PanjangSixthXL_Qty'),
      down_payment: urlParams.get('down_payment'),
      note: urlParams.get('note'),
      spk_no: urlParams.get('spk_no'),
    },
  });

  const onSubmit = async dataOnSubmit => {
    await updateOrderAction({
      variables: {
        payload: dataOnSubmit,
      },
    });
  };

  return (
    <Container maxWidth="md" className={classes.wrapper}>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message="Spk berhasil di perbaharui"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            label="ID"
            name="id"
            fullWidth
            margin="dense"
            inputRef={register}
          />

          <Grid container direction="row" spacing={1}>
            {PRODUCT_CONSTANT.size.map(value => {
              const { name, label } = value;
              return (
                <Grid item xs={4} sm={4} md={4} key={label}>
                  <TextField
                    variant="outlined"
                    label={label}
                    name={name}
                    fullWidth
                    margin="dense"
                    inputRef={register}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            label="No SPK"
            name="spk_no"
            fullWidth
            margin="dense"
            inputRef={register}
          />
          <TextField
            variant="outlined"
            label="Catatan"
            name="note"
            fullWidth
            margin="dense"
            inputRef={register}
          />
          <TextField
            variant="outlined"
            label="Total DP"
            name="down_payment"
            fullWidth
            margin="dense"
            inputRef={register}
          />
        </Grid>
      </Grid>
      <Grid container justify="center" className={classes.footerWrapper}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              variant="contained"
              className={classes.yesButton}
            >
              Ya
            </Button>
            <Button
              onClick={() => {
                history.push('/spk');
              }}
              color="primary"
              variant="contained"
            >
              Tidak
            </Button>
          </>
        )}
      </Grid>
    </Container>
  );
}
