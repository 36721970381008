import gql from 'graphql-tag';

export const statement = `
query productWebsiteVariant($payload:ParamProductWebsiteVariant){
    productWebsiteVariant(v:$payload) {
        id
        stok
        price
        image
        sku
        status
        first_variant {
            id
            id_variant
            name
            variant {
              name
            }
        }
        second_variant {
            id
            id_variant
            name            
            variant {
              name
            }            
        }
    }
}
`;

export default gql`
  ${statement}
`;
