import gql from 'graphql-tag';

export const statement = `
mutation createProductReferral($payload:ParamProductReferral!) {
    createProductReferral(v:$payload) {
        code
        id_issuer
        id_product
        percentage
    }
}
 `;

export default gql`
  ${statement}
`;
