import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CREATE_PRODUCT_MATERIAL from 'graphql/product/materialLinkage/create';
import CREATE_PRODUCT_COLOR from 'graphql/product/colorLinkage/create';
import EDIT_PRODUCT from '../../graphql/product/update';
import Error from '../../component/error/index';
import FormProduct from './form';
import Validation from './validation';
import ValidationProductWeight from './validation_product_weight';
import CREATE_PRODUCT_WEIGHT from '../../graphql/product/weight';
import SUCCESS_REDIRECT from './constant';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentWrapper: {
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  media: {
    height: 350,
    backgroundSize: 'contain',
  },
  buttonChangePrice: {
    marginTop: theme.spacing(1),
  },
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  inputFile: {
    display: 'none',
  },
  header: {
    paddingTop: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  imagePreview: {
    maxWidth: '100%',
  },
}));

export default function ProductEdit() {
  const classes = useStyles();
  const location = useLocation();
  const [productWeight, setProductWeight] = useState(false);
  const urlParams = new URLSearchParams(location.search);
  const EditProductSchema = yup.object().shape({
    ...Validation,
    ...ValidationProductWeight,
  });
  const history = useHistory();
  const { register, handleSubmit, errors, control, setValue, getValues } = useForm({
    validationSchema: EditProductSchema,
    submitFocusError: false,
    nativeValidation: false,
    defaultValues: {
      type: urlParams.get('type') || 'reseller',
      published: urlParams.get('published'),
      name: urlParams.get('name'),
      description: urlParams.get('description'),
      category: urlParams.get('category'),
      S_Price: parseInt(urlParams.get('S_Price'), 10),
      PendekS_Price: parseInt(urlParams.get('PendekS_Price'), 10),
      PanjangS_Price: parseInt(urlParams.get('PanjangS_Price'), 10),
      M_Price: parseInt(urlParams.get('M_Price'), 10),
      PendekM_Price: parseInt(urlParams.get('PendekM_Price'), 10),
      PanjangM_Price: parseInt(urlParams.get('PanjangM_Price'), 10),
      L_Price: parseInt(urlParams.get('L_Price'), 10),
      PendekL_Price: parseInt(urlParams.get('PendekL_Price'), 10),
      PanjangL_Price: parseInt(urlParams.get('PanjangL_Price'), 10),
      XL_Price: parseInt(urlParams.get('XL_Price'), 10),
      PendekXL_Price: parseInt(urlParams.get('PendekXL_Price'), 10),
      PanjangXL_Price: parseInt(urlParams.get('PanjangXL_Price'), 10),
      XXL_Price: parseInt(urlParams.get('XXL_Price'), 10),
      PendekXXL_Price: parseInt(urlParams.get('PendekXXL_Price'), 10),
      PanjangXXL_Price: parseInt(urlParams.get('PanjangXXL_Price'), 10),
      ThreeXL_Price: parseInt(urlParams.get('ThreeXL_Price'), 10),
      PendekThreeXL_Price: parseInt(urlParams.get('PendekThreeXL_Price'), 10),
      PanjangThreeXL_Price: parseInt(urlParams.get('PanjangThreeXL_Price'), 10),
      FourthXL_Price: parseInt(urlParams.get('FourthXL_Price'), 10),
      PendekFourthXL_Price: parseInt(urlParams.get('PendekFourthXL_Price'), 10),
      PanjangFourthXL_Price: parseInt(urlParams.get('PanjangFourthXL_Price'), 10),
      FifthXL_Price: parseInt(urlParams.get('FifthXL_Price'), 10),
      PendekFifthXL_Price: parseInt(urlParams.get('PendekFifthXL_Price'), 10),
      PanjangFifthXL_Price: parseInt(urlParams.get('PanjangFifthXL_Price'), 10),
      SixthXL_Price: parseInt(urlParams.get('SixthXL_Price'), 10),
      PendekSixthXL_Price: parseInt(urlParams.get('PendekSixthXL_Price'), 10),
      PanjangSixthXL_Price: parseInt(urlParams.get('PanjangSixthXL_Price'), 10),

      PendekS_weight: urlParams.get('PendekS_weight') || 0,
      S_weight: urlParams.get('S_weight') || 0,
      PanjangS_weight: urlParams.get('PanjangS_weight') || 0,
      M_weight: urlParams.get('M_weight') || 0,
      PendekM_weight: urlParams.get('PendekM_weight') || 0,
      PanjangM_weight: urlParams.get('PanjangM_weight') || 0,

      L_weight: urlParams.get('L_weight') || 0,
      PendekL_weight: urlParams.get('PendekL_weight') || 0,
      PanjangL_weight: urlParams.get('PanjangL_weight') || 0,

      XL_weight: urlParams.get('XL_weight') || 0,
      PendekXL_weight: urlParams.get('PendekXL_weight') || 0,
      PanjangXL_weight: urlParams.get('PanjangXL_weight') || 0,

      XXL_weight: urlParams.get('XXL_weight'),
      PendekXXL_weight: urlParams.get('PendekXXL_weight') || 0,
      PanjangXXL_weight: urlParams.get('PanjangXXL_weight') || 0,

      ThreeXL_weight: urlParams.get('ThreeXL_weight') || 0,
      PendekThreeXL_weight: urlParams.get('PendekThreeXL_weight') || 0,
      PanjangThreeXL_weight: urlParams.get('PanjangThreeXL_weight') || 0,

      FourthXL_weight: urlParams.get('FourthXL_weight') || 0,
      PendekFourthXL_weight: urlParams.get('PendekFourthXL_weight') || 0,
      PanjangFourthXL_weight: urlParams.get('PanjangFourthXL_weight') || 0,

      FifthXL_weight: urlParams.get('FifthXL_weight') || 0,
      PendekFifthXL_weight: urlParams.get('PendekFifthXL_weight') || 0,
      PanjangFifthXL_weight: urlParams.get('PanjangFifthXL_weight') || 0,

      SixthXL_weight: urlParams.get('SixthXL_weight') || 0,
      PendekSixthXL_weight: urlParams.get('PendekSixthXL_weight') || 0,
      PanjangSixthXL_weight: urlParams.get('PanjangSixthXL_weight') || 0,

      PendekS_Children_weight: urlParams.get('PendekS_Children_weight') || 0,
      S_Children_weight: urlParams.get('S_Children_weight') || 0,
      PanjangS_Children_weight: urlParams.get('PanjangS_Children_weight') || 0,
      PendekM_Children_weight: urlParams.get('PendekM_Children_weight') || 0,
      M_Children_weight: urlParams.get('M_Children_weight') || 0,
      PanjangM_Children_weight: urlParams.get('PanjangM_Children_weight') || 0,

      PendekL_Children_weight: urlParams.get('PendekL_Children_weight') || 0,
      L_Children_weight: urlParams.get('L_Children_weight') || 0,
      PanjangL_Children_weight: urlParams.get('PanjangL_Children_weight') || 0,

      PendekXL_Children_weight: urlParams.get('PendekXL_Children_weight') || 0,
      XL_Children_weight: urlParams.get('XL_Children_weight') || 0,
      PanjangXL_Children_weight: urlParams.get('PanjangXL_Children_weight') || 0,
      S_Children_Price: parseInt(urlParams.get('S_Children_Price'), 10) || 0,
      PendekS_Children_Price: parseInt(urlParams.get('PendekS_Children_Price'), 10) || 0,
      PanjangS_Children_Price: parseInt(urlParams.get('PanjangS_Children_Price'), 10) || 0,
      M_Children_Price: parseInt(urlParams.get('M_Children_Price'), 10) || 0,
      PendekM_Children_Price: parseInt(urlParams.get('PendekM_Children_Price'), 10) || 0,
      PanjangM_Children_Price: parseInt(urlParams.get('PanjangM_Children_Price'), 10) || 0,
      L_Children_Price: parseInt(urlParams.get('L_Children_Price'), 10) || 0,
      PendekL_Children_Price: parseInt(urlParams.get('PendekL_Children_Price'), 10) || 0,
      PanjangL_Children_Price: parseInt(urlParams.get('PanjangL_Children_Price'), 10) || 0,
      XL_Children_Price: parseInt(urlParams.get('XL_Children_Price'), 10) || 0,
      PendekXL_Children_Price: parseInt(urlParams.get('PendekXL_Children_Price'), 10) || 0,
      PanjangXL_Children_Price: parseInt(urlParams.get('PanjangXL_Children_Price'), 10) || 0,
    },
  });

  const [addProductMaterialAction, { loading: loadingProductMaterial }] = useMutation(
    CREATE_PRODUCT_MATERIAL,
  );
  const [addProductColorAction, { loading: loadingProductColor }] = useMutation(
    CREATE_PRODUCT_COLOR,
  );

  const [
    addProductActionWeight,
    { loading: loadingProductWeight, error: errorProductWeight },
  ] = useMutation(CREATE_PRODUCT_WEIGHT, {
    onCompleted: async () => {
      history.push(SUCCESS_REDIRECT);
    },
  });

  const [editProductAction, { loading, error: errorProductRequest }] = useMutation(EDIT_PRODUCT, {
    onCompleted: async dataSuccessProductAction => {
      const reassignProductWeight = productWeight;
      const idProductUpdate = parseInt(dataSuccessProductAction.updateProduct.id, 10);
      reassignProductWeight.id = idProductUpdate;
      const product_material = getValues('product_material');
      const product_color = getValues('product_color');

      const resultProductMaterial = [];
      // eslint-disable-next-line array-callback-return
      product_material.map(value => {
        if (typeof value === 'object') {
          const idAsInt = parseInt(value.id, 10);
          resultProductMaterial.push(idAsInt);
        } else {
          const valueAsInt = parseInt(value, 10);
          resultProductMaterial.push(valueAsInt);
        }
      });

      const resultProductColor = [];
      // eslint-disable-next-line array-callback-return
      product_color.map(value => {
        if (typeof value === 'object') {
          const idAsInt = parseInt(value.id, 10);
          resultProductColor.push(idAsInt);
        } else {
          const valueAsInt = parseInt(value, 10);
          resultProductColor.push(valueAsInt);
        }
      });

      await addProductMaterialAction({
        variables: {
          payload: {
            id_product: idProductUpdate,
            id_product_material: resultProductMaterial,
          },
        },
      });

      await addProductColorAction({
        variables: {
          payload: {
            id_product: idProductUpdate,
            id_product_color: resultProductColor,
          },
        },
      });

      await addProductActionWeight({
        variables: {
          payload: reassignProductWeight,
        },
      });
    },
  });

  const onSubmitUpdate = async dataEditProduct => {
    const {
      PendekFourthXL_Price,
      PendekFifthXL_Price,
      FifthXL_Price,
      FourthXL_Price,
      L_Price,
      M_Price,
      PanjangFifthXL_Price,
      PanjangFourthXL_Price,
      PanjangL_Price,
      PanjangM_Price,
      PanjangS_Price,
      PanjangSixthXL_Price,
      PanjangThreeXL_Price,
      PanjangXL_Price,
      PanjangXXL_Price,
      PendekL_Price,
      PendekM_Price,
      PendekS_Price,
      PendekSixthXL_Price,
      PendekThreeXL_Price,
      PendekXL_Price,
      PendekXXL_Price,
      S_Price,
      SixthXL_Price,
      ThreeXL_Price,
      XL_Price,
      XXL_Price,
      category,
      description,
      name,
      type,

      S_Children_Price,
      PendekS_Children_Price,
      PanjangS_Children_Price,

      M_Children_Price,
      PendekM_Children_Price,
      PanjangM_Children_Price,

      L_Children_Price,
      PendekL_Children_Price,
      PanjangL_Children_Price,

      XL_Children_Price,
      PendekXL_Children_Price,
      PanjangXL_Children_Price,
    } = dataEditProduct;

    const payloadEditProduct = {
      PendekFourthXL_Price,
      PendekFifthXL_Price,
      FifthXL_Price,
      FourthXL_Price,
      L_Price,
      M_Price,
      PanjangFifthXL_Price,
      PanjangFourthXL_Price,
      PanjangL_Price,
      PanjangM_Price,
      PanjangS_Price,
      PanjangSixthXL_Price,
      PanjangThreeXL_Price,
      PanjangXL_Price,
      PanjangXXL_Price,
      PendekL_Price,
      PendekM_Price,
      PendekS_Price,
      PendekSixthXL_Price,
      PendekThreeXL_Price,
      PendekXL_Price,
      PendekXXL_Price,
      S_Price,
      SixthXL_Price,
      ThreeXL_Price,
      XL_Price,
      XXL_Price,
      category,
      description,
      name,
      type,

      S_Children_Price,
      PendekS_Children_Price,
      PanjangS_Children_Price,

      M_Children_Price,
      PendekM_Children_Price,
      PanjangM_Children_Price,

      L_Children_Price,
      PendekL_Children_Price,
      PanjangL_Children_Price,

      XL_Children_Price,
      PendekXL_Children_Price,
      PanjangXL_Children_Price,
    };

    const {
      PendekL_weight,
      PendekM_weight,
      PendekS_weight,
      PendekSixthXL_weight,
      PendekThreeXL_weight,
      PendekXL_weight,
      PendekXXL_weight,
      S_weight,
      SixthXL_weight,
      ThreeXL_weight,

      XL_weight,

      XXL_weight,
      FifthXL_weight,
      L_weight,
      FourthXL_weight,
      M_weight,
      PanjangFifthXL_weight,
      PanjangFourthXL_weight,
      PanjangL_weight,
      PanjangM_weight,
      PanjangS_weight,
      PanjangSixthXL_weight,
      PanjangThreeXL_weight,

      PanjangXL_weight,
      PanjangXXL_weight,
      PendekFifthXL_weight,

      PendekFourthXL_weight,

      PendekS_Children_weight,
      S_Children_weight,
      PanjangS_Children_weight,
      PendekM_Children_weight,
      M_Children_weight,
      PanjangM_Children_weight,

      PendekL_Children_weight,
      L_Children_weight,
      PanjangL_Children_weight,

      PendekXL_Children_weight,
      XL_Children_weight,
      PanjangXL_Children_weight,
    } = dataEditProduct;

    setProductWeight({
      PendekL_weight,
      PendekM_weight,
      PendekS_weight,
      PendekSixthXL_weight,
      PendekThreeXL_weight,
      PendekXL_weight,
      PendekXXL_weight,
      S_weight,
      SixthXL_weight,
      ThreeXL_weight,

      XL_weight,
      XXL_weight,
      FifthXL_weight,
      L_weight,
      FourthXL_weight,
      M_weight,
      PanjangFifthXL_weight,
      PanjangFourthXL_weight,
      PanjangL_weight,
      PanjangM_weight,
      PanjangS_weight,
      PanjangSixthXL_weight,
      PanjangThreeXL_weight,
      PanjangXL_weight,
      PanjangXXL_weight,
      PendekFifthXL_weight,
      PendekFourthXL_weight,

      PendekS_Children_weight,
      S_Children_weight,
      PanjangS_Children_weight,
      PendekM_Children_weight,
      M_Children_weight,
      PanjangM_Children_weight,

      PendekL_Children_weight,
      L_Children_weight,
      PanjangL_Children_weight,

      PendekXL_Children_weight,
      XL_Children_weight,
      PanjangXL_Children_weight,
    });

    payloadEditProduct.id = urlParams.get('id');
    await editProductAction({
      variables: {
        payload: payloadEditProduct,
      },
    });
  };

  if (errorProductRequest || errorProductWeight) {
    return <Error />;
  }

  const media = urlParams.get('media');
  return (
    <FormProduct
      setValue={setValue}
      control={control}
      isEdit
      media={media}
      classes={classes}
      errors={errors}
      register={register}
      loading={loading || loadingProductWeight || loadingProductMaterial || loadingProductColor}
      handleSubmit={handleSubmit}
      onSubmitRegister={onSubmitUpdate}
    />
  );
}
