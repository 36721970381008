import gql from 'graphql-tag';

export const statement = `
mutation subscribeToWorkOrderTopic {
    subscribeToWorkOrderTopic {
        successCount
        failureCount
    }
}
`;

export default gql`
  ${statement}
`;
