import gql from 'graphql-tag';

export const statement = `   
  query product($payload:ParamProductInput){
  product(v:$payload) {
    count
    rows {     
      id
      name                                                                                                              
    }
  }
}   
`;

export default gql`
  ${statement}
`;
