import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import ProductCategoryInternal from 'container/product/category/internal/index';
import ProductCategoryWebsite from 'container/product/category/website/index';
import StoreConfirmation from 'container/store/confirmation/index';
import ProductBanner from 'container/product/banner/index';
import ProductVoucher from 'container/product/voucher/index';
import ProductReferral from 'container/product/referral/index';
import ProductColor from 'container/product/color/index';
import ProductMaterial from 'container/product/material/index';
import { AUTHENTICATED } from '../constant/index';

import NAVIGATION_QUERY from '../graphql/navigation/index';
import Error from '../component/error/index';
import Loading from '../component/loading';
import Navigation from '../hoc/navigation/index';
import Spk from './spk/index';
import Profil from './profil/index';
import Debit from './debit/index';
import DayOff from './dayoff/index';
import Attendance from './attendance/index';
import Product from './product/index';
import Customer from './customer/index';
import Statistic from './statistic/index';

import ProductWebsite from './product/website/index';
import CategoryWebsite from './product/category/website/index';
import VariantValue from './product/variant/value/index';
import Variant from './product/variant/index';
import OrderWebsite from './order/website/index';
import PartnerApplication from './PartnerApplication/index';
import NavigationLayout from './navigation/index';
import Media from './media/index';
import Wallet from './Wallet/index';
import WalletHistory from './Wallet/History/index';
import WithdrawRequest from './Wallet/WithdrawRequest/index';
import TopUpRequest from './Wallet/TopUpRequest/index';
import Bank from './bank/index';
import useAuthCheck from '../utils/useAuthCheck';

const useStyles = makeStyles({
  bottomTabNavigation: {},
  list: {
    width: 250,
  },
});

function RenderAppBar({ classes, setShowDrawer }) {
  const handleLogout = () => {
    const loginData = false; // Assuming you want to set this to false on logout
    localStorage.setItem(AUTHENTICATED, JSON.stringify(loginData));
    window.location.href = '/';
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={setShowDrawer}>
          <MenuIcon />
        </IconButton>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography variant="h6" className={classes.title}>
            Voodoocloth
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

function ApplicationShell() {
  const classes = useStyles();
  useAuthCheck();
  const location = useLocation();
  const path = location.pathname.split('/');

  const { data, error, loading } = useQuery(NAVIGATION_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      payload: {
        limit: 30,
        offset: 0,
        enableRole: true,
      },
    },
  });
  const [showDrawer, setShowDrawer] = React.useState(false);
  const ShouldRender = {
    bank: <Bank />,
    spk: <Spk />,
    penawaran: <Spk />,
    absensi: <Attendance />,
    cuti: <DayOff />,
    kasbon: <Debit />,
    profil: <Profil />,
    produk: <Product />,
    customer: <Customer />,
    navigation: <NavigationLayout />,
    'order-website': <OrderWebsite />,
    'product-material': <ProductMaterial />,
    'product-color': <ProductColor />,
    'product-category-internal': <ProductCategoryInternal />,
    'product-category-website': <ProductCategoryWebsite />,
    voucher: <ProductVoucher />,
    referral: <ProductReferral />,
    'confirmation-store': <StoreConfirmation />,
    banner: <ProductBanner />,
    statistic: <Statistic />,
    variant: <Variant />,
    'variant-value': <VariantValue />,
    'category-website': <CategoryWebsite />,
    'product-website': <ProductWebsite />,
    media: <Media />,
    wallet: <Wallet />,
    'wallet-history': <WalletHistory />,
    'partner-application': <PartnerApplication />,
    'withdraw-request': <WithdrawRequest />,
    'top-up-request': <TopUpRequest />,
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <>
      <Drawer
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false);
        }}
      >
        <Navigation side="left" item={data.accountNavigation} />
      </Drawer>
      <Container maxWidth="md">
        <RenderAppBar
          classes={classes}
          setShowDrawer={() => {
            setShowDrawer(true);
          }}
        />
        {ShouldRender[path[1]]}
      </Container>
    </>
  );
}

export default ApplicationShell;
