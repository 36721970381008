import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Upload from 'component/upload/index';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackComponent from 'component/back/index';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import UploadValidation from 'utils/validation/upload';
import EDIT_PRODUCT_CATEGORY_INTERNAL from 'graphql/product/category/internal/update';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ImageUtils from 'utils/Image';
import { PRODUCT_CATEGORY_INTERNAL } from 'constant/upload_path';
import RenderCarousel from '../../../../component/carousel';
import RenderFileUploadError from '../../component/FileUploadErrorText';
import SUCCESS_REDIRECT from './constant';

function LoadingCenter() {
  return (
    <Grid container direction="row" justify="center" style={{ paddingTop: '16px' }}>
      <CircularProgress />
    </Grid>
  );
}

export default function Form({
  isEdit,
  classes,
  errors,
  register,
  loading,
  handleSubmit,
  onSubmit,
  media,
}) {
  const [loadingEditImage, setLoadingEditImage] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const fileUploadErrorForAdd = errors?.file_upload;
  const [multipleImageUpload, setMultipleImageUpload] = useState([]);
  let mediaAsObject = null;
  if (media) {
    mediaAsObject = JSON.parse(media);
  }
  const uploadValidation = UploadValidation('file_upload');
  const uploadEditImageScheme = yup.object().shape(uploadValidation);
  const {
    register: registetEditImage,
    handleSubmit: handleSubmitEditImage,
    errors: errorsEditImage,
  } = useForm({
    validationSchema: uploadEditImageScheme,
    submitFocusError: false,
    nativeValidation: false,
  });
  const fileUploadErrorForEdit = errorsEditImage?.file_upload;
  const [uploadEditProductCategory, { loading: loadingEditProduct }] = useMutation(
    EDIT_PRODUCT_CATEGORY_INTERNAL,
    {
      onCompleted: async () => {
        window.location.href = SUCCESS_REDIRECT;
      },
      onError: async () => {
        setLoadingEditImage(false);
      },
    },
  );

  const onSubmitEditImage = async dataOnSubmit => {
    setLoadingEditImage(true);
    const fileUpload = dataOnSubmit.file_upload;
    await ImageUtils.delete(
      mediaAsObject,
      () => {
        ImageUtils.upload(
          PRODUCT_CATEGORY_INTERNAL,
          fileUpload,
          async res => {
            const { data } = res;
            const imageRequestID = data.data;
            const idAsInt = parseInt(urlParams.get('id'), 10);
            await uploadEditProductCategory({
              variables: {
                payload: {
                  filename: imageRequestID,
                  id: idAsInt,
                },
              },
            });
          },
          () => {
            setLoadingEditImage(false);
          },
        );
      },
      () => {
        setLoadingEditImage(false);
      },
    );
  };

  return (
    <Paper elevation={3} className={classes.wrapper}>
      <Grid container className={classes.header}>
        <ArrowBackComponent history={history} location="/product-category-internal" />
        <Typography variant="h6" gutterBottom align="center" className={classes.title}>
          Produk kategori internal
        </Typography>
      </Grid>
      <Grid container direction="row" className={classes.contentWrapper} spacing={2}>
        <Grid item xs={12} sm={isEdit ? 6 : 12}>
          <TextField
            helperText={errors?.name?.message}
            error={errors?.name && true}
            variant="outlined"
            label="Nama"
            name="name"
            fullWidth
            margin="dense"
            inputRef={register}
          />
          {isEdit && !loading && (
            <Button
              fullWidth
              onClick={handleSubmit(onSubmit)}
              color="primary"
              variant="contained"
              className={classes.buttonChangePrice}
            >
              Ubah Nama
            </Button>
          )}
          {isEdit && loading && <LoadingCenter />}
        </Grid>
        {isEdit && mediaAsObject && (
          <Grid item xs={12} sm={6}>
            <div>
              <div>
                <Typography gutterBottom align="center">
                  Gambar Sekarang
                </Typography>
              </div>
              <div>
                <RenderCarousel data={mediaAsObject} classes={classes} />
              </div>
            </div>
            <input
              onChange={e => {
                const multiImage = ImageUtils.storeMultiImage(e);
                setMultipleImageUpload(multiImage);
              }}
              ref={registetEditImage}
              name="file_upload"
              accept="image/*"
              className={classes.inputFile}
              id="icon-upload-edit-product-category-internal"
              type="file"
            />
            <Upload.RenderImagePreview image={multipleImageUpload} classes={classes} />
            <Upload.RenderUploadIcon
              htmlFor="icon-upload-edit-product-category-internal"
              text="Ganti gambar"
            />
            {fileUploadErrorForEdit && (
              <FormControl error>
                <FormHelperText>{fileUploadErrorForEdit.message}</FormHelperText>
              </FormControl>
            )}
            {loadingEditImage || loadingEditProduct ? (
              <LoadingCenter />
            ) : (
              <>
                {multipleImageUpload.length > 0 && (
                  <Button
                    fullWidth
                    onClick={handleSubmitEditImage(onSubmitEditImage)}
                    color="primary"
                    variant="contained"
                  >
                    Ganti Gambar
                  </Button>
                )}
              </>
            )}
          </Grid>
        )}{' '}
        {!isEdit && (
          <Grid container direction="column">
            <RenderFileUploadError fileUploadError={fileUploadErrorForAdd} />
            <input
              onChange={e => {
                const multiImage = ImageUtils.storeMultiImage(e);
                setMultipleImageUpload(multiImage);
              }}
              ref={register}
              name="file_upload"
              accept="image/*"
              className={classes.inputFile}
              id="icon-upload-product-category-internal"
              type="file"
            />
            <Upload.RenderImagePreview image={multipleImageUpload} classes={classes} />
            <Upload.RenderUploadIcon
              htmlFor="icon-upload-product-category-internal"
              text="Upload gambar"
            />
          </Grid>
        )}
        {!isEdit && loading && <LoadingCenter />}
        {!isEdit && !loading && (
          <Button
            fullWidth
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
            className={classes.buttonChangePrice}
          >
            Tambah
          </Button>
        )}
      </Grid>
    </Paper>
  );
}
