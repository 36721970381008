import React, { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import CHANGE_ROLE from 'graphql/account/role/change';
import Role from '../../../constant/role';

function ChangeRole({ onClose, open, selectedID, refetch }) {
  const Schema = yup.object().shape({
    role: yup.string().required('Role harus dipilih'),
  });

  const { register, handleSubmit, setValue, errors } = useForm({
    validationSchema: Schema,
    mode: 'onChange',
    submitFocusError: false,
    nativeValidation: false,
  });

  const [changeRoleAction, { loading: loadingChangeRole }] = useMutation(CHANGE_ROLE, {
    onCompleted: () => {
      refetch();
      onClose();
    },
    onError: () => {
      onClose();
    },
  });

  const autoCompleteRole = {};
  autoCompleteRole.options = Role;
  autoCompleteRole.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteRole.renderInput = params => (
    <TextField
      {...params}
      label="Role"
      variant="outlined"
      fullWidth
      margin="dense"
      helperText={errors?.product_color?.message}
      error={errors?.product_color && true}
    />
  );
  autoCompleteRole.noOptionsText = 'Role tidak di temukan';
  autoCompleteRole.size = 'small';
  autoCompleteRole.onChange = (value, newValue) => {
    if (newValue?.name) {
      setValue('role', newValue?.name);
    } else {
      setValue('role', '');
    }
  };

  useEffect(() => {
    register('role');
  }, [register]);

  const onSubmit = async data => {
    await changeRoleAction({
      variables: {
        payload: {
          id_account: selectedID,
          role: data.role,
        },
      },
    });
  };

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle>Ganti Role</DialogTitle>
      <DialogContent>
        <Autocomplete {...autoCompleteRole} />
        <FormHelperText error>{errors?.role?.message}</FormHelperText>
      </DialogContent>
      <DialogActions style={{ paddingBottom: '16px' }}>
        {loadingChangeRole ? (
          <Box mt={1} display="flex" justifyContent="center" style={{ width: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Button onClick={handleSubmit(onSubmit)} fullWidth color="primary" variant="contained">
            Ganti
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(ChangeRole);
