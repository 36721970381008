import gql from 'graphql-tag';

export const statement = `   
  mutation createProductCategoryInternal($payload: ParamProductCategory!) {
    createProductCategoryInternal(v: $payload){
        id
        name  
        media{
             request_id
            url
        }
    }
  } 
`;

export default gql`
  ${statement}
`;
