import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

const Upload = {};
Upload.RenderImagePreview = ({ image }) => {
  return (
    <div>
      {image.length > 0 && (
        <Typography gutterBottom align="center">
          Gambar Baru
        </Typography>
      )}

      <Grid container direction="row" spacing={1} justify="center">
        {image.map((value, index) => {
          return (
            <Grid item xs={12} sm={4} key={index}>
              <img src={value} style={{ maxWidth: '100%' }} alt={value} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
Upload.RenderUploadIcon = ({ htmlFor, text }) => {
  return (
    <Grid container direction="column" alignItems="center">
      <label htmlFor={htmlFor}>
        <IconButton id={htmlFor} color="primary" aria-label="upload picture" component="div">
          <PhotoCamera />
        </IconButton>
      </label>
      <FormHelperText id={htmlFor}>{text}</FormHelperText>
    </Grid>
  );
};

Upload.RenderInputUpload = ({ multiple, onChange, register, name, classes, id }) => {
  return (
    <>
      <input
        onChange={onChange}
        multiple={multiple}
        ref={register}
        name={name}
        accept="image/*"
        className={classes.inputFile}
        id={id}
        type="file"
      />
      <label htmlFor={id}>
        <IconButton color="primary" aria-label="upload picture" component="div">
          <PhotoCamera />
        </IconButton>
      </label>
    </>
  );
};

export default Upload;
