import gql from 'graphql-tag';

export const statement = `
query accountAddress($payload:AccountAddressInput) {
  accountAddress(v: $payload) {
    id
    address_name
    id_province
    province
    id_city
    city
    id_subdistrict
    subdistrict
    full_address
    is_default
  }
}
`;

export default gql`
  ${statement}
`;
