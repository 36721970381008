import gql from 'graphql-tag';

export const statement = `
mutation unsubscribeToWorkOrderTopic {
    unsubscribeToWorkOrderTopic {
        successCount
        failureCount
    }
}
`;

export default gql`
  ${statement}
`;
