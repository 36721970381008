import gql from 'graphql-tag';

export const statement = `   
  query productCategoryInternal($payload: ParamProductCategory) {
    productCategoryInternal(v: $payload){
    id
    name  
    createdAt
     updatedAt
    media{
      request_id
      url
      public_id
    }
  }
}   
`;

export default gql`
  ${statement}
`;
