import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Snackbar from '@material-ui/core/Snackbar';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import pattern from 'utils/pattern';
import CREATE_OPERATOR from 'graphql/account/operator/create';
import Role from 'constant/role';

const countries = [{ code: 'ID', label: 'Indonesia (+62)', phone: '62' }];

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const useStyles = makeStyles(theme => ({
  formPassword: {
    marginTop: theme.spacing(1),
  },
}));

function AddCustomer({ open, onClose }) {
  const RegexPhoneNumber = /^[0-9]*$/;

  const AddCustomerSchema = yup.object().shape({
    name: yup.string().required('Nama harus diisi'),
    phone: yup
      .string()
      .required('Nomor telepon harus diisi')
      .matches(RegexPhoneNumber, 'Nomor telepon harus berisi angka')
      .min(8, 'Panjang nomor telepon minimal 8')
      .max(16, 'Panjang nomor telepon maksimal 16'),
    email: yup
      .string()
      .required('Email harus diisi')
      .matches(pattern.email, 'Email tidak valid'),
    kataSandi: yup
      .string()
      .required('Kata Sandi harus diisi')
      .min(8, 'Kata Sandi minimal 8 karakter'),
    konfirmasiKataSandi: yup
      .string()
      .required('Konfirmasi Kata Sandi harus diisi')
      .oneOf([yup.ref('kataSandi')], 'Konfirmasi Kata Sandi tidak sama'),
  });

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, handleSubmit, formState, setValue, errors } = useForm({
    validationSchema: AddCustomerSchema,
    mode: 'onChange',
    submitFocusError: false,
    nativeValidation: false,
  });

  const [createOperatorAction, { loading: loadingCreateOperator }] = useMutation(CREATE_OPERATOR, {
    onCompleted: () => {
      setOpenSnackBar(true);
      setSnackBarMessage('Customer baru berhasil di tambahkan');
      setTimeout(() => {
        onClose();
      }, 2000);
    },
    onError: errorCustomer => {
      setOpenSnackBar(true);
      setSnackBarMessage(errorCustomer.graphQLErrors[0].message);
      setTimeout(() => {
        onClose();
      }, 2000);
    },
  });
  const classesInner = useStyles();
  const onSubmit = async data => {
    const { email, kataSandi, name, phone, role, id_order_status } = data;
    const payload = {
      name,
      email,
      phone: `62${phone}`,
      password: kataSandi,
    };
    if (role) {
      payload.role = role;
      payload.id_order_status = id_order_status;
    }

    await createOperatorAction({
      variables: {
        payload,
      },
    });
  };

  useEffect(() => {
    register('role');
    register('id_order_status');
  }, [register]);

  const autoCompleteRole = {};
  autoCompleteRole.options = Role;
  autoCompleteRole.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteRole.renderInput = params => (
    <TextField
      {...params}
      label="Role"
      variant="outlined"
      fullWidth
      margin="dense"
      helperText={errors?.product_color?.message}
      error={errors?.product_color && true}
    />
  );
  autoCompleteRole.noOptionsText = 'Role tidak di temukan';
  autoCompleteRole.size = 'small';
  autoCompleteRole.onChange = (value, newValue) => {
    if (newValue?.name) {
      setValue('role', newValue?.name);
      setValue('id_order_status', newValue?.value);
    } else {
      setValue('role', '');
      setValue('id_order_status', '');
    }
  };

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message={snackBarMessage}
      />

      <DialogTitle>Tambah Customer</DialogTitle>
      <DialogContent>
        <TextField
          error={errors?.name && true}
          helperText={errors?.name?.message}
          fullWidth
          label="Nama"
          size="small"
          variant="outlined"
          margin="dense"
          name="name"
          inputRef={register}
        />
        <TextField
          fullWidth
          error={errors?.email && true}
          helperText={errors?.email?.message}
          label="Email"
          size="small"
          variant="outlined"
          margin="dense"
          name="email"
          inputRef={register}
        />
        <Box display="flex" justifyContent="space-between">
          <Autocomplete
            style={{ width: 300, marginRight: '8px' }}
            disabled
            defaultValue={countries[0]}
            options={countries}
            getOptionLabel={option => option.label}
            renderOption={option => (
              <>
                <span>{countryToFlag(option.code)}</span>
                {option.label} ({option.code}) +{option.phone}
              </>
            )}
            renderInput={params => (
              <TextField margin="dense" {...params} label="Pilih Negara" variant="outlined" />
            )}
          />
          <TextField
            fullWidth
            error={errors?.phone && true}
            helperText={errors?.phone?.message}
            label="Phone"
            size="small"
            variant="outlined"
            margin="dense"
            name="phone"
            inputRef={register}
          />
        </Box>

        <FormControl fullWidth variant="outlined" className={classesInner.formPassword}>
          <InputLabel style={{ top: '-7px' }} htmlFor="kataSandi">
            Kata Sandi
          </InputLabel>
          <OutlinedInput
            error={errors?.kataSandi && true}
            helperText={errors?.kataSandi?.message}
            inputRef={register}
            id="kataSandi"
            name="kataSandi"
            margin="dense"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={100}
          />
        </FormControl>

        <FormControl fullWidth variant="outlined" className={classesInner.formPassword}>
          <InputLabel style={{ top: '-7px' }} htmlFor="konfirmasiKataSandi">
            Konfirmasi Kata Sandi
          </InputLabel>
          <OutlinedInput
            error={errors?.konfirmasiKataSandi && true}
            helperText={errors?.konfirmasiKataSandi?.message}
            inputRef={register}
            id="konfirmasiKataSandi"
            name="konfirmasiKataSandi"
            margin="dense"
            type={showConfirmPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                  edge="end"
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={100}
          />
        </FormControl>
        <Autocomplete {...autoCompleteRole} />
        {loadingCreateOperator ? (
          <Box mt={1} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Button
            style={{
              marginTop: '16px',
              marginBottom: '8px',
            }}
            disabled={!formState.isValid}
            id="btn-add-click"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
          >
            Daftar
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(AddCustomer);
