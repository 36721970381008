import gql from 'graphql-tag';

export const statement = `   
query($payload:ProductBannerInput) {
  productBanner(v:$payload){
    id
    title
    createdAt
    updatedAt
    description
     media {
      url
      public_id
    }
  }
}
`;

export default gql`
  ${statement}
`;
