import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useLazyQuery } from '@apollo/react-hooks';
import * as yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { AUTHENTICATED } from '../../constant/index';
import CHECK_ROLE_QUERY from '../../graphql/auth/role/index';
import LOGIN_QUERY from '../../graphql/auth/login/index';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  loginFormWrapper: {
    padding: theme.spacing(4),
  },
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();

  const [values, setValues] = React.useState({
    showPassword: false,
    password: '',
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const [loginData, setLoginData] = React.useState({});
  const [state] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const [modalErrorVisible, setModalErrorVisible] = React.useState(false);

  const { vertical, horizontal } = state;

  const [checkRoleAction] = useLazyQuery(CHECK_ROLE_QUERY, {
    onCompleted: data => {
      const { checkRole } = data;
      const filterRole = checkRole.filter(
        dataRole =>
          dataRole.role === 'BOS' ||
          dataRole.role === 'PRESS' ||
          dataRole.role === 'JAHITAN' ||
          dataRole.role === 'PACKING' ||
          dataRole.role === 'PECAHWARNA' ||
          dataRole.role === 'SABLON' ||
          dataRole.role === 'DESIGN' ||
          dataRole.role === 'BORDIR' ||
          dataRole.role === 'PREPARE' ||
          dataRole.role === 'POTONGAN',
      );
      if (filterRole.length > 0) {
        localStorage.setItem(AUTHENTICATED, JSON.stringify(loginData));
        window.location.href = '/spk';
      } else {
        setModalErrorVisible(true);
      }
      return true;
    },
    onError: () => {
      setModalErrorVisible(true);
    },
    fetchPolicy: 'no-cache',
  });

  const [loginAction, { loading }] = useLazyQuery(LOGIN_QUERY, {
    onCompleted: async data => {
      const { login } = data;
      setLoginData(login);
      await checkRoleAction({
        variables: {
          token: login.accessToken,
        },
      });
    },
    onError: () => {
      setModalErrorVisible(true);
    },
    fetchPolicy: 'no-cache',
  });

  const LoginSchema = yup.object().shape({
    email: yup.string().required('Email di butuhkan'),
    kataSandi: yup.string().required('Kata sandi harus diisi'),
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema: LoginSchema,
    submitFocusError: false,
    nativeValidation: false,
  });

  const onSubmit = async dataOnSubmit => {
    await loginAction({
      variables: {
        payload: {
          email: dataOnSubmit.email,
          password: dataOnSubmit.kataSandi,
        },
      },
    });
  };

  useEffect(() => {
    if (localStorage.getItem(AUTHENTICATED)) {
      history.push('spk');
    }
  }, [history]);

  return (
    <Container maxWidth="sm" className={classes.wrapper}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.wrapper}>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            key={`${vertical},${horizontal}`}
            open={modalErrorVisible}
            onClose={() => {
              setModalErrorVisible(false);
            }}
            message="Login tidak valid"
          />
          <Paper elevation={3} className={classes.loginFormWrapper}>
            <Typography variant="h5" gutterBottom align="center">
              Login Mitra
            </Typography>
            <Box mb={2} mt={3}>
              <TextField
                size="small"
                error={errors?.email && true}
                helperText={errors?.email?.message}
                inputRef={register}
                name="email"
                id="txt_email"
                label="Email"
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box mb={2}>
              <FormControl fullWidth size="small" variant="outlined">
                <InputLabel>Password</InputLabel>
                <OutlinedInput
                  error={errors?.kataSandi && true}
                  helperText={errors?.kataSandi?.message}
                  id="txt_kata_sandi"
                  name="kataSandi"
                  fullWidth
                  type={values.showPassword ? 'text' : 'password'}
                  inputRef={register}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Box>
            <Link href="/forgot-password" variant="body2">
              Lupa Password
            </Link>
            <Box mt={4}>
              {loading ? (
                <Grid container direction="row" justify="center">
                  <CircularProgress size={30} />
                </Grid>
              ) : (
                <Button color="primary" variant="contained" fullWidth type="submit" id="btn-login">
                  Masuk
                </Button>
              )}
            </Box>
          </Paper>
        </div>
      </form>
    </Container>
  );
}
