const Role = {};

Role.Boss = tokenAsObject => {
  const filter = tokenAsObject.accountRole.filter(value => {
    const { role } = value;
    return role === 'BOS';
  });
  return filter.length > 0 && true;
};

Role.CheckByName = (tokenAsObject, name) => {
  const filter = tokenAsObject.accountRole.filter(value => {
    const { role } = value;
    return role === name;
  });
  return filter.length > 0 && true;
};

export default Role;
