import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert/Alert';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import URL from 'utils/url';
import empty from 'assets/svg/empty.svg';
import ATTENDANCE_QUERY from '../../graphql/attendance/index';
import Error from '../../component/error';
import Loading from '../../component/loading';
import Request from './request';
import { PAGE_LIMIT } from '../../constant';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  alertWrapper: {
    marginBottom: theme.spacing(2),
  },
  dateFrom: {
    marginRight: theme.spacing(1),
  },
  dateTo: {
    marginRight: theme.spacing(1),
  },
  imageEmpty: {
    width: '500px',
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paginationWrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const RenderIndex = ({ classes, data, statusAttendanceRequestPage, refetch }) => {
  const history = useHistory();
  const dataLength = data.attendance.count;
  const pageCount = Math.ceil(dataLength / 10);
  return dataLength !== 0 ? (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Kehadiran</Typography>
        <Button
          onClick={() => {
            history.push('/absensi/ajukan');
          }}
          color="primary"
          variant="contained"
        >
          Ajukan
        </Button>
      </div>
      {statusAttendanceRequestPage === 'berhasil' && (
        <Alert
          onClose={() => {
            history.push('/absensi');
          }}
          variant="filled"
          severity="success"
          className={classes.alertWrapper}
        >
          Pengajuan cuti berhasil
        </Alert>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Waktu Absen</TableCell>
              <TableCell align="center">Kode Absen</TableCell>
              <TableCell align="center">Shift</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.attendance.rows.map(row => {
              const { attendance_code: attendanceCode, createdAt, id } = row;
              const { code, shift, status } = attendanceCode;
              const parsedCreatedAt = moment(createdAt).format('DD-MM-YYYY');
              return (
                <TableRow key={id}>
                  <TableCell align="center">{parsedCreatedAt}</TableCell>
                  <TableCell align="center">{code}</TableCell>
                  <TableCell align="center">{shift}</TableCell>
                  <TableCell align="center">{status}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Pagination
          className={classes.paginationWrapper}
          size="large"
          count={pageCount}
          color="primary"
          onChange={(event, value) => {
            refetch({
              payload: {
                limit: PAGE_LIMIT,
                offset: value * PAGE_LIMIT - PAGE_LIMIT,
              },
            });
          }}
        />
      </TableContainer>
    </>
  ) : (
    <Grid direction="column" container alignItems="center">
      <img src={empty} alt="empty_img" className={classes.imageEmpty} />
      <Typography variant="h5" gutterBottom>
        Tidak ada data absensi yang di temukan
      </Typography>
      <Button
        onClick={() => {
          history.push('/absensi/ajukan');
        }}
        color="primary"
        variant="contained"
      >
        Ajukan Absensi Pertama
      </Button>
    </Grid>
  );
};

export default function Attendance() {
  const classes = useStyles();
  const location = useLocation();
  const { search } = location;
  const statusAttendanceRequestPage = URL.queryString(search, 'status');
  const path = location.pathname.split('/');
  const { data, error, loading, refetch } = useQuery(ATTENDANCE_QUERY, {
    fetchPolicy: 'no-cache',
  });

  if (error) {
    return <Error />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {path[2] === 'ajukan' ? (
        <Request />
      ) : (
        <RenderIndex
          refetch={refetch}
          statusAttendanceRequestPage={statusAttendanceRequestPage}
          classes={classes}
          data={data}
        />
      )}
    </>
  );
}
