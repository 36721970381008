import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowBackComponent from 'component/back/index';
import CUSTOMER_QUERY from 'graphql/account/customer';
import PRODUCT_QUERY from 'graphql/product/voucher/product';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Form({
  loading,
  isEdit,
  classes,
  errors,
  register,
  setValue,
  handleSubmit,
  onSubmit,
}) {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const payloadProduct = {
    offset: 0,
    limit: 10,
  };

  const payloadCustomer = {
    offset: 0,
    limit: 10,
  };

  if (isEdit) {
    payloadProduct.id = parseInt(urlParams.get('idProduct'), 10);
    payloadCustomer.id = parseInt(urlParams.get('idCustomer'), 10);
  }

  const {
    data: dataProductInternal,
    loading: loadingProductInternal,
    refetch: refetchProduct,
  } = useQuery(PRODUCT_QUERY, {
    variables: {
      payload: payloadProduct,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: dataCustomer, loading: loadingCustomer, refetch: refetchCustomer } = useQuery(
    CUSTOMER_QUERY,
    {
      variables: {
        payload: payloadCustomer,
      },
      fetchPolicy: 'no-cache',
    },
  );
  const history = useHistory();
  useEffect(() => {
    register('customer');
    register('product');
  }, [register]);

  const autoCompleteCustomerProperty = {};
  if (isEdit) {
    // eslint-disable-next-line prefer-destructuring
    autoCompleteCustomerProperty.defaultValue = dataCustomer?.customer[0];
  }
  autoCompleteCustomerProperty.options = dataCustomer?.customer;
  autoCompleteCustomerProperty.getOptionLabel = option => {
    return `${option.name}-${option.email}`;
  };
  autoCompleteCustomerProperty.renderInput = params => (
    <TextField {...params} label="Penerbit" variant="outlined" fullWidth margin="dense" />
  );
  autoCompleteCustomerProperty.noOptionsText = 'Penerbit tidak di temukan';
  autoCompleteCustomerProperty.size = 'small';
  autoCompleteCustomerProperty.onChange = (value, newValue) => {
    if (newValue?.id) {
      setValue('customer', newValue?.id);
    } else {
      setValue('customer', '');
    }
  };
  autoCompleteCustomerProperty.onInputChange = event => {
    const name = event?.target?.value;
    if (name !== 0) {
      refetchCustomer({
        payload: {
          name,
        },
      });
    }
  };

  let optionsProduct = [
    {
      id: 0,
      name: 'All item',
      __typename: 'nodeQueryProduct',
    },
  ];

  if (dataProductInternal?.product?.rows) {
    optionsProduct = [...optionsProduct, ...dataProductInternal?.product?.rows];
  }

  const autoCompleteProductProperty = {};
  if (isEdit) {
    // eslint-disable-next-line prefer-destructuring
    autoCompleteProductProperty.defaultValue = dataProductInternal?.product?.rows[0];
  }
  autoCompleteProductProperty.options = optionsProduct;
  autoCompleteProductProperty.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteProductProperty.renderInput = params => (
    <TextField {...params} label="Produk" variant="outlined" fullWidth margin="dense" />
  );
  autoCompleteProductProperty.noOptionsText = 'Produk tidak di temukan';
  autoCompleteProductProperty.size = 'small';
  autoCompleteProductProperty.onChange = (value, newValue) => {
    if (newValue?.id || newValue?.id === 0) {
      setValue('product', newValue?.id);
    } else {
      setValue('product', '');
    }
  };
  autoCompleteProductProperty.onInputChange = event => {
    const name = event?.target?.value;
    if (name !== 0) {
      refetchProduct({
        payload: {
          name,
        },
      });
    }
  };

  return (
    <Paper elevation={3} className={classes.wrapper}>
      <Grid container className={classes.header}>
        <ArrowBackComponent history={history} location="/voucher" />
        <Typography variant="h6" gutterBottom align="center" className={classes.title}>
          Produk Referral
        </Typography>
      </Grid>
      <Grid container direction="row" className={classes.contentWrapper} spacing={2}>
        <Grid item xs={12}>
          <TextField
            helperText={errors?.voucher_code?.message}
            error={errors?.voucher_code && true}
            variant="outlined"
            label="Kode Referral"
            name="referral_code"
            fullWidth
            margin="dense"
            inputRef={register}
          />

          <TextField
            helperText={errors?.referral_percentage?.message}
            error={errors?.referral_percentage && true}
            variant="outlined"
            label="Presentase"
            name="referral_percentage"
            fullWidth
            margin="dense"
            inputRef={register}
          />

          <TextField
            helperText={errors?.title?.message}
            error={errors?.title && true}
            variant="outlined"
            label="Judul"
            name="title"
            fullWidth
            margin="dense"
            inputRef={register}
          />
          <TextField
            helperText={errors?.description?.message}
            error={errors?.description && true}
            variant="outlined"
            label="Deskripsi"
            name="description"
            fullWidth
            margin="dense"
            inputRef={register}
          />
          <TextField
            helperText={errors?.image?.message}
            error={errors?.image && true}
            variant="outlined"
            label="Gambar"
            name="image"
            fullWidth
            margin="dense"
            inputRef={register}
          />

          {!loadingCustomer && <Autocomplete {...autoCompleteCustomerProperty} />}

          {errors.customer && (
            <FormControl error>
              <FormHelperText>{errors.customer.message}</FormHelperText>
            </FormControl>
          )}
          {!loadingProductInternal && <Autocomplete {...autoCompleteProductProperty} />}

          {errors.product && (
            <FormControl error>
              <FormHelperText>{errors.product.message}</FormHelperText>
            </FormControl>
          )}
          {loading ? (
            <Box display="flex" justifyContent="center" pt={1}>
              <CircularProgress />
            </Box>
          ) : (
            <Button
              fullWidth
              onClick={handleSubmit(onSubmit)}
              color="primary"
              variant="contained"
              className={classes.buttonSubmit}
            >
              {isEdit ? 'Ubah Referral' : 'Tambah Referral'}
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
