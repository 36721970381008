import gql from 'graphql-tag';

export const statement = `
query bank($payload:ParamBank) {
  bank(v:$payload){
    count
    rows{
      id
      name
      code
      updatedAt
      createdAt
    }
  }
}
`;

export default gql`
  ${statement}
`;
