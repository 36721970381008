import gql from 'graphql-tag';

export const statement = `   
mutation createProductMaterial($payload: ParamProductMaterial!) {
    createProductMaterial(v:$payload) {
        name    
    }
}
`;

export default gql`
  ${statement}
`;
