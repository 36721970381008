import gql from 'graphql-tag';

export const statement = `
mutation createBank($payload:ParamCreateBank) {
    createBank(v:$payload){
        id
        name
        code
    }
}
`;

export default gql`
  ${statement}
`;
