import gql from 'graphql-tag';

export const statement = `
query medias($payload:medias){
    medias (v:$payload){
        version
        created_at
        signature
        secure_url
        width
        height
    }
}
`;

export default gql`
  ${statement}
`;
