import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import CloseIcon from '@material-ui/icons/Close'; // Import CloseIcon for the "X" button
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { useMutation } from '@apollo/react-hooks';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import FormHelperText from '@material-ui/core/FormHelperText';
import UploadValidation from 'utils/validation/upload';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import SPK_UPDATE_PROOF_IMAGE from 'graphql/spk/proofImage';
import ImageUtils from 'utils/Image';
import { PRODUCT_SPK } from 'constant/upload_path';

const useStyles = makeStyles(theme => ({
  inputFile: {
    display: 'none',
  },
  dialogFooter: {
    padding: theme.spacing(2),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function ModalUploadOnGoingImageSpk({
  open,
  onClose,
  selectedSpkNumber,
  setOpenSnackBar,
  setSnackBarMessage,
  mediaProofSPKState,
  imageMediaProofSPK,
  refetchAcc,
}) {
  const classes = useStyles();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const uploadValidation = UploadValidation('file_upload', 3000);
  const uploadScheme = yup.object().shape(uploadValidation);
  const { register, handleSubmit, errors, watch, setValue } = useForm({
    validationSchema: uploadScheme,
    submitFocusError: false,
    nativeValidation: false,
  });

  const watchFileUpload = watch('file_upload');
  function failedCallBack() {
    setOpenSnackBar(true);
    setSnackBarMessage('Upload gambar gagal');
    setLoading(false);
  }

  const [updateSpkImageProof, { loading: loadingUpdateImageProofSPK }] = useMutation(
    SPK_UPDATE_PROOF_IMAGE,
    {
      onCompleted: () => {
        onClose();
        setOpenSnackBar(true);
        setSnackBarMessage('Gambar pengerjaan terkini SPK berhasil diupload');
        refetchAcc();
        setLoading(false);
      },
      onError: () => {
        onClose();
        setOpenSnackBar(true);
        setSnackBarMessage('Gambar pengerjaan terkini SPK gagal diupload');
        setLoading(false);
      },
    },
  );

  const uploadAndUpdateFilenameAfterEdit = async (
    urlUpload,
    dataForm,
    configRequest,
    // eslint-disable-next-line no-shadow
    setLoading,
  ) => {
    axios
      .post(urlUpload, dataForm, configRequest)
      .then(async res => {
        const { data } = res;
        const imageRequestID = data.data;
        await updateSpkImageProof({
          variables: {
            spkNumber: selectedSpkNumber,
            filename: imageRequestID,
          },
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const removeSelectedImage = () => {
    setImagePreviewUrl(null);
    setValue('file_upload', null); // Reset the file input
    // Manually reset the file input element if needed
    document.getElementById('icon-upload-product').value = null;
  };

  const onFileSelect = event => {
    const { files } = event.target;
    if (files && files[0]) {
      const file = files[0];
      const previewUrl = URL.createObjectURL(file);
      setImagePreviewUrl(previewUrl);
    }
  };
  const onSubmit = async dataOnSubmit => {
    setLoading(true);
    const fileUpload = dataOnSubmit.file_upload;
    const fileUploadLength = fileUpload.length;
    const configRequest = ImageUtils.getHeader();
    const dataForm = new FormData();
    for (let i = 0; i < fileUploadLength; i += 1) {
      dataForm.append(`files[${i}]`, fileUpload[i]);
    }
    dataForm.append('image_path', PRODUCT_SPK);
    const urlUpload = `${process.env.REACT_APP_SERVICE_MEDIA}/api/v1/uploadS3`;
    const urlDelete = `${process.env.REACT_APP_SERVICE_MEDIA}/api/v1/deleteS3`;

    if (mediaProofSPKState === 'EDIT') {
      const dataFormDelete = new FormData();
      const decodedKey = decodeURIComponent(imageMediaProofSPK.split('/').pop());
      dataFormDelete.append('key', decodedKey);
      await axios
        .post(
          urlDelete,
          {
            key: decodedKey,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(async () => {
          await uploadAndUpdateFilenameAfterEdit(urlUpload, dataForm, configRequest, setLoading);
          setImagePreviewUrl(null);
        })
        .catch(() => {
          failedCallBack();
        });
    } else {
      await ImageUtils.upload(
        PRODUCT_SPK,
        fileUpload,
        async res => {
          const { data } = res;
          await updateSpkImageProof({
            variables: {
              spkNumber: selectedSpkNumber,
              filename: data.data,
            },
          });
        },
        () => {
          failedCallBack();
        },
      );
    }
  };

  // useEffect hook to set the image preview url
  useEffect(() => {
    if (imageMediaProofSPK && open) {
      setImagePreviewUrl(imageMediaProofSPK);
    }
    // Reset the imagePreviewUrl when the dialog is closed
    if (!open) {
      setImagePreviewUrl(null);
    }
  }, [imageMediaProofSPK, open]); // Re-run the effect when imageMediaProofSPK or open changes
  // eslint-disable-next-line camelcase
  const fileUploadError = errors?.file_upload;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload Gambar Pengerjaan Terikini: {selectedSpkNumber}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {/* eslint-disable-next-line camelcase */}
        {fileUploadError && (
          <FormControl error>
            <FormHelperText>{fileUploadError.message}</FormHelperText>
          </FormControl>
        )}
        <DialogContentText>
          <input
            onChange={onFileSelect} // Handle file selection
            ref={register}
            name="file_upload"
            accept="image/*"
            className={classes.inputFile}
            id="icon-upload-product"
            type="file"
          />
          {/* Other elements */}
          {imagePreviewUrl && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <img
                style={{
                  maxWidth: '100%',
                  height: '300px',
                }}
                src={imagePreviewUrl}
                alt="Preview"
                className={classes.imagePreview}
              />
              <IconButton
                className={classes.deleteImageButton}
                onClick={removeSelectedImage}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </div>
          )}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          {!imagePreviewUrl && (
            <label htmlFor="icon-upload-product">
              <IconButton color="primary" aria-label="upload picture" component="div">
                <PhotoCamera />
              </IconButton>
            </label>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogFooter}>
        {loadingUpdateImageProofSPK || loading ? (
          <Grid container direction justify="center">
            <CircularProgress size={30} />
          </Grid>
        ) : (
          <Button
            disabled={!watchFileUpload || (watchFileUpload && watchFileUpload.length === 0)}
            fullWidth
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
          >
            Ya
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(ModalUploadOnGoingImageSpk);
