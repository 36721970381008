import gql from 'graphql-tag';

export const statement = `
mutation createProductWebsite($payload:ParamCreateProductWebsite!){
  createProductWebsite(v:$payload){
    id_category
    name
    description
    createdBy
  }
}
`;

export default gql`
  ${statement}
`;
