import gql from 'graphql-tag';

export const statement = `
query login($payload:ParamLoginAccount!){
  login(v:$payload) {
    accountRole {
      role
    }
    accessToken
    refreshToken
    isEmailConfirmed
  }
}
`;

export default gql`
  ${statement}
`;
