import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  imageBankTransferReceipt: {
    maxWidth: '100%',
    width: '500px',
  },
}));

function GetBankReceipt({ visible, imageSource, onClose }) {
  const classes = useStyles();
  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>Bukti Bayar</DialogTitle>
      <DialogContent>
        <img
          className={classes.imageBankTransferReceipt}
          src={imageSource}
          alt="bank_transfer_receipt"
        />
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(GetBankReceipt);
