import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useMutation } from '@apollo/react-hooks';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField/TextField';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { EMAIL_NOT_VALID, EMAIL_MUST_BE_FILLED_IN } from 'constant/errorMessage';
import CREATE_FORGOT_PASSWORD_LINK from 'graphql/account/createForgotPasswordLink';
import pattern from 'utils/pattern';

const useStyles = makeStyles(theme => ({
  accountImage: {
    width: '300px',
  },
  formWrapper: {
    padding: theme.spacing(3),
  },
  orText: {
    marginTop: theme.spacing(1),
  },
  wrapper: {
    height: '100vh',
    paddingTop: theme.spacing(5),
  },
  left: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();

  const ForgotPasswordSchema = yup.object().shape({
    email: yup
      .string()
      .required(EMAIL_MUST_BE_FILLED_IN)
      .matches(pattern.email, EMAIL_NOT_VALID),
  });

  const { register, handleSubmit, errors, formState, setValue } = useForm({
    mode: 'onChange',
    validationSchema: ForgotPasswordSchema,
    submitFocusError: false,
    nativeValidation: false,
  });

  const [openSnackBar, setOpenSnackBar] = useState({
    visibility: false,
    message: '',
  });
  const [
    createForgotPasswordLinkAction,
    { loading: loadingCreateForgotPasswordLink },
  ] = useMutation(CREATE_FORGOT_PASSWORD_LINK, {
    onCompleted: () => {
      setValue('email', '');
      setOpenSnackBar({
        visibility: true,
        message: 'Link atur ulang sandi berhasil dikirim ke email anda',
      });
    },
    onError: error => {
      setOpenSnackBar({
        visibility: true,
        message: error.graphQLErrors[0].message,
      });
    },
  });

  const onSubmit = async data => {
    const { email } = data;
    await createForgotPasswordLinkAction({
      variables: {
        email,
      },
    });
  };

  return (
    <Grid container direction="row" justify="center" className={classes.wrapper}>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar.visibility}
        onClose={() => {
          setOpenSnackBar({
            visibility: false,
          });
        }}
        message={openSnackBar.message}
      />

      <Grid item xs={12} sm={4}>
        <Paper elevation={3} className={classes.formWrapper}>
          <Typography variant="h6" gutterBottom>
            Lupa Password
          </Typography>
          <TextField
            margin="dense"
            size="small"
            error={errors?.email && true}
            helperText={errors?.email?.message}
            inputRef={register}
            name="email"
            id="email"
            label="Email"
            variant="outlined"
            fullWidth
          />
          <Box mt={2} display="flex" justifyContent="center">
            {loadingCreateForgotPasswordLink ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                disabled={!formState.isValid}
                onClick={handleSubmit(onSubmit)}
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                KIRIM LINK
              </Button>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
