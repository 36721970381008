import gql from 'graphql-tag';

export const statement = `  
mutation updateProductCategoryWebsite($payload:ParamUpdateProductCategoryWebsite!) {
  updateProductCategoryWebsite(v:$payload){
      id
      name
      image
  }
}
`;

export default gql`
  ${statement}
`;
