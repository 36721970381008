import gql from 'graphql-tag';

export const statement = `
query productInternalRating($payload:QueryProductInternalRating){
  productInternalRating(v:$payload){
    id
    id_product
    rating
    content
    is_active
    createdAt
    reviewer {
      name
    }
  }
}
 `;

export default gql`
  ${statement}
`;
