import gql from 'graphql-tag';

export const statement = `
 query statusSPKList{
    statusSPKList {
        id
        name
    }
}
`;

export default gql`
  ${statement}
`;
