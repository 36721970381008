import gql from 'graphql-tag';

export const statement = `   
 mutation createProductVoucher($payload:ParamProductVoucher!) {
    createProductVoucher(v:$payload) {
        id
        code
        id_product
        id_account
        expiredAt
    }
}
`;

export default gql`
  ${statement}
`;
