import gql from 'graphql-tag';

export const statement = `   
mutation createProductMaterialLinkage($payload:ProductMaterialLinkageInput!) {
  createProductMaterialLinkage(v:$payload) {
    id   
    id_product
    id_product_material
  }
}
`;

export default gql`
  ${statement}
`;
