import * as yup from 'yup';

export default {
  date_from_day_off: yup.date(),
  date_to_day_off: yup
    .date()
    .min(yup.ref('date_from_day_off'), 'Tanggal akhir tidak boleh lebih kecil dari tanggal mulai'),
  txt_reason: yup.string().required('Alasan harus diisi'),
  day_off_type: yup.string().required('Tipe cuti harus dipilih'),
};
