import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import StringUtils from 'utils/string';
import EDIT_DEBIT from '../../graphql/debit/edit';
import Error from '../../component/error/index';
import FormDebit from './form';
import Validation from './validation';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function DebitRequest() {
  const classes = useStyles();
  const EditDebitRequestSchema = yup.object().shape({
    ...Validation,
  });
  const [installmentTime, setInstallmentTime] = useState('');
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: EditDebitRequestSchema,
    submitFocusError: false,
    nativeValidation: false,
    defaultValues: {
      note: urlParams.get('note'),
      installment_time: urlParams.get('installmentTime'),
      amount: parseInt(urlParams.get('amount'), 10),
    },
  });
  const [editDebitAction, { loading, error: errorEditDebitRequest }] = useMutation(EDIT_DEBIT, {
    onCompleted: async () => {
      history.push('/kasbon?status=berhasil');
    },
  });

  const onSubmitUpdate = async dataEditDebitRequest => {
    const { amount, note, installment_time: installmentTimePayload } = dataEditDebitRequest;
    const parsedAmount = parseInt(amount, 10);
    const payloadCreateDebit = {
      id: parseInt(urlParams.get('id'), 10),
      due_date: moment()
        .add(installmentTimePayload, 'M')
        .format('YYYY-MM-DD HH:mm:ss'),
      id_debit_request: StringUtils.generateRandom(6),
      installment_time: installmentTimePayload,
      amount: parsedAmount,
      note,
    };
    await editDebitAction({
      variables: {
        payload: payloadCreateDebit,
      },
    });
  };

  const handleChangeInstallment = event => {
    const { value } = event.target;
    setValue('installment_time', value);
    setInstallmentTime(value);
  };

  const installmentTimeParams = urlParams.get('installmentTime');

  useEffect(() => {
    register({ name: 'installment_time' }, { required: true });
    setInstallmentTime(installmentTimeParams);
  }, [register, installmentTimeParams]);

  if (errorEditDebitRequest) {
    return <Error />;
  }

  return (
    <FormDebit
      classes={classes}
      errors={errors}
      register={register}
      loading={loading}
      handleSubmit={handleSubmit}
      onSubmitRegister={onSubmitUpdate}
      installmentTime={installmentTime}
      handleChangeInstallment={handleChangeInstallment}
    />
  );
}
