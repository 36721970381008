import gql from 'graphql-tag';

export const statement = `
mutation updateSpkStatus( $payload: ParamUpdateSpk!) {
  updateSpkStatus(v: $payload){
    status
  }
}
`;

export default gql`
  ${statement}
`;
