import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { red } from '@material-ui/core/colors';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import * as yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import ADD_NOTE_DIVISION from '../../../../graphql/spk/addNoteDivision';

const useStyles = makeStyles(theme => ({
  root: {
    '&$selected': {
      backgroundColor: red[500],
      color: 'white',
      '&:hover': {
        backgroundColor: red[500],
        color: 'white',
      },
    },
  },
  selected: {},
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Schema = yup.object().shape({
  txt_note_division: yup.string().required('Note dibutuhkan'),
});
function AddDivisionNote({
  setSnackBarMessage,
  setOpenSnackBar,
  idOrderStatus,
  open,
  handleClose,
  orderNumberForUpdateStatus,
  selectedSpkNumber,
}) {
  const classes = useStyles();

  const [upsertDivisionNote, { loading }] = useMutation(ADD_NOTE_DIVISION, {
    onCompleted: () => {
      handleClose();
      setSnackBarMessage('Catatan per divisi berhasil di tambahkan');
      setOpenSnackBar(true);
    },
    onError: () => {
      handleClose();
      setSnackBarMessage('Ada yang error saat menambahkan catatan divisi');
      setOpenSnackBar(true);
    },
  });

  const onSubmit = async dataOnSubmit => {
    await upsertDivisionNote({
      variables: {
        input: {
          note: dataOnSubmit.txt_note_division,
          order_status_id: idOrderStatus,
          order_no: orderNumberForUpdateStatus,
          spk_no: selectedSpkNumber,
        },
      },
    });
    /* await ADD_NOTE_TO_DIVISION_QUERY */
  };
  const { register, handleSubmit, errors } = useForm({
    submitFocusError: false,
    nativeValidation: false,
    validationSchema: Schema,
  });
  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton disabled={loading} edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Tambahkan Catatan Divisi
            </Typography>
            <Button disabled={loading} autoFocus color="inherit" onClick={handleSubmit(onSubmit)}>
              Tambahkan
            </Button>
          </Toolbar>
        </AppBar>
        <div
          style={{
            marginTop: '21px',
            marginLeft: '21px',
            marginRight: '21px',
          }}
        >
          <TextField
            disabled={loading}
            error={errors?.txt_note_division && true}
            helperText={errors?.txt_note_division?.message}
            inputRef={register}
            name="txt_note_division"
            id="txt_note_division"
            label="Note"
            fullWidth
            multiline
            rows={4} // Adjust the number of rows as needed
            variant="outlined"
            placeholder="Enter text here"
          />
        </div>
      </Dialog>
    </div>
  );
}

export default React.memo(AddDivisionNote);
