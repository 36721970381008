import * as yup from 'yup';

export default {
  id: yup
    .number()
    .transform(value => (!value ? null : value))
    .typeError('hanya angka')
    .required(''),
  down_payment: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  S_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PendekS_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PanjangS_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  M_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PendekM_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PajangM_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  L_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PendekL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PanjangL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  XL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PendekXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PanjangXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  XXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PendekXXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PanjangXXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  ThreeXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PendekThreeXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PanjangThreeXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  FourthXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PendekFourthXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PanjangFourthXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  FifthXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PendekFifthXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PanjangFifthXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  SixthXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PendekSixthXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
  PanjangSixthXL_Qty: yup
    .number()
    .transform(value => (!value ? 0 : value))
    .typeError('hanya angka')
    .required(''),
};
