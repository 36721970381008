import React, { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CREATE_PRODUCT_VOUCHER from 'graphql/product/voucher/create';
import AuthContext from '../../../AuthContext';
import FormProductVoucher from './form';
import ProductValidation from './validation';
import SUCCESS_REDIRECT from './constant';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentWrapper: {
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  media: {
    height: 350,
    backgroundSize: 'contain',
  },
  buttonChangePrice: {
    marginTop: theme.spacing(1),
  },
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  inputFile: {
    display: 'none',
  },
  imagePreview: {
    maxWidth: '100%',
  },
  header: {
    paddingTop: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  buttonSubmit: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddProductVoucher() {
  const classes = useStyles();
  const { setShowError, setErrorMessage } = useContext(AuthContext);
  const AddProductVoucherSchema = yup.object().shape(ProductValidation);
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: AddProductVoucherSchema,
    submitFocusError: false,
    nativeValidation: false,
  });

  const [AddProductVoucherAction, { loading }] = useMutation(CREATE_PRODUCT_VOUCHER, {
    onCompleted: async () => {
      window.location.href = SUCCESS_REDIRECT;
    },
    onError: error => {
      setShowError(true);
      setErrorMessage(error.message);
    },
  });

  const onSave = async dataAddVoucher => {
    const {
      customer,
      product,
      voucher_code,
      voucher_expired,
      voucher_percentage,
      title,
      description,
      image,
    } = dataAddVoucher;
    await AddProductVoucherAction({
      variables: {
        payload: {
          title,
          description,
          image,
          code: voucher_code.toUpperCase(),
          percentage: parseInt(voucher_percentage, 10),
          id_product: parseInt(product, 10),
          id_account: parseInt(customer, 10),
          expiredAt: voucher_expired,
        },
      },
    });
  };

  return (
    <FormProductVoucher
      setValue={setValue}
      isEdit={false}
      classes={classes}
      errors={errors}
      register={register}
      loading={loading}
      handleSubmit={handleSubmit}
      onSubmit={onSave}
    />
  );
}
