import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import constant from './constant';

export default function Form({
  classes,
  errors,
  register,
  loading,
  handleSubmit,
  onSubmitRegister,
  installmentTime,
  handleChangeInstallment,
}) {
  return (
    <Paper elevation={3} className={classes.wrapper}>
      <Grid container justify="center">
        <Grid item xs={8}>
          <Typography variant="h6" gutterBottom align="left">
            Ajukan Pinjaman
          </Typography>
          <TextField
            error={errors?.amount && true}
            helperText={errors?.amount?.message}
            inputRef={register}
            margin="dense"
            id="txt_amount"
            label="Jumlah"
            name="amount"
            size="small"
            variant="outlined"
            fullWidth
          />
          <FormControl className={classes.formControl} error={Boolean(errors.installment_time)}>
            <Select
              value={installmentTime}
              inputRef={register}
              name="installment_time"
              variant="outlined"
              labelId="slct-installment-time"
              onChange={handleChangeInstallment}
              displayEmpty
            >
              <MenuItem value="">
                <em>Silahkan Pilih</em>
              </MenuItem>
              {constant.installmentTimeOption.map(valueInstallment => {
                const { name, value } = valueInstallment;
                return (
                  <MenuItem value={value} key={value}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>Lama pelunasan dalam bulan</FormHelperText>
            <FormHelperText>
              {errors.installment_time && errors.installment_time.message}
            </FormHelperText>
          </FormControl>
          <TextField
            error={errors?.note && true}
            helperText={errors?.note?.message}
            inputRef={register}
            margin="dense"
            id="txt_needs"
            label="Keperluan"
            name="note"
            size="small"
            variant="outlined"
            fullWidth
          />
          <Box mt={2} mb={2}>
            <Button
              onClick={handleSubmit(onSubmitRegister)}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              id="btn-request-debit"
            >
              {loading ? <CircularProgress color="secondary" /> : 'Ajukan'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
