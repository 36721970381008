import gql from 'graphql-tag';

export const statement = `
 query productCategoryWebsite($payload:ParamProductCategoryWebsite) {
  productCategoryWebsite (v:$payload){
         id
         publish
         name
         createdAt
         updatedAt
         media {     
            secure_url
         }
  }
}   
`;

export default gql`
  ${statement}
`;
