import gql from 'graphql-tag';

export const statement = `
mutation verifyAccountPartnerApplication($payload:ParamVerifyAccountPartnerApplication!) {
    verifyAccountPartnerApplication(v:$payload) {
        status
    }
}
`;

export default gql`
  ${statement}
`;
