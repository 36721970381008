import gql from 'graphql-tag';

export const statement = `
  mutation createProductWeightInput($payload:ProductWeightInput!){
  createProductWeight(v:$payload) {    
     id
     PendekS_weight              
  }
}
`;

export default gql`
  ${statement}
`;
