import gql from 'graphql-tag';

export const statement = `
mutation createDayOff($payload:ParamCreateDayOff!) {
  createDayOff(v:$payload) {
     id_day_off_type
     startOff
     endOff
     reason
     idRequester
  }
}   
`;

export default gql`
  ${statement}
`;
