import gql from 'graphql-tag';

export const statement = `
query dayOffType{
  dayOffType {
    id
    name
    quota
  }
}
`;

export default gql`
  ${statement}
`;
