import gql from 'graphql-tag';

export const statement = `
mutation confirmWalletTopUpRequest($id:Int!) {
    confirmWalletTopUpRequest(id:$id) {
        status
    }
}
`;

export default gql`
  ${statement}
`;
