import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert/Alert';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import URL from 'utils/url';
import empty from 'assets/svg/empty.svg';
import DAY_OFF_QUERY from '../../graphql/dayoff/index';
import Error from '../../component/error';
import Loading from '../../component/loading';
import Request from './request';
import { PAGE_LIMIT } from '../../constant';
import Edit from './edit';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  alertWrapper: {
    marginBottom: theme.spacing(2),
  },
  filterWrapper: {
    marginBottom: theme.spacing(4),
  },
  dateFrom: {
    marginRight: theme.spacing(1),
  },
  dateTo: {
    marginRight: theme.spacing(1),
  },
  imageEmpty: {
    width: '500px',
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paginationWrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const RenderIndex = ({ classes, data, statusDayOffRequestPage, refetch }) => {
  const history = useHistory();
  const dataLength = data.dayOff.count;
  const pageCount = Math.ceil(dataLength / 10);
  return dataLength !== 0 ? (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Cuti</Typography>
        <Button
          onClick={() => {
            history.push('/cuti/ajukan');
          }}
          color="primary"
          variant="contained"
        >
          Ajukan
        </Button>
      </div>
      {statusDayOffRequestPage === 'berhasil' && (
        <Alert
          onClose={() => {
            history.push('/cuti');
          }}
          variant="filled"
          severity="success"
          className={classes.alertWrapper}
        >
          Pengajuan cuti berhasil
        </Alert>
      )}

      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Tipe</TableCell>
              <TableCell align="center">Mulai</TableCell>
              <TableCell align="center">Berakhir</TableCell>
              <TableCell align="center">Alasan</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Aksi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.dayOff.rows.map(row => {
              const { day_off_type: dayOffType, startOff, endOff, reason, status, id } = row;
              const parsedStartOff = moment(startOff).format('DD-MM-YYYY');
              const parsedEndOff = moment(endOff).format('DD-MM-YYYY');
              return (
                <TableRow key={id}>
                  <TableCell align="center">{dayOffType.name}</TableCell>
                  <TableCell align="center">{parsedStartOff}</TableCell>
                  <TableCell align="center">{parsedEndOff}</TableCell>
                  <TableCell align="center">{reason}</TableCell>
                  <TableCell align="center">{status}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        const payload = {
                          id,
                          day_off_type: dayOffType.id,
                          txt_reason: reason,
                          date_from_day_off: moment(startOff).format('YYYY-MM-DD'),
                          date_to_day_off: moment(endOff).format('YYYY-MM-DD'),
                        };
                        const queryEdit = URL.makeQueryString(payload);
                        history.push(`cuti/ubah${queryEdit}`);
                      }}
                    >
                      <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton size="small">
                      <DeleteIcon color="primary" fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Pagination
          className={classes.paginationWrapper}
          size="large"
          count={pageCount}
          color="primary"
          onChange={(event, value) => {
            refetch({
              payload: {
                limit: PAGE_LIMIT,
                offset: value * PAGE_LIMIT - PAGE_LIMIT,
              },
            });
          }}
        />
      </TableContainer>
    </>
  ) : (
    <Grid direction="column" container alignItems="center">
      <img src={empty} alt="empty_img" className={classes.imageEmpty} />
      <Typography variant="h5" gutterBottom>
        Tidak ada data cuti yang di temukan
      </Typography>
      <Button
        onClick={() => {
          history.push('/cuti/ajukan');
        }}
        color="primary"
        variant="contained"
      >
        Ajukan Cuti Pertama
      </Button>
    </Grid>
  );
};

export default function DayOff() {
  const classes = useStyles();
  const location = useLocation();
  const { search } = location;
  const statusDayOffRequestPage = URL.queryString(search, 'status');
  const path = location.pathname.split('/');
  const { data, error, loading, refetch } = useQuery(DAY_OFF_QUERY, {
    fetchPolicy: 'no-cache',
  });
  if (statusDayOffRequestPage === 'berhasil') {
    refetch();
  }
  if (error) {
    return <Error />;
  }

  if (loading) {
    return <Loading />;
  }

  if (path[2] === 'ajukan') {
    return <Request />;
  }

  if (path[2] === 'ubah') {
    return <Edit />;
  }
  return (
    <RenderIndex
      refetch={refetch}
      statusDayOffRequestPage={statusDayOffRequestPage}
      classes={classes}
      data={data}
    />
  );
}
