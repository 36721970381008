import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { NetworkStatus } from 'apollo-client';
import NAVIGATION_QUERY from 'graphql/navigation/index';
import Loading from 'component/loading';
import Error from 'component/error';
import { useHistory, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import Alert from '@material-ui/lab/Alert/Alert';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import URL from '../../utils/url';
import LoadMore from '../../component/LoadMore';
import empty from '../../assets/svg/empty.svg';

const RenderIndex = ({
  register,
  handleSubmit,
  onSubmit,
  classes,
  data,
  fetchMore,
  loading,
  refetch,
  history,
  statusPage,
  getValues,
}) => {
  const dataNavigation = data.accountNavigation;
  const dataNavigationLength = dataNavigation.length;
  return dataNavigationLength !== 0 ? (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Akun Navigasi</Typography>
      </div>

      <Grid container justify="center" className={classes.searchWrapper}>
        <TextField
          className={classes.searchField}
          size="small"
          variant="outlined"
          inputRef={register}
          name="navigation_name"
          label="Cari Navigasi"
        />
        <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
          Cari
        </Button>
      </Grid>
      {statusPage === 'berhasil' && (
        <Alert
          onClose={() => {
            history.push('/navigation');
          }}
          variant="filled"
          severity="success"
          className={classes.alertWrapper}
        >
          Aksi pada produk kategori website berhasil
        </Alert>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Nama</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Tanggal Tambah</TableCell>
              <TableCell>Tanggal Ubah</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataNavigation.map(row => {
              const { id, link, name, role_name, createdAt, updatedAt } = row;
              const idTable = `${id}`;
              return (
                <TableRow key={idTable}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{link}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{role_name}</TableCell>
                  <TableCell>{moment(createdAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell>{moment(updatedAt).format('DD MMMM YYYY')}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <LoadMore
          loading={loading}
          refetch={refetch}
          fetchMore={async () => {
            const navigationName = getValues('navigation_name');
            const payload = {
              offset: dataNavigationLength,
            };
            if (navigationName) {
              payload.name = navigationName;
            }
            await fetchMore({
              variables: {
                payload,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  ...prev,
                  accountNavigation: [
                    ...prev.accountNavigation,
                    ...fetchMoreResult.accountNavigation,
                  ],
                };
              },
            });
          }}
        />
      </TableContainer>
    </>
  ) : (
    <Grid direction="column" container alignItems="center">
      <img src={empty} alt="empty_img" className={classes.imageEmpty} />
      <Typography variant="h5" gutterBottom>
        Tidak ada data produk kategori website
      </Typography>
      <Button
        onClick={() => {
          history.push('/navigation/tambah');
        }}
        color="primary"
        variant="contained"
      >
        Tambah
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageEmpty: {
    width: '500px',
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loadMoreButtonWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  searchWrapper: {
    paddingBottom: theme.spacing(2),
  },
  searchField: {
    paddingRight: theme.spacing(1),
  },
  alertWrapper: {
    marginBottom: theme.spacing(1),
  },
}));

export default function Navigation() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const statusPage = URL.queryString(search, 'status');
  const { data, error, fetchMore, refetch, networkStatus } = useQuery(NAVIGATION_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      payload: {
        offset: 0,
        limit: 10,
        enableRole: false,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const initialLoading = networkStatus === NetworkStatus.loading;
  const fetchMoreLoading = networkStatus === NetworkStatus.fetchMore;
  const refetchLoading = networkStatus === NetworkStatus.refetch;
  const { register, handleSubmit, getValues } = useForm({
    submitFocusError: false,
    nativeValidation: false,
  });
  if (initialLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  const onSubmit = async dataOnSubmit => {
    const { navigation_name: navigationName } = dataOnSubmit;
    await refetch({
      payload: {
        name: navigationName,
      },
    });
  };

  return (
    <RenderIndex
      getValues={getValues}
      statusPage={statusPage}
      history={history}
      loading={fetchMoreLoading || refetchLoading}
      classes={classes}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      refetch={refetch}
      fetchMore={fetchMore}
      data={data}
    />
  );
}
