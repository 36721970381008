import gql from 'graphql-tag';

export const statement = `
query getSubscribeToWorkOrderTopic {
    getSubscribeToWorkOrderTopic {
        id
        fcm_token_id
        subscribed_at
    }
}
`;

export default gql`
  ${statement}
`;
