import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import CONFIRM_TOP_UP_REQUEST from 'graphql/wallet/TopUpRequest/confirm';

export default function DialogApproveTopUp({ open, onClose, image, money, status, id, refetch }) {
  const [confirmTopUpRequestAction] = useMutation(CONFIRM_TOP_UP_REQUEST, {
    onCompleted: () => {
      onClose();
      refetch();
    },
    onError: () => {},
  });

  const onYes = async () => {
    await confirmTopUpRequestAction({
      variables: {
        id: parseInt(id, 10),
      },
    });
  };

  const onNo = () => {
    onClose();
  };

  const success = status === 'SUCCESS';

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{success ? 'Lihat Bukti' : 'Setujui Permintaan Top Up'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {success ? 'Bukti Tranfer' : `Dana sebesar ${money} akan di top up ke wallet customer`}
        </DialogContentText>
        <img src={image} style={{ width: '100%' }} alt="image_proof" />
      </DialogContent>
      {!success && (
        <DialogActions>
          <Button onClick={onYes} color="primary" variant="contained">
            Ya
          </Button>
          <Button onClick={onNo} color="primary" variant="outlined">
            Tidak
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
