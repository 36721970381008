import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import React from 'react';
import Loading from '../../../../component/loading';

function RenderConfirmationAcc({ selectedSpkNumber, visible, onYes, onClose, loadingUpdateSpk }) {
  return (
    <Dialog open={visible}>
      {loadingUpdateSpk && <Loading />}
      {!loadingUpdateSpk && (
        <>
          <DialogTitle>ACC SPK</DialogTitle>
          <DialogContent>
            <DialogContentText>ACC SPK {selectedSpkNumber} ? </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onYes} color="primary" variant="contained">
              Ya
            </Button>
            <Button onClick={onClose} color="primary" variant="outlined">
              Tidak
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default React.memo(RenderConfirmationAcc);
