import gql from 'graphql-tag';

export const statement = `
query productReferral($payload:QueryProductReferral){
  productReferral(v:$payload) {
    id_issuer
    percentage
    code
    image
    description
    Issuer {
      name
      email
    }
  }
}
 `;

export default gql`
  ${statement}
`;
