import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CREATE_PRODUCT from 'graphql/product/add';
import CREATE_PRODUCT_MATERIAL from 'graphql/product/materialLinkage/create';
import CREATE_PRODUCT_COLOR from 'graphql/product/colorLinkage/create';
import CREATE_PRODUCT_WEIGHT from 'graphql/product/weight/index';
import UploadValidation from 'utils/validation/upload';
import ImageUtils from 'utils/Image';
import { PRODUCT_INTERNAL } from 'constant/upload_path';
import Error from '../../component/error/index';
import FormProduct from './form';
import Validation from './validation';
import ValidationProductWeight from './validation_product_weight';

import SUCCESS_REDIRECT from './constant';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentWrapper: {
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  media: {
    height: 350,
    backgroundSize: 'contain',
  },
  buttonChangePrice: {
    marginTop: theme.spacing(1),
  },
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  inputFile: {
    display: 'none',
  },
  header: {
    paddingTop: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  imagePreview: {
    maxWidth: '100%',
  },
}));

export default function ProductAdd() {
  const classes = useStyles();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [productWeight, setProductWeight] = useState(false);
  const uploadValidation = UploadValidation('file_upload');
  const AddProductSchema = yup.object().shape({
    ...Validation,
    ...ValidationProductWeight,
    ...uploadValidation,
  });
  const history = useHistory();
  const { register, handleSubmit, errors, control, setValue, getValues } = useForm({
    validationSchema: AddProductSchema,
    submitFocusError: false,
    nativeValidation: false,
    defaultValues: {
      category: '',
      type: 'reseller',
      S_Price: 0,
      PendekS_Price: 0,
      PanjangS_Price: 0,
      M_Price: 0,
      PendekM_Price: 0,
      PanjangM_Price: 0,
      L_Price: 0,
      PendekL_Price: 0,
      PanjangL_Price: 0,
      XL_Price: 0,
      PendekXL_Price: 0,
      PanjangXL_Price: 0,
      XXL_Price: 0,
      PendekXXL_Price: 0,
      PanjangXXL_Price: 0,
      ThreeXL_Price: 0,
      PendekThreeXL_Price: 0,
      PanjangThreeXL_Price: 0,
      FourthXL_Price: 0,
      PendekFourthXL_Price: 0,
      PanjangFourthXL_Price: 0,
      FifthXL_Price: 0,
      PendekFifthXL_Price: 0,
      PanjangFifthXL_Price: 0,
      SixthXL_Price: 0,
      PendekSixthXL_Price: 0,
      PanjangSixthXL_Price: 0,
      PendekS_weight: 0,
      S_weight: 0,
      PanjangS_weight: 0,
      PendekM_weight: 0,
      M_weight: 0,
      PanjangM_weight: 0,

      S_Children_Price: 0,
      PendekS_Children_Price: 0,
      PanjangS_Children_Price: 0,

      M_Children_Price: 0,
      PendekM_Children_Price: 0,
      PanjangM_Children_Price: 0,

      L_Children_Price: 0,
      PendekL_Children_Price: 0,
      PanjangL_Children_Price: 0,

      XL_Children_Price: 0,
      PendekXL_Children_Price: 0,
      PanjangXL_Children_Price: 0,

      PendekL_weight: 0,
      L_weight: 0,
      PanjangL_weight: 0,

      PendekXL_weight: 0,
      XL_weight: 0,
      PanjangXL_weight: 0,

      PendekXXL_weight: 0,
      XXL_weight: 0,
      PanjangXXL_weight: 0,

      PendekThreeXL_weight: 0,
      ThreeXL_weight: 0,
      PanjangThreeXL_weight: 0,

      PendekFourthXL_weight: 0,
      FourthXL_weight: 0,
      PanjangFourthXL_weight: 0,

      PendekFifthXL_weight: 0,
      FifthXL_weight: 0,
      PanjangFifthXL_weight: 0,

      PendekSixthXL_weight: 0,
      SixthXL_weight: 0,
      PanjangSixthXL_weight: 0,

      PendekS_Children_weight: 0,
      S_Children_weight: 0,
      PanjangS_Children_weight: 0,
      PendekM_Children_weight: 0,
      M_Children_weight: 0,
      PanjangM_Children_weight: 0,

      PendekL_Children_weight: 0,
      L_Children_weight: 0,
      PanjangL_Children_weight: 0,

      PendekXL_Children_weight: 0,
      XL_Children_weight: 0,
      PanjangXL_Children_weight: 0,
    },
  });

  const [addProductMaterialAction] = useMutation(CREATE_PRODUCT_MATERIAL);
  const [addProductColorAction] = useMutation(CREATE_PRODUCT_COLOR);

  const [
    addProductActionWeight,
    { loading: loadingProductWeight, error: errorProductWeight },
  ] = useMutation(CREATE_PRODUCT_WEIGHT, {
    onCompleted: async () => {
      setLoadingUpload(false);
      history.push(SUCCESS_REDIRECT);
    },
    onError: () => {
      setLoadingUpload(false);
    },
  });

  const [addProductAction, { loading, error: errorProductRequest }] = useMutation(CREATE_PRODUCT, {
    onCompleted: async dataSuccessProductAction => {
      const reassignProductWeight = productWeight;
      const idProductCreation = parseInt(dataSuccessProductAction.createProduct.id, 10);
      reassignProductWeight.id = idProductCreation;

      const product_material = getValues('product_material');
      const product_color = getValues('product_color');
      const resultProductMaterial = [];
      // eslint-disable-next-line array-callback-return
      product_material.map(value => {
        resultProductMaterial.push(value.id);
      });

      const resultProductColor = [];
      // eslint-disable-next-line array-callback-return
      product_color.map(value => {
        resultProductColor.push(value.id);
      });

      await addProductMaterialAction({
        variables: {
          payload: {
            id_product: idProductCreation,
            id_product_material: resultProductMaterial,
          },
        },
      });

      await addProductColorAction({
        variables: {
          payload: {
            id_product: idProductCreation,
            id_product_color: resultProductColor,
          },
        },
      });

      await addProductActionWeight({
        variables: {
          payload: reassignProductWeight,
        },
      });
    },
    onError: () => {
      setLoadingUpload(false);
    },
  });

  const onAddProductWeight = async dataProductWeight => {
    const reassign = dataProductWeight;
    reassign.id = '2';
    await addProductActionWeight({
      variables: {
        payload: reassign,
      },
    });
  };

  const onSubmitAdd = async dataAddProduct => {
    const {
      PendekL_weight,
      PendekM_weight,
      PendekS_weight,
      PendekSixthXL_weight,
      PendekThreeXL_weight,
      PendekXL_weight,
      PendekXXL_weight,
      S_weight,
      SixthXL_weight,
      ThreeXL_weight,

      XL_weight,

      XXL_weight,
      FifthXL_weight,
      L_weight,
      FourthXL_weight,
      M_weight,
      PanjangFifthXL_weight,
      PanjangFourthXL_weight,
      PanjangL_weight,
      PanjangM_weight,
      PanjangS_weight,
      PanjangSixthXL_weight,
      PanjangThreeXL_weight,

      PanjangXL_weight,
      PanjangXXL_weight,
      PendekFifthXL_weight,

      PendekFourthXL_weight,

      PendekS_Children_weight,
      S_Children_weight,
      PanjangS_Children_weight,
      PendekM_Children_weight,
      M_Children_weight,
      PanjangM_Children_weight,

      PendekL_Children_weight,
      L_Children_weight,
      PanjangL_Children_weight,

      PendekXL_Children_weight,
      XL_Children_weight,
      PanjangXL_Children_weight,
    } = dataAddProduct;

    const {
      PendekFourthXL_Price,
      PendekFifthXL_Price,
      FifthXL_Price,
      FourthXL_Price,
      L_Price,
      M_Price,
      PanjangFifthXL_Price,
      PanjangFourthXL_Price,
      PanjangL_Price,
      PanjangM_Price,
      PanjangS_Price,
      PanjangSixthXL_Price,
      PanjangThreeXL_Price,
      PanjangXL_Price,
      PanjangXXL_Price,
      PendekL_Price,
      PendekM_Price,
      PendekS_Price,
      PendekSixthXL_Price,
      PendekThreeXL_Price,
      PendekXL_Price,
      PendekXXL_Price,
      S_Price,
      SixthXL_Price,
      ThreeXL_Price,
      XL_Price,
      XXL_Price,
      category,
      description,
      file_upload,
      name,
      type,

      S_Children_Price,
      PendekS_Children_Price,
      PanjangS_Children_Price,

      M_Children_Price,
      PendekM_Children_Price,
      PanjangM_Children_Price,

      L_Children_Price,
      PendekL_Children_Price,
      PanjangL_Children_Price,

      XL_Children_Price,
      PendekXL_Children_Price,
      PanjangXL_Children_Price,
    } = dataAddProduct;

    const payloadAddProduct = {
      PendekFourthXL_Price,
      PendekFifthXL_Price,
      FifthXL_Price,
      FourthXL_Price,
      L_Price,
      M_Price,
      PanjangFifthXL_Price,
      PanjangFourthXL_Price,
      PanjangL_Price,
      PanjangM_Price,
      PanjangS_Price,
      PanjangSixthXL_Price,
      PanjangThreeXL_Price,
      PanjangXL_Price,
      PanjangXXL_Price,
      PendekL_Price,
      PendekM_Price,
      PendekS_Price,
      PendekSixthXL_Price,
      PendekThreeXL_Price,
      PendekXL_Price,
      PendekXXL_Price,
      S_Price,
      SixthXL_Price,
      ThreeXL_Price,
      XL_Price,
      XXL_Price,
      category,
      description,
      file_upload,
      name,
      type,

      S_Children_Price,
      PendekS_Children_Price,
      PanjangS_Children_Price,

      M_Children_Price,
      PendekM_Children_Price,
      PanjangM_Children_Price,

      L_Children_Price,
      PendekL_Children_Price,
      PanjangL_Children_Price,

      XL_Children_Price,
      PendekXL_Children_Price,
      PanjangXL_Children_Price,
    };

    setProductWeight({
      PendekL_weight,
      PendekM_weight,
      PendekS_weight,
      PendekSixthXL_weight,
      PendekThreeXL_weight,
      PendekXL_weight,
      PendekXXL_weight,
      S_weight,
      SixthXL_weight,
      ThreeXL_weight,

      XL_weight,
      XXL_weight,
      FifthXL_weight,
      L_weight,
      FourthXL_weight,
      M_weight,
      PanjangFifthXL_weight,
      PanjangFourthXL_weight,
      PanjangL_weight,
      PanjangM_weight,
      PanjangS_weight,
      PanjangSixthXL_weight,
      PanjangThreeXL_weight,
      PanjangXL_weight,
      PanjangXXL_weight,
      PendekFifthXL_weight,
      PendekFourthXL_weight,

      PendekS_Children_weight,
      S_Children_weight,
      PanjangS_Children_weight,
      PendekM_Children_weight,
      M_Children_weight,
      PanjangM_Children_weight,

      PendekL_Children_weight,
      L_Children_weight,
      PanjangL_Children_weight,

      PendekXL_Children_weight,
      XL_Children_weight,
      PanjangXL_Children_weight,
    });

    setLoadingUpload(true);
    const fileUpload = dataAddProduct.file_upload;
    await ImageUtils.upload(
      PRODUCT_INTERNAL,
      fileUpload,
      async res => {
        const { data } = res;
        delete payloadAddProduct.file_upload;
        payloadAddProduct.image = data.data;
        await addProductAction({
          variables: {
            payload: payloadAddProduct,
          },
        });
      },
      () => {
        setLoadingUpload(false);
      },
    );
  };

  if (errorProductRequest || errorProductWeight) {
    return <Error />;
  }

  return (
    <FormProduct
      setValue={setValue}
      control={control}
      isEdit={false}
      classes={classes}
      errors={errors}
      register={register}
      loading={loading || loadingUpload || loadingProductWeight}
      handleSubmit={handleSubmit}
      onSubmitRegister={onSubmitAdd}
      onAddProductWeight={onAddProductWeight}
    />
  );
}
