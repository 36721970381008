import React from 'react';
import { FormControlLabel, FormLabel, Radio, RadioGroup, FormControl } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';

const Option = [
  {
    value: 'umum',
    label: 'Umum',
  },
  {
    value: 'reseller',
    label: 'Reseller',
  },
  {
    value: 'polos',
    label: 'Polos',
  },
];

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: theme.spacing(1),
  },
}));

export default function ProductType({ name, control }) {
  const classes = useStyles();
  return (
    <FormControl component="fieldset" className={classes.wrapper}>
      <FormLabel component="legend">Tipe Produk</FormLabel>
      <Controller
        as={
          <RadioGroup>
            {Option.map(valueOption => {
              const { value: valueOptionContent, label } = valueOption;
              return (
                <FormControlLabel
                  key={valueOptionContent}
                  value={valueOptionContent}
                  control={<Radio color="primary" />}
                  label={label}
                />
              );
            })}
          </RadioGroup>
        }
        name={name}
        control={control}
      />
    </FormControl>
  );
}
