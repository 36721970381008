import React, { useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { NetworkStatus } from 'apollo-client';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import OrderIcon from '@material-ui/icons/ShoppingCart';
import RoomIcon from '@material-ui/icons/Room';
import PeopleIcon from '@material-ui/icons/People';
import BlockIcon from '@material-ui/icons/Block';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import ACCOUNT_ADDRESS_QUERY from 'graphql/account/address';
import CUSTOMER_QUERY from 'graphql/account/customer';
import ORDER_QUERY from 'graphql/order/index';
import empty from 'assets/svg/empty.svg';
import Error from '../../component/error';
import Loading from '../../component/loading';
import DetailOrder from './component/DetailOrder';
import DetailAddress from './component/Address';
import AddCustomer from './component/Add';
import ChangeRole from './component/ChangeRole';
import ActivateOrDeactivate from './component/ActivateOrDeactivate';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageEmpty: {
    width: '500px',
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loadMoreButtonWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  searchWrapper: {
    paddingBottom: theme.spacing(2),
  },
  searchField: {
    paddingRight: theme.spacing(1),
  },
}));

const RenderIndex = ({
  register,
  handleSubmit,
  onSubmit,
  classes,
  data,
  fetchMore,
  getOrderAction,
  getAccountAddress,
  setSelectedCustomer,
  refetch,
  setOpenAddCustomer,
  setOpenChangeRole,
  setSelectedID,
  setCustomerEmail,
  setActivateDeactivateVisibility,
  setCustomerActive,
}) => {
  const dataCustomer = data.customer;
  const dataCustomerLength = dataCustomer.length;
  return dataCustomerLength !== 0 ? (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Customer</Typography>
      </div>
      <Grid container direction="row" className={classes.searchWrapper}>
        <Grid item xs={6}>
          <TextField
            className={classes.searchField}
            size="small"
            variant="outlined"
            inputRef={register}
            name="customer_name"
            label="Cari Nama customer"
          />
          <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
            Cari
          </Button>
        </Grid>
        <Grid container item xs={6}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setOpenAddCustomer(true);
            }}
          >
            Tambah
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Aktif</TableCell>
              <TableCell>Nama</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Telepon</TableCell>
              <TableCell>Tgl Gabung</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Detail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataCustomer.map(row => {
              const { name, id, email, phone, createdAt, account_roles, is_active } = row;
              const idTable = `${id}-${email}`;
              return (
                <TableRow key={idTable}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{is_active}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>{phone}</TableCell>
                  <TableCell>{moment(createdAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell>{account_roles[0]?.role}</TableCell>
                  <TableCell>
                    <Tooltip title="Aktif non aktifkan">
                      <IconButton
                        size="small"
                        onClick={async () => {
                          setActivateDeactivateVisibility(true);
                          setCustomerEmail(email);
                          setCustomerActive(is_active);
                        }}
                      >
                        <BlockIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Detail Order">
                      <IconButton
                        size="small"
                        onClick={async () => {
                          const idAsInt = parseInt(id, 10);
                          setSelectedCustomer(idAsInt);
                          await getOrderAction({
                            variables: {
                              payload: {
                                createdByCustomer: idAsInt,
                              },
                            },
                          });
                        }}
                      >
                        <OrderIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Alamat">
                      <IconButton
                        size="small"
                        onClick={async () => {
                          const idAsInt = parseInt(id, 10);
                          setSelectedCustomer(idAsInt);
                          await getAccountAddress({
                            variables: {
                              payload: {
                                id_account: idAsInt,
                              },
                            },
                          });
                        }}
                      >
                        <RoomIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ganti Role">
                      <IconButton
                        size="small"
                        onClick={async () => {
                          setOpenChangeRole(true);
                          setSelectedID(id);
                        }}
                      >
                        <PeopleIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Grid container justify="center" className={classes.loadMoreButtonWrapper}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={async () => {
              const payload = {
                offset: dataCustomerLength,
              };
              await fetchMore({
                variables: {
                  payload,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return { ...prev, customer: [...prev.customer, ...fetchMoreResult.customer] };
                },
              });
            }}
          >
            Muat lebih banyak
          </Button>
        </Grid>
      </TableContainer>
    </>
  ) : (
    <Grid direction="column" container alignItems="center">
      <img src={empty} alt="empty_img" className={classes.imageEmpty} />
      <Typography variant="h5" gutterBottom>
        Tidak ada data customer yang di temukan
      </Typography>
      <Button
        onClick={() => {
          refetch({
            payload: {
              offset: 0,
              limit: 10,
            },
          });
        }}
        color="primary"
        variant="contained"
      >
        Kembali ke Pencarian
      </Button>
    </Grid>
  );
};

export default function Customer() {
  const classes = useStyles();
  const { register, handleSubmit } = useForm({
    submitFocusError: false,
    nativeValidation: false,
  });

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openDetailOrder, setOpenDetailOrder] = useState(false);
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const [openChangeRole, setOpenChangeRole] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [openDetailAddress, setOpenDetailAddress] = useState(false);
  const [detailOrderData, setDetailOrderData] = useState({ order: [] });
  const [detailAddressData, setDetailAddressData] = useState({ accountAddress: [] });
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerActive, setCustomerActive] = useState('');
  const [activateDeactivateVisibility, setActivateDeactivateVisibility] = useState(false);

  const [
    getAccountAddress,
    { loading: loadingAccountAddress, error: errorAccountAddress },
  ] = useLazyQuery(ACCOUNT_ADDRESS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: dataAddress => {
      setOpenDetailAddress(true);
      let returnValue = dataAddress;
      if (detailAddressData.accountAddress.length > 0) {
        const mergeNewArray = [...detailAddressData.accountAddress, ...dataAddress.accountAddress];
        returnValue = {
          accountAddress: mergeNewArray,
        };
      }
      setDetailAddressData(returnValue);
    },
  });

  const [getOrderAction, { loading: loadingOrder, error: errorOrder }] = useLazyQuery(ORDER_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: dataOrder => {
      setOpenDetailOrder(true);
      let returnValue = dataOrder;
      if (detailOrderData.order.length > 0) {
        const mergeNewArray = [...detailOrderData.order, ...dataOrder.order];
        returnValue = {
          order: mergeNewArray,
        };
      }
      setDetailOrderData(returnValue);
    },
  });

  const { data, error, fetchMore, networkStatus, refetch } = useQuery(CUSTOMER_QUERY, {
    variables: {
      payload: {
        offset: 0,
        limit: 10,
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const onSubmit = async dataOnSubmit => {
    const { customer_name: customerName } = dataOnSubmit;
    await refetch({
      payload: {
        name: customerName,
      },
    });
  };

  const loadingCustomer = networkStatus === NetworkStatus.loading;

  if (error || errorOrder || errorAccountAddress) {
    return <Error />;
  }

  if (loadingCustomer || loadingOrder || loadingAccountAddress) {
    return <Loading />;
  }

  return (
    <>
      <ActivateOrDeactivate
        setCustomerActive={setCustomerActive}
        customerActive={customerActive}
        refetch={refetch}
        setActivateDeactivateVisibility={setActivateDeactivateVisibility}
        visiblity={activateDeactivateVisibility}
        customerEmail={customerEmail}
      />
      <ChangeRole
        refetch={refetch}
        selectedID={selectedID}
        onClose={() => {
          setOpenChangeRole(false);
        }}
        open={openChangeRole}
      />
      <AddCustomer
        onClose={() => {
          setOpenAddCustomer(false);
        }}
        open={openAddCustomer}
      />
      <DetailAddress
        getAccountAddress={async () => {
          const accountAddressLength = detailAddressData.accountAddress.length;
          await getAccountAddress({
            variables: {
              payload: {
                id_account: selectedCustomer,
                offset: accountAddressLength,
                limit: 10,
              },
            },
          });
        }}
        data={detailAddressData}
        open={openDetailAddress}
        handleClose={() => {
          setOpenDetailAddress(false);
          setDetailAddressData({
            accountAddress: [],
          });
        }}
      />
      <DetailOrder
        getOrderAction={async () => {
          const orderLength = detailOrderData.order.length;
          await getOrderAction({
            variables: {
              payload: {
                createdByCustomer: selectedCustomer,
                offset: orderLength,
                limit: 10,
              },
            },
          });
        }}
        data={detailOrderData}
        open={openDetailOrder}
        handleClose={() => {
          setOpenDetailOrder(false);
          setDetailOrderData({
            order: [],
          });
        }}
      />
      <RenderIndex
        setCustomerActive={setCustomerActive}
        setActivateDeactivateVisibility={setActivateDeactivateVisibility}
        setCustomerEmail={setCustomerEmail}
        setSelectedID={setSelectedID}
        setOpenChangeRole={setOpenChangeRole}
        setOpenAddCustomer={setOpenAddCustomer}
        setSelectedCustomer={id => {
          setSelectedCustomer(id);
        }}
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        refetch={refetch}
        fetchMore={fetchMore}
        classes={classes}
        data={data}
        getOrderAction={getOrderAction}
        getAccountAddress={getAccountAddress}
      />
    </>
  );
}
