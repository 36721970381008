import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { red } from '@material-ui/core/colors';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CheckIcon from '@material-ui/icons/Check';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
  root: {
    '&$selected': {
      backgroundColor: red[500],
      color: 'white',
      '&:hover': {
        backgroundColor: red[500],
        color: 'white',
      },
    },
  },
  selected: {},
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function UpdateStatus({
  open,
  handleClose,
  handleYes,
  list,
  idActive,
  setOrderStatusForUpdate,
  orderStatusForUpdate,
}) {
  const classes = useStyles();
  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Status SPK
            </Typography>
            <Button autoFocus color="inherit" onClick={handleYes}>
              Ubah
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          {list.map(value => {
            const { name, id } = value;
            const isSelected = orderStatusForUpdate === id && true;
            return (
              <div key={id}>
                <ListItem
                  button
                  selected={isSelected}
                  classes={{ root: classes.root, selected: classes.selected }}
                  onClick={() => {
                    setOrderStatusForUpdate(id);
                  }}
                >
                  <ListItemText primary={name} />
                  {idActive === id && (
                    <ListItemSecondaryAction>
                      <IconButton edge="end">
                        <CheckIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
                <Divider />
              </div>
            );
          })}
        </List>
      </Dialog>
    </div>
  );
}

export default React.memo(UpdateStatus);
