import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CREATE_CATEGORY_WEBSITE from 'graphql/product/category/website/create';
import Error from 'component/error/index';

import UploadValidation from 'utils/validation/upload';
import FormProductCategoryWebsite from './form';

import { FAILED_REDIRECT, SUCCESS_REDIRECT } from './constant';
import ImageUtils from '../../../../utils/Image';
import { PRODUCT_CATEGORY_WEBSITE } from '../../../../constant/upload_path';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentWrapper: {
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  media: {
    height: 350,
    backgroundSize: 'contain',
  },
  buttonChangePrice: {
    marginTop: theme.spacing(1),
  },
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  inputFile: {
    display: 'none',
  },
  imagePreview: {
    maxWidth: '100%',
  },
  header: {
    paddingTop: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ProductCategoryWebsiteAdd() {
  const classes = useStyles();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const uploadValidation = UploadValidation('file_upload');
  const AddProductCategoryWebsiteSchema = yup.object().shape({
    name: yup.string().required('Nama produk harus diisi'),
    ...uploadValidation,
  });
  const { register, handleSubmit, errors, control, formState } = useForm({
    mode: 'onChange',
    validationSchema: AddProductCategoryWebsiteSchema,
    submitFocusError: false,
    nativeValidation: false,
  });

  const [
    addProductCategoryWebsiteAction,
    { loading, error: errorProductCategoryWebsite },
  ] = useMutation(CREATE_CATEGORY_WEBSITE, {
    onCompleted: async () => {
      window.location.href = SUCCESS_REDIRECT;
    },
    onError: () => {
      window.location.href = FAILED_REDIRECT;
    },
  });

  const onSubmitAdd = async dataAddProduct => {
    const reassign = dataAddProduct;
    setLoadingUpload(true);
    const fileUpload = reassign.file_upload;
    await ImageUtils.upload(
      PRODUCT_CATEGORY_WEBSITE,
      fileUpload,
      async res => {
        const { data } = res;
        delete reassign.file_upload;
        reassign.image = data.data;
        await addProductCategoryWebsiteAction({
          variables: {
            payload: reassign,
          },
        });
      },
      () => {
        setLoadingUpload(false);
      },
    );
  };

  if (errorProductCategoryWebsite) {
    return <Error />;
  }

  return (
    <FormProductCategoryWebsite
      formState={formState}
      control={control}
      isEdit={false}
      classes={classes}
      errors={errors}
      register={register}
      loading={loading || loadingUpload}
      handleSubmit={handleSubmit}
      onSubmit={onSubmitAdd}
    />
  );
}
