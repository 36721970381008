import React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

function RenderDetailNote({ open, handleClose, message }) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Catatan</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(RenderDetailNote);
