import gql from 'graphql-tag';

export const statement = `
mutation updateDayOff($payload:ParamCreateDayOff!) {
  updateDayOff(v:$payload) {
     status
  }
}   
`;

export default gql`
  ${statement}
`;
