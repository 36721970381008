import React, { Suspense, useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { getToken, onMessage } from 'firebase/messaging';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import ApplicationShell from './container/ApplicaitonShell';
import SpkEdit from './container/spk/edit/index';
import PrivateRoute from './PrivateRoute';
import Login from './container/auth/login';
import ForgotPassword from './container/auth/forgot-password/index';
import ResetPassword from './container/ResetPassword/index';
import { messaging } from './firebaseConfig';
import SAVE_TOKEN from './graphql/token/create';
import ME_QUERY from './graphql/account/me';

function Routes() {
  const [saveTokenAction] = useMutation(SAVE_TOKEN, {
    onCompleted: () => {},
  });

  const [meAction] = useLazyQuery(ME_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: async dataMeAction => {
      // requesting permission using Notification API
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_KEY_PAIR,
        });
        if (dataMeAction.me && token) {
          const roles = dataMeAction.me.account_roles;
          // Check if there are roles and filter out 'customer' if more than one role
          let selectedRole;
          if (roles && roles.length > 1) {
            selectedRole = roles.find(role => role.role.toLowerCase() !== 'customer')?.role;
          } else if (roles && roles.length === 1) {
            selectedRole = roles[0].role;
          }
          await saveTokenAction({
            variables: {
              payload: {
                fcm_token: token,
                topic: selectedRole,
              },
            },
          });
        }
      } else if (permission === 'denied') {
        // notifications are blocked
        alert('You denied for the notification');
      }
    },
  });

  async function requestPermission() {
    await meAction();
  }
  useEffect(() => {
    requestPermission();
  }, []);

  onMessage(messaging, payload => {
    console.log('incoming msg', payload);
  });

  return (
    <BrowserRouter>
      <Switch>
        <Suspense
          fallback={() => {
            return <div>Sedang memuat...</div>;
          }}
        >
          <Route exact path="/">
            <Login />
          </Route>
          <PrivateRoute path="/bank">
            <ApplicationShell />
          </PrivateRoute>
          <Route exact path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route exact path="/reset-sandi">
            <ResetPassword />
          </Route>
          <PrivateRoute path="/customer">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/produk">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/spk">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/penawaran">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/absensi">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/cuti">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/kasbon">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/navigation">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/profil">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute exact path="/spk-edit">
            <SpkEdit />
          </PrivateRoute>
          <PrivateRoute path="/product-category-internal">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/product-category-website">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/product-material">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/product-color">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/voucher">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/referral">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/banner">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/statistic">
            <ApplicationShell />
          </PrivateRoute>

          <PrivateRoute path="/variant">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/variant-value">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/category-website">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/product-website">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/order-website">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/media">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/wallet">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/wallet-history">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/partner-application">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/withdraw-request">
            <ApplicationShell />
          </PrivateRoute>
          <PrivateRoute path="/top-up-request">
            <ApplicationShell />
          </PrivateRoute>
        </Suspense>
      </Switch>
    </BrowserRouter>
  );
}
export default Routes;
