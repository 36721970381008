import gql from 'graphql-tag';

export const statement = `
query debit($payload:ParamQueryDebit){
  debit(v:$payload) {
    count    
    rows {
      id
      id_debit_request
      due_date
      installment_time
      amount
      note
       DebitStatus{
        name
      }
    }    
  }
}
`;

export default gql`
  ${statement}
`;
