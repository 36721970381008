import gql from 'graphql-tag';

export const statement = `
query checkRole($token:String!){
  checkRole(token:$token){
    id_account
    role
  }
}
`;

export default gql`
  ${statement}
`;
