import gql from 'graphql-tag';

export const statement = `   
query productByVoucherCode($payload:ParamProductByVoucherCode){
    productByVoucherCode(v:$payload) {
        count     
        rows {
            Product {              
              rows {
                 name
                 description
                 media {
                    secure_url
                 }
              }                                                        
            }           
        }     
    }
}
`;

export default gql`
  ${statement}
`;
