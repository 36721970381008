import gql from 'graphql-tag';

export const statement = `
mutation saveToken($payload:ParamSaveFCMToken!) {
    saveToken(v:$payload){
        id
        owner_email
        fcm_token
        topic
        created_at
        updated_at
    }
}
`;

export default gql`
  ${statement}
`;
