import gql from 'graphql-tag';

export const statement = `
query orderWebsite($payload:ParamOrderWebsite){
  orderWebsite(v:$payload){
    createdAt
    order_no
    quantity
    payment_method
    pickup_options
    pickup_cod_time
    ProductVariant {
      product{
        name
        description
      }
      price
      image
      stok
      sku
      first_variant{
        id
        name
        meta_value
        variant {
          name
        }
      }            
      second_variant{
        id
        name
        meta_value
        variant {
          name
        }
      }                      
    }                   
    Customer {
        name
        email
        phone 
    }
    CustomerAddress{
      address_name
      province
      city
      subdistrict
      full_address
    }
    ShipmentCost {
      etd
      service
      name
      price
      origin_details_city
      origin_details_province
      origin_details_subdistrict
      destination_details_city
      destination_details_province
      destination_details_subdistrict
    }
  }
} 
 `;

export default gql`
  ${statement}
`;
