import gql from 'graphql-tag';

export const statement = ` 
mutation updateOrder($payload:ParamUpdateOrderInput!) {
    updateOrder(v: $payload) {
        status
    }
}
`;

export default gql`
  ${statement}
`;
