import { useMutation } from '@apollo/react-hooks';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as yup from 'yup';
import CREATE_INVOICE from 'graphql/invoice/create';
import CheckoutUtils from 'utils/checkout';

function ModalInvoice({
  open,
  spkNo,
  handleClose,
  amountPayable,
  setOpenSnackBar,
  setSnackBarMessage,
  downPayment,
  refetchAcc,
  invoiceProductID,
  additionalInvoiceData,
}) {
  const RegexNumber = /^[0-9]*$/;
  const errorMustBeFilledInMesssage = 'Harus diisi';
  const errorMustBeNumberMesssage = 'Harus Berupa angka';
  const InvoiceSchema = yup.object().shape({
    billed_amount: yup
      .string()
      .required(errorMustBeFilledInMesssage)
      .matches(RegexNumber, errorMustBeNumberMesssage),
    down_payment: yup
      .string()
      .required()
      .matches(RegexNumber, errorMustBeNumberMesssage),
    pay_amount: yup
      .string()
      .required(errorMustBeFilledInMesssage)
      .matches(RegexNumber, errorMustBeNumberMesssage),
    return_amount: yup
      .string()
      .required(errorMustBeFilledInMesssage)
      .matches(RegexNumber, 'Harus berupa angka dan tidak boleh minus'),
  });

  const [createMutationAction, { loading: loadingMutationAction }] = useMutation(CREATE_INVOICE, {
    onCompleted: () => {
      handleClose();
      setOpenSnackBar(true);
      setSnackBarMessage(`INVOICE ${spkNo} berhasil di buat`);
      refetchAcc();
    },
    onError: () => {
      handleClose();
      handleClose();
      setOpenSnackBar(true);
      setSnackBarMessage(`Kesalahan saat membuat invoice`);
    },
  });

  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: InvoiceSchema,
    submitFocusError: false,
    nativeValidation: false,
    defaultValues: {
      return_amount: 0,
    },
  });

  let amountPayableAsInt = parseInt(amountPayable, 10);

  let downPaymentFilter = parseInt(downPayment, 10);
  if (additionalInvoiceData?.Voucher) {
    const voucherPercentage = additionalInvoiceData?.Voucher?.[0]?.percentage;
    const getDiscount = CheckoutUtils.showDiscount(voucherPercentage, amountPayableAsInt);
    amountPayableAsInt = getDiscount.bare;
    downPaymentFilter = amountPayableAsInt / 2;
  }

  if (additionalInvoiceData?.Referral) {
    const voucherPercentage = additionalInvoiceData?.Referral?.[0]?.percentage;
    const getDiscount = CheckoutUtils.showDiscount(voucherPercentage, amountPayableAsInt);
    amountPayableAsInt = getDiscount.bare;
    downPaymentFilter = amountPayableAsInt / 2;
  }
  let restOfBill = amountPayableAsInt - downPaymentFilter;
  if (additionalInvoiceData?.OrderDelivery) {
    restOfBill += additionalInvoiceData?.OrderDelivery?.courier_cost;
  }
  const Field = [
    {
      name: 'billed_amount',
      id: 'billed_amount',
      label: 'Jumlah Tagihan',
      disabled: true,
      value: amountPayableAsInt,
    },
    {
      name: 'down_payment',
      id: 'down_payment',
      label: 'DP',
      disabled: true,
      value: downPaymentFilter,
    },
    {
      name: 'rest_of_the_bill',
      id: 'rest_of_the_bill',
      label: 'Sisa Tagihan',
      disabled: true,
      value: restOfBill,
    },
    {
      name: 'pay_amount',
      id: 'pay_amount',
      label: 'Total Bayar',
      disabled: false,
      autoFocus: true,
      onChange: e => {
        const inputValue = e.target.value;
        const returnAmount = inputValue - restOfBill;
        setValue('return_amount', returnAmount);
      },
    },
    {
      label: 'Kembalian',
      name: 'return_amount',
      id: 'kembalian',
      disabled: true,
    },
  ];

  const onSubmit = async data => {
    const {
      // eslint-disable-next-line camelcase
      billed_amount,
      // eslint-disable-next-line camelcase
      pay_amount,
      // eslint-disable-next-line camelcase
      return_amount,
    } = data;

    const payAmountAsInt = parseInt(pay_amount, 10);
    const returnAmountAsInt = parseInt(return_amount, 10);
    const billedAmountAsInt = parseInt(billed_amount, 10);
    const invoiceProductIDAsInt = parseInt(invoiceProductID, 10);
    await createMutationAction({
      variables: {
        payload: {
          spk_no: spkNo,
          product_id: invoiceProductIDAsInt,
          is_confirmed: 'Y',
          billed_amount: billedAmountAsInt,
          PPN: 10,
          pay_amount: payAmountAsInt,
          return_amount: returnAmountAsInt,
        },
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Buat Invoice {spkNo}</DialogTitle>
      <DialogContent>
        {Field.map((value, index) => {
          const { name, id, label, autoFocus, disabled, onChange, value: valueField } = value;
          const helperText = errors[name]?.message;
          const isError = errors[name] && true;
          const addProps = {};
          if (valueField) {
            addProps.value = valueField;
          }

          if (onChange) {
            addProps.onChange = onChange;
          }

          if (autoFocus) {
            addProps.autoFocus = autoFocus;
          }

          if (label) {
            addProps.label = label;
          }

          return (
            <TextField
              {...addProps}
              key={index}
              helperText={helperText}
              error={isError}
              size="small"
              disabled={disabled}
              inputRef={register}
              variant="outlined"
              margin="dense"
              id={id}
              name={name}
              fullWidth
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        {loadingMutationAction ? (
          <CircularProgress size={30} />
        ) : (
          <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
            Buat
          </Button>
        )}

        <Button variant="outlined" color="primary" onClick={handleClose}>
          Batal
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(ModalInvoice);
