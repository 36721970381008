import gql from 'graphql-tag';

export const statement = `
mutation updateDebit($payload:ParamCreateDebit!) {
    updateDebit(v:$payload){
        status              
    }
}
`;

export default gql`
  ${statement}
`;
