import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useLocation, useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import Alert from '@material-ui/lab/Alert/Alert';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-hook-form';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PRODUCT_WEBSITE_QUERY from 'graphql/product/website/get';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { NetworkStatus } from 'apollo-client';
import TableStyle from 'utils/style/table';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import URL from '../../../utils/url';
import LoadMore from '../../../component/LoadMore';
import empty from '../../../assets/svg/empty.svg';
import ProductWebsiteEdit from './edit';
import ProductWebsiteAdd from './add';
import Loading from '../../../component/loading';
import Error from '../../../component/error';
import EDIT_PRODUCT_WEBSITE from '../../../graphql/product/website/edit';

const RenderIndex = ({
  loadingUpdatePublish,
  register,
  handleSubmit,
  onSubmit,
  classes,
  data,
  fetchMore,
  loading,
  refetch,
  history,
  statusPage,
  editProductWebsite,
}) => {
  const dataProductWebsite = data.productWebsite;
  const dataProductWebsiteLength = dataProductWebsite.length;
  return dataProductWebsiteLength !== 0 ? (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Produk Website</Typography>
        <Button
          onClick={() => {
            history.push('/product-website/tambah');
          }}
          color="primary"
          variant="contained"
        >
          Tambah
        </Button>
      </div>

      <Grid container justify="center" className={classes.searchWrapper}>
        <TextField
          className={classes.searchField}
          size="small"
          variant="outlined"
          inputRef={register}
          name="product_website"
          label="Cari Produk Website"
        />
        <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
          Cari
        </Button>
      </Grid>
      {statusPage === 'berhasil' && (
        <Alert
          onClose={() => {
            history.push('/product-website');
          }}
          variant="filled"
          severity="success"
          className={classes.alertWrapper}
        >
          Aksi Pada produk berhasil
        </Alert>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nama</TableCell>
              <TableCell>Published</TableCell>
              <TableCell>Deskripsi</TableCell>
              <TableCell>Video</TableCell>
              <TableCell>Tipe Berat</TableCell>
              <TableCell>Berat</TableCell>
              <TableCell>Tinggi</TableCell>
              <TableCell>Lebar</TableCell>
              <TableCell>Panjang</TableCell>
              <TableCell>Tanggal Tambah</TableCell>
              <TableCell>Tanggal Ubah</TableCell>
              <TableCell>Ubah</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProductWebsite.map(row => {
              const {
                id,
                createdAt,
                updatedAt,
                name,
                description,
                height,
                weight,
                video,
                wide,
                width,
                weight_type,
                product_category,
                publish,
              } = row;
              const idTable = `${id}`;
              return (
                <TableRow key={idTable}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={loadingUpdatePublish}
                          onChange={event =>
                            editProductWebsite({
                              variables: {
                                payload: {
                                  publish: event.target.checked ? 'Y' : 'N', // Assuming 'N' is the opposite state,
                                  id,
                                },
                              },
                            })
                          }
                          checked={publish === 'Y'}
                          color="primary"
                        />
                      }
                    />
                  </TableCell>
                  <TableCell>{description}</TableCell>
                  <TableCell>{video}</TableCell>
                  <TableCell>{weight_type}</TableCell>
                  <TableCell>{weight}</TableCell>
                  <TableCell>{height}</TableCell>
                  <TableCell>{wide}</TableCell>
                  <TableCell>{width}</TableCell>
                  <TableCell>{moment(createdAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell>{moment(updatedAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => {
                        const payload = {
                          id,
                          name,
                          description,
                          height,
                          weight,
                          video,
                          wide,
                          width,
                          weight_type,
                          product_category_id: product_category.id,
                          product_category_name: product_category.name,
                        };
                        const queryEdit = URL.makeQueryString(payload);
                        history.push(`product-website/ubah${queryEdit}`);
                      }}
                    >
                      <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <LoadMore
          loading={loading}
          refetch={refetch}
          fetchMore={async () => {
            const payload = {
              offset: dataProductWebsiteLength,
            };
            await fetchMore({
              variables: {
                payload,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  ...prev,
                  productWebsite: [...prev.productWebsite, ...fetchMoreResult.productWebsite],
                };
              },
            });
          }}
        />
      </TableContainer>
    </>
  ) : (
    <Grid direction="column" container alignItems="center">
      <img src={empty} alt="empty_img" className={classes.imageEmpty} />
      <Typography variant="h5" gutterBottom>
        Tidak ada data produk website
      </Typography>
      <Box display="flex">
        <Button
          onClick={() => {
            history.push('/product-website/tambah');
          }}
          color="primary"
          variant="contained"
        >
          Tambah
        </Button>
        <Button
          style={{ marginLeft: '8px' }}
          onClick={() => {
            refetch({
              payload: {
                name: '',
              },
            });
          }}
          color="primary"
          variant="contained"
        >
          Kembali
        </Button>
      </Box>
    </Grid>
  );
};

const useStyles = TableStyle;

export default function ProductWebsite() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { search } = location;
  const statusPage = URL.queryString(search, 'status');
  const path = location.pathname.split('/');
  const [editProductWebsite, { loading }] = useMutation(EDIT_PRODUCT_WEBSITE, {
    refetchQueries: [
      { query: PRODUCT_WEBSITE_QUERY }, // Add more queries if necessary
    ],
    awaitRefetchQueries: true, // Ensures mutation completion before refetching
    onCompleted: () => {},
    onError: () => {},
  });

  const { register, handleSubmit } = useForm({
    submitFocusError: false,
    nativeValidation: false,
  });
  const { data, error, refetch, fetchMore, networkStatus } = useQuery(PRODUCT_WEBSITE_QUERY, {
    variables: {
      payload: {},
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const loadingInitial = networkStatus === NetworkStatus.loading;

  const onSubmit = async dataOnSubmit => {
    const { product_website: productWebsite } = dataOnSubmit;
    await refetch({
      payload: {
        name: productWebsite,
      },
    });
  };

  if (loadingInitial) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (path[2] === 'tambah') {
    // Uses from component is edit set to false
    return <ProductWebsiteAdd />;
  }

  if (path[2] === 'ubah') {
    return <ProductWebsiteEdit />;
  }

  return (
    <RenderIndex
      loadingUpdatePublish={loading}
      editProductWebsite={editProductWebsite}
      statusPage={statusPage}
      history={history}
      loading={networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.fetchMore}
      classes={classes}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      refetch={refetch}
      fetchMore={fetchMore}
      data={data}
    />
  );
}
