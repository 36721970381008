import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Upload from 'component/upload/index';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackComponent from 'component/back/index';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import UploadValidation from 'utils/validation/upload';
import EDIT_PRODUCT_BANNER from 'graphql/product/banner/update';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import RenderCarousel from 'component/carousel';
import ImageUtils from 'utils/Image';
import { PRODUCT_BANNER } from 'constant/upload_path';
import RenderFileUploadError from '../component/FileUploadErrorText';
import SUCCESS_REDIRECT from './constant';

function LoadingCenter() {
  return (
    <Grid container direction="row" justify="center" style={{ paddingTop: '16px' }}>
      <CircularProgress />
    </Grid>
  );
}

export default function Form({
  isEdit,
  classes,
  errors,
  register,
  loading,
  handleSubmit,
  onSubmit,
  onDelete,
  media,
}) {
  const [loadingEditImage, setLoadingEditImage] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const fileUploadErrorForAdd = errors?.file_upload;
  const [multipleImageUpload, setMultipleImageUpload] = useState([]);
  let mediaAsObject = null;
  if (media) {
    mediaAsObject = JSON.parse(media);
  }
  const uploadValidation = UploadValidation('file_upload');
  const uploadEditImageScheme = yup.object().shape(uploadValidation);
  const {
    register: registerEditImage,
    handleSubmit: handleSubmitEditImage,
    errors: errorsEditImage,
  } = useForm({
    validationSchema: uploadEditImageScheme,
    submitFocusError: false,
    nativeValidation: false,
  });
  const fileUploadErrorForEdit = errorsEditImage?.file_upload;
  const [uploadEditProductBanner, { loading: loadingEditProduct }] = useMutation(
    EDIT_PRODUCT_BANNER,
    {
      onCompleted: async () => {
        window.location.href = SUCCESS_REDIRECT;
      },
      onError: async () => {
        setLoadingEditImage(false);
      },
    },
  );

  const onSubmitEditImage = async dataOnSubmit => {
    setLoadingEditImage(true);
    const fileUpload = dataOnSubmit.file_upload;
    try {
      await ImageUtils.delete(mediaAsObject, () => {
        ImageUtils.upload(PRODUCT_BANNER, fileUpload, async res => {
          const { data } = res;
          const imageRequestID = data.data;
          const idAsInt = parseInt(urlParams.get('id'), 10);
          await uploadEditProductBanner({
            variables: {
              payload: {
                image: imageRequestID,
                id: idAsInt,
              },
            },
          });
        });
      });
    } catch (e) {
      setLoadingEditImage(false);
    }
  };

  return (
    <Paper elevation={3} className={classes.wrapper}>
      <Grid container className={classes.header}>
        <ArrowBackComponent history={history} location="/banner" />
        <Typography variant="h6" gutterBottom align="center" className={classes.title}>
          Produk Banner
        </Typography>
      </Grid>
      <Grid container direction="row" className={classes.contentWrapper} spacing={2}>
        <Grid item xs={12} sm={isEdit ? 6 : 12}>
          <TextField
            helperText={errors?.title?.message}
            error={errors?.title && true}
            variant="outlined"
            label="Judul"
            name="title"
            fullWidth
            margin="dense"
            inputRef={register}
          />

          <TextField
            helperText={errors?.description?.message}
            error={errors?.description && true}
            variant="outlined"
            label="Deskripsi"
            name="description"
            fullWidth
            margin="dense"
            inputRef={register}
          />

          {isEdit && !loading && (
            <>
              <Button
                fullWidth
                onClick={handleSubmit(onSubmit)}
                color="primary"
                variant="contained"
                className={classes.buttonChangePrice}
              >
                Ubah Nama
              </Button>
              <Button
                fullWidth
                onClick={async () => {
                  await ImageUtils.delete(
                    mediaAsObject,
                    () => {
                      onDelete({
                        variables: {
                          payload: {
                            id: parseInt(urlParams.get('id'), 10),
                          },
                        },
                      });
                    },
                    () => {
                      setLoadingEditImage(false);
                    },
                  );
                }}
                color="primary"
                variant="contained"
                className={classes.buttonChangePrice}
              >
                Hapus Banner
              </Button>
            </>
          )}
          {isEdit && loading && <LoadingCenter />}
        </Grid>
        {isEdit && mediaAsObject && (
          <Grid item xs={12} sm={6}>
            <RenderCarousel data={mediaAsObject} classes={classes} />
            <input
              onChange={e => {
                const multiImage = ImageUtils.storeMultiImage(e);
                setMultipleImageUpload(multiImage);
              }}
              ref={registerEditImage}
              name="file_upload"
              accept="image/*"
              className={classes.inputFile}
              id="icon-upload-edit-product-banner"
              type="file"
            />
            <Upload.RenderImagePreview image={multipleImageUpload} />
            <Upload.RenderUploadIcon
              htmlFor="icon-upload-edit-product-banner"
              text="Ganti gambar"
            />
            {fileUploadErrorForEdit && (
              <FormControl error>
                <FormHelperText>{fileUploadErrorForEdit.message}</FormHelperText>
              </FormControl>
            )}
            {loadingEditImage || loadingEditProduct ? (
              <LoadingCenter />
            ) : (
              <Button
                fullWidth
                onClick={handleSubmitEditImage(onSubmitEditImage)}
                color="primary"
                variant="contained"
              >
                Ganti Gambar
              </Button>
            )}
          </Grid>
        )}{' '}
        {!isEdit && (
          <Grid container direction="column">
            <RenderFileUploadError fileUploadError={fileUploadErrorForAdd} />
            <input
              onChange={e => {
                const multiImage = ImageUtils.storeMultiImage(e);
                setMultipleImageUpload(multiImage);
              }}
              ref={register}
              name="file_upload"
              accept="image/*"
              className={classes.inputFile}
              id="icon-upload-product-banner"
              type="file"
            />
            <Upload.RenderImagePreview image={multipleImageUpload} classes={classes} />
            <Upload.RenderUploadIcon htmlFor="icon-upload-product-banner" text="Upload gambar" />
          </Grid>
        )}
        {!isEdit && loading && <LoadingCenter />}
        {!isEdit && !loading && (
          <Button
            fullWidth
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
            className={classes.buttonChangePrice}
          >
            Tambah
          </Button>
        )}
      </Grid>
    </Paper>
  );
}
