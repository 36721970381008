import gql from 'graphql-tag';

export const statement = `
   
  query product($payload:ParamProductInput){
  product(v:$payload) {
    count
    rows {     
      id
      name
      type
      media {
        url
        public_id
      }
      published
      description
      image
      stok                              
      S_Price
      PendekS_Price
      PanjangS_Price     
      M_Price
      PendekM_Price
      PanjangM_Price     
      L_Price
      PendekL_Price
      PanjangL_Price     
      XL_Price
      PendekXL_Price
      PanjangXL_Price         
      XXL_Price
      PendekXXL_Price
      PanjangXXL_Price                    
      ThreeXL_Price
      PendekThreeXL_Price 
      PanjangThreeXL_Price
      FourthXL_Price
      PendekFourthXL_Price
      PanjangFourthXL_Price
      FifthXL_Price
      PendekFifthXL_Price
      PanjangFifthXL_Price    
      SixthXL_Price
      PendekSixthXL_Price
      PanjangSixthXL_Price                                       
    }
  }
}   
`;

export default gql`
  ${statement}
`;
