import gql from 'graphql-tag';

export const statement = `   
mutation createVariantValue($payload:ParamCreateVariantValue!) {
  createVariantValue(v:$payload) {
      id_variant
      name
      meta_value
  }
}
`;

export default gql`
  ${statement}
`;
