import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { onMessage } from 'firebase/messaging';
import Snackbar from '@material-ui/core/Snackbar';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar/AppBar';
import { useForm } from 'react-hook-form';
import Link from '@material-ui/core/Link';
import { NetworkStatus } from 'apollo-client';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SPK_QUERY from 'graphql/spk/index';
import SPK_STATUS_LIST from 'graphql/spk/status/all';
import SPK_UPDATE from 'graphql/spk/update';
import UPDATE_SPK_STATUS from 'graphql/spk/status/update';
import Role from 'utils/role';
import LoadMore from 'component/LoadMore/index';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CUSTOMER_QUERY from 'graphql/account/customer';
import RenderConfirmationAcc from './component/ConfirmationDialogAcc/index';
import RenderWorkOrder from './component/WorkOrder/index';
import useStyles from './style/index';
import Error from '../../component/error/index';
import Loading from '../../component/loading/index';
import UpdateStatus from './component/UpdateStatus';
import AddDivisionNote from './component/AddDivisionNote';
import RenderModalAsDetail from './component/ModalAsDetail/index';
import RenderModalInvoice from './component/ModalInvoice/index';
import RenderModalUploadSPKPicture from './component/ModalUploadSPKPicture/index';
import RenderModalImageAfterEdit from './component/ImageAfterEdit/index';
import RenderDetailNote from './component/DetailNote/index';
import RenderModalOrderDetail from './component/ModalOrderDetail/index';
import RenderBankReceipt from './component/BankReceipt/get';
import RenderModalUploadOnGoingImageSPK from './component/ModalUploadOngoingImageSpk';
import RenderModalPayment from './component/ModalPayment/index';
import GET_SUBSCRIBE_TO_TOPIC from '../../graphql/getSubscribeToTopic';
import { AUTHENTICATED } from '../../constant/index';
import SUBSCRIBE_TO_TOPIC from '../../graphql/subscribeToTopic';
import UNSUBSCRIBE_FROM_TOPIC from '../../graphql/unsubscribeFromTopic';

import PrintFinish from './print/finish';
import { messaging } from '../../firebaseConfig';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: '500px',
    overflow: 'auto',
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function customSort(data) {
  // Define the custom order
  const order = ['Prepare', 'Design', 'Potongan', 'PecahWarna', 'Sablon', 'Bordir'];

  // Sort the array
  return data.sort((a, b) => {
    // Get the index of the names from the order array
    let indexA = order.indexOf(a.name);
    let indexB = order.indexOf(b.name);

    // If the name is not found in the order array, set a large index
    if (indexA === -1) indexA = order.length + 1;
    if (indexB === -1) indexB = order.length + 1;

    // Compare the indices
    return indexA - indexB;
  });
}

async function fetchMoreAction(callbackTwo, data, type, getValues) {
  const payload = {
    offset: data.spk.length,
    is_confirmed: type === 'acc' ? 'Y' : 'N',
  };
  if (getValues) {
    payload.createdBy = getValues;
  }
  await callbackTwo({
    variables: {
      payload,
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) return prev;
      return { ...prev, spk: [...prev.spk, ...fetchMoreResult.spk] };
    },
  });
}
export default function Spk() {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split('/');
  const classes = useStyles();
  const { getValues, setValue, register } = useForm({
    submitFocusError: false,
    nativeValidation: false,
  });

  const [showInvoice, setShowInvoice] = React.useState(false);
  const [idOrderStatus, setIdOrderStatus] = React.useState(0);
  const [additionalInvoiceData, setAdditionalInvoiceData] = React.useState(null);
  const [modalPayment, setModalPayment] = React.useState(false);
  const [invoiceDetail, setInvoiceDetail] = React.useState({});
  const [sizeDetail, setSizeDetail] = React.useState({});
  const [modalSpkImageAfterEdit, setModalSpkImageAfterEdit] = React.useState(false);
  const [modalUploadOnGoingImageSPK, setModalUploadOnGoingImageSPK] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [openUpdateStatus, setOpenUpdateStatus] = React.useState(false);
  const [openAddDivisionNote, setOpenAddDivisionNote] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [contentForModal, setContentForModal] = React.useState([]);
  const [valueTab, setValueTab] = React.useState(0);
  const [showAccConfirmation, setShowAccConfirmation] = React.useState(false);
  const [selectedSpkNumber, setSelectedSpkNumber] = React.useState(null);
  const [idOrderForUpdateStatus, setIdOrderForUpdateStatus] = React.useState(null);
  const [orderNumberForUpdateStatus, setOrderNumberForUpdateStatus] = React.useState('');
  const [emailCustomerForUpdateStatus, setEmailCustomerForUpdateStatus] = React.useState('');
  const [orderStatusForUpdate, setOrderStatusForUpdate] = React.useState(null);
  const [isRoleBoss, setIsRoleBoss] = React.useState(false);
  const [isRoleDesign, setIsRoleDesign] = React.useState(false);
  const [openImageAfterEdit, setOpenImageAfterEdit] = React.useState(false);
  const [imageAfterEdit, setImageAfterEdit] = React.useState([]);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [detailNote, setDetaillNote] = React.useState({
    visibility: false,
    message: '',
  });

  const [snackBarMessage, setSnackBarMessage] = React.useState('Status order berhasil di pindah');
  const [spkUploadPictureState, setSpkUploadPictureState] = React.useState('UPLOAD');
  const [imageSPKPublicId, setImageSPKPublicId] = React.useState('');
  const [mediaProofSPKState, setMediaProofSPKState] = React.useState('UPLOAD');
  const [paymentDetail, setPaymentDetail] = React.useState('');
  const [imageMediaProofSPK, setImageMediaProofSPK] = React.useState('');

  const [seeUploadBankReceipt, setSeeUploadBankReceipt] = React.useState({
    visibility: false,
    imageSource: null,
  });
  const theme = useTheme();
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangeTabIndex = index => {
    setValueTab(index);
  };
  const payloadCustomer = {
    offset: 0,
    limit: 10,
  };

  const {
    data: dataGetSubscribeTopic,
    loading: loadingGetSubscription,
    refetch: refetchGetSubscribeTopic,
  } = useQuery(GET_SUBSCRIBE_TO_TOPIC, {
    fetchPolicy: 'no-cache',
  });

  const [subscribeToTopicAction, { loadingSubscribeToTopicAction }] = useMutation(
    SUBSCRIBE_TO_TOPIC,
    {
      onCompleted: () => {
        setSnackBarMessage('Berhasil subscribe ke topic');
        setOpenSnackBar(true);
        refetchGetSubscribeTopic(); // Refetch to get the latest data
      },
    },
  );

  const [unsubscribeToTopicAction, { loadingUnSubscribeToTopicAction }] = useMutation(
    UNSUBSCRIBE_FROM_TOPIC,
    {
      onCompleted: () => {
        setSnackBarMessage('Berhasil unsubscribe dari topic');
        setOpenSnackBar(true);
        refetchGetSubscribeTopic(); // Refetch to get the latest data
      },
    },
  );

  const { data: dataCustomer, loading: loadingCustomer, refetch: refetchCustomer } = useQuery(
    CUSTOMER_QUERY,
    {
      variables: {
        payload: payloadCustomer,
      },
      fetchPolicy: 'no-cache',
    },
  );

  const handleSubscribeOrUnsubscribe = () => {
    if (dataGetSubscribeTopic?.getSubscribeToWorkOrderTopic?.id) {
      unsubscribeToTopicAction();
    }
    if (dataGetSubscribeTopic?.getSubscribeToWorkOrderTopic === null) {
      subscribeToTopicAction();
    }
  };

  const payloadParameterAcc = {
    is_confirmed: 'Y',
    offset: 0,
    limit: 10,
  };
  const payloadParameterNotAcc = {
    is_confirmed: 'N',
    offset: 0,
    limit: 10,
  };
  const {
    data: dataSpkStatusList,
    loading: loadingSpkStatusList,
    error: errorSpkStatusList,
  } = useQuery(SPK_STATUS_LIST);
  const {
    data,
    error,
    refetch: refetchAcc,
    fetchMore: fetchMoreAcc,
    networkStatus: networkStatusDataAcc,
  } = useQuery(SPK_QUERY, {
    variables: {
      payload: payloadParameterAcc,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: dataNotAcc,
    error: errorNotAcc,
    refetch: refetchNotAcc,
    fetchMore: fetchMoreNotAcc,
    networkStatus: networkStatusDataNotAcc,
  } = useQuery(SPK_QUERY, {
    variables: {
      payload: payloadParameterNotAcc,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [updateSpkStatusAction, { loading: loadingUpdateSpkStatus }] = useMutation(
    UPDATE_SPK_STATUS,
    {
      onCompleted: () => {
        setOpenUpdateStatus(false);
        setOpenSnackBar(true);
        setOrderStatusForUpdate(null);
        refetchAcc();
      },
    },
  );

  const [updateSpkAction, { loading: loadingUpdateSpk, error: errorUpdateSpk }] = useMutation(
    SPK_UPDATE,
    {
      onCompleted: async () => {
        await refetchNotAcc();
        await refetchAcc();
        setSnackBarMessage('SPK berhasil di ACC');
        setShowAccConfirmation(false);
        setOpenSnackBar(true);
      },
    },
  );

  useEffect(() => {
    const token = localStorage.getItem(AUTHENTICATED);
    const tokenAsObject = JSON.parse(token);
    setIsRoleBoss(Role.CheckByName(tokenAsObject, 'BOS'));
    setIsRoleDesign(Role.CheckByName(tokenAsObject, 'DESIGN'));
  }, []);

  useEffect(() => {
    register('search_spk_acc');
    register('search_spk_not_acc');
  }, [register]);

  if (path[2] === 'print') {
    return <PrintFinish />;
  }
  const loadingAcc = networkStatusDataAcc === NetworkStatus.loading;
  const loadingNotAcc = networkStatusDataNotAcc === NetworkStatus.loading;
  if (loadingAcc || loadingNotAcc || loadingSpkStatusList || loadingUpdateSpkStatus) {
    return <Loading />;
  }
  if (error || errorNotAcc || errorUpdateSpk || errorSpkStatusList) {
    return <Error />;
  }

  // Check if voucher exist or not
  const downPayment = invoiceDetail?.invoice?.down_payment;

  const subTotal = invoiceDetail?.invoice?.sub_total;
  const spkNo = invoiceDetail?.invoice?.spk_no;
  const invoiceProductID = invoiceDetail?.invoice?.product_id;

  const spkLength = data.spk.length;
  const spkExist = spkLength > 0;
  const additionalParameterUpdateStatus = {};
  if (!isRoleBoss) {
    additionalParameterUpdateStatus.idActive = data.spk[0]?.Order.id_order_status;
  }

  const autoCompleteCustomerProperty = {};
  autoCompleteCustomerProperty.fullWidth = true;
  autoCompleteCustomerProperty.options = dataCustomer?.customer;
  autoCompleteCustomerProperty.getOptionLabel = option => {
    return `${option.name}-${option.email}`;
  };
  autoCompleteCustomerProperty.renderInput = params => (
    <TextField {...params} label="Customer" variant="outlined" margin="dense" />
  );
  autoCompleteCustomerProperty.noOptionsText = 'Customer tidak di temukan';
  autoCompleteCustomerProperty.size = 'small';
  autoCompleteCustomerProperty.onChange = (value, newValue) => {
    if (newValue?.id) {
      const idValue = newValue?.id;
      const payloadRefetch = {
        createdBy: idValue,
        offset: 0,
        is_confirmed: 'Y',
      };
      if (valueTab === 0) {
        refetchAcc({
          payload: payloadRefetch,
        });
        setValue('search_spk_acc', idValue);
      }
      if (valueTab === 1) {
        refetchNotAcc({
          payload: payloadRefetch,
        });
        setValue('search_spk_not_acc', idValue);
      }
    } else {
      if (valueTab === 0) {
        setValue('search_spk_acc', '');
      }
      if (valueTab === 1) {
        setValue('search_spk_not_acc', '');
      }
    }
  };
  autoCompleteCustomerProperty.onInputChange = event => {
    const name = event?.target?.value;
    // close button is clicked
    if (name !== 0) {
      refetchCustomer({
        payload: {
          name,
        },
      });

      // Reset searching
      const payloadSearch = {
        offset: 0,
        limit: 10,
      };
      if (valueTab === 0) {
        refetchAcc({
          payload: payloadSearch,
        });
      }
      if (valueTab === 1) {
        refetchNotAcc({
          payload: payloadSearch,
        });
      }
    }
  };

  onMessage(messaging, payload => {
    console.log('incoming message', payload);
    setSnackBarMessage('New job coming in please reload your browser');
    setOpenSnackBar(true);
  });

  return (
    <div className={classes.wrapper}>
      <AppBar position="static" color="default">
        <Tabs
          value={valueTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Sudah di ACC" />
          {isRoleBoss && <Tab label="Belum di ACC" />}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={valueTab}
        onChangeIndex={handleChangeTabIndex}
      >
        <TabPanel value={valueTab} index={0} dir={theme.direction}>
          {spkExist && (
            <Grid container justify="center" className={classes.wrapperSearch}>
              {!loadingCustomer && <Autocomplete {...autoCompleteCustomerProperty} />}
            </Grid>
          )}
          <Grid
            style={{
              marginBottom: '30px',
            }}
            onClick={() => handleSubscribeOrUnsubscribe()}
          >
            <Card>
              <CardContent
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {loadingUnSubscribeToTopicAction ||
                loadingGetSubscription ||
                loadingSubscribeToTopicAction ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {!dataGetSubscribeTopic?.getSubscribeToWorkOrderTopic ? (
                      <>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link href="#">Subscribe</Link>
                        <span style={{ marginLeft: '4px' }}>
                          untuk mendapatkan notifikasi pekerjaan
                        </span>
                      </>
                    ) : (
                      <>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link href="#">Unsubscribe</Link>
                        <span style={{ marginLeft: '4px' }}>
                          untuk berhenti notifikasi pekerjaan
                        </span>
                      </>
                    )}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <RenderModalInvoice
              additionalInvoiceData={additionalInvoiceData}
              refetchAcc={refetchAcc}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              amountPayable={subTotal}
              downPayment={downPayment}
              spkNo={spkNo}
              invoiceProductID={invoiceProductID}
              open={showInvoice}
              handleClose={() => {
                setShowInvoice(false);
              }}
            />
            <RenderWorkOrder
              setIdOrderStatus={setIdOrderStatus}
              setOpenAddDivisionNote={setOpenAddDivisionNote}
              OnEmpty={() => {
                return <Autocomplete {...autoCompleteCustomerProperty} />;
              }}
              setAdditionalInvoiceData={setAdditionalInvoiceData}
              setModalPayment={setModalPayment}
              setPaymentDetail={setPaymentDetail}
              setMediaProofSPKState={setMediaProofSPKState}
              setImageMediaProofSPK={setImageMediaProofSPK}
              setModalUploadOnGoingImageSPK={setModalUploadOnGoingImageSPK}
              setOrderNumberForUpdateStatus={setOrderNumberForUpdateStatus}
              setSeeUploadBankReceipt={setSeeUploadBankReceipt}
              setSizeDetail={setSizeDetail}
              setDetaillNote={setDetaillNote}
              setImageSPKPublicId={setImageSPKPublicId}
              setSpkUploadPictureState={setSpkUploadPictureState}
              setImageAfterEdit={setImageAfterEdit}
              setOpenImageAfterEdit={setOpenImageAfterEdit}
              setSelectedSpkNumber={setSelectedSpkNumber}
              setModalSpkImageAfterEdit={setModalSpkImageAfterEdit}
              setInvoiceDetail={setInvoiceDetail}
              setShowInvoice={setShowInvoice}
              setIdOrderForUpdateStatus={setIdOrderForUpdateStatus}
              history={history}
              isRoleDesign={isRoleDesign}
              isRoleBoss={isRoleBoss}
              changeSpkStatus={() => {
                setOpenUpdateStatus(true);
              }}
              data={data}
              setEmailCustomerForUpdateStatus={setEmailCustomerForUpdateStatus}
              classes={classes}
              setContentForModal={setContentForModal}
              setOpenModal={setOpenModal}
            />
          </Grid>
          {spkExist && (
            <LoadMore
              loading={
                networkStatusDataAcc === NetworkStatus.refetch ||
                networkStatusDataAcc === NetworkStatus.fetchMore
              }
              refetch={refetchAcc}
              fetchMore={async () => {
                const searchValue = getValues('search_spk_acc');
                await fetchMoreAction(fetchMoreAcc, data, 'acc', searchValue);
              }}
            />
          )}
        </TabPanel>
        {isRoleBoss && (
          <TabPanel value={valueTab} index={1} dir={theme.direction}>
            <Grid container justify="center" className={classes.wrapperSearch}>
              {!loadingCustomer && <Autocomplete {...autoCompleteCustomerProperty} />}
            </Grid>
            <Grid container direction="row" spacing={2}>
              <RenderWorkOrder
                setImageMediaProofSPK={setImageMediaProofSPK}
                setMediaProofSPKState={setMediaProofSPKState}
                OnEmpty={() => {
                  return <Autocomplete {...autoCompleteCustomerProperty} />;
                }}
                setModalUploadOnGoingImageSPK={setModalUploadOnGoingImageSPK}
                setEmailCustomerForUpdateStatus={setEmailCustomerForUpdateStatus}
                setAdditionalInvoiceData={setAdditionalInvoiceData}
                setModalPayment={setModalPayment}
                setPaymentDetail={setPaymentDetail}
                setSizeDetail={setSizeDetail}
                setDetaillNote={setDetaillNote}
                history={history}
                isRoleBoss={isRoleBoss}
                data={dataNotAcc}
                classes={classes}
                setContentForModal={setContentForModal}
                setOpenModal={setOpenModal}
                setShowAccConfirmation={setShowAccConfirmation}
                setSelectedSpkNumber={setSelectedSpkNumber}
              />
            </Grid>
            {spkExist && (
              <LoadMore
                loading={
                  networkStatusDataNotAcc === NetworkStatus.refetch ||
                  networkStatusDataNotAcc === NetworkStatus.fetchMore
                }
                refetch={refetchNotAcc}
                fetchMore={async () => {
                  const searchValue = getValues('search_spk_not_acc');
                  await fetchMoreAction(fetchMoreNotAcc, dataNotAcc, 'notacc', searchValue);
                }}
              />
            )}
          </TabPanel>
        )}
      </SwipeableViews>
      <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message={snackBarMessage}
      />

      <RenderModalPayment
        open={modalPayment}
        data={paymentDetail}
        handleClose={() => {
          setModalPayment(false);
        }}
      />
      <RenderDetailNote
        open={detailNote.visibility}
        message={detailNote.message}
        handleClose={() => {
          setDetaillNote({
            visibility: false,
          });
        }}
      />
      <RenderModalImageAfterEdit
        imageAfterEdit={imageAfterEdit}
        open={openImageAfterEdit}
        handleClose={() => {
          setOpenImageAfterEdit(false);
        }}
      />

      <RenderModalOrderDetail
        data={sizeDetail}
        open={Object.keys(sizeDetail).length > 0 && true}
        handleClose={() => {
          setSizeDetail({});
        }}
      />
      <RenderModalUploadSPKPicture
        refetchAcc={refetchAcc}
        imageSPKPublicId={imageSPKPublicId}
        spkUploadPictureState={spkUploadPictureState}
        setSnackBarMessage={setSnackBarMessage}
        setOpenSnackBar={setOpenSnackBar}
        open={modalSpkImageAfterEdit}
        selectedSpkNumber={selectedSpkNumber}
        onClose={() => {
          setModalSpkImageAfterEdit(false);
        }}
      />
      <RenderModalUploadOnGoingImageSPK
        refetchAcc={refetchAcc}
        imageMediaProofSPK={imageMediaProofSPK}
        mediaProofSPKState={mediaProofSPKState}
        setSnackBarMessage={setSnackBarMessage}
        setOpenSnackBar={setOpenSnackBar}
        open={modalUploadOnGoingImageSPK}
        selectedSpkNumber={selectedSpkNumber}
        onClose={() => {
          setModalUploadOnGoingImageSPK(false);
          setImageMediaProofSPK('');
          setMediaProofSPKState('UPLOAD');
        }}
      />
      <RenderConfirmationAcc
        selectedSpkNumber={selectedSpkNumber}
        loadingUpdateSpk={loadingUpdateSpk}
        visible={showAccConfirmation}
        onYes={async () => {
          await updateSpkAction({
            variables: {
              payload: {
                customerEmail: emailCustomerForUpdateStatus,
                spkNumber: selectedSpkNumber,
                isConfirmed: 'Y',
                enableNotif: true,
              },
            },
          });
        }}
        onClose={() => {
          setShowAccConfirmation(false);
        }}
      />
      <RenderBankReceipt
        onClose={() => {
          setSeeUploadBankReceipt({
            visibility: false,
          });
        }}
        visible={seeUploadBankReceipt.visibility}
        imageSource={seeUploadBankReceipt.imageSource}
      />
      <RenderModalAsDetail
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        classes={classes}
        modalStyle={modalStyle}
        content={contentForModal}
      />
      {spkExist && (
        <AddDivisionNote
          setSnackBarMessage={setSnackBarMessage}
          setOpenSnackBar={setOpenSnackBar}
          idOrderStatus={idOrderStatus}
          selectedSpkNumber={selectedSpkNumber}
          orderNumberForUpdateStatus={orderNumberForUpdateStatus}
          open={openAddDivisionNote}
          handleClose={() => {
            setOpenAddDivisionNote(false);
          }}
        />
      )}
      {spkExist && (
        <UpdateStatus
          {...additionalParameterUpdateStatus}
          setOrderStatusForUpdate={setOrderStatusForUpdate}
          orderStatusForUpdate={orderStatusForUpdate}
          handleClose={() => {
            setOpenUpdateStatus(false);
          }}
          handleYes={async () => {
            const idOrderAsInt = parseInt(idOrderForUpdateStatus, 10);
            const idOrderStatusAsInt = parseInt(orderStatusForUpdate, 10);
            const payloadUpdateStatus = {
              idOrderStatus: idOrderStatusAsInt,
              idOrder: idOrderAsInt,
              customerEmail: emailCustomerForUpdateStatus,
              orderNumber: orderNumberForUpdateStatus,
              spkNumber: selectedSpkNumber,
            };
            await updateSpkStatusAction({
              variables: {
                payload: payloadUpdateStatus,
              },
            });
          }}
          list={customSort(dataSpkStatusList.statusSPKList)}
          open={openUpdateStatus}
        />
      )}
    </div>
  );
}
