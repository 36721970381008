import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 20,
  },
  media: {
    height: 250,
    backgroundSize: 'contain',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  wrapperSearch: {
    marginBottom: theme.spacing(3),
  },
  textfieldSearch: {
    width: '40%',
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
