import React from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import Money from 'utils/money';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import CardMedia from '@material-ui/core/CardMedia';
import { NetworkStatus } from 'apollo-client';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ORDER_WEBSITE_QUERY from 'graphql/order/website/index';
import Loading from 'component/loading/index';
import Error from 'component/error/index';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import LoadMore from '../../../component/LoadMore/index';

const useStyles = makeStyles(theme => ({
  cardContent: {
    minHeight: '200px',
  },
  media: {
    height: '250px',
    backgroundSize: 'contain',
  },
  wrapper: {
    marginBottom: theme.spacing(1),
  },
  expand: {
    display: 'flex',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

async function fetchMoreAction(callbackTwo, data) {
  const payload = {
    offset: data.orderWebsite.length,
  };

  await callbackTwo({
    variables: {
      payload,
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) return prev;
      return { ...prev, orderWebsite: [...prev.orderWebsite, ...fetchMoreResult.orderWebsite] };
    },
  });
}

export default function OrderWebsite() {
  const classes = useStyles();
  const {
    data,
    error,
    refetch: refetchOrderWebsite,
    fetchMore: fetchMoreOrderWebsite,
    networkStatus: networkStatusOrderWebsite,
  } = useQuery(ORDER_WEBSITE_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [expandedIndex, setExpandedIndex] = React.useState(null);
  const handleExpandClickIndex = index => {
    setExpandedIndex(index);
  };
  const orderFetchMore = networkStatusOrderWebsite === NetworkStatus.fetchMore;
  const orderInitial = networkStatusOrderWebsite === NetworkStatus.loading;

  if (orderInitial) {
    return (
      <>
        <Loading />
      </>
    );
  }

  if (error) {
    return <Error />;
  }

  const dataOrderWebsite = data?.orderWebsite;
  const dataOrderLength = dataOrderWebsite.length;
  const dataOrderExist = dataOrderLength > 0;

  return (
    <>
      {dataOrderExist ? (
        <>
          <Grid container direction="row" spacing={2} className={classes.wrapper}>
            {dataOrderWebsite.map((value, index) => {
              const {
                order_no,
                quantity,
                pickup_cod_time,
                payment_method,
                pickup_options,
                ProductVariant,
                CustomerAddress,
                Customer,
                ShipmentCost,
                createdAt,
              } = value;

              const pickupCodTimeFilter = moment(pickup_cod_time).format('DD MMMM YYYY HH:MM');
              const onlineDelivery = pickup_options === 'online_delivery';
              let pickupOptionsFilter = 'Online Delivery';
              if (!onlineDelivery) {
                pickupOptionsFilter = 'Kantor Voodoo';
              }
              const productVariantPrice = ProductVariant?.[0]?.price || 0;
              const productVariantFirstTitle = ProductVariant?.[0]?.first_variant?.variant?.name;
              const productVariantFirstName = ProductVariant?.[0]?.first_variant?.name;
              const productVariantSecondTitle = ProductVariant?.[0]?.second_variant?.variant?.name;
              const productVariantSecondName = ProductVariant?.[0]?.second_variant?.name;
              const productVariantPriceAsString = Money.formatIDR(productVariantPrice.toString());
              const productVariantImage = ProductVariant?.[0]?.image;
              const productVariantProductName = ProductVariant?.[0]?.product?.name;
              const productVariantProductDescription = ProductVariant?.[0]?.product?.description;
              const address_name = CustomerAddress?.address_name;
              const full_address = CustomerAddress?.full_address;
              const customerName = Customer?.[0]?.name;
              const customerEmail = Customer?.[0]?.email;
              const customerPhone = Customer?.[0]?.phone;
              const etd = ShipmentCost?.etd;
              const service = ShipmentCost?.service;
              const name = ShipmentCost?.name;
              const price = ShipmentCost?.price;
              const priceAsString = Money.formatIDR(parseInt(price, 10).toString());
              const destination_details_city = ShipmentCost?.destination_details_city;
              const destination_details_province = ShipmentCost?.destination_details_province;
              const destination_details_subdistrict = ShipmentCost?.destination_details_subdistrict;
              const totalItem = quantity * productVariantPrice;
              const totalItemAsString = Money.formatIDR(totalItem.toString());
              let grandTotal = quantity * productVariantPrice + parseInt(price, 10);
              if (!onlineDelivery) {
                grandTotal = quantity * productVariantPrice;
              }
              const grandTotalAsString = Money.formatIDR(grandTotal.toString());

              return (
                <Grid item xs={12} sm={12} md={4} key={index}>
                  <Card>
                    <CardActionArea>
                      <CardMedia
                        key={index}
                        className={classes.media}
                        src={productVariantImage}
                        image={productVariantImage}
                      />
                    </CardActionArea>
                    <CardContent>
                      <Typography variant="h6">{order_no}</Typography>
                      <Typography gutterBottom variant="body2" color="textSecondary">
                        {moment(createdAt).format('DD MMMM YYYY')}
                      </Typography>
                      <Typography gutterBottom variant="body1">
                        {customerName}
                      </Typography>

                      <Typography gutterBottom variant="body1">
                        {customerEmail}
                      </Typography>

                      <Typography gutterBottom variant="body1">
                        {customerPhone}
                      </Typography>

                      <Typography gutterBottom variant="h6" color="textSecondary">
                        {productVariantProductName || '-'}
                      </Typography>
                      <Typography gutterBottom variant="body2" color="textSecondary">
                        {productVariantProductDescription || '-'}
                      </Typography>

                      <Box display="flex" flexDireaction="row" justifyContent="space-between">
                        <Typography gutterBottom variant="body2" color="textSecondary">
                          {productVariantFirstTitle || '-'}
                        </Typography>
                        <Typography gutterBottom variant="body2" color="textSecondary">
                          {productVariantFirstName || '-'}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDireaction="row" justifyContent="space-between">
                        <Typography gutterBottom variant="body2" color="textSecondary">
                          {productVariantSecondTitle || '-'}
                        </Typography>
                        <Typography gutterBottom variant="body2" color="textSecondary">
                          {productVariantSecondName || '-'}
                        </Typography>
                      </Box>

                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expandedIndex === index,
                        })}
                        onClick={() => {
                          handleExpandClickIndex(expandedIndex === index ? null : index);
                        }}
                        aria-expanded={expandedIndex === index}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                      <Collapse in={expandedIndex === index || false} timeout="auto" unmountOnExit>
                        <Box display="flex" flexDireaction="row" justifyContent="space-between">
                          <Typography variant="body2" color="textSecondary">
                            Harga
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body2"
                            color="textSecondary"
                            justifyContent="space-between"
                          >
                            {productVariantPriceAsString || '-'}
                          </Typography>
                        </Box>
                        <Box display="flex" flexDireaction="row" justifyContent="space-between">
                          <Typography variant="body2" color="textSecondary">
                            Kuantitas
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body2"
                            color="textSecondary"
                            justifyContent="space-between"
                          >
                            {quantity || '-'}
                          </Typography>
                        </Box>
                        <Box display="flex" flexDireaction="row" justifyContent="space-between">
                          <Typography variant="body2" color="textSecondary">
                            Harga Item
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body2"
                            color="textSecondary"
                            justifyContent="space-between"
                          >
                            {totalItemAsString || '-'}
                          </Typography>
                        </Box>
                        <Box display="flex" flexDireaction="row" justifyContent="space-between">
                          <Typography variant="body2" color="textSecondary">
                            Metode Pembayaran
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body2"
                            color="textSecondary"
                            justifyContent="space-between"
                          >
                            {payment_method || '-'}
                          </Typography>
                        </Box>
                        <Box display="flex" flexDireaction="row" justifyContent="space-between">
                          <Typography variant="body2" color="textSecondary">
                            Opsi Ambil
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body2"
                            color="primary"
                            justifyContent="space-between"
                          >
                            {pickupOptionsFilter || '-'}
                          </Typography>
                        </Box>
                        {onlineDelivery && (
                          <>
                            <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
                            <Box display="flex" flexDireaction="row" justifyContent="space-between">
                              <Typography gutterBottom variant="body2" color="textSecondary">
                                Provinsi Tujuan
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="body2"
                                color="textSecondary"
                                justifyContent="space-between"
                              >
                                {destination_details_province || '-'}
                              </Typography>
                            </Box>
                            <Box display="flex" flexDireaction="row" justifyContent="space-between">
                              <Typography gutterBottom variant="body2" color="textSecondary">
                                Kota Tujuan
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="body2"
                                color="textSecondary"
                                justifyContent="space-between"
                              >
                                {destination_details_city || '-'}
                              </Typography>
                            </Box>
                            <Box display="flex" flexDireaction="row" justifyContent="space-between">
                              <Typography gutterBottom variant="body2" color="textSecondary">
                                Kecamatan Tujuan
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="body2"
                                color="textSecondary"
                                justifyContent="space-between"
                              >
                                {destination_details_subdistrict || '-'}
                              </Typography>
                            </Box>
                            <Box display="flex" flexDireaction="row" justifyContent="space-between">
                              <Typography gutterBottom variant="body2" color="textSecondary">
                                ETD
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="body2"
                                color="textSecondary"
                                justifyContent="space-between"
                              >
                                {etd || '-'}
                              </Typography>
                            </Box>
                            <Box display="flex" flexDireaction="row" justifyContent="space-between">
                              <Typography gutterBottom variant="body2" color="textSecondary">
                                Service
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="body2"
                                color="textSecondary"
                                justifyContent="space-between"
                              >
                                {service || '-'}
                              </Typography>
                            </Box>
                            <Box display="flex" flexDireaction="row" justifyContent="space-between">
                              <Typography gutterBottom variant="body2" color="textSecondary">
                                Kurir
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="body2"
                                color="textSecondary"
                                justifyContent="space-between"
                              >
                                {name || '-'}
                              </Typography>
                            </Box>

                            <Box display="flex" flexDireaction="row" justifyContent="space-between">
                              <Typography gutterBottom variant="body2" color="textSecondary">
                                Harga per gram
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="body2"
                                color="primary"
                                justifyContent="space-between"
                              >
                                {priceAsString || '-'}
                              </Typography>
                            </Box>
                          </>
                        )}
                        <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
                        <Typography gutterBottom variant="body2">
                          {address_name}
                        </Typography>
                        <Typography gutterBottom variant="body2" color="textSecondary">
                          {full_address}
                        </Typography>
                        {!onlineDelivery && (
                          <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="body2" color="textSecondary">
                              Diambil Pada
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {pickupCodTimeFilter}
                            </Typography>
                          </Box>
                        )}
                        <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
                      </Collapse>
                      <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
                      <Typography gutterBottom variant="h6" color="primary">
                        Total Harga: {grandTotalAsString}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          <LoadMore
            loading={orderInitial || orderFetchMore}
            refetch={refetchOrderWebsite}
            fetchMore={async () => {
              await fetchMoreAction(fetchMoreOrderWebsite, data);
            }}
          />
        </>
      ) : (
        <div>Data order website tidak di temukan</div>
      )}
    </>
  );
}
