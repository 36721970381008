import gql from 'graphql-tag';

export const statement = `
query customer($payload: ParamQueryCustomer) {
    customer(v: $payload) {
        id
        name               
        email
        phone
        createdAt
        is_active
        account_roles {
          role
        }                                
    }
}
`;

export default gql`
  ${statement}
`;
