import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackComponent from 'component/back/index';
import EDIT_PRODUCT_CATEGORY_WEBSITE from 'graphql/product/category/website/update';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RenderFileUploadError from '../../component/FileUploadErrorText';
import ImageUtils from '../../../../utils/Image';
import Upload from '../../../../component/upload';
import { PRODUCT_CATEGORY_WEBSITE } from '../../../../constant/upload_path';
import UploadValidation from '../../../../utils/validation/upload';
import { SUCCESS_REDIRECT, FAILED_REDIRECT } from './constant';
import RenderCarousel from '../../../../component/carousel';

function LoadingCenter() {
  return (
    <Grid container direction="row" justify="center" style={{ paddingTop: '16px' }}>
      <CircularProgress />
    </Grid>
  );
}

export default function Form({
  isEdit,
  classes,
  errors,
  register,
  loading,
  handleSubmit,
  onSubmit,
  formState,
  media,
}) {
  const fileUploadErrorForAdd = errors?.file_upload;
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [multipleImageUpload, setMultipleImageUpload] = useState([]);
  const [loadingEditImage, setLoadingEditImage] = useState(false);
  let mediaAsObject = null;
  if (media) {
    mediaAsObject = JSON.parse(media);
  }
  const uploadValidation = UploadValidation('file_upload');
  const uploadEditImageScheme = yup.object().shape(uploadValidation);
  const {
    register: registetEditImage,
    handleSubmit: handleSubmitEditImage,
    errors: errorsEditImage,
  } = useForm({
    validationSchema: uploadEditImageScheme,
    submitFocusError: false,
    nativeValidation: false,
  });
  const fileUploadErrorForEdit = errorsEditImage?.file_upload;
  const [uploadEditProductCategory, { loading: loadingEditProduct }] = useMutation(
    EDIT_PRODUCT_CATEGORY_WEBSITE,
    {
      onCompleted: async () => {
        window.location.href = SUCCESS_REDIRECT;
      },
      onError: async () => {
        window.location.href = FAILED_REDIRECT;
        setLoadingEditImage(false);
      },
    },
  );

  const onSubmitEditImage = async dataOnSubmit => {
    setLoadingEditImage(true);
    const fileUpload = dataOnSubmit.file_upload;
    await ImageUtils.delete(
      mediaAsObject,
      () => {
        ImageUtils.upload(
          PRODUCT_CATEGORY_WEBSITE,
          fileUpload,
          async res => {
            const { data } = res;
            const imageRequestID = data.data;
            const idAsInt = parseInt(urlParams.get('id'), 10);
            await uploadEditProductCategory({
              variables: {
                payload: {
                  image: imageRequestID,
                  id: idAsInt,
                },
              },
            });
          },
          () => {
            setLoadingEditImage(false);
          },
        );
      },
      () => {
        setLoadingEditImage(false);
      },
    );
  };

  return (
    <Paper elevation={3} className={classes.wrapper}>
      <Grid container className={classes.header}>
        <ArrowBackComponent history={history} location="/category-website" />
        <Typography variant="h6" gutterBottom align="center" className={classes.title}>
          Produk Kategori Website
        </Typography>
      </Grid>
      <Grid container direction="row" className={classes.contentWrapper} spacing={2}>
        <Grid item xs={12} sm={isEdit ? 6 : 12}>
          <TextField
            helperText={errors?.name?.message}
            error={errors?.name && true}
            variant="outlined"
            label="Nama"
            name="name"
            fullWidth
            margin="dense"
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12} sm={isEdit ? 6 : 12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Published</FormLabel>
            <RadioGroup row aria-label="publish" name="publish">
              <FormControlLabel
                inputRef={register}
                value="Y"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                inputRef={register}
                value="N"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {isEdit && mediaAsObject && (
          <Grid item xs={12} sm={12}>
            <div style={{ marginBottom: '30px' }}>
              <div>
                <Typography gutterBottom align="center">
                  Gambar Sekarang
                </Typography>
              </div>
              <div>
                <RenderCarousel data={mediaAsObject} classes={classes} />
              </div>
            </div>
            <div>
              <input
                onChange={e => {
                  const multiImage = ImageUtils.storeMultiImage(e);
                  setMultipleImageUpload(multiImage);
                }}
                ref={registetEditImage}
                name="file_upload"
                accept="image/*"
                className={classes.inputFile}
                id="icon-upload-edit-product-category-internal"
                type="file"
              />
            </div>
            <Upload.RenderImagePreview image={multipleImageUpload} classes={classes} />
            <Upload.RenderUploadIcon
              htmlFor="icon-upload-edit-product-category-internal"
              text="Ganti gambar"
            />
            {fileUploadErrorForEdit && (
              <FormControl error>
                <FormHelperText>{fileUploadErrorForEdit.message}</FormHelperText>
              </FormControl>
            )}
            {loadingEditImage || loadingEditProduct ? (
              <LoadingCenter />
            ) : (
              <>
                {multipleImageUpload.length > 0 && (
                  <Button
                    fullWidth
                    onClick={handleSubmitEditImage(onSubmitEditImage)}
                    color="primary"
                    variant="contained"
                  >
                    Ganti Gambar
                  </Button>
                )}
              </>
            )}
          </Grid>
        )}{' '}
        {!isEdit && (
          <Grid container direction="column">
            <RenderFileUploadError fileUploadError={fileUploadErrorForAdd} />
            <input
              onChange={e => {
                const multiImage = ImageUtils.storeMultiImage(e);
                setMultipleImageUpload(multiImage);
              }}
              ref={register}
              name="file_upload"
              accept="image/*"
              className={classes.inputFile}
              id="icon-upload-product-category-website"
              type="file"
            />
            <Upload.RenderImagePreview image={multipleImageUpload} classes={classes} />
            <Upload.RenderUploadIcon
              htmlFor="icon-upload-product-category-website"
              text="Upload gambar"
            />

            {fileUploadErrorForEdit && (
              <FormControl error>
                <FormHelperText>{fileUploadErrorForEdit.message}</FormHelperText>
              </FormControl>
            )}
          </Grid>
        )}
        {loading ? (
          <LoadingCenter />
        ) : (
          <Button
            disabled={!formState.isValid}
            fullWidth
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
            className={classes.buttonAdd}
          >
            {!isEdit ? ' Tambah' : 'Ubah'}
          </Button>
        )}
      </Grid>
    </Paper>
  );
}
