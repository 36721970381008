import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

/**
 * @return {null}
 */
export default function RenderFileUploadError({ fileUploadError }) {
  if (fileUploadError) {
    return (
      <FormControl error>
        <FormHelperText>{fileUploadError.message}</FormHelperText>
      </FormControl>
    );
  }
  return null;
}
