import React from 'react';
import WALLET_HISTORY_QUERY from 'graphql/wallet/History/get';
import { useQuery } from '@apollo/react-hooks';
import Loading from 'component/loading';
import Error from 'component/error';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { NetworkStatus } from 'apollo-client';
import { useLocation, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackComponent from 'component/back/index';
import Money from 'utils/money';
import LoadMore from 'component/LoadMore';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center',
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function WalletHistory() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const { data, error, fetchMore, refetch, networkStatus } = useQuery(WALLET_HISTORY_QUERY, {
    variables: {
      payload: {
        offset: 0,
        limit: 10,
        id_account: parseInt(urlParams.get('id'), 10),
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const walletHistoryFetchMore = networkStatus === NetworkStatus.fetchMore;
  const walletHistoryRefetch = networkStatus === NetworkStatus.refetch;
  const walletHistoryLoading = networkStatus === NetworkStatus.loading;

  if (walletHistoryLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const walletHistoryCount = data?.walletHistory?.count;
  const walletHistoryRows = data?.walletHistory?.rows;

  return (
    <>
      <div className={classes.headerWrapper}>
        <Grid container>
          <ArrowBackComponent history={history} location="/wallet" />
          <Typography variant="h5" className={classes.title}>
            Wallet History
          </Typography>
        </Grid>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Jumlah</TableCell>
              <TableCell>Tipe</TableCell>
              <TableCell>Deskripsi</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Updated At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {walletHistoryRows.map(value => {
              const { amount, createdAt, description, type, updatedAt } = value;
              return (
                <TableRow>
                  <TableCell>{Money.formatIDR(amount.toString())}</TableCell>
                  <TableCell>{type}</TableCell>
                  <TableCell>{description}</TableCell>
                  <TableCell>{moment(createdAt).format('YYYY-MM-DD')}</TableCell>
                  <TableCell>{moment(updatedAt).format('YYYY-MM-DD')}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {walletHistoryCount > 10 && (
        <>
          {walletHistoryFetchMore ? (
            <CircularProgress color="secondary" />
          ) : (
            <Box pt={2}>
              <LoadMore
                loading={walletHistoryFetchMore || walletHistoryRefetch}
                refetch={refetch}
                fetchMore={async () => {
                  const walletHistoryLength = walletHistoryRows.length;
                  const payload = {
                    offset: walletHistoryLength,
                  };
                  await fetchMore({
                    variables: {
                      payload,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      const newRows = fetchMoreResult.walletHistory.rows;
                      const newCount = fetchMoreResult.walletHistory.count;
                      return {
                        walletHistory: {
                          ...prev.walletHistory,
                          rows: [...prev.walletHistory.rows, ...newRows],
                          count: newCount,
                        },
                      };
                    },
                  });
                }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
}
