import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation, useHistory } from 'react-router-dom';
import PRODUCT_CATEGORY_WEBSITE from 'graphql/product/category/website/index';
import PRODUCT_VARIANT_GET from 'graphql/product/website/variant/get';
import CREATE_PRODUCT_WEBSITE from 'graphql/product/website/create';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import BackIcon from 'component/back/index';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import Error from 'component/error';
import { PRODUCT_WEBSITE } from 'constant/upload_path';
import axios from 'axios';
import ImageUtils from 'utils/Image';
import StringUtils from 'utils/string';
import Loading from 'component/loading/index';
import Validation from './validation';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export default function ProductWebsiteEdit() {
  const classes = useStyles();
  const history = useHistory();
  let tableHeader = ['Harga', 'Stok', 'Sku', 'Gambar', 'Status'];
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const {
    data: dataProductCategoryWebsite,
    loading: loadingProductCategoryWebsite,
    error: errorProductCategoryWebsite,
  } = useQuery(PRODUCT_CATEGORY_WEBSITE, {
    variables: {
      payload: {
        publish: 'Y',
        limit: 10,
        offset: 0,
      },
    },
  });

  const idProduct = parseInt(urlParams.get('id'), 10);
  const {
    data: dataProductVariant,
    loading: loadingProductVariant,
    error: errorProductVariant,
  } = useQuery(PRODUCT_VARIANT_GET, {
    variables: {
      payload: {
        id_product: idProduct,
      },
    },
  });

  const [createProductWebsite] = useMutation(CREATE_PRODUCT_WEBSITE, {});

  const AddProductSchema = yup.object().shape(Validation);
  const { register, handleSubmit, errors, setValue, getValues, setError } = useForm({
    validationSchema: AddProductSchema,
    mode: 'onChange',
    submitFocusError: false,
    nativeValidation: false,
    defaultValues: {
      name: urlParams.get('name'),
      height: urlParams.get('height'),
      wide: urlParams.get('wide'),
      width: urlParams.get('wide'),
      weight: urlParams.get('weight'),
      product_video: urlParams.get('video'),
      product_description: urlParams.get('description'),
      product_name: urlParams.get('name'),
    },
  });

  const mergeVariant = [];

  const onSubmit = async dataSave => {
    const schema = yup.object().shape({
      price: yup.number().min(100),
      stok: yup.number().min(1),
    });
    const dataForm = new FormData();
    const fileUpload = dataSave.image;
    mergeVariant.map((value, index) => {
      const priceName = getValues(`price[${index}]`);
      const stokName = getValues(`stok[${index}]`);
      const imageName = getValues(`image[${index}]`);
      const imageEmpty = imageName.length === 0;
      schema
        .validate({ price: priceName, stok: stokName })
        .then(() => {
          if (imageEmpty) {
            const error = `image_variant_error_${index}`;
            setError(error, error, 'Upload file dibutuhkan');
            return false;
          }
          dataForm.append(`files[${index}]`, fileUpload[index]);
        })
        .catch(() => {
          const errorStock = `stock_variant_error_${index}`;
          const errorPrice = `price_variant_error_${index}`;
          setError(errorStock, errorStock, 'Stok minimal 1');
          setError(errorPrice, errorPrice, 'Harga minimal 100');
          return false;
        });
    });

    dataForm.append('image_path', PRODUCT_WEBSITE);
    const urlUpload = `${process.env.REACT_APP_SERVICE_MEDIA}/api/v1/uploadS3`;
    const configRequest = ImageUtils.getHeader();
    axios
      .post(urlUpload, dataForm, configRequest)
      .then(() => {})
      .catch(() => {});

    const {
      category,
      product_name,
      product_video,
      product_description,
      firstVariantID,
      secondVariantID,
      price,
      stok,
      sku,
      image,
      status,
      weight_type,
      weight,
      height,
      wide,
      width,
    } = dataSave;

    const payloadSave = {};
    payloadSave.category = category;
    payloadSave.product_name = product_name;
    payloadSave.product_video = product_video;
    payloadSave.product_description = product_description;
    payloadSave.firstVariantID = firstVariantID;
    payloadSave.secondVariantID = secondVariantID;
    payloadSave.price = price;
    payloadSave.stok = stok;
    payloadSave.sku = sku;
    payloadSave.image = image;
    payloadSave.status = status;
    payloadSave.weight_type = weight_type;
    payloadSave.weight = weight;
    payloadSave.height = height;
    payloadSave.wide = wide;
    payloadSave.width = width;
    payloadSave.image = ['a', 'b', 'c', 'd', 'e'];
    await createProductWebsite({
      variables: {
        payload: payloadSave,
      },
    });
  };

  useEffect(() => {
    register('weight_type');
    register('category');
    register('variant');
    register('variant_value');
    register('second_variant');
    register('second_variant_value');
    register('show_product_video');
  }, [register]);

  if (errorProductCategoryWebsite || errorProductVariant) {
    return <Error />;
  }

  if (loadingProductVariant) {
    return <Loading />;
  }

  const autoCompleteWeight = {};
  autoCompleteWeight.options = [
    {
      name: 'Gram',
      value: 'gram',
    },
    {
      name: 'Kilogram',
      value: 'kilogram',
    },
  ];

  const weightType = urlParams.get('weight_type');
  autoCompleteWeight.defaultValue = {
    name: StringUtils.capitalizeFirstLetter(weightType),
    value: weightType,
  };

  autoCompleteWeight.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteWeight.renderInput = params => (
    <TextField
      {...params}
      label="Tipe berat"
      variant="outlined"
      fullWidth
      margin="dense"
      helperText={errors?.weight_type?.message}
      error={errors?.weight_type && true}
    />
  );
  autoCompleteWeight.noOptionsText = 'Tipe berat tidak di temukan';
  autoCompleteWeight.size = 'small';
  autoCompleteWeight.onChange = (value, newValue) => {
    if (newValue?.name) {
      setValue('weight_type', newValue?.value);
    } else {
      setValue('weight_type', '');
    }
  };

  const autoCompleteProductCategory = {};
  autoCompleteProductCategory.defaultValue = {
    id: urlParams.get('product_category_id'),
    name: urlParams.get('product_category_name'),
  };
  autoCompleteProductCategory.options = dataProductCategoryWebsite?.productCategoryWebsite;
  autoCompleteProductCategory.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteProductCategory.renderInput = params => (
    <TextField
      {...params}
      label="Kategori"
      variant="outlined"
      fullWidth
      margin="dense"
      helperText={errors?.category?.message}
      error={errors?.category && true}
    />
  );
  autoCompleteProductCategory.noOptionsText = 'Kategori tidak di temukan';
  autoCompleteProductCategory.size = 'small';
  autoCompleteProductCategory.onChange = (value, newValue) => {
    if (newValue?.id) {
      setValue('category', newValue?.id);
    } else {
      setValue('category', '');
    }
  };

  const { productWebsiteVariant } = dataProductVariant;
  const productWebsiteVariantLength = productWebsiteVariant.length;

  const firstVariant = productWebsiteVariant[0]?.first_variant?.variant?.name;
  const secondVariant = productWebsiteVariant[0]?.second_variant?.variant?.name;
  tableHeader = [secondVariant, firstVariant, ...tableHeader];

  return (
    <div>
      <Box mt={2} display="flex">
        <BackIcon history={history} location="/product-website" />
        <Typography
          align="center"
          variant="h6"
          gutterBottom
          style={{
            marginTop: '8px',
            flexGrow: 1,
          }}
        >
          Tambah Produk
        </Typography>
      </Box>

      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Upload Produk
        </Typography>
        <input type="file" ref={register} />
        <input type="file" ref={register} />
        <input type="file" ref={register} />
        <input type="file" ref={register} />
        <input type="file" ref={register} />
      </Paper>

      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Informasi Produk
        </Typography>
        <TextField
          inputRef={register}
          margin="dense"
          fullWidth
          size="small"
          variant="outlined"
          name="product_name"
          label="Nama Produk"
          helperText={errors?.product_name?.message}
          error={errors?.product_name && true}
        />
        {!loadingProductCategoryWebsite && <Autocomplete {...autoCompleteProductCategory} />}
        <TextField
          inputRef={register}
          fullWidth
          name="product_description"
          label="Deskripsi"
          multiline
          rows={4}
          margin="dense"
          variant="outlined"
          helperText={errors?.product_description?.message}
          error={errors?.product_description && true}
        />
        <TextField
          onChange={event => {
            const targetLength = event.target.value.length;
            if (targetLength > 0) {
              setValue('show_product_video', true);
            } else {
              setValue('show_product_video', false);
            }
          }}
          inputRef={register}
          margin="dense"
          fullWidth
          size="small"
          variant="outlined"
          name="product_video"
          label="Video Produk"
          helperText={errors?.product_video?.message}
          error={errors?.product_video && true}
        />
      </Paper>
      {productWebsiteVariantLength > 0 && (
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Tabel Variant
          </Typography>
          <TableContainer>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  {tableHeader.map((value, index) => {
                    return <TableCell key={index}>{value}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {productWebsiteVariant.map((value, index) => {
                  const { price, sku, stok } = value;

                  const firstVariantProduct = value?.firstVariant;
                  const firstVariantName = firstVariantProduct?.name;
                  const firstVariantID = firstVariantProduct?.id;

                  const secondVariantProduct = value?.secondVariant;
                  const secondVariantName = secondVariantProduct?.name;
                  const secondVariantID = secondVariantProduct?.id;

                  const priceName = `price[${index}]`;
                  const stokName = `stok[${index}]`;
                  const skuName = `sku[${index}]`;
                  const imageName = `image[${index}]`;
                  const statusName = `status[${index}]`;

                  const firstVariantIDHidden = `firstVariantID[${index}]`;
                  const secondVariantIDHidden = `secondVariantID[${index}]`;

                  const price_variant_error = errors[`price_variant_error_${index}`] || false;
                  const stock_variant_error = errors[`stock_variant_error_${index}`] || false;
                  const image_variant_error = errors[`image_variant_error_${index}`] || false;

                  return (
                    <TableRow key={index}>
                      {secondVariantName && (
                        <>
                          <TableCell>{secondVariantName}</TableCell>
                          <input
                            value={secondVariantID}
                            type="hidden"
                            ref={register}
                            name={secondVariantIDHidden}
                          />
                        </>
                      )}
                      <input
                        value={firstVariantID}
                        type="hidden"
                        ref={register}
                        name={firstVariantIDHidden}
                      />

                      <TableCell>{firstVariantName}</TableCell>
                      <TableCell>
                        <TextField
                          defaultValue={price}
                          inputRef={register}
                          size="small"
                          variant="outlined"
                          name={priceName}
                          label="Harga"
                        />
                        {price_variant_error && (
                          <FormHelperText error>{price_variant_error.message}</FormHelperText>
                        )}
                      </TableCell>
                      <TableCell>
                        <TextField
                          defaultValue={stok}
                          name={stokName}
                          size="small"
                          variant="outlined"
                          label="Stok"
                          inputRef={register}
                        />
                        {stock_variant_error && (
                          <FormHelperText error>{stock_variant_error.message}</FormHelperText>
                        )}
                      </TableCell>
                      <TableCell>
                        <TextField
                          defaultValue={sku}
                          name={skuName}
                          size="small"
                          variant="outlined"
                          label="Sku"
                          inputRef={register}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          ref={register}
                          name={imageName}
                          accept="image/*"
                          className={classes.inputFile}
                          id="icon-upload-edit-product-category-internal"
                          type="file"
                        />
                        {image_variant_error && (
                          <FormHelperText error>{image_variant_error.message}</FormHelperText>
                        )}
                      </TableCell>
                      <TableCell>
                        <Switch
                          name={statusName}
                          inputRef={register}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </TableCell>
                      <TableCell>
                        <Button color="primary" variant="contained">
                          Set Default
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Berat & Pengiriman
        </Typography>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box alignSelf="center">
            <FormLabel>Masukan berat</FormLabel>
          </Box>
          <Box display="flex" flexDirection="row">
            <Autocomplete {...autoCompleteWeight} style={{ marginRight: '8px', width: '100%' }} />
            <TextField
              helperText={errors?.weight?.message}
              error={errors?.weight && true}
              size="small"
              variant="outlined"
              margin="dense"
              name="weight"
              inputRef={register}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box alignSelf="center">
            <FormLabel>Masukan ukuran</FormLabel>
          </Box>
          <Box>
            <TextField
              helperText={errors?.width?.message}
              error={errors?.width && true}
              name="width"
              inputRef={register}
              label="Panjang"
              size="small"
              variant="outlined"
              margin="dense"
              style={{ marginRight: '8px' }}
            />
            <TextField
              helperText={errors?.wide?.message}
              error={errors?.wide && true}
              name="wide"
              inputRef={register}
              label="Lebar"
              size="small"
              variant="outlined"
              margin="dense"
              style={{ marginRight: '8px' }}
            />
            <TextField
              helperText={errors?.height?.message}
              error={errors?.height && true}
              name="height"
              inputRef={register}
              label="Tinggi"
              size="small"
              variant="outlined"
              margin="dense"
              style={{ marginRight: '8px' }}
            />
          </Box>
        </Box>
      </Paper>

      <Box display="flex" justifyContent="flex-end">
        <Button color="primary" variant="outlined" style={{ marginRight: '8px' }}>
          Batal
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
          Simpan
        </Button>
      </Box>
    </div>
  );
}
