import gql from 'graphql-tag';

export const statement = `   
mutation updateProductColor($payload:UpdateProductColor!) {
  updateProductColor(v:$payload) {
    id
    name
  }
}
`;

export default gql`
  ${statement}
`;
