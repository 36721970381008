import gql from 'graphql-tag';

export const statement = `
mutation changeRole($payload:ParamChangeRole!) {
    changeRole(v:$payload){
        status
    }
}
`;

export default gql`
  ${statement}
`;
