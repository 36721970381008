import gql from 'graphql-tag';

export const statement = `
mutation updateProductWebsite($payload:UpdateProductWebsiteInput!) {
    updateProductWebsite(v:$payload){
        status
    }
}
`;
export default gql`
  ${statement}
`;
