import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import StringUtils from 'utils/string';
import CREATE_DEBIT from '../../graphql/debit/create';
import Error from '../../component/error/index';
import FormDebit from './form';
import Validation from './validation';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function DebitRequest() {
  const classes = useStyles();
  const CreateDebitRequestSchema = yup.object().shape({
    ...Validation,
  });

  const [installmentTime, setInstallmentTime] = useState('');
  const history = useHistory();
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: CreateDebitRequestSchema,
    submitFocusError: false,
    nativeValidation: false,
  });
  const [createDebitAction, { loading, error: errorCreateDebitRequest }] = useMutation(
    CREATE_DEBIT,
    {
      onCompleted: async () => {
        history.push('/kasbon?status=berhasil');
      },
    },
  );

  useEffect(() => {
    register({ name: 'installment_time' }, { required: true });
  }, [register]);

  const onSubmitRegister = async dataCreateDebitRequest => {
    const { amount, note, installment_time: installmentTimePayload } = dataCreateDebitRequest;
    const parsedAmount = parseInt(amount, 10);
    const payloadCreateDebit = {
      due_date: moment()
        .add(installmentTimePayload, 'M')
        .format('YYYY-MM-DD HH:mm:ss'),
      id_debit_request: StringUtils.generateRandom(6),
      installment_time: installmentTimePayload,
      amount: parsedAmount,
      note,
    };
    await createDebitAction({
      variables: {
        payload: payloadCreateDebit,
      },
    });
  };

  const handleChangeInstallment = event => {
    const { value } = event.target;
    setValue('installment_time', value);
    setInstallmentTime(value);
  };

  if (errorCreateDebitRequest) {
    return <Error />;
  }
  return (
    <FormDebit
      classes={classes}
      errors={errors}
      register={register}
      loading={loading}
      handleSubmit={handleSubmit}
      onSubmitRegister={onSubmitRegister}
      installmentTime={installmentTime}
      handleChangeInstallment={handleChangeInstallment}
    />
  );
}
