import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import BANK_QUERY from 'graphql/bank/get';
import { useQuery } from '@apollo/react-hooks';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import { NetworkStatus } from 'apollo-client';
import Loading from 'component/loading';
import Error from 'component/error';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TableEmpty from 'component/TableEmpty/index';
import LoadMore from 'component/LoadMore';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import AddBank from './component/AddBank/index';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  headerWrapper: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export default function Bank() {
  const classes = useStyles();
  const [openSnackBar, setOpenSnackBar] = useState({
    visibility: false,
    message: '',
  });
  const [editData, setEditData] = useState(null);
  const [visibleAddBank, setVisibleAddBank] = useState(false);
  const { data, error, networkStatus, refetch, fetchMore } = useQuery(BANK_QUERY, {
    variables: {
      payload: {
        offset: 0,
        limit: 10,
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const bankLoading = networkStatus === NetworkStatus.loading;
  const bankRefetch = networkStatus === NetworkStatus.refetch;
  const bankFetchMore = networkStatus === NetworkStatus.fetchMore;

  if (bankLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  const bankCount = data?.bank?.count;
  const bankRows = data?.bank?.rows;

  return (
    <>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar.visibility}
        onClose={() => {
          setOpenSnackBar({
            visibility: false,
          });
        }}
        message={openSnackBar.message}
      />

      <AddBank
        setOpenSnackBar={setOpenSnackBar}
        data={editData}
        refetch={refetch}
        open={visibleAddBank}
        onClose={() => {
          setVisibleAddBank(false);
        }}
      />
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Bank</Typography>
        <Button
          onClick={() => {
            setEditData(null);
            setVisibleAddBank(true);
          }}
          color="primary"
          variant="contained"
        >
          Tambah
        </Button>
      </div>
      <TableContainer component={Paper}>
        {bankCount === 0 ? (
          <TableEmpty text="Data bank tidak ditemukan" />
        ) : (
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nama</TableCell>
                <TableCell>Bank</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bankRows.map(value => {
                const { id, name, code, updatedAt, createdAt } = value;
                const formatCreatedAt = moment(createdAt).format('YYYY-MM-DD');
                const formatUpdatedAt = moment(updatedAt).format('YYYY-MM-DD');
                return (
                  <TableRow>
                    <TableCell>{id}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>{code}</TableCell>
                    <TableCell>{formatCreatedAt}</TableCell>
                    <TableCell>{formatUpdatedAt}</TableCell>
                    <TableCell>
                      <Tooltip title="Edit Bank">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setVisibleAddBank(true);
                            setEditData({
                              id,
                              name,
                              code,
                            });
                          }}
                        >
                          <EditIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        {bankCount > 10 && (
          <LoadMore
            loading={bankRefetch || bankFetchMore}
            refetch={refetch}
            fetchMore={async () => {
              const bankRowsLength = bankRows.length;
              const payload = {
                offset: bankRowsLength,
              };
              await fetchMore({
                variables: {
                  payload,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  const newRows = fetchMoreResult.bank.rows;
                  const newCount = fetchMoreResult.bank.count;
                  return {
                    bank: {
                      ...prev.bank,
                      rows: [...prev.bank.rows, ...newRows],
                      count: newCount,
                    },
                  };
                },
              });
            }}
          />
        )}
      </TableContainer>
    </>
  );
}
