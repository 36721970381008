import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorImage from 'assets/svg/error.svg';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  image: {
    width: '300px',
    marginBottom: theme.spacing(1),
  },
}));

function Error() {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <img alt="error" className={classes.image} src={ErrorImage} />
      <Typography align="center" variant="h6">
        Sedang terjadi masalah. Mohon coba beberapa saat lagi
      </Typography>
    </div>
  );
}

export default Error;
