import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import VARIANT from 'graphql/product/variant/get';
import VARIANT_VALUE from 'graphql/product/variantValue/get';
import PRODUCT_CATEGORY_WEBSITE from 'graphql/product/category/website/index';
import CREATE_PRODUCT_WEBSITE from 'graphql/product/website/create';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import BackIcon from 'component/back/index';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import Error from 'component/error';
import { PRODUCT_WEBSITE } from 'constant/upload_path';
import axios from 'axios';
import Validation from './validation';
import ImageUtils from '../../../utils/Image';
import { SUCCESS_REDIRECT, FAILED_REDIRECT } from './constant';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export default function ProductWebsiteAdd() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tableHeader, setTableHeader] = useState(['Harga', 'Stok', 'Sku', 'Gambar', 'Status']);
  const [variant, setVariant] = useState([]);
  const [variantValue, setVariantValue] = useState([]);
  const [secondVariantValue, setSecondVariantValue] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const secondVariantValueExist = secondVariantValue.length > 0;

  const variantValueLength = variantValue.length;
  const variantValueExist = variantValueLength > 0;
  const {
    data: dataProductCategoryWebsite,
    loading: loadingProductCategoryWebsite,
    error: errorProductCategoryWebsite,
  } = useQuery(PRODUCT_CATEGORY_WEBSITE);

  const [createProductWebsite] = useMutation(CREATE_PRODUCT_WEBSITE, {
    onCompleted: () => {
      setLoading(false);
      window.location.href = SUCCESS_REDIRECT;
    },
    onError: () => {
      setLoading(false);
      window.location.href = FAILED_REDIRECT;
    },
  });

  const [
    getVariantAction,
    { data: dataVariant, loading: loadingVariant, error: errorVariant },
  ] = useLazyQuery(VARIANT);

  const [
    getVariantValueAction,
    { data: dataVariantValue, loading: loadingVariantValue, error: errorVariantValue },
  ] = useLazyQuery(VARIANT_VALUE);

  const [
    getSecondVariantAction,
    { data: dataSecondVariant, loading: loadingSecondVariant, error: errorSecondVariant },
  ] = useLazyQuery(VARIANT);

  const [
    getSecondVariantValueAction,
    {
      data: dataSecondVariantValue,
      loading: loadingSecondVariantValue,
      error: errorSecondVariantValue,
    },
  ] = useLazyQuery(VARIANT_VALUE);

  const AddProductSchema = yup.object().shape(Validation);
  const { register, handleSubmit, errors, setValue, getValues, setError, clearError } = useForm({
    validationSchema: AddProductSchema,
    mode: 'onChange',
    submitFocusError: false,
    nativeValidation: false,
  });

  const mergeVariant = [];
  const onSubmit = async dataSave => {
    const mergeVariantLength = mergeVariant.length;
    // At least one variant product is filled in
    if (mergeVariantLength === 0) {
      setOpenSnackBar(true);
      setSnackBarMessage('Minimal satu variant produk harus diisi');
      return false;
    }
    // Create validation on each of variant table item
    const schema = yup.object().shape({
      price: yup.number().min(100),
      stok: yup.number().min(1),
    });
    // Do validation on each variant item
    mergeVariant.map((value, index) => {
      const priceName = getValues(`price[${index}]`);
      const stokName = getValues(`stok[${index}]`);
      const imageName = getValues(`image[${index}]`);
      const imageEmpty = imageName.length === 0;
      schema
        .validate({ price: priceName, stok: stokName })
        .then(() => {
          if (imageEmpty) {
            const error = `image_variant_error_${index}`;
            setError(error, error, 'Upload file dibutuhkan');
            return false;
          }
        })
        .catch(() => {
          const errorStock = `stock_variant_error_${index}`;
          const errorPrice = `price_variant_error_${index}`;
          setError(errorStock, errorStock, 'Stok minimal 1');
          setError(errorPrice, errorPrice, 'Harga minimal 100');
          return false;
        });
      return null;
    });
    const fileUpload = dataSave.image;
    const fileUploadLength = fileUpload.length;
    const configRequest = ImageUtils.getHeader();
    const dataForm = new FormData();
    for (let i = 0; i < fileUploadLength; i += 1) {
      dataForm.append(`files[${i}]`, fileUpload[i][0]);
    }
    dataForm.append('image_path', PRODUCT_WEBSITE);
    const urlUpload = `${process.env.REACT_APP_SERVICE_MEDIA}/api/v1/uploadS3`;
    const imageVariant = [];
    setLoading(true);
    // Upload image of product website first
    let secureURLProductWebsite;
    try {
      const fileUploadProductWebsiteOnly = dataSave.image_product;
      const dataFormProductWebsite = new FormData();
      dataFormProductWebsite.append(`files[0]`, fileUploadProductWebsiteOnly[0]);
      dataFormProductWebsite.append('image_path', PRODUCT_WEBSITE);
      const uploadProductWebsite = await axios.post(
        urlUpload,
        dataFormProductWebsite,
        configRequest,
      );
      secureURLProductWebsite = uploadProductWebsite.data.data.secure_url;
      // Upload product variant image
      axios
        .post(urlUpload, dataForm, configRequest)
        .then(async res => {
          const { data } = res;
          data.data.map(value => {
            // Push variant image with the value of url obtained from cloudinary
            imageVariant.push(value.secure_url);
            return null;
          });
          const {
            category,
            product_name,
            product_video,
            product_description,
            firstVariantID,
            secondVariantID,
            price,
            stok,
            sku,
            status,
            weight_type,
            weight,
            height,
            wide,
            width,
          } = dataSave;

          const payloadSave = {};
          payloadSave.category = category;
          payloadSave.product_name = product_name;
          payloadSave.product_video = product_video;
          payloadSave.product_description = product_description;
          payloadSave.firstVariantID = firstVariantID;
          payloadSave.secondVariantID = secondVariantID;
          payloadSave.price = price;
          payloadSave.stok = stok;
          payloadSave.sku = sku;
          payloadSave.image = imageVariant;
          payloadSave.status = status;
          payloadSave.weight_type = weight_type;
          payloadSave.weight = weight;
          payloadSave.height = height;
          payloadSave.wide = wide;
          payloadSave.width = width;
          payloadSave.image_product = secureURLProductWebsite;
          await createProductWebsite({
            variables: {
              payload: payloadSave,
            },
          });
        })
        .catch(() => {
          setLoading(false);
          setOpenSnackBar(true);
          setSnackBarMessage('Ada kesalahan saat membat product website');
        });
    } catch (e) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage('Ada kesalahan saat mengupload gambar product website');
    }
  };

  useEffect(() => {
    register('weight_type');
    register('category');
    register('variant');
    register('variant_value');
    register('second_variant');
    register('second_variant_value');
    register('show_product_video');
  }, [register]);

  if (
    errorProductCategoryWebsite ||
    errorVariantValue ||
    errorVariant ||
    errorSecondVariant ||
    errorSecondVariantValue
  ) {
    return <Error />;
  }

  const autoCompleteWeight = {};
  autoCompleteWeight.options = [
    {
      name: 'Gram',
      value: 'gram',
    },
    {
      name: 'Kilogram',
      value: 'kilogram',
    },
  ];
  autoCompleteWeight.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteWeight.renderInput = params => (
    <TextField
      {...params}
      label="Tipe berat"
      variant="outlined"
      fullWidth
      margin="dense"
      helperText={errors?.weight_type?.message}
      error={errors?.weight_type && true}
    />
  );
  autoCompleteWeight.noOptionsText = 'Tipe berat tidak di temukan';
  autoCompleteWeight.size = 'small';
  autoCompleteWeight.onChange = (value, newValue) => {
    if (newValue?.name) {
      setValue('weight_type', newValue?.value);
    } else {
      setValue('weight_type', '');
    }
  };

  const autoCompleteProductCategory = {};
  autoCompleteProductCategory.options = dataProductCategoryWebsite?.productCategoryWebsite;
  autoCompleteProductCategory.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteProductCategory.renderInput = params => (
    <TextField
      {...params}
      label="Kategori"
      variant="outlined"
      fullWidth
      margin="dense"
      helperText={errors?.category?.message}
      error={errors?.category && true}
    />
  );
  autoCompleteProductCategory.noOptionsText = 'Kategori tidak di temukan';
  autoCompleteProductCategory.size = 'small';
  autoCompleteProductCategory.onChange = (value, newValue) => {
    if (newValue?.id) {
      setValue('category', newValue?.id);
    } else {
      setValue('category', '');
    }
  };

  /* First Variant */
  const autoCompleteVariant = {};
  autoCompleteVariant.options = dataVariant?.variant;
  autoCompleteVariant.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteVariant.renderInput = params => (
    <TextField
      {...params}
      label="Variant 1"
      variant="outlined"
      fullWidth
      margin="dense"
      helperText={errors?.product_color?.message}
      error={errors?.product_color && true}
    />
  );
  autoCompleteVariant.noOptionsText = 'Variant tidak di temukan';
  autoCompleteVariant.size = 'small';
  autoCompleteVariant.onChange = async (value, newValue) => {
    if (newValue?.name) {
      const id = parseInt(newValue.id, 10);
      await getVariantValueAction({
        variables: {
          payload: {
            id_variant: id,
          },
        },
      });
      setTableHeader([newValue?.name, ...tableHeader]);
      setValue('variant', newValue);
      setVariant(newValue);
    } else {
      setValue('variant', '');
      setVariant([]);
    }
  };

  const autoCompleteVariantValue = {};
  autoCompleteVariantValue.options = dataVariantValue?.variantValue;
  autoCompleteVariantValue.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteVariantValue.renderInput = params => (
    <TextField
      {...params}
      label="Variant value 1"
      variant="outlined"
      fullWidth
      margin="dense"
      helperText={errors?.product_color?.message}
      error={errors?.product_color && true}
    />
  );
  autoCompleteVariantValue.noOptionsText = 'Variant value tidak di temukan';
  autoCompleteVariantValue.size = 'small';
  autoCompleteVariantValue.onChange = (value, newValue) => {
    if (newValue.length > 0) {
      setValue('variant_value', newValue);
      setVariantValue(newValue);
    } else {
      setValue('variant_value', '');
    }
  };

  /* Second Variant */
  const autoCompleteSecondVariant = {};
  autoCompleteSecondVariant.options = dataSecondVariant?.variant;
  autoCompleteSecondVariant.getOptionDisabled = option => {
    return option === variant;
  };
  autoCompleteSecondVariant.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteSecondVariant.renderInput = params => (
    <TextField
      {...params}
      label="Variant 2"
      variant="outlined"
      fullWidth
      margin="dense"
      helperText={errors?.product_color?.message}
      error={errors?.product_color && true}
    />
  );
  autoCompleteSecondVariant.noOptionsText = 'Variant tidak di temukan';
  autoCompleteSecondVariant.size = 'small';
  autoCompleteSecondVariant.onChange = async (value, newValue) => {
    if (newValue?.name) {
      const id = parseInt(newValue.id, 10);
      await getSecondVariantValueAction({
        variables: {
          payload: {
            id_variant: id,
          },
        },
      });
      setTableHeader([newValue?.name, ...tableHeader]);
      setValue('second_variant', newValue);
    } else {
      setValue('second_variant', '');
    }
  };

  const autoCompleteSecondVariantValue = {};
  autoCompleteSecondVariantValue.options = dataSecondVariantValue?.variantValue;
  autoCompleteSecondVariantValue.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteSecondVariantValue.renderInput = params => (
    <TextField
      {...params}
      label="Variant value 2"
      variant="outlined"
      fullWidth
      margin="dense"
      helperText={errors?.product_color?.message}
      error={errors?.product_color && true}
    />
  );
  autoCompleteSecondVariantValue.noOptionsText = 'Variant value tidak di temukan';
  autoCompleteSecondVariantValue.size = 'small';
  autoCompleteSecondVariantValue.onChange = (value, newValue) => {
    if (newValue.length > 0) {
      setValue('second_variant_value', newValue);
      setSecondVariantValue(newValue);
    } else {
      setValue('second_variant_value', '');
    }
  };

  variantValue.map(valueOfVariantValue => {
    const { name: nameValueOfVariantValue, id: idValueOfVariantValue } = valueOfVariantValue;
    const firstVariantReturn = {
      firstVariant: {
        name: nameValueOfVariantValue,
        id: idValueOfVariantValue,
      },
    };
    if (secondVariantValueExist) {
      secondVariantValue.map(valueOfSecondVariantValue => {
        const { id, id_variant, name } = valueOfSecondVariantValue;
        mergeVariant.push({
          ...firstVariantReturn,
          secondVariant: {
            id,
            id_variant,
            name,
          },
        });
        return null;
      });
    } else {
      mergeVariant.push({
        ...firstVariantReturn,
        secondVariant: {},
      });
    }
    return null;
  });

  const errorAddVariant = errors?.add_variant;
  return (
    <div>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message={snackBarMessage}
      />
      <Box mt={2} display="flex">
        <BackIcon history={history} location="/product-website" />
        <Typography
          align="center"
          variant="h6"
          gutterBottom
          style={{
            marginTop: '8px',
            flexGrow: 1,
          }}
        >
          Tambah Produk
        </Typography>
      </Box>

      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Upload Produk
        </Typography>
        <input type="file" ref={register} name="image_product" />
      </Paper>

      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Informasi Produk
        </Typography>
        <TextField
          inputRef={register}
          margin="dense"
          fullWidth
          size="small"
          variant="outlined"
          name="product_name"
          label="Nama Produk"
          helperText={errors?.product_name?.message}
          error={errors?.product_name && true}
        />
        {!loadingProductCategoryWebsite && <Autocomplete {...autoCompleteProductCategory} />}
        <TextField
          inputRef={register}
          fullWidth
          name="product_description"
          label="Deskripsi"
          multiline
          rows={4}
          margin="dense"
          variant="outlined"
          helperText={errors?.product_description?.message}
          error={errors?.product_description && true}
        />
        <TextField
          onChange={event => {
            const targetLength = event.target.value.length;
            if (targetLength > 0) {
              setValue('show_product_video', true);
            } else {
              setValue('show_product_video', false);
            }
          }}
          inputRef={register}
          margin="dense"
          fullWidth
          size="small"
          variant="outlined"
          name="product_video"
          label="Video Produk"
          helperText={errors?.product_video?.message}
          error={errors?.product_video && true}
        />
      </Paper>

      <Paper className={classes.paper}>
        <Box mb={2} display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            <Typography variant="h6">Variant Produk</Typography>
            <Typography variant="body2" color="textSecondary">
              Tambahkan varian seperti warna, ukuran, atau lainnya. Pilih maksimum 2 tipe varian.
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={async () => {
                const productName = getValues('product_name');
                const productDescription = getValues('product_description');
                const productCategory = getValues('category');
                if (!productName || !productDescription || !productCategory) {
                  setError('add_variant', 'add_variant', 'some_message');
                } else {
                  const previousVariant = getValues('variant');
                  if (previousVariant) {
                    await getSecondVariantAction();
                  } else {
                    await getVariantAction();
                  }
                  clearError('add_variant');
                }
              }}
              color="primary"
              variant="contained"
            >
              Tambah Variant
            </Button>
          </Box>
        </Box>
        {errorAddVariant && (
          <FormHelperText error>
            Nama Produk, kategori dan deskripsi harus di lengkapi
          </FormHelperText>
        )}
        {!loadingVariant && dataVariant?.variant && <Autocomplete {...autoCompleteVariant} />}
        {!loadingVariantValue && dataVariantValue?.variantValue && (
          <Autocomplete multiple {...autoCompleteVariantValue} />
        )}

        <Box mt={2}>
          {!loadingSecondVariant && dataSecondVariant?.variant && (
            <Autocomplete {...autoCompleteSecondVariant} />
          )}
          {!loadingSecondVariantValue && dataSecondVariantValue?.variantValue && (
            <Autocomplete multiple {...autoCompleteSecondVariantValue} />
          )}
        </Box>
      </Paper>
      {variantValueExist && (
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Tabel Variant
          </Typography>
          <TableContainer>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  {tableHeader.map((value, index) => {
                    return <TableCell key={index}>{value}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {mergeVariant.map((value, index) => {
                  const firstVariant = value?.firstVariant;
                  const firstVariantName = firstVariant?.name;
                  const firstVariantID = firstVariant?.id;

                  const secondVariant = value?.secondVariant;
                  const secondVariantName = secondVariant?.name;
                  const secondVariantID = secondVariant?.id;

                  const priceName = `price[${index}]`;
                  const stokName = `stok[${index}]`;
                  const skuName = `sku[${index}]`;
                  const imageName = `image[${index}]`;
                  const statusName = `status[${index}]`;

                  const firstVariantIDHidden = `firstVariantID[${index}]`;
                  const secondVariantIDHidden = `secondVariantID[${index}]`;

                  const price_variant_error = errors[`price_variant_error_${index}`] || false;
                  const stock_variant_error = errors[`stock_variant_error_${index}`] || false;
                  const image_variant_error = errors[`image_variant_error_${index}`] || false;

                  return (
                    <TableRow key={index}>
                      {secondVariantName && (
                        <>
                          <TableCell>{secondVariantName}</TableCell>
                          <input
                            value={secondVariantID}
                            type="hidden"
                            ref={register}
                            name={secondVariantIDHidden}
                          />
                        </>
                      )}
                      <input
                        value={firstVariantID}
                        type="hidden"
                        ref={register}
                        name={firstVariantIDHidden}
                      />

                      <TableCell>{firstVariantName}</TableCell>
                      <TableCell>
                        <TextField
                          inputRef={register}
                          size="small"
                          variant="outlined"
                          name={priceName}
                          label="Harga"
                        />
                        {price_variant_error && (
                          <FormHelperText error>{price_variant_error.message}</FormHelperText>
                        )}
                      </TableCell>
                      <TableCell>
                        <TextField
                          name={stokName}
                          size="small"
                          variant="outlined"
                          label="Stok"
                          inputRef={register}
                        />
                        {stock_variant_error && (
                          <FormHelperText error>{stock_variant_error.message}</FormHelperText>
                        )}
                      </TableCell>
                      <TableCell>
                        <TextField
                          name={skuName}
                          size="small"
                          variant="outlined"
                          label="Sku"
                          inputRef={register}
                        />
                      </TableCell>
                      <TableCell>
                        <input
                          ref={register}
                          name={imageName}
                          accept="image/*"
                          className={classes.inputFile}
                          id="icon-upload-edit-product-category-internal"
                          type="file"
                        />
                        {image_variant_error && (
                          <FormHelperText error>{image_variant_error.message}</FormHelperText>
                        )}
                      </TableCell>
                      <TableCell>
                        <Switch
                          name={statusName}
                          inputRef={register}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Berat & Pengiriman
        </Typography>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box alignSelf="center">
            <FormLabel>Masukan berat</FormLabel>
          </Box>
          <Box display="flex" flexDirection="row">
            <Autocomplete {...autoCompleteWeight} style={{ marginRight: '8px', width: '100%' }} />
            <TextField
              helperText={errors?.weight?.message}
              error={errors?.weight && true}
              size="small"
              variant="outlined"
              margin="dense"
              name="weight"
              inputRef={register}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box alignSelf="center">
            <FormLabel>Masukan ukuran</FormLabel>
          </Box>
          <Box>
            <TextField
              helperText={errors?.width?.message}
              error={errors?.width && true}
              name="width"
              inputRef={register}
              label="Panjang"
              size="small"
              variant="outlined"
              margin="dense"
              style={{ marginRight: '8px' }}
            />
            <TextField
              helperText={errors?.wide?.message}
              error={errors?.wide && true}
              name="wide"
              inputRef={register}
              label="Lebar"
              size="small"
              variant="outlined"
              margin="dense"
              style={{ marginRight: '8px' }}
            />
            <TextField
              helperText={errors?.height?.message}
              error={errors?.height && true}
              name="height"
              inputRef={register}
              label="Tinggi"
              size="small"
              variant="outlined"
              margin="dense"
              style={{ marginRight: '8px' }}
            />
          </Box>
        </Box>
      </Paper>

      <Box display="flex" justifyContent="flex-end">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Button color="primary" variant="outlined" style={{ marginRight: '8px' }}>
              Batal
            </Button>
            <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
              Simpan
            </Button>
          </>
        )}
      </Box>
    </div>
  );
}
