import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-client';
import PRODUCT_CATEGORY_INTERNAL_QUERY from 'graphql/product/category/internal/index';
import Loading from 'component/loading';
import Error from 'component/error';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import empty from 'assets/svg/empty.svg';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert/Alert';
import LoadMore from '../../../../component/LoadMore';
import Add from './add';
import Edit from './edit';
import URL from '../../../../utils/url';

const RenderIndex = ({
  register,
  handleSubmit,
  onSubmit,
  classes,
  data,
  fetchMore,
  loading,
  refetch,
  history,
  statusPage,
}) => {
  const dataProductCategoryInternal = data.productCategoryInternal;
  const dataProductCategoryInternalLength = dataProductCategoryInternal.length;
  return dataProductCategoryInternalLength !== 0 ? (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Produk Kategori Internal</Typography>
        <Button
          onClick={() => {
            history.push('/product-category-internal/tambah');
          }}
          color="primary"
          variant="contained"
        >
          Tambah
        </Button>
      </div>

      <Grid container justify="center" className={classes.searchWrapper}>
        <TextField
          className={classes.searchField}
          size="small"
          variant="outlined"
          inputRef={register}
          name="product_category_internal"
          label="Cari Kategori Internal"
        />
        <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
          Cari
        </Button>
      </Grid>
      {statusPage === 'berhasil' && (
        <Alert
          onClose={() => {
            history.push('/product-category-internal');
          }}
          variant="filled"
          severity="success"
          className={classes.alertWrapper}
        >
          Aksi Pada produk berhasil
        </Alert>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Kategori</TableCell>
              <TableCell>Gambar</TableCell>
              <TableCell>Tanggal Tambah</TableCell>
              <TableCell>Tanggal Ubah</TableCell>
              <TableCell>Ubah</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProductCategoryInternal.map(row => {
              const { name, id, media, createdAt, updatedAt } = row;
              const { url } = media[0];
              const idTable = `${id}`;
              return (
                <TableRow key={idTable}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>
                    <img
                      src={url}
                      style={{ width: '300px' }}
                      alt="image_category_product_internal"
                    />
                  </TableCell>
                  <TableCell>{moment(createdAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell>{moment(updatedAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => {
                        const payload = {
                          id,
                          name,
                          media: JSON.stringify(media),
                        };
                        const queryEdit = URL.makeQueryString(payload);
                        history.push(`product-category-internal/ubah${queryEdit}`);
                      }}
                    >
                      <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <LoadMore
          loading={loading}
          refetch={refetch}
          fetchMore={async () => {
            const payload = {
              offset: dataProductCategoryInternalLength,
            };
            await fetchMore({
              variables: {
                payload,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  ...prev,
                  productCategoryInternal: [
                    ...prev.productCategoryInternal,
                    ...fetchMoreResult.productCategoryInternal,
                  ],
                };
              },
            });
          }}
        />
      </TableContainer>
    </>
  ) : (
    <Grid direction="column" container alignItems="center">
      <img src={empty} alt="empty_img" className={classes.imageEmpty} />
      <Typography variant="h5" gutterBottom>
        Tidak ada data produk kategori internal
      </Typography>
      <Button
        onClick={() => {
          history.push('/product-category-internal/tambah');
        }}
        color="primary"
        variant="contained"
      >
        Tambah
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageEmpty: {
    width: '500px',
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loadMoreButtonWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  searchWrapper: {
    paddingBottom: theme.spacing(2),
  },
  searchField: {
    paddingRight: theme.spacing(1),
  },
  alertWrapper: {
    marginBottom: theme.spacing(1),
  },
}));

export default function ProductCategoryInternal() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split('/');
  const { search } = location;
  const statusPage = URL.queryString(search, 'status');
  const { register, handleSubmit } = useForm({
    submitFocusError: false,
    nativeValidation: false,
  });

  const { data, error, fetchMore, refetch, networkStatus } = useQuery(
    PRODUCT_CATEGORY_INTERNAL_QUERY,
    {
      variables: {
        payload: {
          offset: 0,
          limit: 10,
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );
  const loadingInitial = networkStatus === NetworkStatus.loading;
  const onSubmit = async dataOnSubmit => {
    const { product_category_internal: productCategoryInternal } = dataOnSubmit;
    await {
      payload: {
        name: productCategoryInternal,
      },
    };
  };

  if (loadingInitial) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (path[2] === 'tambah') {
    // Uses from component is edit set to false
    return <Add />;
  }

  if (path[2] === 'ubah') {
    return <Edit refetch={refetch} />;
  }
  return (
    <RenderIndex
      statusPage={statusPage}
      history={history}
      loading={networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.fetchMore}
      classes={classes}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      refetch={refetch}
      fetchMore={fetchMore}
      data={data}
    />
  );
}
