import * as yup from 'yup';

const errorMustBeFilledInMesssage = 'Harga harus diisi. Isi 0 jika tidak membutuhkan';
const RegexNumber = /^[0-9]*$/;
const errorMustBeNumberMesssage = 'Harga harus berupa angka';

export default {
  name: yup.string().required('Nama produk harus diisi'),
  category: yup.string().required('Kategori harus diisi'),
  product_material: yup.string().required('Material Produk harus diisi'),
  product_color: yup.string().required('Warna Produk harus diisi'),
  description: yup.string().required('Deskripsi produk harus diisi'),
  S_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PendekS_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PanjangS_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  M_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PendekM_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PanjangM_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  L_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PendekL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PanjangL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  XL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PendekXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PanjangXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  XXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PendekXXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PanjangXXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  ThreeXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PendekThreeXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PanjangThreeXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  FourthXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PendekFourthXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PanjangFourthXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  FifthXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PendekFifthXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PanjangFifthXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  SixthXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PendekSixthXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PanjangSixthXL_Price: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
};
