import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import CardMedia from '@material-ui/core/CardMedia';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DetailSizeIcon from '@material-ui/icons/EventNote';
import SpkIcon from '@material-ui/icons/Work';
import InvoiceIcon from '@material-ui/icons/Receipt';
import ArrowBack from 'assets/svg/arrow_back.svg';
import empty from 'assets/svg/empty.svg';
import PRODUCT_CONSTANT from '../../../constant/product';
import RenderCarousel from '../../../component/carousel/index';

const RenderArrowBack = ({ classes, onClick }) => {
  return (
    <img
      className={classes.backImage}
      alt="back-arrow"
      src={ArrowBack}
      width={20}
      onClick={() => {
        onClick();
      }}
    />
  );
};
const RenderDetailOrderItem = ({
  orderData,
  classes,
  activeView,
  setActiveView,
  setActiveData,
  activeData,
  getOrderAction,
}) => {
  if (activeView === 'DETAIL_ORDER') {
    return (
      <>
        <DialogTitle>Detail Order</DialogTitle>
        <DialogContent>
          {orderData.map((value, index) => {
            const {
              order_no: orderNo,
              createdAt,
              OrderStatus,
              Spk,
              Product,
              // eslint-disable-next-line camelcase
              S_Qty,
              // eslint-disable-next-line camelcase
              PendekS_Qty,
              // eslint-disable-next-line camelcase
              PanjangS_Qty,
              // eslint-disable-next-line camelcase
              M_Qty,
              // eslint-disable-next-line camelcase
              PendekM_Qty,
              // eslint-disable-next-line camelcase
              PajangM_Qty,
              // eslint-disable-next-line camelcase
              L_Qty,
              // eslint-disable-next-line camelcase
              PendekL_Qty,
              // eslint-disable-next-line camelcase
              PanjangL_Qty,
              // eslint-disable-next-line camelcase
              XL_Qty,
              // eslint-disable-next-line camelcase
              PendekXL_Qty,
              // eslint-disable-next-line camelcase
              PanjangXL_Qty,
              // eslint-disable-next-line camelcase
              XXL_Qty,
              // eslint-disable-next-line camelcase
              PendekXXL_Qty,
              // eslint-disable-next-line camelcase
              PanjangXXL_Qty,
              // eslint-disable-next-line camelcase
              ThreeXL_Qty,
              // eslint-disable-next-line camelcase
              PendekThreeXL_Qty,
              // eslint-disable-next-line camelcase
              PanjangThreeXL_Qty,
              // eslint-disable-next-line camelcase
              FourthXL_Qty,
              // eslint-disable-next-line camelcase
              PendekFourthXL_Qty,
              // eslint-disable-next-line camelcase
              PanjangFourthXL_Qty,
              // eslint-disable-next-line camelcase
              FifthXL_Qty,
              // eslint-disable-next-line camelcase
              PendekFifthXL_Qty,
              // eslint-disable-next-line camelcase
              PanjangFifthXL_Qty,
              // eslint-disable-next-line camelcase
              SixthXL_Qty,
              // eslint-disable-next-line camelcase
              PendekSixthXL_Qty,
              // eslint-disable-next-line camelcase
              PanjangSixthXL_Qty,
            } = value;
            // eslint-disable-next-line camelcase
            const spkNumber = Spk[0]?.spk_no;
            const invoiceData = Spk[0]?.Invoice;
            const productName = Product.rows[0]?.name;
            const productDescription = Product.rows[0]?.description;
            const imageSource = value.Product.rows[0].media[0].url;
            const orderStatusName = OrderStatus?.name;
            const orderCreatedAt = moment(createdAt).format('DD MMMM YYYY');
            const spkLength = Spk.length;

            return (
              <Paper elevation={3} className={classes.orderItemWrapper}>
                <Grid container direction="row">
                  <Grid item xs={12} sm={3}>
                    <CardMedia
                      key={index}
                      src={imageSource}
                      image={imageSource}
                      className={classes.media}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9} className={classes.rightContent}>
                    <Typography variant="h6" gutterBottom>
                      {orderNo}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {spkNumber}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {productName}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {productDescription}
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                      {orderCreatedAt}
                    </Typography>
                    <Chip
                      color="primary"
                      label={orderStatusName}
                      className={classes.chipOrderStatus}
                    />
                    {invoiceData && (
                      <Tooltip title="Detail Invoice">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setActiveView('DETAIL_INVOICE');
                            setActiveData({
                              detailInvoice: {
                                orderNo,
                                item: invoiceData,
                              },
                            });
                          }}
                        >
                          <InvoiceIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {spkLength > 0 && (
                      <Tooltip title="Detail SPK">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setActiveView('DETAIL_SPK');
                            setActiveData({
                              detailSPK: {
                                orderNo,
                                item: Spk,
                              },
                            });
                          }}
                        >
                          <SpkIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Detail Ukuran">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setActiveView('DETAIL_SIZE');
                          setActiveData({
                            detailSize: {
                              orderNo,
                              S_Qty,
                              PendekS_Qty,
                              PanjangS_Qty,
                              M_Qty,
                              PendekM_Qty,
                              PajangM_Qty,
                              L_Qty,
                              PendekL_Qty,
                              PanjangL_Qty,
                              XL_Qty,
                              PendekXL_Qty,
                              PanjangXL_Qty,
                              XXL_Qty,
                              PendekXXL_Qty,
                              PanjangXXL_Qty,
                              ThreeXL_Qty,
                              PendekThreeXL_Qty,
                              PanjangThreeXL_Qty,
                              FourthXL_Qty,
                              PendekFourthXL_Qty,
                              PanjangFourthXL_Qty,
                              FifthXL_Qty,
                              PendekFifthXL_Qty,
                              PanjangFifthXL_Qty,
                              SixthXL_Qty,
                              PendekSixthXL_Qty,
                              PanjangSixthXL_Qty,
                            },
                          });
                        }}
                      >
                        <DetailSizeIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </DialogContent>
        <DialogActions style={{ paddingBottom: '16px' }}>
          <Button fullWidth color="primary" variant="contained" onClick={getOrderAction}>
            Muat lebih
          </Button>
        </DialogActions>
      </>
    );
  }
  if (activeView === 'DETAIL_SPK') {
    const { detailSPK } = activeData;
    const {
      spk_no: spkNo,
      down_payment: downPayment,
      is_confirmed: isConfirmed,
      note,
      sub_total: subTotal,
      paid,
      media,
    } = detailSPK.item[0];
    return (
      <>
        <DialogTitle>Detail Order {detailSPK.orderNo}</DialogTitle>
        <DialogContent>
          <RenderArrowBack
            classes={classes}
            onClick={() => {
              setActiveView('DETAIL_ORDER');
            }}
          />

          <Grid container direction="row">
            <Grid item xs={12} sm={3}>
              <RenderCarousel data={media} classes={classes} />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Grid container direction="row">
                <Grid item xs={6}>
                  No SPK
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    {spkNo}
                  </Typography>
                </Grid>
              </Grid>

              {note && (
                <Grid container direction="row">
                  <Grid item xs={6}>
                    Catatan
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" gutterBottom>
                      {note}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container direction="row">
                <Grid item xs={6}>
                  Sudah Lunas
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    {paid}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container direction="row">
                <Grid item xs={6}>
                  Sudah di konfirmasi
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    {isConfirmed}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container direction="row">
                <Grid item xs={6}>
                  Sub Total
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    {subTotal}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container direction="row">
                <Grid item xs={6}>
                  Down Payment
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    {downPayment}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container direction="row">
                <Grid item xs={6}>
                  Sisa Bayar
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    {subTotal - downPayment}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  }

  if (activeView === 'DETAIL_INVOICE') {
    const { detailInvoice } = activeData;
    const { item } = detailInvoice;
    const {
      billed_amount: billedAmount,
      pay_amount: payAmount,
      return_amount: returnAmount,
      createdAt,
    } = item;

    return (
      <>
        <DialogTitle>Detail Order {detailInvoice.orderNo}</DialogTitle>
        <DialogContent>
          <RenderArrowBack
            classes={classes}
            onClick={() => {
              setActiveView('DETAIL_ORDER');
            }}
          />
          <DialogContentText>
            <Grid container direction="row">
              <Grid item xs={6}>
                Jumlah Tagihan
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  {billedAmount}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row">
              <Grid item xs={6}>
                Jumlah Dibayarkan
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  {payAmount}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row">
              <Grid item xs={6}>
                Kembalian
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  {returnAmount}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row">
              <Grid item xs={6}>
                Tanggal Invoice
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  {moment(createdAt).format('DD MMMM YYYY hh:mm A')}
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </>
    );
  }

  if (activeView === 'DETAIL_SIZE') {
    const { detailSize } = activeData;
    return (
      <>
        <DialogTitle>Detail Order {detailSize.orderNo}</DialogTitle>
        <DialogContent>
          <RenderArrowBack
            classes={classes}
            onClick={() => {
              setActiveView('DETAIL_ORDER');
            }}
          />
          <DialogContentText>
            <Grid container direction="row">
              {PRODUCT_CONSTANT.size.map(value => {
                const { name, label } = value;
                return (
                  <Grid item xs={4} className={classes.wrapperSizeItem}>
                    <div>{label}</div>
                    <div>{detailSize[name]}</div>
                  </Grid>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </>
    );
  }
  return null;
};

const useStyles = makeStyles(theme => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
  },
  backImage: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  orderItemWrapper: {
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  rightContent: {
    paddingLeft: theme.spacing(2),
  },
  chipOrderStatus: {
    marginLeft: theme.spacing(1),
  },
  imageEmpty: {
    maxWidth: '100%',
  },
  wrapperEmpty: {
    padding: theme.spacing(4),
  },
  wrapperSizeItem: {
    marginBottom: theme.spacing(1),
  },
}));

function DetailOrder({ open, handleClose, data, getOrderAction }) {
  const classes = useStyles();
  const [activeView, setActiveView] = useState('DETAIL_ORDER');
  const [activeData, setActiveData] = useState({});
  const orderData = data.order;
  const orderDataLength = orderData.length;
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      {orderDataLength > 0 ? (
        <>
          <RenderDetailOrderItem
            getOrderAction={getOrderAction}
            classes={classes}
            orderData={orderData}
            activeView={activeView}
            setActiveView={setActiveView}
            setActiveData={setActiveData}
            activeData={activeData}
          />
        </>
      ) : (
        <div className={classes.wrapperEmpty}>
          <img src={empty} alt="empty_img" className={classes.imageEmpty} />
          <Typography variant="h6" align="center">
            Detail order tidak di temukan
          </Typography>
        </div>
      )}
    </Dialog>
  );
}

export default React.memo(DetailOrder);
