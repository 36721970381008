import React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  media: {
    height: 700,
    backgroundSize: 'contain',
  },
}));

function RenderImageAfterEdit({ open, handleClose, imageAfterEdit }) {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Gambar finish</DialogTitle>
      <DialogContent>
        {imageAfterEdit.map((item, index) => {
          return (
            <CardMedia key={index} className={classes.media} src={item.url} image={item.url} />
          );
        })}
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(RenderImageAfterEdit);
