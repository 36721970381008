import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Form({
  handleSubmit,
  onSubmitRegister,
  classes,
  errors,
  handleChangeDayOffType,
  dataDayOffType,
  dayOffType,
  register,
  todayDate,
  loading,
}) {
  return (
    <Paper elevation={3} className={classes.wrapper}>
      <Grid container justify="center">
        <Grid item xs={8}>
          <Typography variant="h6" gutterBottom align="left">
            Ajukan Cuti
          </Typography>
          <FormControl className={classes.formControl} error={Boolean(errors.day_off_type)}>
            <Select
              value={dayOffType}
              inputRef={register}
              name="day_off_type"
              variant="outlined"
              labelId="slct-day-off-type"
              onChange={handleChangeDayOffType}
              displayEmpty
            >
              <MenuItem value="">
                <em>Silahkan Pilih</em>
              </MenuItem>
              {dataDayOffType.dayOffType.map(valueInstallment => {
                const { name, id } = valueInstallment;
                return (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>Tipe cuti yang ingin di ambil</FormHelperText>
            <FormHelperText>{errors.day_off_type && errors.day_off_type.message}</FormHelperText>
          </FormControl>
          <div className={classes.dateWrapper}>
            <TextField
              /* eslint-disable-next-line camelcase */
              error={errors?.date_from_day_off && true}
              /* eslint-disable-next-line camelcase */
              helperText={errors?.date_from_day_off?.message}
              size="small"
              fullWidth
              inputRef={register}
              className={classes.dateFrom}
              id="date_from_day_off"
              name="date_from_day_off"
              label="Dari"
              type="date"
              defaultValue={todayDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={classes.dateWrapper}>
            <TextField
              /* eslint-disable-next-line camelcase */
              error={errors?.date_to_day_off && true}
              /* eslint-disable-next-line camelcase */
              helperText={errors?.date_to_day_off?.message}
              size="small"
              fullWidth
              inputRef={register}
              className={classes.dateTo}
              id="date_to_day_off"
              name="date_to_day_off"
              label="Ke"
              type="date"
              defaultValue={todayDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <TextField
            /* eslint-disable-next-line camelcase */
            error={errors?.txt_reason && true}
            /* eslint-disable-next-line camelcase */
            helperText={errors?.txt_reason?.message}
            inputRef={register}
            margin="dense"
            id="txt_reason"
            label="Alasan"
            name="txt_reason"
            size="small"
            variant="outlined"
            fullWidth
          />
          <Box mt={2} mb={2}>
            <Button
              onClick={handleSubmit(onSubmitRegister)}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              id="btn-request-dayoff"
            >
              {loading ? <CircularProgress color="secondary" /> : 'Ajukan'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
