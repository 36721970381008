import gql from 'graphql-tag';

export const statement = `
query walletTopUpRequest($payload:ParamWalletTopUpRequestQuery) {
  walletTopUpRequest(v:$payload) {
    count
    rows{
      id
      status
      amount
      image_done_proof
      createdAt
      updatedAt
      media{
        secure_url
      }
      account{
        name
        email
        phone
      }        
    }
  }
}
`;

export default gql`
  ${statement}
`;
