import gql from 'graphql-tag';

export const statement = `
query wallet($payload:ParamWalletQuery){
  wallet(v:$payload){
    count
    rows{  
      id_account
      account{
        name
        email
        phone
      }     
      amount
      createdAt
      updatedAt
    }
  }
}
`;

export default gql`
  ${statement}
`;
