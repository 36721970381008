import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import React from 'react';

function RenderModalAsDetail({ openModal, handleCloseModal, classes, modalStyle, content }) {
  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <div style={modalStyle} className={classes.paper}>
        <h2 id="modal-detail-work-order">Detail</h2>
        {content.map((value, index) => {
          const keyValue = Object.keys(value);
          return (
            <List dense key={index}>
              <ListItem>
                <ListItemText primary={`${keyValue} : ${value[keyValue]}`} />
              </ListItem>
            </List>
          );
        })}
      </div>
    </Modal>
  );
}

export default React.memo(RenderModalAsDetail);
