import React, { useEffect } from 'react';
import WALLET_QUERY from 'graphql/wallet/get';
import { useQuery } from '@apollo/react-hooks';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import { NetworkStatus } from 'apollo-client';
import CUSTOMER_QUERY from 'graphql/account/customer';
import Loading from 'component/loading';
import Error from 'component/error';
import Money from 'utils/money';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import DetailsIcon from '@material-ui/icons/Details';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import TableEmpty from 'component/TableEmpty/index';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  imageEmpty: {
    maxWidth: '100%',
  },
  emptyWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function Wallet() {
  const classes = useStyles();
  const history = useHistory();
  const payloadCustomer = {
    offset: 0,
    limit: 10,
  };
  const { data: dataCustomer, loading: loadingCustomer, refetch: refetchCustomer } = useQuery(
    CUSTOMER_QUERY,
    {
      variables: {
        payload: payloadCustomer,
      },
      fetchPolicy: 'no-cache',
    },
  );

  const { register, setValue } = useForm({
    submitFocusError: false,
    nativeValidation: false,
  });

  const { data, error, networkStatus, refetch } = useQuery(WALLET_QUERY, {
    variables: {
      payload: {
        offset: 0,
        limit: 10,
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const walletLoading = networkStatus === NetworkStatus.loading;

  useEffect(() => {
    register('customer');
  }, [register]);

  if (walletLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const walletCount = data?.wallet?.count;
  const walletRows = data?.wallet?.rows;

  const autoCompleteCustomerProperty = {};
  autoCompleteCustomerProperty.fullWidth = true;
  autoCompleteCustomerProperty.options = dataCustomer?.customer;
  autoCompleteCustomerProperty.getOptionLabel = option => {
    return `${option.name}-${option.email}`;
  };
  autoCompleteCustomerProperty.renderInput = params => (
    <TextField {...params} label="Customer" variant="outlined" margin="dense" />
  );
  autoCompleteCustomerProperty.noOptionsText = 'Customer tidak di temukan';
  autoCompleteCustomerProperty.size = 'small';
  autoCompleteCustomerProperty.onChange = (value, newValue) => {
    if (newValue?.id) {
      const idValue = newValue?.id;
      refetch({
        payload: {
          id_account: idValue,
        },
      });
      setValue('customer', idValue);
    } else {
      setValue('customer', '');
    }
  };
  autoCompleteCustomerProperty.onInputChange = event => {
    const name = event?.target?.value;
    // close button is clicked
    if (name !== 0) {
      refetchCustomer({
        payload: {
          name,
        },
      });
      refetch({
        payload: {
          offset: 0,
          limit: 10,
        },
      });
    }
  };

  return (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Wallet</Typography>
        <Grid container>
          {!loadingCustomer && <Autocomplete {...autoCompleteCustomerProperty} />}
        </Grid>
      </div>
      <TableContainer component={Paper}>
        {walletCount === 0 ? (
          <>
            <TableEmpty text="Wallet tidak di temukan" />
          </>
        ) : (
          <>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Nama</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Telepon</TableCell>
                  <TableCell>Wallet</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Updated At</TableCell>
                  <TableCell>Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {walletRows.map(() => {
                  const idAccount = walletRows[0].id_account;
                  const accountName = walletRows[0].account[0].name;
                  const accountEmail = walletRows[0].account[0].email;
                  const accountPhone = walletRows[0].account[0].phone;
                  const formatCreatedAt = moment(walletRows[0].createdAt).format('YYYY-MM-DD');
                  const formatUpdatedAt = moment(walletRows[0].updatedAt).format('YYYY-MM-DD');
                  return (
                    <TableRow>
                      <TableCell>{accountName}</TableCell>
                      <TableCell>{accountEmail}</TableCell>
                      <TableCell>{accountPhone}</TableCell>
                      <TableCell>
                        {walletCount === 0 ? 0 : Money.formatIDR(walletRows[0].amount.toString())}
                      </TableCell>
                      <TableCell>{formatCreatedAt}</TableCell>
                      <TableCell>{formatUpdatedAt}</TableCell>
                      <TableCell>
                        <Tooltip title="Detail Wallet History">
                          <IconButton
                            size="small"
                            onClick={() => {
                              history.push(`/wallet-history?id=${idAccount}`);
                            }}
                          >
                            <DetailsIcon color="primary" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        )}
      </TableContainer>
    </>
  );
}
