import { useEffect } from 'react';
import { useHistory } from 'react-router-dom'; // Make sure you have react-router-dom installed
import { AUTHENTICATED } from '../constant/index';

// Custom Hook for authentication check
function useAuthCheck() {
  const history = useHistory();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem(AUTHENTICATED);

    if (!isAuthenticated) {
      // Redirect to homepage if AUTHENTICATED is not found
      history.push('/');
    }
  }, [history]);
}

export default useAuthCheck;
