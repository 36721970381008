import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import STORE_CONFIRMATION_QUERY from 'graphql/store/confirmation/index';
import Loading from 'component/loading';
import Error from 'component/error';

export default function StoreConfirmation() {
  const { error, loading } = useQuery(STORE_CONFIRMATION_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return <div>A</div>;
}
