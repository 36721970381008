import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DialogImageProof({ image, open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Bukti Transfer</DialogTitle>
      <DialogContent>
        <img src={image} style={{ maxWidth: '100%' }} alt="dialog_image_proof" />
      </DialogContent>
    </Dialog>
  );
}
