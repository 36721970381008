import gql from 'graphql-tag';

export const statement = `   
 mutation createProductBanner($payload:ParamProductBanner!) {
    createProductBanner(v:$payload) {
        id
        title
        description        
    }
}
`;

export default gql`
  ${statement}
`;
