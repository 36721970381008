import gql from 'graphql-tag';

export const statement = `
mutation resetPasswordAccount($payload:ParamResetPasswordAccount!) {
  resetPasswordAccount(v:$payload) {
    status
  }
}
`;

export default gql`
  ${statement}
`;
