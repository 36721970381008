import * as yup from 'yup';

export default {
  referral_code: yup
    .string()
    .required('Kode referral harus diisi')
    .max(20, 'Kode referral maximal 20 karakter'),
  referral_percentage: yup
    .number('Presentase harus angka')
    .required('Presentase dibutuhkan')
    .positive()
    .integer()
    .min(1, 'Minimal presentase 1')
    .max(100, 'Minimal presentase 100'),
  customer: yup.string().required('Nama customer harus dipillih'),
  product: yup.string().required('Nama produk harus diisi'),
};
