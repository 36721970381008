import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackComponent from 'component/back/index';

function LoadingCenter() {
  return (
    <Grid container direction="row" justify="center" style={{ paddingTop: '16px' }}>
      <CircularProgress />
    </Grid>
  );
}

export default function Form({
  isEdit,
  classes,
  errors,
  register,
  loading,
  handleSubmit,
  onSubmit,
  formState,
}) {
  const history = useHistory();

  return (
    <Paper elevation={3} className={classes.wrapper}>
      <Grid container className={classes.header}>
        <ArrowBackComponent history={history} location="/product-color" />
        <Typography variant="h6" gutterBottom align="center" className={classes.title}>
          Produk Color
        </Typography>
      </Grid>
      <Grid container direction="row" className={classes.contentWrapper} spacing={2}>
        <Grid item xs={12} sm={isEdit ? 6 : 12}>
          <TextField
            helperText={errors?.name?.message}
            error={errors?.name && true}
            variant="outlined"
            label="Nama"
            name="name"
            fullWidth
            margin="dense"
            inputRef={register}
          />
        </Grid>

        {loading ? (
          <LoadingCenter />
        ) : (
          <Button
            disabled={!formState.isValid}
            fullWidth
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
            className={classes.buttonAdd}
          >
            {!isEdit ? ' Tambah' : 'Ubah'}
          </Button>
        )}
      </Grid>
    </Paper>
  );
}
