import gql from 'graphql-tag';

export const statement = `
query dayOff($payload:ParamQueryDayOff){
  dayOff(v:$payload) {
    count
    rows{
      id
      startOff
      endOff
      reason
      status
      day_off_type {
        id
        name
      }
    }
  }
}
`;

export default gql`
  ${statement}
`;
