import * as yup from 'yup';

export default {
  voucher_code: yup
    .string()
    .required('Kode Voucher harus diisi')
    .max(20, 'Kode Voucher maximal 20 karakter'),
  voucher_percentage: yup
    .number('Presentase harus angka')
    .required('Presentase dibutuhkan')
    .positive()
    .integer()
    .min(1, 'Minimal presentase 1')
    .max(100, 'Minimal presentase 100'),
  customer: yup.string().required('Customer harus diisi'),
  product: yup.string().required('Produk harus diisi'),
  voucher_expired: yup.string().required('Voucher Expired harus diisi'),
};
