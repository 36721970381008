import React from 'react';
import AccountCircleIcon from '@material-ui/core/SvgIcon/SvgIcon';
import PRODUCT_CONSTANT from './product';

const AUTHENTICATED = 'token';
const PAGE_LIMIT = 10;
const FIREBASE_TOKEN_STORAGE = 'FCM_TOKEN';
const ORDER_STATUS_MAPPING = [
  {
    number: 5,
    name: 'DESIGN',
  },
  {
    number: 6,
    name: 'POTONGAN',
  },
  {
    number: 9,
    name: 'PECAHWARNA',
  },
  {
    number: 10,
    name: 'SABLON',
  },
  {
    number: 11,
    name: 'PRESS',
  },
  {
    number: 12,
    name: 'JAHITAN',
  },
  {
    number: 13,
    name: 'PACKING',
  },
  {
    number: 14,
    name: 'FINISH',
  },
  {
    number: 15,
    name: 'BORDIR',
  },
  {
    number: 16,
    name: 'PREPARE',
  },
];

const navigationItem = [
  {
    name: 'Beranda',
    icon: <AccountCircleIcon />,
    link: '/beranda',
  },
  {
    name: 'Mitra',
    icon: <AccountCircleIcon />,
    link: '/mitra',
  },
  {
    name: 'Produk Website',
    icon: <AccountCircleIcon />,
    link: '/produk-website',
  },
  {
    name: 'Event',
    icon: <AccountCircleIcon />,
    link: '/event',
  },
  {
    name: 'Promo',
    icon: <AccountCircleIcon />,
    link: '/promo',
  },
  {
    name: 'Profil',
    icon: <AccountCircleIcon />,
    link: '/profil',
  },
  {
    name: 'Statistik',
    icon: <AccountCircleIcon />,
    link: '/statistik',
  },
  {
    name: 'Keluar',
    icon: <AccountCircleIcon />,
    link: '/keluar',
  },
];
export {
  PRODUCT_CONSTANT,
  navigationItem,
  AUTHENTICATED,
  FIREBASE_TOKEN_STORAGE,
  PAGE_LIMIT,
  ORDER_STATUS_MAPPING,
};
