import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/react-hooks';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import CREATE_ATTENDANCE from '../../graphql/attendance/create';

import Error from '../../component/error/index';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dateWrapper: {
    marginBottom: theme.spacing(2),
  },
}));

export default function AttendanceRequest() {
  const classes = useStyles();
  const CreateAttendanceRequestSchema = yup.object().shape({
    day_off_type: yup.string().required('Tipe cuti harus dipilih'),
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema: CreateAttendanceRequestSchema,
    submitFocusError: false,
    nativeValidation: false,
  });
  const history = useHistory();
  const [createAttendanceAction, { loading, error: errorCreateAttendanceRequest }] = useMutation(
    CREATE_ATTENDANCE,
    {
      onCompleted: async () => {
        history.push('/absensi?status=berhasil');
      },
    },
  );

  const onSubmitRegister = async dataCreateDayOff => {
    const {
      day_off_type: idDayOffType,
      date_from_day_off: startOff,
      date_to_day_off: endOff,
      txt_code_attendance: reason,
    } = dataCreateDayOff;
    const payloadCreateAttendance = {
      id_day_off_type: parseInt(idDayOffType, 10),
      startOff,
      endOff,
      reason,
    };
    await createAttendanceAction({
      variables: {
        payload: payloadCreateAttendance,
      },
    });
  };

  if (errorCreateAttendanceRequest) {
    return <Error />;
  }

  return (
    <Paper elevation={3} className={classes.wrapper}>
      <Grid container justify="center">
        <Grid item xs={8}>
          <Typography variant="h6" gutterBottom align="left">
            Absensi
          </Typography>
          <TextField
            error={errors?.txt_code_attendance && true}
            helperText={errors?.txt_code_attendance?.message}
            inputRef={register}
            margin="dense"
            id="txt_code_attendance"
            label="Kode Kehadiran"
            name="txt_code_attendance"
            size="small"
            variant="outlined"
            fullWidth
          />
          <Box mt={2} mb={2}>
            <Button
              onClick={handleSubmit(onSubmitRegister)}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              id="btn-create-attendance"
            >
              {loading ? <CircularProgress color="secondary" /> : 'Absen'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
