const EMAIL_NOT_VALID = 'Email tidak valid';
const EMAIL_MUST_BE_FILLED_IN = 'Email di butuhkan';
const PASSWORD_MINIMUM_EIGHT = 'Kata Sandi minimal 8 karakter';
const PASSWORD_MUST_BE_FILLED_IN = 'Kata Sandi di butuhkan';
const CONFIRM_PASSWORD_MUST_BE_FILLED_IN = 'Konfirmasi Kata Sandi di butuhkan';
const CONFIRM_PASSWORD_NOT_SAME = 'Konfirmasi Kata Sandi tidak sama';

export {
  CONFIRM_PASSWORD_NOT_SAME,
  EMAIL_NOT_VALID,
  EMAIL_MUST_BE_FILLED_IN,
  PASSWORD_MINIMUM_EIGHT,
  PASSWORD_MUST_BE_FILLED_IN,
  CONFIRM_PASSWORD_MUST_BE_FILLED_IN,
};
