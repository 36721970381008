import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import WALLET_TOP_QUERY from 'graphql/wallet/TopUpRequest/get';
import { useQuery } from '@apollo/react-hooks';
import Loading from 'component/loading';
import Error from 'component/error';
import { useForm } from 'react-hook-form';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import { NetworkStatus } from 'apollo-client';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import LoadMore from 'component/LoadMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import TableEmpty from 'component/TableEmpty';
import Money from 'utils/money';
import CUSTOMER_QUERY from '../../../graphql/account/customer';
import DialogApproveTopUp from './component/DialogApproveTopUp';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  imageEmpty: {
    maxWidth: '100%',
  },
  emptyWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export default function WalletTopUpRequest() {
  const [id, setId] = useState(null);
  const [imageProof, setImageProof] = useState(null);
  const [approveTopUp, setApproveTopUp] = useState(false);
  const [money, setMoney] = useState(null);
  const [status, setStatus] = useState(null);

  const reset = () => {
    setStatus(null);
    setMoney(null);
    setApproveTopUp(false);
    setImageProof(null);
    setId(null);
  };

  const classes = useStyles();
  const payloadCustomer = {
    offset: 0,
    limit: 10,
  };

  const { register, setValue } = useForm({
    submitFocusError: false,
    nativeValidation: false,
  });

  const { data: dataCustomer, loading: loadingCustomer, refetch: refetchCustomer } = useQuery(
    CUSTOMER_QUERY,
    {
      variables: {
        payload: payloadCustomer,
      },
      fetchPolicy: 'no-cache',
    },
  );

  const { data, error, fetchMore, refetch, networkStatus } = useQuery(WALLET_TOP_QUERY, {
    variables: {
      payload: {
        offset: 0,
        limit: 10,
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const topUpRequestFetchMore = networkStatus === NetworkStatus.fetchMore;
  const topUpRequestRefetch = networkStatus === NetworkStatus.refetch;
  const topUpRequestLoading = networkStatus === NetworkStatus.loading;

  useEffect(() => {
    register('customer');
  }, [register]);

  if (topUpRequestLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const topUpRequestCount = data?.walletTopUpRequest?.count;
  const topUpRequestRows = data?.walletTopUpRequest?.rows;

  const autoCompleteCustomerProperty = {};
  autoCompleteCustomerProperty.fullWidth = true;
  autoCompleteCustomerProperty.options = dataCustomer?.customer;
  autoCompleteCustomerProperty.getOptionLabel = option => {
    return `${option.name}-${option.email}`;
  };
  autoCompleteCustomerProperty.renderInput = params => (
    <TextField {...params} label="Customer" variant="outlined" margin="dense" />
  );
  autoCompleteCustomerProperty.noOptionsText = 'Customer tidak di temukan';
  autoCompleteCustomerProperty.size = 'small';
  autoCompleteCustomerProperty.onChange = (value, newValue) => {
    if (newValue?.id) {
      const idValue = newValue?.id;
      refetch({
        payload: {
          id_account: idValue,
        },
      });
      setValue('customer', idValue);
    } else {
      setValue('customer', '');
    }
  };
  autoCompleteCustomerProperty.onInputChange = event => {
    const name = event?.target?.value;
    // close button is clicked
    if (name !== 0) {
      refetchCustomer({
        payload: {
          name,
        },
      });
      refetch({
        payload: {
          offset: 0,
          limit: 10,
        },
      });
    }
  };

  return (
    <>
      <DialogApproveTopUp
        refetch={refetch}
        id={id}
        status={status}
        money={money}
        image={imageProof}
        onClose={() => {
          reset();
        }}
        open={approveTopUp}
      />
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Top Up Request</Typography>
        <Grid container>
          {!loadingCustomer && <Autocomplete {...autoCompleteCustomerProperty} />}
        </Grid>
      </div>

      <TableContainer component={Paper}>
        {topUpRequestCount === 0 ? (
          <>
            <TableEmpty text="Top Up Request tidak di temukan" />
          </>
        ) : (
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Nama</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Telepon</TableCell>
                <TableCell>Jumlah</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topUpRequestRows.map(value => {
                const {
                  image_done_proof,
                  account,
                  status: statusTopUp,
                  id: idTopUp,
                  amount,
                  updatedAt,
                  createdAt,
                  media,
                } = value;
                const checkMark =
                  image_done_proof && (statusTopUp === 'PROCESSED' || statusTopUp === 'SUCCESS');
                const formatMoney = Money.formatIDR(amount.toString());
                return (
                  <TableRow>
                    <TableCell>{idTopUp}</TableCell>
                    <TableCell>{statusTopUp}</TableCell>
                    <TableCell>{account?.[0]?.name || '-'}</TableCell>
                    <TableCell>{account?.[0]?.email || '-'}</TableCell>
                    <TableCell>{account?.[0]?.phone || '-'}</TableCell>
                    <TableCell>{formatMoney}</TableCell>
                    <TableCell>{moment(createdAt).format('DD-MM-YYYY')}</TableCell>
                    <TableCell>{moment(updatedAt).format('DD-MM-YYYY')}</TableCell>
                    <TableCell>
                      {checkMark ? (
                        <Tooltip title="Lihat bukti transaksi dan setujui">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setId(id);
                              setStatus(status);
                              setMoney(formatMoney);
                              setImageProof(media?.[0]?.secure_url);
                              setApproveTopUp(true);
                            }}
                          >
                            <DoneIcon color="primary" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <div>Gambar bukti blm di upload</div>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {topUpRequestCount > 10 && (
        <>
          {topUpRequestRefetch ? (
            <CircularProgress color="secondary" />
          ) : (
            <Box pt={2}>
              <LoadMore
                loading={topUpRequestFetchMore || topUpRequestRefetch}
                refetch={refetch}
                fetchMore={async () => {
                  const withdrawRequestLength = topUpRequestRows.length;
                  const payload = {
                    offset: withdrawRequestLength,
                  };
                  await fetchMore({
                    variables: {
                      payload,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      const newRows = fetchMoreResult.walletTopUpRequest.rows;
                      const newCount = fetchMoreResult.walletTopUpRequest.count;
                      return {
                        walletTopUpRequest: {
                          ...prev.walletTopUpRequest,
                          rows: [...prev.walletTopUpRequest.rows, ...newRows],
                          count: newCount,
                        },
                      };
                    },
                  });
                }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
}
