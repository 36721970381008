import gql from 'graphql-tag';

export const statement = `
  query order($payload: ParamOrderInput){
    order(v:$payload) {
      id
      order_no
      S_Qty
      PendekS_Qty  
      PanjangS_Qty
      M_Qty
      PendekM_Qty
      PajangM_Qty
    
      L_Qty
      PendekL_Qty
      PanjangL_Qty
    
      XL_Qty
      PendekXL_Qty
      PanjangXL_Qty
                  
      XXL_Qty
      PendekXXL_Qty
      PanjangXXL_Qty
    
    
    
      ThreeXL_Qty
      PendekThreeXL_Qty
      PanjangThreeXL_Qty
      
      
    
      FourthXL_Qty
      PendekFourthXL_Qty
      PanjangFourthXL_Qty
    
      FifthXL_Qty
      PendekFifthXL_Qty
      PanjangFifthXL_Qty
    
      SixthXL_Qty
      PendekSixthXL_Qty
      PanjangSixthXL_Qty
    
      OrderStatus {
        id
        name
      }                         
      Product{
        rows{
          name
          description
          media{
            url
          }
        }
      }                               
      Spk {
        id
        spk_no
        down_payment        
        is_confirmed
        note
        sub_total
        paid                
        Invoice{
          spk_no
          billed_amount
          pay_amount
          return_amount
          createdAt
        }                          
        media {
          public_id
          url
        }                             
      }                               
  }
}`;

export default gql`
  ${statement}
`;
