import String from './string';

const Money = {};

Money.formatIDR = angka => {
  let separator = null;
  const numberString = angka.replace(/[^,\d]/g, '').toString();
  const split = numberString.split(',');
  const sisa = split[0].length % 3;
  let rupiah = split[0].substr(0, sisa);
  const ribuan = split[0].substr(sisa).match(/\d{3}/gi);
  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }
  return split[1] !== undefined ? `${rupiah},${split[1]}` : rupiah;
};

Money.setPriceWithCurrency = price => {
  return `IDR ${String.thousandSeparator(price)}`;
};

export default Money;
