import gql from 'graphql-tag';

export const statement = `   
query productMaterial($payload:ParamProductMaterial){
  productMaterial(v:$payload){
    id
    name
    createdAt
    updatedAt    
  }
}
`;

export default gql`
  ${statement}
`;
