import gql from 'graphql-tag';

export const statement = `
mutation createProductWebsite($payload:ParamCreateProductWebsite!){
    createProductWebsite(v:$payload){            
         id
         name
         description
         height
         weight
         video
         wide
         width
         weight_type            
    }
}
`;

export default gql`
  ${statement}
`;
