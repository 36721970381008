import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { useMutation } from '@apollo/react-hooks';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import FormHelperText from '@material-ui/core/FormHelperText';
import UploadValidation from 'utils/validation/upload';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import URL from 'utils/url';
import SPK_UPDATE from 'graphql/spk/update';
import ImageUtils from 'utils/Image';
import { PRODUCT_SPK } from 'constant/upload_path';

const useStyles = makeStyles(theme => ({
  inputFile: {
    display: 'none',
  },
  dialogFooter: {
    padding: theme.spacing(2),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function ModalUploadSPKPicture({
  open,
  onClose,
  selectedSpkNumber,
  setOpenSnackBar,
  setSnackBarMessage,
  spkUploadPictureState,
  imageSPKPublicId,
  refetchAcc,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const uploadValidation = UploadValidation('file_upload');
  const uploadScheme = yup.object().shape(uploadValidation);
  const { register, handleSubmit, errors } = useForm({
    validationSchema: uploadScheme,
    submitFocusError: false,
    nativeValidation: false,
  });

  const [updateSpkImageAfterEdit, { loading: loadingUpdateSpkImage }] = useMutation(SPK_UPDATE, {
    onCompleted: () => {
      onClose();
      setOpenSnackBar(true);
      setSnackBarMessage('Gambar finish dari spk berhasil diupload');
      refetchAcc();
      setLoading(false);
    },
    onError: () => {
      onClose();
      setOpenSnackBar(true);
      setSnackBarMessage('Gambar finish dari spk gagal diupload');
      setLoading(false);
    },
  });

  const uploadAndUpdateFilenameAfterEdit = async (
    urlUpload,
    dataForm,
    configRequest,
    // eslint-disable-next-line no-shadow
    setLoading,
  ) => {
    axios
      .post(urlUpload, dataForm, configRequest)
      .then(async res => {
        const { data } = res;
        const imageRequestID = data.data;
        await updateSpkImageAfterEdit({
          variables: {
            payload: {
              spkNumber: selectedSpkNumber,
              isConfirmed: 'Y',
              filenameAfterEdit: imageRequestID,
            },
          },
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSubmit = async dataOnSubmit => {
    setLoading(true);
    const fileUpload = dataOnSubmit.file_upload;
    const fileUploadLength = fileUpload.length;
    const configRequest = ImageUtils.getHeader();
    const dataForm = new FormData();
    for (let i = 0; i < fileUploadLength; i += 1) {
      dataForm.append(`files[${i}]`, fileUpload[i]);
    }
    dataForm.append('image_path', PRODUCT_SPK);
    const payload = {
      clientId: process.env.REACT_APP_SERVICE_MEDIA_CLIENT_ID,
      clientSecret: process.env.REACT_APP_SERVICE_MEDIA_CLIENT_SECRET,
    };
    const queryURL = URL.makeQueryString(payload);
    const urlUpload = `${process.env.REACT_APP_SERVICE_MEDIA}/api/v1/cloudinary${queryURL}`;
    const urlDelete = `${process.env.REACT_APP_SERVICE_MEDIA}/api/v1/cloudinary/destroy${queryURL}`;

    if (spkUploadPictureState === 'EDIT') {
      const dataFormDelete = new FormData();
      dataFormDelete.append('public_id', imageSPKPublicId);
      await axios
        .post(urlDelete, dataFormDelete, configRequest)
        .then(async () => {
          await uploadAndUpdateFilenameAfterEdit(urlUpload, dataForm, configRequest, setLoading);
        })
        .catch(() => {
          setOpenSnackBar(true);
          setSnackBarMessage('Upload gambar gagal');
          setLoading(false);
        });
    } else {
      await uploadAndUpdateFilenameAfterEdit(urlUpload, dataForm, configRequest, setLoading);
    }
  };
  // eslint-disable-next-line camelcase
  const fileUploadError = errors?.file_upload;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload Gambar Finish: {selectedSpkNumber}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {/* eslint-disable-next-line camelcase */}
        {fileUploadError && (
          <FormControl error>
            <FormHelperText>{fileUploadError.message}</FormHelperText>
          </FormControl>
        )}
        <DialogContentText>
          <input
            ref={register}
            name="file_upload"
            accept="image/*"
            className={classes.inputFile}
            id="icon-upload-product"
            type="file"
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="icon-upload-product">
            <IconButton color="primary" aria-label="upload picture" component="div">
              <PhotoCamera />
            </IconButton>
          </label>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogFooter}>
        {loadingUpdateSpkImage || loading ? (
          <Grid container direction justify="center">
            <CircularProgress size={30} />
          </Grid>
        ) : (
          <Button fullWidth onClick={handleSubmit(onSubmit)} color="primary" variant="contained">
            Ya
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(ModalUploadSPKPicture);
