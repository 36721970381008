import React from 'react';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

function RenderModalPayment({ open, handleClose, data }) {
  if (data) {
    const { pickup_options, OrderDelivery, OrderPickupSchedule } = data[0];
    const orderSummaryOnlineDelivery = pickup_options === 'online_delivery';
    const OrderDeliveryAddress = OrderDelivery?.AccountAddress;

    const orderSummaryPickupScheduleTime = moment(OrderPickupSchedule?.pickupAt).format(
      'DD MMMM YYYY',
    );

    return (
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Informasi Pembayaran</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="subtitle1">Opsi Pengambilan</Typography>
              <Typography variant="subtitle1">{pickup_options}</Typography>
            </Box>

            {orderSummaryOnlineDelivery ? (
              <>
                <Box display="flex" flexDirection="column" mb={1}>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography variant="caption" color="textSecondary">
                      ETD
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {OrderDelivery?.courier_etd} Hari
                    </Typography>
                  </Box>

                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography variant="caption" color="textSecondary">
                      Kurir
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {OrderDelivery?.courier_name}
                    </Typography>
                  </Box>

                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography variant="caption" color="textSecondary">
                      Layanan
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {OrderDelivery?.courier_service}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography variant="caption" color="textSecondary">
                      Harga
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {OrderDelivery?.courier_cost}
                    </Typography>
                  </Box>
                </Box>

                <Typography variant="subtitle2" color="textSecondary">
                  {OrderDeliveryAddress?.address_name}
                </Typography>

                <Typography variant="caption" color="textSecondary">
                  {OrderDeliveryAddress?.province}, {OrderDeliveryAddress?.city},{' '}
                  {OrderDeliveryAddress?.subdistrict}
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  {OrderDeliveryAddress?.full_address}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body2" color="textSecondary">
                  Diambil Pada: {orderSummaryPickupScheduleTime}
                </Typography>
              </>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  return null;
}

export default React.memo(RenderModalPayment);
