import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-client';
import Typography from '@material-ui/core/Typography';
import LoadMore from 'component/LoadMore/index';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import MEDIA_QUERY from 'graphql/media/index';
import Loading from 'component/loading/index';
import Error from 'component/error/index';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingBottom: theme.spacing(2),
  },
  media: {
    height: '250px',
    backgroundSize: 'contain',
  },
}));

export default function Media() {
  const classes = useStyles();
  const { data, error, networkStatus, refetch, fetchMore } = useQuery(MEDIA_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  if (networkStatus === NetworkStatus.loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  return (
    <div>
      <Grid container direction="row" spacing={2} className={classes.wrapper}>
        {data.medias.map((value, index) => {
          const { version, created_at, signature, secure_url, width, height } = value;
          return (
            <Grid item xs={12} sm={12} md={4} key={index}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    key={index}
                    className={classes.media}
                    src={secure_url}
                    image={secure_url}
                  />
                </CardActionArea>
                <CardContent>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Secure URL: {secure_url}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Version: {version}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Created At: {created_at}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Signature: {signature}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Width: {width}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Height: {height}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <LoadMore
        loading={
          networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.fetchMore
        }
        refetch={refetch}
        fetchMore={async () => {
          await fetchMore({
            variables: {
              payload: {
                offset: data.medias.length,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return { ...prev, medias: [...prev.medias, ...fetchMoreResult.medias] };
            },
          });
        }}
      />
    </div>
  );
}
