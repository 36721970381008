import gql from 'graphql-tag';

export const statement = `
mutation editBank($payload:ParamEditBank!){
    editBank(v:$payload) {
        id
        name
    }
}
`;

export default gql`
  ${statement}
`;
