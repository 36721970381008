import gql from 'graphql-tag';

export const statement = `   
query variantValue($payload:ParamVariantValue){
    variantValue(v:$payload){
        id
        id_variant
        name
        meta_value
        createdAt
        updatedAt
        variant{
          id
          name
        }
    }
}
`;

export default gql`
  ${statement}
`;
