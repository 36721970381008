import gql from 'graphql-tag';

export const statement = `
mutation confirmWalletWithdrawRequest($payload:ParamConfirmWalletWithdrawRequest!) {
    confirmWalletWithdrawRequest(v:$payload)  {
        status
    }
}
`;

export default gql`
  ${statement}
`;
