import gql from 'graphql-tag';

export const statement = `
mutation createForgotPasswordLink($email:String!){
  createForgotPasswordLink(email:$email) {
    access_token  
  }
}
`;

export default gql`
  ${statement}
`;
