const Product = {};
Product.size = [
  {
    name: 'PendekS_Qty',
    label: 'Pendek S Qty',
  },
  {
    name: 'S_Qty',
    label: '3/4 S Qty',
  },
  {
    name: 'PanjangS_Qty',
    label: 'Panjang S Qty',
  },
  {
    name: 'PendekM_Qty',
    label: 'Pendek M Qty',
  },
  {
    name: 'M_Qty',
    label: '3/4 M Qty',
  },
  {
    name: 'PajangM_Qty',
    label: 'Panjang M Qty',
  },
  {
    name: 'PendekL_Qty',
    label: 'Pendek L Qty',
  },
  {
    name: 'L_Qty',
    label: '3/4 L Qty',
  },
  {
    name: 'PanjangL_Qty',
    label: 'Panjang L Qty',
  },
  {
    name: 'PendekXL_Qty',
    label: 'Pendek XL Qty',
  },
  {
    name: 'XL_Qty',
    label: '3/4 XL Qty',
  },
  {
    name: 'PanjangXL_Qty',
    label: 'Panjang XL Qty',
  },
  {
    name: 'PendekXXL_Qty',
    label: 'Pendek XXL Qty',
  },
  {
    name: 'XXL_Qty',
    label: '3/4 XXL Qty',
  },
  {
    name: 'PanjangXXL_Qty',
    label: 'Panjang XXL Qty',
  },
  {
    name: 'PendekThreeXL_Qty',
    label: 'Pendek 3XL Qty',
  },
  {
    name: 'ThreeXL_Qty',
    label: '3/4 3XL Qty',
  },
  {
    name: 'PanjangThreeXL_Qty',
    label: 'Panjang 3XL Qty',
  },
  {
    name: 'PendekFourthXL_Qty',
    label: 'Pendek 4XL Qty',
  },
  {
    name: 'FourthXL_Qty',
    label: '3/4 4XL Qty',
  },
  {
    name: 'PanjangFourthXL_Qty',
    label: 'Panjang 4XL Qty',
  },
  {
    name: 'PendekFifthXL_Qty',
    label: 'Pendek 5XL Qty',
  },
  {
    name: 'FifthXL_Qty',
    label: '3/4 5XL Qty',
  },
  {
    name: 'PanjangFifthXL_Qty',
    label: 'Panjang 5XL Qty',
  },
  {
    name: 'PendekSixthXL_Qty',
    label: 'Pendek 6XL Qty',
  },
  {
    name: 'SixthXL_Qty',
    label: '3/4 6XL Qty',
  },
  {
    name: 'PanjangSixthXL_Qty',
    label: 'Panjang 6XL Qty',
  },
];

Product.sizeChildren = [
  {
    name: 'PendekS_Children_Price',
    label: 'Pendek S Price',
  },
  {
    name: 'S_Children_Price',
    label: '3/4 S Price',
  },
  {
    name: 'PanjangS_Children_Price',
    label: 'Panjang S Price',
  },
  {
    name: 'PendekM_Children_Price',
    label: 'Pendek M Price',
  },
  {
    name: 'M_Children_Price',
    label: '3/4 M Price',
  },
  {
    name: 'PanjangM_Children_Price',
    label: 'Panjang M Price',
  },
  {
    name: 'PendekL_Children_Price',
    label: 'Pendek L Price',
  },
  {
    name: 'L_Children_Price',
    label: '3/4 L Price',
  },
  {
    name: 'PanjangL_Children_Price',
    label: 'Panjang L Price',
  },
  {
    name: 'PendekXL_Children_Price',
    label: 'Pendek XL Price',
  },
  {
    name: 'XL_Children_Price',
    label: '3/4 XL Price',
  },
  {
    name: 'PanjangXL_Children_Price',
    label: 'Panjang XL Price',
  },
];

Product.field = [
  {
    name: 'PendekS_Price',
    label: 'Pendek S Price',
  },
  {
    name: 'S_Price',
    label: '3/4 S Price',
  },
  {
    name: 'PanjangS_Price',
    label: 'Panjang S Price',
  },
  {
    name: 'PendekM_Price',
    label: 'Pendek M Price',
  },
  {
    name: 'M_Price',
    label: '3/4 M Price',
  },
  {
    name: 'PanjangM_Price',
    label: 'Panjang M Price',
  },
  {
    name: 'PendekL_Price',
    label: 'Pendek L Price',
  },
  {
    name: 'L_Price',
    label: '3/4 L Price',
  },
  {
    name: 'PanjangL_Price',
    label: 'Panjang L Price',
  },
  {
    name: 'PendekXL_Price',
    label: 'Pendek XL Price',
  },
  {
    name: 'XL_Price',
    label: '3/4 XL Price',
  },
  {
    name: 'PanjangXL_Price',
    label: 'Panjang XL Price',
  },
  {
    name: 'PendekXXL_Price',
    label: 'Pendek XXL Price',
  },
  {
    name: 'XXL_Price',
    label: '3/4 XXL Price',
  },
  {
    name: 'PanjangXXL_Price',
    label: 'Panjang XXL Price',
  },
  {
    name: 'PendekThreeXL_Price',
    label: 'Pendek 3XL Price',
  },
  {
    name: 'ThreeXL_Price',
    label: '3/4 3XL Price',
  },
  {
    name: 'PanjangThreeXL_Price',
    label: 'Panjang 3XL Price',
  },

  {
    name: 'PendekFourthXL_Price',
    label: 'Pendek 4XL Price',
  },
  {
    name: 'FourthXL_Price',
    label: '3/4 4XL Price',
  },
  {
    name: 'PanjangFourthXL_Price',
    label: 'Panjang 4XL Price',
  },

  {
    name: 'PendekFifthXL_Price',
    label: 'Pendek 5XL Price',
  },
  {
    name: 'FifthXL_Price',
    label: '3/4 5XL Price',
  },
  {
    name: 'PanjangFifthXL_Price',
    label: 'Panjang 5XL Price',
  },

  {
    name: 'PendekSixthXL_Price',
    label: 'Pendek 6XL Price',
  },
  {
    name: 'SixthXL_Price',
    label: '3/4 6XL Price',
  },
  {
    name: 'PanjangSixthXL_Price',
    label: 'Panjang 6XL Price',
  },
];

Product.weight = [
  {
    name: 'PendekS_weight',
    label: 'Pendek S Weight',
  },
  {
    name: 'S_weight',
    label: '3/4 S Weight',
  },
  {
    name: 'PanjangS_weight',
    label: 'Panjang S Weight',
  },
  {
    name: 'PendekM_weight',
    label: 'Pendek M Weight',
  },
  {
    name: 'M_weight',
    label: '3/4 M Weight',
  },
  {
    name: 'PanjangM_weight',
    label: 'Panjang M Weight',
  },
  {
    name: 'PendekL_weight',
    label: 'Pendek L Weight',
  },
  {
    name: 'L_weight',
    label: '3/4 L Weight',
  },
  {
    name: 'PanjangL_weight',
    label: 'Panjang L Weight',
  },
  {
    name: 'PendekXL_weight',
    label: 'Pendek XL Weight',
  },
  {
    name: 'XL_weight',
    label: '3/4 XL Weight',
  },
  {
    name: 'PanjangXL_weight',
    label: 'Panjang XL Weight',
  },
  {
    name: 'PendekXXL_weight',
    label: 'Pendek XXL Weight',
  },
  {
    name: 'XXL_weight',
    label: '3/4 XXL Weight',
  },
  {
    name: 'PanjangXXL_weight',
    label: 'Panjang XXL Weight',
  },
  {
    name: 'PendekThreeXL_weight',
    label: 'Pendek 3XL Weight',
  },
  {
    name: 'ThreeXL_weight',
    label: '3/4 3XL Weight',
  },
  {
    name: 'PanjangThreeXL_weight',
    label: 'Panjang 3XL Weight',
  },

  {
    name: 'PendekFourthXL_weight',
    label: 'Pendek 4XL Weight',
  },
  {
    name: 'FourthXL_weight',
    label: '3/4 4XL Weight',
  },
  {
    name: 'PanjangFourthXL_weight',
    label: 'Panjang 4XL Weight',
  },

  {
    name: 'PendekFifthXL_weight',
    label: 'Pendek 5XL Weight',
  },
  {
    name: 'FifthXL_weight',
    label: '3/4 5XL Weight',
  },
  {
    name: 'PanjangFifthXL_weight',
    label: 'Panjang 5XL Weight',
  },

  {
    name: 'PendekSixthXL_weight',
    label: 'Pendek 6XL Weight',
  },
  {
    name: 'SixthXL_weight',
    label: '3/4 6XL Weight',
  },
  {
    name: 'PanjangSixthXL_weight',
    label: 'Panjang 6XL Weight',
  },
];

Product.weightChildren = [
  {
    name: 'PendekS_Children_weight',
    label: 'Pendek S Weight',
  },
  {
    name: 'S_Children_weight',
    label: '3/4 S Weight',
  },
  {
    name: 'PanjangS_Children_weight',
    label: 'Panjang S Weight',
  },
  {
    name: 'PendekM_Children_weight',
    label: 'Pendek M Weight',
  },
  {
    name: 'M_Children_weight',
    label: '3/4 M Weight',
  },
  {
    name: 'PanjangM_Children_weight',
    label: 'Panjang M Weight',
  },
  {
    name: 'PendekL_Children_weight',
    label: 'Pendek L Weight',
  },
  {
    name: 'L_Children_weight',
    label: '3/4 L Weight',
  },
  {
    name: 'PanjangL_Children_weight',
    label: 'Panjang L Weight',
  },
  {
    name: 'PendekXL_Children_weight',
    label: 'Pendek XL Weight',
  },
  {
    name: 'XL_Children_weight',
    label: '3/4 XL Weight',
  },
  {
    name: 'PanjangXL_Children_weight',
    label: 'Panjang XL Weight',
  },
];

export default Product;
