import gql from 'graphql-tag';

export const statement = `   
mutation updateProductVoucher($payload:EditProductVoucher!){
    updateProductVoucher(v:$payload){
        status
    }
}
`;

export default gql`
  ${statement}
`;
