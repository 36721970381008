import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import EDIT_DAY_OFF from '../../graphql/dayoff/edit';
import DAY_OFF_TYPE_QUERY from '../../graphql/dayoff/type/index';

import Error from '../../component/error/index';
import Loading from '../../component/loading/index';
import Form from './form';
import Validation from './validation';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dateWrapper: {
    marginBottom: theme.spacing(2),
  },
}));

export default function DayOffRequest() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const CreateDayOffRequestSchema = yup.object().shape({
    ...Validation,
  });

  const [dayOffType, setDayOffType] = useState('');
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: CreateDayOffRequestSchema,
    submitFocusError: false,
    nativeValidation: false,
    defaultValues: {
      day_off_type: urlParams.get('day_off_type'),
      date_from_day_off: urlParams.get('date_from_day_off'),
      date_to_day_off: urlParams.get('date_to_day_off'),
      txt_reason: urlParams.get('txt_reason'),
    },
  });

  const { data: dataDayOffType, loading: loadingDayOffType, error: errorDayOffType } = useQuery(
    DAY_OFF_TYPE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [editDayOffAction, { loading, error: errorCreateDayOffRequest }] = useMutation(
    EDIT_DAY_OFF,
    {
      onCompleted: async () => {
        history.push('/cuti?status=berhasil');
      },
    },
  );
  const day_off_type = urlParams.get('day_off_type');
  useEffect(() => {
    register({ name: 'day_off_type' }, { required: true });
    setDayOffType(day_off_type);
  }, [register, day_off_type]);

  const onSubmitRegister = async dataCreateDayOff => {
    const {
      day_off_type: idDayOffType,
      date_from_day_off: startOff,
      date_to_day_off: endOff,
      txt_reason: reason,
    } = dataCreateDayOff;
    const payloadCreateDayOff = {
      id: parseInt(urlParams.get('id'), 10),
      id_day_off_type: parseInt(idDayOffType, 10),
      startOff,
      endOff,
      reason,
    };
    await editDayOffAction({
      variables: {
        payload: payloadCreateDayOff,
      },
    });
  };
  const todayDate = moment().format('YYYY-MM-DD');
  const handleChangeDayOffType = event => {
    const { value } = event.target;
    setValue('day_off_type', value);
    setDayOffType(value);
  };

  if (loadingDayOffType) {
    return <Loading />;
  }

  if (errorCreateDayOffRequest || errorDayOffType) {
    return <Error />;
  }

  return (
    <Form
      handleSubmit={handleSubmit}
      onSubmitRegister={onSubmitRegister}
      classes={classes}
      errors={errors}
      handleChangeDayOffType={handleChangeDayOffType}
      dataDayOffType={dataDayOffType}
      dayOffType={dayOffType}
      register={register}
      todayDate={todayDate}
      loading={loading}
    />
  );
}
