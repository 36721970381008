/* eslint-disable no-useless-escape */
export default {
  email: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
  name: /^(?![\s.]+$)[a-zA-Z\s.]*$/,
  phone: /^([1-9])(?:\d{7,12})$/,
  address: /^[A-Za-z0-9 #&'\.\-=+,()\s]+$/,
  alphabetOnly: /^[a-zA-Z ]+$/,
  numberOnly: /^([0-9])+$/,
  KTP: /^(?:\d{16})$/,
  NPWP: /^(?:\d{15})$/,
  alphanumericOnly: /^[a-zA-Z0-9 ]*$/,
  licensePlate: /^([A-Za-z]{1,2})([0-9]{1,4})([A-Za-z]{1,3})$/,
  awb: /^[a-zA-Z0-9 /-]*$/,
};
