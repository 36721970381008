import gql from 'graphql-tag';

export const statement = `
 mutation createImageProofWorkOrder($filename:String!,$spkNumber: String!) {
    createImageProofWorkOrder(filename:$filename,spkNumber:$spkNumber) {
        status
    }
}
`;

export default gql`
  ${statement}
`;
