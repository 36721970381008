import gql from 'graphql-tag';

export const statement = `   
mutation deleteProductBanner($payload:ParamProductBanner!) {
    deleteProductBanner(v:$payload){
        id
    }
}
`;

export default gql`
  ${statement}
`;
