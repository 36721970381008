import React, { useState, useContext } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-client';
import PRODUCT_REFERRAL_QUERY from 'graphql/product/referral/get';
import PRODUCT_VOUCHER_BY_CODE_QUERY from 'graphql/product/voucher/byCode/index';
import Loading from 'component/loading';
import Error from 'component/error';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import empty from 'assets/svg/empty.svg';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DetailsIcon from '@material-ui/icons/Details';
import Alert from '@material-ui/lab/Alert/Alert';
import LoadMore from 'component/LoadMore';
import URL from 'utils/url';
import Add from './add';
import Edit from './edit';
import AuthContext from '../../../AuthContext';

const RenderIndex = ({
  register,
  handleSubmit,
  onSubmit,
  classes,
  data,
  fetchMore,
  loading,
  refetch,
  history,
  statusPage,
  getProductVoucherByCodeAction,
  setSelectedCode,
}) => {
  const dataProductReferral = data.productReferral;
  const dataProductReferralLength = dataProductReferral.length;
  return dataProductReferralLength !== 0 ? (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Produk Referral</Typography>
        <Button
          onClick={() => {
            history.push('/referral/tambah');
          }}
          color="primary"
          variant="contained"
        >
          Tambah
        </Button>
      </div>

      <Grid container justify="center" className={classes.searchWrapper}>
        <TextField
          className={classes.searchField}
          size="small"
          variant="outlined"
          inputRef={register}
          name="product_referral"
          label="Cari Referral"
        />
        <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
          Cari
        </Button>
      </Grid>
      {statusPage === 'berhasil' && (
        <Alert
          onClose={() => {
            history.push('/voucher');
          }}
          variant="filled"
          severity="success"
          className={classes.alertWrapper}
        >
          Aksi Pada produk berhasil
        </Alert>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Kode</TableCell>
              <TableCell>Presentase</TableCell>
              <TableCell>Referral Dari</TableCell>
              <TableCell>Aksi</TableCell>
              <TableCell>Produk Item</TableCell>
              <TableCell>Cek Penggunaan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProductReferral.map(row => {
              const { Issuer, code, percentage } = row;
              const idTable = `${code}`;
              const customer = Issuer[0];
              return (
                <TableRow key={idTable}>
                  <TableCell>{code}</TableCell>
                  <TableCell>{percentage}</TableCell>
                  <TableCell>{customer.name}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => {
                        const payload = {};
                        const queryEdit = URL.makeQueryString(payload);
                        history.push(`referral/ubah${queryEdit}`);
                      }}
                    >
                      <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedCode(code);
                        getProductVoucherByCodeAction({
                          variables: {
                            payload: {
                              code,
                            },
                          },
                        });
                      }}
                    >
                      <DetailsIcon color="primary" fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <LoadMore
          loading={loading}
          refetch={refetch}
          fetchMore={async () => {
            const payload = {
              offset: dataProductReferralLength,
            };
            await fetchMore({
              variables: {
                payload,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  ...prev,
                  productReferral: [...prev.productReferral, ...fetchMoreResult.productReferral],
                };
              },
            });
          }}
        />
      </TableContainer>
    </>
  ) : (
    <Grid direction="column" container alignItems="center">
      <img src={empty} alt="empty_img" className={classes.imageEmpty} />
      <Typography variant="h5" gutterBottom>
        Tidak ada data produk Referral
      </Typography>
      <Button
        onClick={() => {
          history.push('/referral/tambah');
        }}
        color="primary"
        variant="contained"
      >
        Tambah
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageEmpty: {
    width: '500px',
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loadMoreButtonWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  searchWrapper: {
    paddingBottom: theme.spacing(2),
  },
  searchField: {
    paddingRight: theme.spacing(1),
  },
  alertWrapper: {
    marginBottom: theme.spacing(1),
  },
}));

export default function ProductReferral() {
  const classes = useStyles();
  const { setShowError, setErrorMessage } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const [visibilityVoucherByCode, setVisibilityVoucherByCode] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);
  const path = location.pathname.split('/');
  const { search } = location;
  const statusPage = URL.queryString(search, 'status');
  const { register, handleSubmit } = useForm({
    submitFocusError: false,
    nativeValidation: false,
  });

  const [
    getProductReferralByCodeAction,
    {
      data: dataProductReferral,
      networkStatus: networkProductReferral,
      fetchMore: fetchMoreProductReferral,
    },
  ] = useLazyQuery(PRODUCT_VOUCHER_BY_CODE_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setVisibilityVoucherByCode(true);
    },
    onError: error => {
      setShowError(true);
      setErrorMessage(error.message);
    },
  });

  const referralByVoucherInitial = networkProductReferral === NetworkStatus.loading;
  const productByVoucherFetchMore = networkProductReferral === NetworkStatus.fetchMore;

  const { data, error, fetchMore, refetch, networkStatus } = useQuery(PRODUCT_REFERRAL_QUERY, {
    variables: {
      payload: {
        offset: 0,
        limit: 10,
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const loadingInitial = networkStatus === NetworkStatus.loading;
  const onSubmit = async dataOnSubmit => {
    const { product_referral: ProductReferralInput } = dataOnSubmit;
    await refetch({
      payload: {
        code: ProductReferralInput,
      },
    });
  };

  if (loadingInitial || referralByVoucherInitial) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (path[2] === 'tambah') {
    // Uses from component is edit set to false
    return <Add />;
  }

  if (path[2] === 'ubah') {
    return <Edit refetch={refetch} />;
  }

  const dataProductReferralRows = dataProductReferral?.productByVoucherCode?.rows.length;
  const dataProductReferralLength = dataProductReferral?.productByVoucherCode?.count;

  return (
    <>
      <Dialog
        open={visibilityVoucherByCode}
        fullWidth
        onClose={() => {
          setVisibilityVoucherByCode(false);
        }}
      >
        <DialogTitle>Produk</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dataProductReferralLength > 0 &&
              dataProductReferral?.productByVoucherCode?.rows?.map((value, index) => {
                const { Product } = value;
                const singleProduct = Product.rows[0];
                const ProductName = singleProduct.name;
                const ProductDescription = singleProduct.description;
                const ProductUrl = singleProduct.media[0].secure_url;
                return (
                  <Card key={index} style={{ marginBottom: '16px' }}>
                    <CardMedia
                      style={{
                        height: '200px',
                        backgroundSize: 'contain',
                      }}
                      src={ProductUrl}
                      image={ProductUrl}
                    />
                    <CardContent>
                      <Typography variant="h6">{ProductName}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {ProductDescription}
                      </Typography>
                    </CardContent>
                  </Card>
                );
              })}
          </DialogContentText>
          {dataProductReferralLength > 10 && (
            <Box display="flex" justifyContent="center">
              {productByVoucherFetchMore ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={async () => {
                    const payloadFetchMore = {
                      offset: dataProductReferralRows,
                      code: selectedCode,
                    };
                    await fetchMoreProductReferral({
                      variables: {
                        payload: payloadFetchMore,
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        const newRows = fetchMoreResult.productByVoucherCode.rows;
                        const newCount = fetchMoreResult.productByVoucherCode.count;
                        const mergeRow = [...prev.productByVoucherCode.rows, ...newRows];
                        return {
                          productByVoucherCode: {
                            ...prev.productByVoucherCode,
                            rows: mergeRow,
                            count: newCount,
                          },
                        };
                      },
                    });
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Muat Lebih
                </Button>
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>

      <RenderIndex
        setSelectedCode={setSelectedCode}
        getProductReferralByCodeAction={getProductReferralByCodeAction}
        statusPage={statusPage}
        history={history}
        loading={
          networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.fetchMore
        }
        classes={classes}
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        refetch={refetch}
        fetchMore={fetchMore}
        data={data}
      />
    </>
  );
}
