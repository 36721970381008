import gql from 'graphql-tag';

export const statement = `
mutation deleteDebit($id:Int!) {
    deleteDebit(id:$id){
        status                
    }
}
`;

export default gql`
  ${statement}
`;
