import axios from 'axios';

const Image = {};

Image.storeMultiImage = e => {
  const targetFilesCollection = [];
  const targetFiles = e.target.files;
  const targetFilesLength = targetFiles.length;
  for (let i = 0; i < targetFilesLength; i += 1) {
    targetFilesCollection.push(URL.createObjectURL(targetFiles[i]));
  }
  return targetFilesCollection;
};

Image.getHeader = () => {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
};

Image.uploadIgnoreRequestID = async (
  imagePath,
  fileUpload,
  callbackSuccess,
  callbackFailed,
  requestID,
) => {
  const configRequest = Image.getHeader();
  const fileUploadLength = fileUpload.length;
  const dataForm = new FormData();
  for (let i = 0; i < fileUploadLength; i += 1) {
    dataForm.append(`files[${i}]`, fileUpload[i]);
  }
  dataForm.append('image_path', imagePath);
  dataForm.append('request_id', requestID);
  const urlUpload = `${process.env.REACT_APP_SERVICE_MEDIA}/api/v1/cloudinary/ignore-request-id`;
  axios
    .post(urlUpload, dataForm, configRequest)
    .then(async res => {
      callbackSuccess(res);
    })
    .catch(() => {
      callbackFailed();
    });
};

Image.updateUpload = async (
  imagePath,
  fileUpload,
  callbackSuccess,
  callbackFailed,
  publicID,
  requestID,
) => {
  const configRequest = Image.getHeader();
  const fileUploadLength = fileUpload.length;
  const dataForm = new FormData();
  for (let i = 0; i < fileUploadLength; i += 1) {
    dataForm.append(`files[${i}]`, fileUpload[i]);
  }
  dataForm.append('image_path', imagePath);
  dataForm.append('public_id', publicID);
  dataForm.append('request_id', requestID);
  const urlUpload = `${process.env.REACT_APP_SERVICE_MEDIA}/api/v1/cloudinary/update`;
  axios
    .post(urlUpload, dataForm, configRequest)
    .then(async res => {
      callbackSuccess(res);
    })
    .catch(() => {
      callbackFailed();
    });
};

Image.upload = async (imagePath, fileUpload, callbackSuccess, callbackFailed) => {
  const configRequest = Image.getHeader();
  const fileUploadLength = fileUpload.length;
  const dataForm = new FormData();
  for (let i = 0; i < fileUploadLength; i += 1) {
    dataForm.append(`files[${i}]`, fileUpload[i]);
  }
  dataForm.append('image_path', imagePath);
  const urlUpload = `${process.env.REACT_APP_SERVICE_MEDIA}/api/v1/uploadS3`;
  axios
    .post(urlUpload, dataForm, configRequest)
    .then(async res => {
      callbackSuccess(res);
    })
    .catch(() => {
      callbackFailed();
    });
};

Image.delete = async (mediaAsObject, callbackSuccess, callbackFailed) => {
  const urlDelete = `${process.env.REACT_APP_SERVICE_MEDIA}/api/v1/cloudinary/destroy`;
  const dataFormDelete = new FormData();
  dataFormDelete.append('public_id', mediaAsObject[0].public_id);
  const configRequest = Image.getHeader;
  await axios
    .post(urlDelete, dataFormDelete, configRequest)
    .then(async () => {
      callbackSuccess();
    })
    .catch(() => {
      callbackFailed();
    });
};

Image.deleteUploadInformation = async (public_id, callbackSuccess, callbackFailed) => {
  const urlDelete = `${process.env.REACT_APP_SERVICE_MEDIA}/api/v1/delete-upload-information`;
  const configRequest = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  await axios
    .post(urlDelete, { public_id }, configRequest)
    .then(() => {
      callbackSuccess();
    })
    .catch(() => {
      callbackFailed();
    });
};

export default Image;
