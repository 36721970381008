import React, { useEffect } from 'react';
import moment from 'moment';
import { useLazyQuery } from '@apollo/react-hooks';
import SALES_TOTAL from 'graphql/statistic/sales';
import SALES_MAX from 'graphql/statistic/salesMax';
import SALES_MIN from 'graphql/statistic/salesMin';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Money from 'utils/money';
import Error from 'component/error';

function Loading() {
  return (
    <Box mt={1} display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
}

function RenderInfo({ left, right }) {
  let leftFilter = left;
  let rightFilter = right;

  let receiveableFilter = left - right;

  if (left !== 0) {
    leftFilter = Money.formatIDR(parseInt(left, 10).toString());
  }

  if (right !== 0) {
    rightFilter = Money.formatIDR(parseInt(right, 10).toString());
  }
  if (receiveableFilter !== 0) {
    receiveableFilter = Money.formatIDR(parseInt(receiveableFilter, 10).toString());
  }

  return (
    <Box mt={1}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">
          Sub total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {leftFilter}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">
          Down payment
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {rightFilter}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">
          Recieveable
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {receiveableFilter}
        </Typography>
      </Box>
    </Box>
  );
}
export default function Statistic() {
  const [
    getSalesTotal,
    { data: dataSalesTotal, loading: loadingSalesTotal, error: errorSalesTotal },
  ] = useLazyQuery(SALES_TOTAL);

  const [
    getSalesMax,
    { data: dataSalesMax, loading: loadingSalesMax, error: errorSalesMax },
  ] = useLazyQuery(SALES_MAX);

  const [
    getSalesMin,
    { data: dataSalesMin, loading: loadingSalesMin, error: errorSalesMin },
  ] = useLazyQuery(SALES_MIN);

  const todayDate = moment().format('YYYY-MM-DD');

  const ValidationSchema = yup.object().shape({
    start_date: yup.date(),
    end_date: yup
      .date()
      .min(yup.ref('start_date'), 'Tanggal akhir tidak boleh lebih kecil dari tanggal mulai'),
  });
  const { register, handleSubmit, errors, getValues } = useForm({
    validationSchema: ValidationSchema,
    submitFocusError: false,
    nativeValidation: false,
  });

  const onSubmit = async dataStatistic => {
    const { start_date, end_date } = dataStatistic;
    const formatStartDate = moment(start_date).format('YYYY-MM-DD');
    const formatEndDate = moment(end_date).format('YYYY-MM-DD');
    await getSalesTotal({
      variables: {
        payload: {
          start_at: formatStartDate,
          end_at: formatEndDate,
        },
      },
    });
    await getSalesMax({
      variables: {
        payload: {
          start_at: formatStartDate,
          end_at: formatEndDate,
        },
      },
    });
    await getSalesMin({
      variables: {
        payload: {
          start_at: formatStartDate,
          end_at: formatEndDate,
        },
      },
    });
  };

  const subTotalSales = dataSalesTotal?.salesTotal?.[0]?.sub_total || 0;
  const downPaymentSales = dataSalesTotal?.salesTotal?.[0]?.down_payment || 0;

  const subTotalSalesMax = dataSalesMax?.salesMaximum?.[0]?.sub_total || 0;
  const downPaymentSalesMax = dataSalesMax?.salesMaximum?.[0]?.down_payment || 0;

  const subTotalSalesMin = dataSalesMin?.salesMinimum?.[0]?.sub_total || 0;
  const downPaymentSalesMin = dataSalesMin?.salesMinimum?.[0]?.down_payment || 0;

  const startDate = moment(getValues('start_date')).format('DD MMMM YYYY');
  const endDate = moment(getValues('end_date')).format('DD MMMM YYYY');
  const startDateDash = getValues('start_date');
  const endDateDash = getValues('end_date');
  useEffect(() => {
    const init = async () => {
      const formatStartDate = moment(startDateDash).format('YYYY-MM-DD');
      const formatEndDate = moment(endDateDash).format('YYYY-MM-DD');

      await getSalesTotal({
        variables: {
          payload: {
            start_at: formatStartDate,
            end_at: formatEndDate,
          },
        },
      });
      await getSalesMax({
        variables: {
          payload: {
            start_at: formatStartDate,
            end_at: formatEndDate,
          },
        },
      });
      await getSalesMin({
        variables: {
          payload: {
            start_at: formatStartDate,
            end_at: formatEndDate,
          },
        },
      });
    };

    init();
  }, [getSalesTotal, getSalesMax, getSalesMin, startDateDash, endDateDash]);

  if (errorSalesTotal || errorSalesMax || errorSalesMin) {
    return <Error />;
  }

  return (
    <div style={{ paddingTop: '16px' }}>
      <TextField
        /* eslint-disable-next-line camelcase */
        error={errors?.start_date && true}
        /* eslint-disable-next-line camelcase */
        helperText={errors?.start_date?.message}
        size="small"
        fullWidth
        inputRef={register}
        id="start_date"
        name="start_date"
        label="Dari"
        type="date"
        defaultValue={todayDate}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        /* eslint-disable-next-line camelcase */
        error={errors?.end_date && true}
        /* eslint-disable-next-line camelcase */
        helperText={errors?.end_date?.message}
        size="small"
        fullWidth
        inputRef={register}
        id="end_date"
        name="end_date"
        label="Ke"
        type="date"
        defaultValue={todayDate}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <Button
        style={{
          marginTop: '16px',
          marginBottom: '16px',
        }}
        fullWidth
        color="primary"
        variant="contained"
        onClick={handleSubmit(onSubmit)}
      >
        Hitung
      </Button>

      <Typography variant="subtitle1" align="center">
        Menampilkan hasil filter dari {startDate} ke {endDate}
      </Typography>

      <Grid container direction="row" spacing={2} style={{ marginTop: '8px' }}>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Total Penjualan
              </Typography>
              {loadingSalesTotal ? (
                <Loading />
              ) : (
                <RenderInfo left={subTotalSales} right={downPaymentSales} />
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Total Penjualan Max
              </Typography>
              {loadingSalesMax ? (
                <Loading />
              ) : (
                <RenderInfo left={subTotalSalesMax} right={downPaymentSalesMax} />
              )}
            </CardContent>
          </Card>{' '}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Total Penjualan Min
              </Typography>
              {loadingSalesMin ? (
                <Loading />
              ) : (
                <RenderInfo left={subTotalSalesMin} right={downPaymentSalesMin} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
