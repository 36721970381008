import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CREATE_PRODUCT_CATEGORY_INTERNAL from 'graphql/product/category/internal/create';
import Error from 'component/error/index';
import UploadValidation from 'utils/validation/upload';
import ImageUtils from 'utils/Image';
import { PRODUCT_CATEGORY_INTERNAL } from 'constant/upload_path';
import FormProductCategoryInternal from './form';
import Validation from './validation';
import SUCCESS_REDIRECT from './constant';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentWrapper: {
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  media: {
    height: 350,
    backgroundSize: 'contain',
  },
  buttonChangePrice: {
    marginTop: theme.spacing(1),
  },
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  inputFile: {
    display: 'none',
  },
  imagePreview: {
    maxWidth: '100%',
  },
  header: {
    paddingTop: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function AddProductCategoryInternal() {
  const classes = useStyles();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const uploadValidation = UploadValidation('file_upload');
  const AddProductCategoryInternalSchema = yup.object().shape({
    ...Validation,
    ...uploadValidation,
  });
  const { register, handleSubmit, errors } = useForm({
    validationSchema: AddProductCategoryInternalSchema,
    submitFocusError: false,
    nativeValidation: false,
  });

  const [
    addProductCategoryInternalAction,
    { loading, error: errorProductCategryInternal },
  ] = useMutation(CREATE_PRODUCT_CATEGORY_INTERNAL, {
    onCompleted: async () => {
      setLoadingUpload(false);
      window.location.href = SUCCESS_REDIRECT;
    },
    onError: () => {
      setLoadingUpload(false);
    },
  });

  const onSave = async dataAddProduct => {
    const reassign = dataAddProduct;
    setLoadingUpload(true);
    const fileUpload = reassign.file_upload;
    await ImageUtils.upload(
      PRODUCT_CATEGORY_INTERNAL,
      fileUpload,
      async res => {
        const { data } = res;
        delete reassign.file_upload;
        reassign.filename = data.data;
        await addProductCategoryInternalAction({
          variables: {
            payload: reassign,
          },
        });
      },
      () => {
        setLoadingUpload(false);
      },
    );
  };

  if (errorProductCategryInternal) {
    return <Error />;
  }

  return (
    <FormProductCategoryInternal
      isEdit={false}
      classes={classes}
      errors={errors}
      register={register}
      loading={loading || loadingUpload}
      handleSubmit={handleSubmit}
      onSubmit={onSave}
    />
  );
}
