import gql from 'graphql-tag';

export const statement = `
query attendance($payload:ParamQueryAttendance){
  attendance(v:$payload) {
  count
    rows{
      id
      createdAt
      attendance_code {
        code
        shift
        status
      }
    }
  }
}
`;

export default gql`
  ${statement}
`;
