import * as yup from 'yup';

const errorMustBeFilledInMesssage = 'Berat harus diisi. Isi 0 jika tidak membutuhkan';
const RegexNumber = /^\d+(\.\d{1,2})?$/;

const errorMustBeNumberMesssage = 'Berat harus berupa angka';

export default {
  S_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PendekS_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PanjangS_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  M_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PendekM_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PanjangM_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  L_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PendekL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PanjangL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  XL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PendekXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PanjangXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  XXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PendekXXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PanjangXXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  ThreeXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PendekThreeXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PanjangThreeXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  FourthXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PendekFourthXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
  PanjangFourthXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  FifthXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PendekFifthXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PanjangFifthXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  SixthXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PendekSixthXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),

  PanjangSixthXL_weight: yup
    .string()
    .required(errorMustBeFilledInMesssage)
    .matches(RegexNumber, errorMustBeNumberMesssage),
};
