import gql from 'graphql-tag';

export const statement = `
query accountPartnerApplication($payload:ParamAccountPartnerApplication) {
    accountPartnerApplication(v:$payload) {
        count
        rows{
            account{
              name
              phone
              email
            }       
            id                
            id_account
            ktp
            npwp            
            is_verified
            createdAt
            updatedAt                
        }
    }
}
`;

export default gql`
  ${statement}
`;
