import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import CREATE_BANK from 'graphql/bank/create';
import EDIT_BANK from 'graphql/bank/edit';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import * as yup from 'yup';

function AddBank({ open, onClose, refetch, data, setOpenSnackBar }) {
  const RegexNumber = /^[0-9]*$/;
  const validationSchema = yup.object().shape({
    name: yup.string().required('Nama bank harus diisi'),
    code: yup
      .string()
      .required('Kode bank harus diisi')
      .matches(RegexNumber, 'Kode bank harus berupa angka'),
  });
  const [createBankAction] = useMutation(CREATE_BANK, {
    onCompleted: () => {
      setOpenSnackBar({
        visibility: true,
        message: 'Data Bank berhasil di tambahkan',
      });
      refetch();
      onClose();
    },
    onError: errorCreate => {
      setOpenSnackBar({
        visibility: true,
        message: errorCreate.graphQLErrors[0].message,
      });
    },
  });

  const [editBankAction] = useMutation(EDIT_BANK, {
    onCompleted: () => {
      setOpenSnackBar({
        visibility: true,
        message: 'Data Bank berhasil di ubah',
      });
      refetch();
      onClose();
    },
    onError: errorEdit => {
      setOpenSnackBar({
        visibility: true,
        message: errorEdit.graphQLErrors[0].message,
      });
    },
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    submitFocusError: false,
    nativeValidation: false,
  });

  const textFieldProperty = {};
  textFieldProperty.variant = 'outlined';
  textFieldProperty.fullWidth = true;
  textFieldProperty.margin = 'dense';
  textFieldProperty.inputRef = register;

  const bankName = {
    ...textFieldProperty,
    helperText: errors?.name?.message,
    error: errors?.name && true,
    label: 'Nama Bank',
    name: 'name',
  };
  // for edit
  if (data?.name) {
    bankName.defaultValue = data?.name;
  }

  const bankCode = {
    ...textFieldProperty,
    helperText: errors?.code?.message,
    error: errors?.code && true,
    label: 'Kode Bank',
    name: 'code',
  };

  if (data?.code) {
    bankCode.defaultValue = data?.code;
  }

  const onSubmit = async dataBank => {
    const reassign = dataBank;
    if (!data) {
      await createBankAction({
        variables: {
          payload: dataBank,
        },
      });
    } else {
      reassign.id = data.id;
      await editBankAction({
        variables: {
          payload: reassign,
        },
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{!data ? 'Tambah Bank' : 'Edit Bank'}</DialogTitle>
      <DialogContent>
        <TextField {...bankName} />
        <TextField {...bankCode} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained">
          Ya
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          Tidak
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(AddBank);
