import gql from 'graphql-tag';

export const statement = `
 query spk($payload:ParamSpkInput) {
  spk(v:$payload) {
   NoteWorkOrder{
      id
      note
      order_status_id
      OrderStatus{
        name
      }
      order_no
      spk_no      
    }
   Customer{
      name
      phone
    }
    id
    spk_no      
    customer_name
    customer_phone  
    down_payment
    order_id    
    note
    sub_total      
    paid
    is_confirmed
    Invoice{
      spk_no
      billed_amount
      pay_amount
      return_amount
      createdAt
    }    
    Order {      
      OrderChildren {
        S_Qty
        PendekS_Qty
        PanjangS_Qty
        M_Qty
        PendekM_Qty
        PanjangM_Qty
        L_Qty
        PendekL_Qty
        PanjangL_Qty
        XL_Qty
        PendekXL_Qty
        PanjangXL_Qty
      }                                                   
      OrderDelivery {                
        courier_etd
        courier_cost
        courier_name
        courier_service           
        AccountAddress{
            id
            address_name
            province
            city
            subdistrict
            full_address
        }                                                                        
      }         
      OrderPickupSchedule {        
        pickupAt
      }                                                       
      id
      order_no
      Customer {
        email       
      }
      Product {           
        rows {           
            name
        }                        
      }
      Voucher {
        code
        percentage
      }     
      Referral{
        code
        percentage
      }                      
      BankTransferReceipt {     
        media{
          url
        }
      }    
      payment_method
      id_order_status             
      S_Qty
      PendekS_Qty
      PanjangS_Qty
      M_Qty
      PendekM_Qty
      PajangM_Qty
      L_Qty
      PendekL_Qty
      PanjangL_Qty
      XL_Qty
      PendekXL_Qty
      PanjangXL_Qty
      
      XXL_Qty
      PendekXXL_Qty
      PanjangXXL_Qty
      
      ThreeXL_Qty
      PendekThreeXL_Qty
      PanjangThreeXL_Qty
      
      FourthXL_Qty
      PendekFourthXL_Qty
      PanjangFourthXL_Qty
      
      FifthXL_Qty
      PendekFifthXL_Qty
      PanjangFifthXL_Qty
      
      SixthXL_Qty
      PendekSixthXL_Qty
      PanjangSixthXL_Qty                                                            
    }    
    media {
      secure_url
    }
     media_after_edit {      
      url
      public_id
    }      
    media_proof_spk {
      url
      public_id
      secure_url
    }
    createdAt
  }
}`;

export default gql`
  ${statement}
`;
