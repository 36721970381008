import gql from 'graphql-tag';

export const statement = `   
mutation createVariant($payload:ParamCreateVariant!){
    createVariant(v:$payload){
        name
    }
}
`;

export default gql`
  ${statement}
`;
