import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackComponent from 'component/back/index';
import { useQuery } from '@apollo/react-hooks';
import VARIANT from '../../../../graphql/product/variant/get';

function LoadingCenter() {
  return (
    <Grid container direction="row" justify="center" style={{ paddingTop: '16px' }}>
      <CircularProgress />
    </Grid>
  );
}

export default function Form({
  isEdit,
  classes,
  errors,
  register,
  loading,
  handleSubmit,
  onSubmit,
  formState,
  setValue,
}) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const variantID = urlParams.get('variant_id');
  const variantNameQS = urlParams.get('variant_name');
  const payloadVariant = {
    offset: 0,
    limit: 10,
  };
  if (isEdit) {
    payloadVariant.name = variantNameQS;
  }
  const { data, refetch, loading: loadingVariant } = useQuery(VARIANT, {
    fetchPolicy: 'no-cache',
    variables: {
      payload: payloadVariant,
    },
  });

  useEffect(() => {
    register('id_variant');
  }, [register]);

  useEffect(() => {
    if (isEdit) {
      setValue('id_variant', variantID);
      setValue('name', variantNameQS);
    }
  }, []);
  const autoCompleteProperty = {};
  if (isEdit && data?.variant) {
    autoCompleteProperty.defaultValue = data?.variant[0];
  }
  autoCompleteProperty.options = data?.variant;
  autoCompleteProperty.getOptionLabel = option => {
    return option.name;
  };
  autoCompleteProperty.renderInput = params => (
    <TextField {...params} label="variant" variant="outlined" fullWidth />
  );
  autoCompleteProperty.noOptionsText = 'variant tidak di temukan';
  autoCompleteProperty.size = 'small';
  autoCompleteProperty.onChange = (value, newValue) => {
    if (newValue?.name) {
      setValue('id_variant', newValue?.id);
    } else {
      setValue('id_variant', '');
    }
  };
  autoCompleteProperty.onInputChange = event => {
    const variantName = event?.target?.value;
    if (variantName !== 0) {
      refetch({
        payload: {
          name: variantName,
        },
      });
    }
  };
  return (
    <Paper elevation={3} className={classes.wrapper}>
      <Grid container className={classes.header}>
        <ArrowBackComponent history={history} location="/variant-value" />
        <Typography variant="h6" gutterBottom align="center" className={classes.title}>
          Produk Variant Value
        </Typography>
      </Grid>
      <Grid container direction="row" className={classes.contentWrapper} spacing={2}>
        <Grid item xs={12}>
          {!loadingVariant && <Autocomplete {...autoCompleteProperty} />}
          <TextField
            helperText={errors?.name?.message}
            error={errors?.name && true}
            variant="outlined"
            label="Nama"
            name="name"
            fullWidth
            margin="dense"
            inputRef={register}
          />
          <TextField
            helperText="Isi dengan meta value misal #EFEFEF yg mengindiskan itu warna"
            error={errors?.meta_value && true}
            variant="outlined"
            label="Meta Value"
            name="meta_value"
            fullWidth
            margin="dense"
            inputRef={register}
          />
        </Grid>

        {loading ? (
          <LoadingCenter />
        ) : (
          <Button
            disabled={!formState.isValid}
            fullWidth
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
            className={classes.buttonAdd}
          >
            {!isEdit ? ' Tambah' : 'Ubah'}
          </Button>
        )}
      </Grid>
    </Paper>
  );
}
