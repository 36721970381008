import gql from 'graphql-tag';

export const statement = `
mutation activeOrDeactivateAccount($payload:ParamActivateOrDeactivate!){
    activeOrDeactivateAccount(v:$payload){
        status
    }
}
`;

export default gql`
  ${statement}
`;
