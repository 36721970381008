import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import URL from 'utils/url';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  createdAt: {
    marginTop: theme.spacing(1),
  },
}));

export default function PrintFinish() {
  const classes = useStyles();
  const location = useLocation();
  const { search } = location;
  const billedAmount = URL.queryString(search, 'billed_amount');
  const payAmount = URL.queryString(search, 'pay_amount');
  const returnAmount = URL.queryString(search, 'return_amount');
  const spkNo = URL.queryString(search, 'spk_no');
  const createdAt = URL.queryString(search, 'created_at');
  const downPayment = URL.queryString(search, 'down_payment');
  const parseCreatedAt = moment(createdAt)
    .utc(true)
    .format('DD-MM-YYYY HH:mm:ss');
  return (
    <Paper elevation={3} className={classes.wrapper}>
      <Typography variant="h5" align="center" gutterBottom>
        Kwitansi Lunas
      </Typography>
      <Grid container justify="center">
        <Grid container item sm={6}>
          <Grid container item diretion="row">
            <Grid item sm={6} container>
              <Typography variant="h6">No SPK</Typography>
            </Grid>
            <Grid item sm={6} container>
              <Typography variant="h6">{spkNo}</Typography>
            </Grid>
          </Grid>
          <Grid container item diretion="row">
            <Grid item sm={6} container>
              <Typography variant="h6">Total Tagihan</Typography>
            </Grid>
            <Grid item sm={6} container>
              <Typography variant="h6">{billedAmount}</Typography>
            </Grid>
          </Grid>
          <Grid container item diretion="row">
            <Grid item sm={6} container>
              <Typography variant="h6">DP</Typography>
            </Grid>
            <Grid item sm={6} container>
              <Typography variant="h6">{downPayment}</Typography>
            </Grid>
          </Grid>
          <Grid container item diretion="row">
            <Grid item sm={6} container>
              <Typography variant="h6">Bayar</Typography>
            </Grid>
            <Grid item sm={6} container>
              <Typography variant="h6">{payAmount}</Typography>
            </Grid>
          </Grid>
          <Grid container item diretion="row">
            <Grid item sm={6} container>
              <Typography variant="h6">Kembalian</Typography>
            </Grid>
            <Grid item sm={6} container>
              <Typography variant="h6">{returnAmount}</Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" color="textSecondary" className={classes.createdAt}>
            Dibuat pada : {parseCreatedAt}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
