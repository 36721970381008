import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { NetworkStatus } from 'apollo-client';
import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from '@material-ui/lab/Rating';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert/Alert';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import RateReviewIcon from '@material-ui/icons/RateReview';
import EditIcon from '@material-ui/icons/Edit';
import PRODUCT_QUERY from 'graphql/product/index';
import PRODUCT_REVIEW from 'graphql/product/review/get';
import moment from 'moment';
import empty from 'assets/svg/empty.svg';
import URL from 'utils/url';
import { PAGE_LIMIT } from '../../constant';
import Loading from '../../component/loading';
import Error from '../../component/error';
import Request from './request';
import Edit from './edit';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  alertWrapper: {
    marginBottom: theme.spacing(2),
  },
  dateFrom: {
    marginRight: theme.spacing(1),
  },
  dateTo: {
    marginRight: theme.spacing(1),
  },
  imageEmpty: {
    width: '500px',
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paginationWrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const RenderIndex = ({ classes, data, statusProductPage, refetch, loadReview }) => {
  const history = useHistory();
  const dataLength = data.product.count;
  const pageCount = Math.ceil(dataLength / 10);
  return dataLength !== 0 ? (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Produk</Typography>
        <Button
          onClick={() => {
            history.push('/produk/ajukan');
          }}
          color="primary"
          variant="contained"
        >
          Tambah
        </Button>
      </div>
      {statusProductPage === 'berhasil' && (
        <Alert
          onClose={() => {
            history.push('/produk');
          }}
          variant="filled"
          severity="success"
          className={classes.alertWrapper}
        >
          Penambahan produk berhasil
        </Alert>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nama Produk</TableCell>
              <TableCell>Deskripsi</TableCell>
              <TableCell>Tipe</TableCell>
              <TableCell>Kategori</TableCell>
              <TableCell>Material</TableCell>
              <TableCell>Warna</TableCell>
              <TableCell>Tanggal Tambah</TableCell>
              <TableCell>Tanggal Ubah</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.product.rows.map(row => {
              const {
                createdAt,
                updatedAt,
                material,
                color,
                name,
                description,
                category,
                published,
                id,
                type,
                S_Price,
                PendekS_Price,
                PanjangS_Price,
                M_Price,
                PendekM_Price,
                PanjangM_Price,
                L_Price,
                PendekL_Price,
                PanjangL_Price,
                XL_Price,
                PendekXL_Price,
                PanjangXL_Price,
                XXL_Price,
                PendekXXL_Price,
                PanjangXXL_Price,
                ThreeXL_Price,
                PendekThreeXL_Price,
                PanjangThreeXL_Price,
                FourthXL_Price,
                PendekFourthXL_Price,
                PanjangFourthXL_Price,
                FifthXL_Price,
                PendekFifthXL_Price,
                PanjangFifthXL_Price,
                SixthXL_Price,
                PendekSixthXL_Price,
                PanjangSixthXL_Price,
                media,
                Product_Child,
              } = row;

              const {
                weight: {
                  PendekS_weight,
                  S_weight,
                  PanjangS_weight,

                  M_weight,
                  PendekM_weight,
                  PanjangM_weight,

                  L_weight,
                  PendekL_weight,
                  PanjangL_weight,

                  XL_weight,
                  PendekXL_weight,
                  PanjangXL_weight,

                  XXL_weight,
                  PendekXXL_weight,
                  PanjangXXL_weight,

                  ThreeXL_weight,
                  PendekThreeXL_weight,
                  PanjangThreeXL_weight,

                  FourthXL_weight,
                  PendekFourthXL_weight,
                  PanjangFourthXL_weight,

                  FifthXL_weight,
                  PendekFifthXL_weight,
                  PanjangFifthXL_weight,

                  SixthXL_weight,
                  PendekSixthXL_weight,
                  PanjangSixthXL_weight,

                  PendekS_Children_weight,
                  S_Children_weight,
                  PanjangS_Children_weight,
                  PendekM_Children_weight,
                  M_Children_weight,
                  PanjangM_Children_weight,

                  PendekL_Children_weight,
                  L_Children_weight,
                  PanjangL_Children_weight,

                  PendekXL_Children_weight,
                  XL_Children_weight,
                  PanjangXL_Children_weight,
                },
              } = row;

              const materialArray = material.map(value => {
                const {
                  product_material: { name: nameMaterial },
                } = value;

                return nameMaterial;
              });

              const materialIDArray = material.map(value => {
                const {
                  product_material: { id: idMaterial },
                } = value;

                return idMaterial;
              });

              const colorArray = color.map(value => {
                const {
                  product_color: { name: nameColor },
                } = value;

                return nameColor;
              });

              const colorIDArray = color.map(value => {
                const {
                  product_color: { id: idColor },
                } = value;

                return idColor;
              });

              return (
                <TableRow key={id}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{description}</TableCell>
                  <TableCell>{type}</TableCell>
                  <TableCell>{category || '-'}</TableCell>
                  <TableCell>{materialArray.join(',')}</TableCell>
                  <TableCell>{colorArray.join(',')}</TableCell>
                  <TableCell>{moment(createdAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell>{moment(updatedAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => {
                        const payload = {
                          id: row.id,
                          type,
                          name,
                          category,
                          description,
                          S_Price,
                          PendekS_Price,
                          PanjangS_Price,
                          M_Price,
                          PendekM_Price,
                          PanjangM_Price,
                          L_Price,
                          PendekL_Price,
                          PanjangL_Price,
                          XL_Price,
                          PendekXL_Price,
                          PanjangXL_Price,
                          XXL_Price,
                          PendekXXL_Price,
                          PanjangXXL_Price,
                          ThreeXL_Price,
                          PendekThreeXL_Price,
                          PanjangThreeXL_Price,
                          FourthXL_Price,
                          PendekFourthXL_Price,
                          PanjangFourthXL_Price,
                          FifthXL_Price,
                          PendekFifthXL_Price,
                          PanjangFifthXL_Price,
                          SixthXL_Price,
                          PendekSixthXL_Price,
                          PanjangSixthXL_Price,
                          S_Children_Price: Product_Child?.S_Children_Price,
                          PendekS_Children_Price: Product_Child?.PendekS_Children_Price,
                          PanjangS_Children_Price: Product_Child?.PanjangS_Children_Price,
                          M_Children_Price: Product_Child?.M_Children_Price,
                          PendekM_Children_Price: Product_Child?.PendekM_Children_Price,
                          PanjangM_Children_Price: Product_Child?.PanjangM_Children_Price,
                          L_Children_Price: Product_Child?.L_Children_Price,
                          PendekL_Children_Price: Product_Child?.PendekL_Children_Price,
                          PanjangL_Children_Price: Product_Child?.PanjangL_Children_Price,
                          XL_Children_Price: Product_Child?.XL_Children_Price,
                          PendekXL_Children_Price: Product_Child?.PendekXL_Children_Price,
                          PanjangXL_Children_Price: Product_Child?.PanjangXL_Children_Price,
                          published,
                          media: JSON.stringify(media),
                          PendekS_weight,
                          S_weight,
                          PanjangS_weight,

                          M_weight,
                          PendekM_weight,
                          PanjangM_weight,

                          L_weight,
                          PendekL_weight,
                          PanjangL_weight,

                          XL_weight,
                          PendekXL_weight,
                          PanjangXL_weight,

                          XXL_weight,
                          PendekXXL_weight,
                          PanjangXXL_weight,

                          ThreeXL_weight,
                          PendekThreeXL_weight,
                          PanjangThreeXL_weight,

                          FourthXL_weight,
                          PendekFourthXL_weight,
                          PanjangFourthXL_weight,

                          FifthXL_weight,
                          PendekFifthXL_weight,
                          PanjangFifthXL_weight,

                          SixthXL_weight,
                          PendekSixthXL_weight,
                          PanjangSixthXL_weight,
                          material: materialIDArray,
                          color: colorIDArray,

                          PendekS_Children_weight,
                          S_Children_weight,
                          PanjangS_Children_weight,
                          PendekM_Children_weight,
                          M_Children_weight,
                          PanjangM_Children_weight,

                          PendekL_Children_weight,
                          L_Children_weight,
                          PanjangL_Children_weight,

                          PendekXL_Children_weight,
                          XL_Children_weight,
                          PanjangXL_Children_weight,
                        };
                        const queryEdit = URL.makeQueryString(payload);
                        history.push(`produk/ubah${queryEdit}`);
                      }}
                    >
                      <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={async () => {
                        await loadReview({
                          variables: {
                            payload: {
                              id_product: id,
                            },
                          },
                        });
                      }}
                    >
                      <RateReviewIcon color="primary" fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Pagination
          className={classes.paginationWrapper}
          size="large"
          count={pageCount}
          color="primary"
          onChange={(event, value) => {
            refetch({
              payload: {
                limit: PAGE_LIMIT,
                offset: value * PAGE_LIMIT - PAGE_LIMIT,
              },
            });
          }}
        />
      </TableContainer>
    </>
  ) : (
    <Grid direction="column" container alignItems="center">
      <img src={empty} alt="empty_img" className={classes.imageEmpty} />
      <Typography variant="h5" gutterBottom>
        Tidak ada data absensi yang di temukan
      </Typography>
      <Button
        onClick={() => {
          history.push('/produk/tambah');
        }}
        color="primary"
        variant="contained"
      >
        Tambah produk
      </Button>
    </Grid>
  );
};

export default function Product() {
  const classes = useStyles();
  const [visibilityModalProductReview, setVisibilityModalProductReview] = useState(false);
  const location = useLocation();
  const path = location.pathname.split('/');
  const { search } = location;
  const statusProductPage = URL.queryString(search, 'status');
  const [
    getProductInternalReviewAction,
    {
      data: dataReview,
      error: errorReview,
      fetchMore: fetchMoreProductReview,
      networkStatus: networkStatusProductReview,
    },
  ] = useLazyQuery(PRODUCT_REVIEW, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      setVisibilityModalProductReview(true);
    },
  });

  const { data, error, loading, refetch } = useQuery(PRODUCT_QUERY, {
    fetchPolicy: 'no-cache',
  });
  const productReviewFetchMore = networkStatusProductReview === NetworkStatus.fetchMore;
  const productReviewInitial = networkStatusProductReview === NetworkStatus.loading;

  if (statusProductPage === 'berhasil') {
    refetch();
  }

  if (error || errorReview) {
    return <Error />;
  }

  if (loading || productReviewInitial) {
    return <Loading />;
  }

  if (path[2] === 'ajukan') {
    return <Request />;
  }

  if (path[2] === 'ubah') {
    return <Edit refetch={refetch} />;
  }

  const dataLength = dataReview?.productInternalRating?.length;

  return (
    <>
      <RenderIndex
        classes={classes}
        data={data}
        refetch={refetch}
        statusProductPage={statusProductPage}
        loadReview={getProductInternalReviewAction}
      />

      <Dialog
        onClose={() => {
          setVisibilityModalProductReview(false);
        }}
        open={visibilityModalProductReview}
        fullWidth
      >
        <DialogTitle>Review</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <List>
              {dataLength === 0 && <div>Review tidak ada</div>}
              {dataLength > 0 &&
                dataReview?.productInternalRating?.map(value => {
                  const { rating, content, reviewer, createdAt } = value;
                  return (
                    <>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <>
                              <Typography color="textSecondary" component="span" variant="caption">
                                {reviewer[0].name}-{moment(createdAt).format('DD MMMM YYYY')}
                              </Typography>
                            </>
                          }
                          secondary={
                            <>
                              <div>
                                <Rating name="size-small" defaultValue={rating} disabled />
                              </div>
                              <div>
                                <Typography color="textSecondary" component="span" variant="body2">
                                  {content}
                                </Typography>
                              </div>
                            </>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  );
                })}
            </List>
          </DialogContentText>

          {dataLength > 0 && (
            <Box display="flex" justifyContent="center" pt={1}>
              {productReviewFetchMore ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={async () => {
                    await fetchMoreProductReview({
                      variables: {
                        payload: {
                          offset: dataLength,
                        },
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        return {
                          ...prev,
                          productInternalRating: [
                            ...prev.productInternalRating,
                            ...fetchMoreResult.productInternalRating,
                          ],
                        };
                      },
                    });
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Muat Lebih
                </Button>
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
