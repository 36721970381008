import gql from 'graphql-tag';

export const statement = `
 mutation updateSpk($payload:UpdateSpkInput!) {
    updateSpk(v:$payload) {
        status
    }
}
`;

export default gql`
  ${statement}
`;
