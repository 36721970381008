import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PRODUCT_CATEGORY_WEBSITE from 'graphql/product/category/website/index';
import Loading from 'component/loading';
import Error from 'component/error';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import Alert from '@material-ui/lab/Alert/Alert';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { NetworkStatus } from 'apollo-client';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import URL from '../../../../utils/url';
import LoadMore from '../../../../component/LoadMore';
import empty from '../../../../assets/svg/empty.svg';
import Add from './add';
import Edit from './edit';
import EDIT_PRODUCT_WEBSITE_CATEGORY from '../../../../graphql/product/category/website/update';

const RenderIndex = ({
  loadingPublish,
  editProductWebsiteCategory,
  register,
  handleSubmit,
  onSubmit,
  classes,
  data,
  fetchMore,
  loading,
  refetch,
  history,
  statusPage,
}) => {
  const dataProductCategoryWebsiteValue = data.productCategoryWebsite;
  const dataProductCategoryWebsiteValueLength = dataProductCategoryWebsiteValue.length;
  return dataProductCategoryWebsiteValueLength !== 0 ? (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="h5">Produk Kategori Website</Typography>
        <Button
          onClick={() => {
            history.push('/category-website/tambah');
          }}
          color="primary"
          variant="contained"
        >
          Tambah
        </Button>
      </div>

      <Grid container justify="center" className={classes.searchWrapper}>
        <TextField
          className={classes.searchField}
          size="small"
          variant="outlined"
          inputRef={register}
          name="variant_value"
          label="Cari kategori website"
        />
        <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
          Cari
        </Button>
      </Grid>
      {statusPage === 'berhasil' && (
        <Alert
          onClose={() => {
            history.push('/category-website');
          }}
          variant="filled"
          severity="success"
          className={classes.alertWrapper}
        >
          Aksi pada produk kategori website berhasil
        </Alert>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nama</TableCell>
              <TableCell>Publish</TableCell>
              <TableCell>Gambar</TableCell>
              <TableCell>Tanggal Tambah</TableCell>
              <TableCell>Tanggal Ubah</TableCell>
              <TableCell>Ubah</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProductCategoryWebsiteValue.map(row => {
              const { name, id, media, createdAt, updatedAt, publish } = row;
              const { secure_url } = media[0];
              const idTable = `${id}`;
              return (
                <TableRow key={idTable}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={loadingPublish}
                          onChange={event => {
                            editProductWebsiteCategory({
                              variables: {
                                payload: {
                                  publish: event.target.checked ? 'Y' : 'N', // Assuming 'N' is the opposite state,
                                  id,
                                },
                              },
                            });
                          }}
                          checked={publish === 'Y'}
                          color="primary"
                        />
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <img
                      src={secure_url}
                      style={{ width: '300px' }}
                      alt="image_category_product_website"
                    />
                  </TableCell>
                  <TableCell>{moment(createdAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell>{moment(updatedAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => {
                        const payload = {
                          id,
                          name,
                          publish,
                          media: JSON.stringify(media),
                        };
                        const queryEdit = URL.makeQueryString(payload);
                        history.push(`category-website/ubah${queryEdit}`);
                      }}
                    >
                      <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <LoadMore
          loading={loading}
          refetch={refetch}
          fetchMore={async () => {
            const payload = {
              offset: dataProductCategoryWebsiteValueLength,
            };
            await fetchMore({
              variables: {
                payload,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  ...prev,
                  productCategoryWebsite: [
                    ...prev.productCategoryWebsite,
                    ...fetchMoreResult.productCategoryWebsite,
                  ],
                };
              },
            });
          }}
        />
      </TableContainer>
    </>
  ) : (
    <Grid direction="column" container alignItems="center">
      <img src={empty} alt="empty_img" className={classes.imageEmpty} />
      <Typography variant="h5" gutterBottom>
        Tidak ada data produk kategori website
      </Typography>
      <Button
        onClick={() => {
          history.push('/category-website/tambah');
        }}
        color="primary"
        variant="contained"
      >
        Tambah
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  headerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageEmpty: {
    width: '500px',
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loadMoreButtonWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  searchWrapper: {
    paddingBottom: theme.spacing(2),
  },
  searchField: {
    paddingRight: theme.spacing(1),
  },
  alertWrapper: {
    marginBottom: theme.spacing(1),
  },
}));

export default function ProductCategoryWebsite() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split('/');
  const { data, error, fetchMore, refetch, networkStatus } = useQuery(PRODUCT_CATEGORY_WEBSITE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      payload: {
        offset: 0,
        limit: 10,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const initialLoading = networkStatus === NetworkStatus.loading;
  const fetchMoreLoading = networkStatus === NetworkStatus.fetchMore;
  const refetchLoading = networkStatus === NetworkStatus.refetch;

  const { search } = location;
  const statusPage = URL.queryString(search, 'status');

  const [editProductWebsiteCategory, { loading }] = useMutation(EDIT_PRODUCT_WEBSITE_CATEGORY, {
    refetchQueries: [
      { query: PRODUCT_CATEGORY_WEBSITE }, // Add more queries if necessary
    ],
    awaitRefetchQueries: true, // Ensures mutation completion before refetching
    onCompleted: () => {},
    onError: () => {},
  });

  const { register, handleSubmit } = useForm({
    submitFocusError: false,
    nativeValidation: false,
  });

  if (initialLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  const onSubmit = async dataOnSubmit => {
    const { variant_value: variantValue } = dataOnSubmit;
    await refetch({
      payload: {
        name: variantValue,
      },
    });
  };

  if (path[2] === 'tambah') {
    // Uses from component is edit set to false
    return <Add />;
  }

  if (path[2] === 'ubah') {
    return <Edit refetch={refetch} />;
  }

  return (
    <RenderIndex
      editProductWebsiteCategory={editProductWebsiteCategory}
      loadingPublish={loading}
      statusPage={statusPage}
      history={history}
      loading={fetchMoreLoading || refetchLoading}
      classes={classes}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      refetch={refetch}
      fetchMore={fetchMore}
      data={data}
    />
  );
}
