import gql from 'graphql-tag';

export const statement = `
query walletHistory($payload:ParamWalletHistoryQuery){
  walletHistory(v:$payload){
    count
    rows{  
      amount
      createdAt
      updatedAt
      type
      description
    }
  }
}
`;

export default gql`
  ${statement}
`;
