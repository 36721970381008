import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  button: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

function LoadMore({ refetch, fetchMore, loading, showFetchMore = true }) {
  const classes = useStyles();
  return (
    <Grid container justify="center">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={async () => {
              await refetch();
            }}
          >
            Muat Ulang
          </Button>
          {showFetchMore && (
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={async () => {
                await fetchMore();
              }}
            >
              Muat lebih banyak
            </Button>
          )}
        </>
      )}
    </Grid>
  );
}

export default React.memo(LoadMore);
