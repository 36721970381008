import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import * as yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import ME_QUERY from 'graphql/account/me';
import UPDATE_PASSWORD_ACCOUNT from 'graphql/account/password/update';
import UPDATE_ACCOUNT from 'graphql/account/update';
import Error from '../../component/error';
import Loading from '../../component/loading';
import { AUTHENTICATED } from '../../constant/index';

const useStyles = makeStyles(theme => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  profileWrapper: {
    marginTop: theme.spacing(2),
  },
  buttonWrapper: {
    marginTop: theme.spacing(1),
  },
  form: {
    width: '100%',
  },
  formUpdateAccount: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  btnChangePassword: {
    marginTop: theme.spacing(2),
  },
  alertWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  accountRoleWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
}));

export default function Profil() {
  const classes = useStyles();
  const [showAlert, setShowAlert] = useState({});
  const [accountRole, setAccountRole] = useState([]);
  const updateAccountSchema = yup.object().shape({
    name: yup.string().required('Nama harus diisi'),
    phone: yup
      .string()
      .required('Nomor telepon harus diisi')
      .min(8, 'Nomor telepon minimal 8 karakter'),
  });

  const updatePasswordSchema = yup.object().shape({
    old_password: yup.string().required('Password lama harus diisi'),
    new_password: yup
      .string()
      .required('Kata sandi baru harus diisi')
      .min(8, 'Kata sandi baru minimal 8 karakter'),
    new_confirm_password: yup
      .string()
      .required('Konfirmasi kata sandi harus diisi')
      .oneOf([yup.ref('new_password')], 'Kata sandi tidak sesuai'),
  });

  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: updatePasswordSchema,
    submitFocusError: false,
    nativeValidation: false,
  });

  const {
    register: registerUpdateAccount,
    handleSubmit: handleSubmitUpdateAccount,
    errors: errorsUpdateAccount,
    setValue: setValueUpdateAccount,
    getValues: getValuesUpdateAccount,
  } = useForm({
    validationSchema: updateAccountSchema,
    submitFocusError: false,
    nativeValidation: false,
  });

  const [updateAccountAction, { loading: loadingUpdateAccount }] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: () => {
      setValueUpdateAccount('name', '');
      setValueUpdateAccount('phone', '');
    },
    onError: () => {
      setShowAlert({
        type: 'error',
        message: 'Perbaharui akun gagal',
      });
    },
  });

  const [updatePasswordAccountAction, { loading: loadingUpdatePassword }] = useMutation(
    UPDATE_PASSWORD_ACCOUNT,
    {
      onCompleted: () => {
        setShowAlert({
          type: 'success',
          message: 'Ubah kata sandi berhasil',
        });
        setValue('old_password', '');
        setValue('new_password', '');
        setValue('new_confirm_password', '');
      },
      onError: () => {
        setShowAlert({
          type: 'error',
          message: 'Ubah kata sandi gagal',
        });
      },
    },
  );
  const { data, error, loading } = useQuery(ME_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const onSubmitUpdateAccount = async dataUpdateAccount => {
    await updateAccountAction({
      variables: {
        payload: dataUpdateAccount,
      },
    });
  };

  const onSubmitUpdatePassword = async dataUpdatePassword => {
    await updatePasswordAccountAction({
      variables: {
        payload: dataUpdatePassword,
      },
    });
  };
  useEffect(() => {
    setValueUpdateAccount('name', 'Faris');
    setValueUpdateAccount('phone', '123');
    const token = localStorage.getItem(AUTHENTICATED);
    const tokenAsObject = JSON.parse(token);
    setAccountRole(tokenAsObject.accountRole);
  }, []);
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const { me } = data;

  const errorOldPassword = errors?.old_password;
  const errorNewPassword = errors?.new_password;
  const errorConfirmPassword = errors?.new_confirm_password;

  const errorUpdateAccountName = errorsUpdateAccount?.name;
  const errorUpdateAccountPhone = errorsUpdateAccount?.phone;

  return (
    <>
      <Grid container direction="column" alignItems="center" className={classes.profileWrapper}>
        <Avatar alt="profile image" src="/static/images/avatar/1.jpg" className={classes.large} />
        <Typography variant="h6">{me.name}</Typography>
        <Typography color="textSecondary">{me.email}</Typography>
        <Typography color="textSecondary">{me.phone}</Typography>
        <div className={classes.accountRoleWrapper}>
          {accountRole.map(value => {
            const { role } = value;
            return <Chip color="primary" label={role} />;
          })}
        </div>
        <Button
          className={classes.buttonWrapper}
          color="primary"
          variant="contained"
          onClick={() => {
            localStorage.removeItem(AUTHENTICATED);
            window.location.href = '/';
          }}
        >
          Keluar
        </Button>
      </Grid>
      <Grid>
        {Object.keys(showAlert).length > 0 && (
          <Alert
            onClose={() => {
              setShowAlert({});
            }}
            variant="filled"
            severity={showAlert.type}
            className={classes.alertWrapper}
          >
            {showAlert.message}
          </Alert>
        )}
        <FormControl className={classes.form}>
          <Typography variant="h6">Ubah Sandi</Typography>
          <TextField
            error={errorOldPassword && true}
            helperText={errorOldPassword?.message}
            inputRef={register}
            margin="dense"
            name="old_password"
            size="small"
            type="password"
            label="Kata Sandi Lama"
            variant="outlined"
          />
          <TextField
            error={errorNewPassword && true}
            helperText={errorNewPassword?.message}
            inputRef={register}
            margin="dense"
            name="new_password"
            size="small"
            type="password"
            label="Kata Sandi Baru"
            variant="outlined"
          />
          <TextField
            error={errorConfirmPassword && true}
            helperText={errorConfirmPassword?.message}
            inputRef={register}
            margin="dense"
            name="new_confirm_password"
            size="small"
            type="password"
            label="Konfirmasi Kata Sandi Baru"
            variant="outlined"
          />
          <Button
            onClick={handleSubmit(onSubmitUpdatePassword)}
            className={classes.btnChangePassword}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            id="btn-change-password"
          >
            {loadingUpdatePassword ? <CircularProgress color="secondary" /> : 'Ubah Kata Sandi'}
          </Button>
        </FormControl>

        <FormControl className={classes.formUpdateAccount}>
          <Typography variant="h6">Perbaharui Akun</Typography>
          <TextField
            value={getValuesUpdateAccount('name')}
            error={errorUpdateAccountName && true}
            helperText={errorUpdateAccountName?.message}
            inputRef={registerUpdateAccount}
            margin="dense"
            name="name"
            size="small"
            type="text"
            label="Nama"
            variant="outlined"
          />
          <TextField
            value={getValuesUpdateAccount('phone')}
            error={errorUpdateAccountPhone && true}
            helperText={errorUpdateAccountPhone?.message}
            inputRef={registerUpdateAccount}
            margin="dense"
            name="phone"
            size="small"
            type="text"
            label="No. Telepon"
            variant="outlined"
          />
          <Button
            onClick={handleSubmitUpdateAccount(onSubmitUpdateAccount)}
            className={classes.btnChangePassword}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            id="btn-update-account"
          >
            {loadingUpdateAccount ? <CircularProgress color="secondary" /> : 'Perbaharui Akun'}
          </Button>
        </FormControl>
      </Grid>
    </>
  );
}
