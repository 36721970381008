import gql from 'graphql-tag';

export const statement = `  
mutation updateVariantValue($payload:ParamUpdateVariantValue!) {
 updateVariantValue(v:$payload) {
    id
    id_variant
   name
 }
}
`;

export default gql`
  ${statement}
`;
