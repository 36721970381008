import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import empty from '../../assets/svg/empty.svg';

const useStyles = makeStyles(theme => ({
  emptyWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  imageEmpty: {
    maxWidth: '100%',
  },
}));

function TableEmpty({ text }) {
  const classes = useStyles();
  return (
    <div className={classes.emptyWrapper}>
      <img src={empty} alt="empty_img" className={classes.imageEmpty} />
      <Typography variant="h6" align="center">
        {text}
      </Typography>
    </div>
  );
}

export default React.memo(TableEmpty);
