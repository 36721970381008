import gql from 'graphql-tag';

export const statement = `   
  mutation updateProductCategoryInternal($payload: ParamProductCategory!) {
    updateProductCategoryInternal(v: $payload){
        status                
    }
  } 
`;

export default gql`
  ${statement}
`;
