import gql from 'graphql-tag';

export const statement = `
query productWebsite($payload:ParamProductWebsite){
  productWebsite(v:$payload) {
    id
    name
    publish
    description
    height
    weight
    video
    wide
    width
    weight_type
    product_category{
        id
        name
        image
    }                    
  }
}
`;

export default gql`
  ${statement}
`;
