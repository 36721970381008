import gql from 'graphql-tag';

export const statement = `
query order($payload: ParamOrderInput) {
  order(v: $payload) {       
    pickup_options
    OrderDelivery {         
            courier_etd
            courier_cost
            courier_name
            courier_service 
            AccountAddress{
                    id
                    address_name
                    province
                    city
                    subdistrict
                    full_address
            }
    }
    OrderPickupSchedule {
            id_order
            pickupAt
    }
  }
}`;

export default gql`
  ${statement}
`;
