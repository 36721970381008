import gql from 'graphql-tag';

export const statement = `
mutation createDebit($payload:ParamCreateDebit!) {
    createDebit(v:$payload){
        amount
        note
        installment_time
        createdAt
    }
}
`;

export default gql`
  ${statement}
`;
